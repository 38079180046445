import React, { useEffect, useState } from "react";
import useAppStore from "../../../appStore";
import CustomError from "../../../shared/components/page/popup-dialog/CustomError";
import CustomLoadingDots from "../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { Typography } from "@mui/material";
import UserItemsTable from "./UserItemsTable";
import { CasesService } from "../../../shared/services/cases/casesService";

const UserItemsList = ( {setStep} ) => {
  const { loggedInUser, appImages } = useAppStore();
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const service = new CasesService();

  useEffect(() => {
      setIsLoading(true);
      setError("");
      service.getCreatedCases(loggedInUser.organization?.id, loggedInUser?.employee.id)
        .then(items => {
          const sortedItems = items.sort((a, b) => new Date(b.create_date).getTime() - new Date(a.create_date).getTime());
          setItems(sortedItems);
        })
        .catch(error => {
          setError(error instanceof Error ? error.message : String(error));
        })
        .finally(() => {
          setIsLoading(false);
        });
  }, [loggedInUser]);

  return (
    <div>
      {error && <CustomError error={error} />}
      {isLoading && <CustomLoadingDots />}
      {!error && !isLoading && (
        <UserItemsTable items={items}/>
      )}
      {items.length === 0 && (
        <Typography sx={{ justifyContent: 'center', textAlign: 'center', fontSize: '1.1rem', fontWeight: 'bold'}}>No items found</Typography>
      )}
    </div>
  );
};

export default UserItemsList;

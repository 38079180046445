import React, { useEffect, useState } from "react";
import { Box, Button, Step, StepLabel, Stepper } from "@mui/material";
import { WorkItemsService } from "../../../../shared/services/work-items/workItemsService";
import useAppStore from "../../../../appStore";
import { useWorkItem } from "../../context/workItemContext";
import EmployeeQuickSelect from "../../../../shared/components/forms/widgets/selection-forms/EmployeeQuickSelect";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import BadgeStepIcon from "./components/table/components/other/BadgeStepIcon";
import SortOptions from "./components/table/components/other/SortOptions";
import WorkItemTable from "./components/table/WorkItemTable";

const stepLabels = ['Unassigned', 'In Progress', 'Closed'];
const statusKeys = ['pending_count', 'assigned_count', 'closed_count'];

const WorkItemList = ({ onEdit, onDelete }) => {
    const service = new WorkItemsService();
    const { workItems, setWorkItems, sortBy, setSortBy, refresh } = useWorkItem();
    const { loggedInUser, selectedLocation } = useAppStore();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [isEmployeeDialogOpen, setEmployeeDialogOpen] = useState(false);
    const [workItemToEdit, setWorkItemToEdit] = useState(null);
    const [activeStep, setActiveStep] = useState(() => parseInt(localStorage.getItem("activeStep") || '0', 10));
    const [statusCounts, setStatusCounts] = useState({ pending_count: 0, assigned_count: 0, closed_count: 0 });
    const [page, setPage] = useState(0);


    useEffect(() => {
        const fetchItems = async () => {
            const limit = 10;
            const offset = page * limit;

            if (page === 0) {
                setWorkItems([])
            }

            setIsLoading(true);
            try {
                const [statusCounts, items] = await Promise.all([
                    service.getWorkItemStatusCounts(loggedInUser.organization.id, selectedLocation.id),
                    service.getWorkItems(loggedInUser.organization?.id, selectedLocation.id, activeStep, limit, offset)
                ]);
                setStatusCounts(statusCounts);
                const sortedItems = sortItems(items, sortBy);
                setWorkItems(prevItems => [...prevItems, ...sortedItems]);
            } catch (error) {
                setError(error instanceof Error ? error.message : String(error));
            } finally {
                setIsLoading(false);
            }
        };

        if (selectedLocation.id !== 0) {
            fetchItems().then();
        }

    }, [activeStep, refresh, sortBy, selectedLocation, page]);

    const handleStepChange = (step: number) => {
        setPage(0);
        setActiveStep(step);
        localStorage.setItem("activeStep", step.toString());
    };

    const handleShowMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handleCloseDialog = () => {
        setEmployeeDialogOpen(false);
        setWorkItemToEdit(null);
    };

    const handleAssignClicked = (workItem) => {
        setWorkItemToEdit(workItem);
        setEmployeeDialogOpen(true);
    };

    const handleSaveEmployees = async (workItem, employees) => {
        workItem.assigned_to_employees = employees;
        try {
            await service.updateWorkItem(workItem);
            handleCloseDialog();
        } catch (e) {
            console.error('Error in handleSaveEmployees:', e);
        }
    };

    const sortItems = (items, criterion) => {
        switch (criterion) {
            case 'create_date':
                return [...items].sort((a, b) => new Date(b.create_date).getTime() - new Date(a.create_date).getTime());
            case 'urgency':
                return [...items].sort((a, b) => b.urgency - a.urgency);
            default:
                return items;
        }
    };


    if (error) return <CustomError error={error} />;

    return (
      <div style={{ width: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', mt: 4 }}>
              <Stepper activeStep={activeStep} alternativeLabel sx={{ flex: 1, mt: 3, width: '100%' }}>
                  {stepLabels.map((label, index) => (
                    <Step key={label} onClick={() => handleStepChange(index)}>
                        <StepLabel StepIconComponent={props => (
                          <BadgeStepIcon {...props} count={statusCounts[statusKeys[index]]}
                                         active={index === activeStep} />
                        )}>
                            {label}
                        </StepLabel>
                    </Step>
                  ))}
              </Stepper>
          </Box>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  maxWidth: '1200px',
                  justifyContent: 'center'
              }}>
                  <SortOptions sortBy={sortBy} setSortBy={setSortBy} sortItems={sortItems} workItems={workItems}
                               setWorkItems={setWorkItems} />
                  {isLoading && workItems.length === 0 ? (
                    <div style={{ marginTop: '15px' }}>
                        <CustomLoadingDots />
                    </div>
                  ) : (
                    <>
                        <WorkItemTable workItems={workItems} onEdit={onEdit} onDelete={onDelete}
                                       handleAssign={handleAssignClicked} />
                        {workItems.length !== statusCounts[statusKeys[activeStep]] &&
                          <div style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row"
                          }}>
                              <Button
                                sx={{
                                    width: '100%',
                                    padding: '12px',
                                    marginBottom: '20px',
                                    fontSize: '1.2rem',
                                    fontWeight: 'bold',
                                    color: '#ffffff',
                                    background: 'linear-gradient(45deg, #15aa78 30%, #5ec3a1 90%)',
                                    borderRadius: '5px',
                                    boxShadow: '0 3px 5px 2px rgba(.5, .5, .5, .3)',
                                    textTransform: 'none',
                                }}
                                onClick={handleShowMore}
                              >
                                  Load More
                              </Button>
                          </div>
                        }
                        {isEmployeeDialogOpen && (
                          <EmployeeQuickSelect open={isEmployeeDialogOpen} onClose={handleCloseDialog}
                                               handleSave={handleSaveEmployees} item={workItemToEdit} />
                        )}
                    </>
                  )}
              </div>
          </div>
      </div>

    );
};

export default WorkItemList;




import { LocationSerializer } from "./locationSerializer";
import { Contact } from "../contact.model";
import { BaseSerializer } from "./baseSerializer";
import { EmployeeSerializer } from "./employeeSerializer";

export class ContactSerializer extends BaseSerializer {

  public deserialize(data): Contact {
    const contact = new Contact();
    this._copyAttributes(contact, data);

    let locationSerializer = new LocationSerializer();
    contact.location = locationSerializer.deserialize(data.location);
    let employeeSerializer = new EmployeeSerializer();
    contact.employee = employeeSerializer.deserialize(data.employee);

    return contact;
  }

  // Include serialization logic if needed
}
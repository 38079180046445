import React, { useState } from "react";
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Button } from "@mui/material";
import { FrequencyModel } from "../../../../../../../../shared/models/frequency.model";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:first-of-type)': {
      marginLeft: 0,
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
    '&:first-of-type': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
    '&:last-child': {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    '&:hover': {
      borderColor: theme.palette.action.hover,
    },
    '&.Mui-disabled': {
      borderColor: theme.palette.action.disabled,
    },
  },
}));

const ServiceItemScheduler = ({item, onSchedule}) => {
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [selectedTimesPer, setSelectedTimesPer] = useState(1);


  const handleScheduleChange = (event, newSchedule) => {
    setSelectedPeriod(newSchedule);
  };

  const handleFrequencyChange = (event) => {
    setSelectedTimesPer(event.target.value);
  };

  const handleSubmit = () => {
    let itemSchedule = new FrequencyModel();
    itemSchedule.id = item.frequency.id;
    itemSchedule.repeat_period = selectedPeriod;
    itemSchedule.times_per_period = selectedTimesPer;
    itemSchedule.is_active = item.frequency.is_active;

    item.frequency = itemSchedule;
    onSchedule(item);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <FormLabel component="legend" sx={{ fontWeight: 'bold' }}>Schedule</FormLabel>
      <StyledToggleButtonGroup
        value={selectedPeriod}
        exclusive
        onChange={handleScheduleChange}
        aria-label="date selection"
      >
        <ToggleButton value="Day">Daily</ToggleButton>
        <ToggleButton value="Week">Weekly</ToggleButton>
        <ToggleButton value="Month">Monthly</ToggleButton>
        <ToggleButton value="Year">Yearly</ToggleButton>
      </StyledToggleButtonGroup>
        <FormControl component="fieldset">
          <FormLabel component="legend" sx={{ fontWeight: "bold", marginBottom: "10px" }}>Frequency</FormLabel>
          <RadioGroup
            row
            aria-label="frequency"
            name="frequency"
            value={selectedTimesPer}
            onChange={handleFrequencyChange}
          >
            <FormControlLabel value={1} control={<Radio />} label="1x" />
            <FormControlLabel value={2} control={<Radio />} label="2x" />
            <FormControlLabel value={3} control={<Radio />} label="3x" />
            <FormControlLabel value={4} control={<Radio />} label="4x" />
          </RadioGroup>
        </FormControl>
          <Button onClick={() => handleSubmit()}>Submit</Button>
    </Box>
  );
};

export default ServiceItemScheduler;

import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";

export class CommandDeleteUserDepartment {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(department_id, employee_id) {
    return `${this.SERVICE}/reporting_groups/group_entries/${department_id}/employees/${employee_id}/`;
  }

  async run(department_id, employee_id) {

    const url = this.getUrl(department_id, employee_id)

    const tracer = useTracer.getState()
    tracer.trace('Equipment', 'Api', `DEL ${url}`)

    try {
      const response = await httpClient.delete(url);
      return response.data;
    } catch (error) {
      tracer.trace('Department', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    } finally {
      tracer.trace('Department', 'Api', 'Success')
    }

  }

}



export function findMatchedNodes(node, matches:number[]=[]) {

    if (!node)
        return matches;

    if (node.matched == true)
        matches.push(node.id.toString())

    // If node has children, search through them.
    if (!node.children || node.children.length == 0)
        return matches

    for (let childNode of node.children) {
        findMatchedNodes(childNode, matches);
    }

    return matches
}

import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import CaseRow from "./CaseRow";
import MobileCaseRow from "../mobile/MobileCaseRow";


const CaseTable = (props) => {
  const { cases, onEdit } = props;
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  const renderItems = (items) => (
    isMobile ? (
      <>
        {items.map((item, index) => (
          <MobileCaseRow key={index} item={item} onEdit={onEdit} />
        ))}
      </>
    ) : (
      <>
        {items.map((item, index) => (
          <CaseRow key={index} item={item} onEdit={onEdit} />
        ))}
      </>
    )
  );

  return (
    <>
      <div className={"card m-0 p-0"} style={{ border: '2px solid #e8e8e8' }}>
        <div className="table-responsive">
          <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
            <thead>
            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
              {isMobile ? (
                <>

                </>
              ) : (
                <>
                  <th className="p-0 pt-2 ps-5 pb-3 min-w-225px text-start">CASE</th>
                  <th className="p-0 pt-2 pb-3 min-w-75px text-start px-3">CREATED</th>
                  <th className="p-0 pt-2 pb-3 min-w-200px text-start px-3">ASSIGNED TO</th>
                  <th className="p-0 pt-2 pb-3 min-w-25px text-center"></th>
                </>
              )}
            </tr>
            </thead>
            <tbody>
            {renderItems(cases)}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CaseTable;




import { ServiceItem } from "./serviceItem.model";
import { InspectionPointModel } from "./inspectionPoint.model";


export class ServiceItemScore {

    id: null | number;
    uuid: null | string;
    score: number;
    is_wow: boolean;
    inspection_points: InspectionPointModel[];
    service_item: ServiceItem;


    constructor() {
        this.id = null;
        this.uuid = null;
        this.score = 0;
        this.is_wow = false;
        this.service_item = new ServiceItem();
        this.inspection_points = [];
    }

}


import React, { useEffect, useState } from "react";
import GreetingDashboardCard from "../components/greeting/GreetingDashboardCard";
import UserWorkItemsList from "../components/user-work-items/UserWorkItemsList";
import EditWorkItem from "../../work-items/components/edit-work-item/EditWorkItem";
import Grid from "@mui/material/Grid";
import { useWorkItem } from "../../work-items/context/workItemContext";
import { WorkItem } from "../../../shared/models/workItem.model";
import DashboardSection from "../components/DashboardSection";
import ServiceScanTile from "./components/ServiceScanTile";
import { LocationService } from "../../../shared/services/locations/locationService";
import useAppStore from "../../../appStore";
import { LocationModel } from "../../../shared/models/location.model";

const EmployeeDashboard = () => {

  const { selectedLocation } = useAppStore();
  const [locationDetail, setLocationDetail] = useState<LocationModel>(selectedLocation);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const service = new LocationService();

    const fetchLocationDetails = async () => {
      setIsLoading(true);
      try {
        const location = await service.getLocationInfo(selectedLocation?.id);
        setLocationDetail(location);
      } catch (error) {
        console.error('Failed to fetch location media:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedLocation?.id) {
      fetchLocationDetails().then();
    }
  }, [selectedLocation?.id]);

  const {
    workItemToEdit, setWorkItemToEdit,
    setEditing, open, setOpen,
    setSnackbarOpen
  } = useWorkItem();

  const handlerWorkItemEditorAction = (action) => {
    setEditing(false);
    setOpen(false);
    setWorkItemToEdit(new WorkItem());
    if (action === 'save') {
      setSnackbarOpen(true);
    }
  }

  return (
    <>
      <GreetingDashboardCard />

      <div style={{ display: 'flex', flexDirection: 'column' }}>

        <div style={{ width: '100%' }}>
          <div>
            <Grid container>
              {locationDetail.is_qr_scanning_enabled &&
                <Grid item xs={12} md={12}>
                  <DashboardSection title={"Service Scan"} tile={<ServiceScanTile />} />
                </Grid>
              }
              <Grid item xs={12} md={12}>
                <UserWorkItemsList manager={false} />
              </Grid>
            </Grid>
          </div>
        </div>

        {open && workItemToEdit &&
          <EditWorkItem
            open={open}
            onActionClicked={handlerWorkItemEditorAction}
          />
        }

      </div>
    </>
  );
};

export default EmployeeDashboard;
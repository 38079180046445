import React from "react";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";

const LaunchProjectsRow = (props) => {

    const {
        children,
        value,
        index,
        ...other } = props;

    /*
    badge-light-warning
    badge-light-primary
    badge-light-success
     */

    return (
        <>
            <tr>
                {/* PROJECT NAME */}
                <td>
                    <div className="d-flex align-items-center">
                        <div className="symbol symbol-50px me-4">
                            {/* primary success info warning danger*/}
                            <span className="symbol-label bg-light-primary">
                                <i className="ki-duotone ki-category fs-2x text-primary">
                                    <span
                                        className="path1"></span>
                                    <span
                                        className="path2"></span>
                                    <span
                                        className="path3"></span>
                                </i>
                            </span>
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">Building 1</a>
                            <span className="text-gray-400 fw-semibold d-block fs-7">Floor Care</span>
                        </div>
                    </div>
                </td>

                {/* WORK ITEMS */}
                <td className="text-start pe-0">
                    <div className="d-flex justify-content-lg flex-column">
                        <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">Front Lobby</a>
                        <span className="text-gray-400 fw-semibold d-block fs-7">Building 1</span>
                    </div>
                </td>

                {/* ASSIGNED */}
                <td className="text-start">
                    <span className="badge py-3 px-4 fs-7 badge-light-primary">Scheduled</span>
                </td>

                {/* ACTIONS */}
                <td>
                    <div className="d-flex justify-content-center flex-shrink-0">
                        <ActionButton iconType="pencil" onClick={(e) => {
                            e.preventDefault();
                        }} />
                        <ActionButton iconType="trash" onClick={(e) => {
                            e.preventDefault();
                        }} />
                    </div>
                </td>

            </tr>
        </>
    )
}

export default LaunchProjectsRow
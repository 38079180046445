import React, { useState } from "react";
import {
  Dialog, DialogContent, DialogActions,
  Button, AppBar, IconButton, Divider, TextField
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import useAppStore from "../../../../../../../../appStore";
import ServiceItemChoices from "./ServiceItemChoices";
import { ServiceItem } from "../../../../../../../../shared/models/serviceItem.model";

const ServiceItemSelector = ({ open, onClose, onSubmit }) => {
  const { whiteLabelProps, selectedLocation } = useAppStore();
  const [serviceItemsToAdd, setServiceItemsToAdd] = useState<ServiceItem[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchFor, setSearchFor] = useState<string>('');

  const handleItemSelected = (selectedItem) => {
    setServiceItemsToAdd(prevItems => [...prevItems, selectedItem]);
  }

  const handleRemoveItem = (itemToRemove) => {
    setServiceItemsToAdd(prevItems => prevItems.filter(item => item.id !== itemToRemove.id));
  }

  const handleSubmit = () => {
    onSubmit(serviceItemsToAdd);
    setServiceItemsToAdd([]);
    onClose();
  }

  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      setSearchFor(searchQuery);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleSubmit} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        <div className="d-flex flex-row" style={{ minHeight: '300px' }}>

          <div className="d-flex flex-column" style={{ width: "60%", marginRight: '10px' }}>
            <div className="d-flex flex-row mt-5">
              <TextField
                variant="outlined"
                placeholder="Find service items..."
                value={searchQuery}
                fullWidth
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={handleSearch}
              />
            </div>
            {searchFor ? (
              <ServiceItemChoices search={searchFor} onItemSelected={handleItemSelected} />
            ) : (
              <div style={{ flex: 1, textAlign: "center", padding: "20px", marginTop: "35px" }}>
                <p style={{
                  fontSize: "15px",
                  fontWeight: "semibold",
                  marginBottom: "0px",
                  color: whiteLabelProps.primary_color
                }}>Select a Service</p>
                <p style={{ fontSize: "12px", color: "gray" }}>to view service items</p>
              </div>
            )}
          </div>
          <Divider orientation="vertical" flexItem
                   style={{ backgroundColor: "gray", marginTop: "0px" }} />
          <div style={{ width: "40%" }}>
            <p className="text-gray-600" style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "5px", padding: "10px", paddingTop: '25px', }}>
              Service Items to Add:
            </p>
            <Divider style={{ backgroundColor: "gray", width: "100%" }} />
            <div style={{ marginTop: "18px" }}>
              {serviceItemsToAdd.map((item, index) => (
                <div key={index}
                     style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginLeft: "20px" }}>
                  <IconButton
                    edge="start"
                    color="primary"
                    onClick={() => handleRemoveItem(item)}
                    aria-label="remove"
                  >
                    <CloseIcon />
                  </IconButton>
                  <div className="d-flex flex-column">
                    <span style={{ flex: 1, marginLeft: "5px", fontWeight: 'bold' }}>{item?.entity_type?.name}</span>
                    <span style={{ flex: 1, marginLeft: "5px" }}>{item?.service?.path}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>

        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceItemSelector;


import React from "react";
import {ErrorMessage} from "../../../../../shared/components/page/error-message/ErrorMessage";
import clsx from "clsx";


const PostFormFields = (props) => {

    const {
        formik,
        errors = {
        apiError: '',
        error: '',
        toast: '',
        name: ''},
    } = props

    return (
        <>
            <form className="form form-label-right mb-4"
                  onSubmit={formik.handleSubmit}
            >
                <div className="form-group row">
                    <div className="col-lg-12" style={{display: 'none'}}>
                        <div className="form-group">
                            <label className='required fw-bold fs-6 mb-2'>Post Title</label>
                            <input
                                placeholder='Post title'
                                type='text'
                                name='name'
                                className={clsx(
                                    'form-control form-control-solid mb-3 mb-lg-0',
                                    //{'is-invalid': formik.touched.name && formik.errors.name},
                                    {
                                    //    'is-valid': formik.touched.name && !formik.errors.name,
                                    }
                                )}
                                autoComplete='off'
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                // disabled={formik.isSubmitting || isUserLoading}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <textarea
                                name="description"
                                placeholder="Type your message here"
                                className="form-control form-control-solid mb-3 mb-lg-0"
                                style={{height: '150px'}}
                                onChange={formik.handleChange}
                                value={formik.values.description}
                            />
                            <ErrorMessage name="description">
                                {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                            </ErrorMessage>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group mt-4">
                            <label className='fw-bold fs-6 mb-2'>Who can see this post</label>
                            <select
                                className="form-select form-control form-control-solid mb-3 mb-lg-0"
                                name="visibility"
                                placeholder="Select visibilty"
                                onChange={formik.handleChange}
                                value={formik.values.visibility}
                            >
                                <option value='public'>Public</option>
                                <option value='followers'>Followers</option>
                                <option value='contributors'>Contributors</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group mt-4">
                            <label className='required fw-bold fs-6 mb-2'>Post about</label>
                            <select
                                className="form-select form-control form-control-solid mb-3 mb-lg-0"
                                name="about"
                                placeholder="Select about"
                                onChange={formik.handleChange}
                                value={formik.values.about}
                            >
                                <option value='0'>Select a fundraiser, department or school (optional)</option>
                                <option value='1'>Lemon Grove Athletics</option>
                                <option value='2'>Marquis Sign</option>
                                <option value='3'>Landscaping</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <ErrorMessage
                            apiError={errors.apiError}
                            error={errors.error}
                            toast={errors.toast}
                            name={errors.name}
                        />
                    </div>
                    <div className="col-lg-12">
                    </div>
                </div>
            </form>
        </>
    )
}
export default PostFormFields
import { LocationModel } from "../location.model";
import { BaseSerializer } from "./baseSerializer";

export class LocationSerializer extends BaseSerializer {


    public deserialize( data ): any {

        const location = new LocationModel();

        this._copyAttributes(location, data);

        return location;

    }

}
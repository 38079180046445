import React from "react";
import SpaceTypesDualViewer from "../../../space-types/desktop/components/space-type-dual-viewer/SpaceTypesDualViewer";

const LocationSpaceTypes = () => {

  return (
    <>
        <SpaceTypesDualViewer global={false} />
    </>
  );
}

export default LocationSpaceTypes;





import React, { useState } from "react";
import useAppStore from "../../../../appStore";
import PageHeader from "../../../../shared/components/page/page-header/PageHeader";
import ContactsList from "./components/contacts-list/ContactsList";
import { SalesContact } from "../../../../shared/models/salesContact.model";
import EditLocationContact
  from "../../../inspections/components/edit-inspection/components/joint-inspection/components/EditLocationContact";


const LocationContacts = () => {
  const { loggedInUser } = useAppStore();
  const [contactToEdit, setContactToEdit] = useState<SalesContact | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [refreshList, setRefreshList] = useState(false);

  const handleDialogClose = (action = '') => {
    setIsDialogOpen(false);
    setContactToEdit(null);
    if (action === 'save') {
      setRefreshList(prev => !prev);
    }
  };

  const handleNew = () => {
    let newEquipment = new SalesContact();
    newEquipment.organization = loggedInUser?.organization;
    setContactToEdit(newEquipment);
    setIsDialogOpen(true);
  };

  const handleEdit = (equipment) => {
    setContactToEdit(equipment);
    setIsDialogOpen(true);
  };


  const handleSaveAndRefresh = (response) => {
    handleDialogClose('save');
  }


  return (
    <div className="card w-100">
      <div className="card-body d-flex flex-column" style={{ width: '100%' }}>
        <PageHeader
          title="Location Contacts"
          actionButtonName="New Contact"
          onActionClicked={handleNew}
        />
        <ContactsList
          canEdit={true}
          refreshList={refreshList}
          onEdit={handleEdit}
        />
        {isDialogOpen && (
          <EditLocationContact contactToEdit={contactToEdit} open={isDialogOpen} onClose={() => setIsDialogOpen(false)} onSave={handleSaveAndRefresh} />
        )}
      </div>
    </div>
  );
}

export default LocationContacts;





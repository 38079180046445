import React from "react";
import { Box, Button, Divider, useTheme } from "@mui/material";
import TrainingHierarchy from "./components/TrainingHierarchy";
import CourseListTable from "../course-list/CourseListTable";

const TrainingCatalogTreeViewer = (props) => {
  const {
    selectedCategory,
    handleAddCategory,
    handleEditCourse,
    handleChange
  } = props;

  const theme = useTheme();

  return (
    <>
        <div className="card">
          <div className="card-body d-flex flex-row" style={{ alignItems: 'flex-start' }}>
            {/* Left Panel */}
            <div className="d-flex flex-column flex-row-auto w-400px" style={{ minHeight: '600px', overflow: 'auto' }}>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h3>Categories</h3>
                <Button variant="contained" color="primary" onClick={handleAddCategory} style={{ height: '35px' }}>
                  + Category
                </Button>
              </div>
              <TrainingHierarchy />
            </div>

            <Divider orientation="vertical" flexItem style={{ backgroundColor: 'gray', marginLeft: '15px' }}/>

            {/* Main Body */}
            <div
              className="d-flex flex-column flex-row-fluid ms-5"
              key={selectedCategory ? selectedCategory.id : 'no-category'}
            >
              {selectedCategory?.name ? (
                <>
                  <div className="d-flex flex-row-fluid justify-content-between">
                    <h3>{selectedCategory.name}</h3>
                    <Button variant="contained" color="primary" onClick={handleEditCourse} style={{ height: '35px' }}>
                      + Course
                    </Button>
                  </div>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }} className={"mt-5 mb-10"} style={{ position: 'sticky', top: 0, zIndex: 100 }}>
                    <div className="d-flex flex-column flex-row-fluid ms-5">
                        <CourseListTable
                          onEditCourseClicked={handleEditCourse}
                          onDeleteCourseClicked={null}
                          selectedCategory={selectedCategory}/>
                    </div>
                  </Box>
                </>
              ) : (
                <div className="d-flex justify-content-center align-items-center" style={{ flex: 1, textAlign: "center", padding: "20px" , marginTop: "20px"}}>
                  <div>
                    <p style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "15px" }}>
                      No Category Selected.
                    </p>
                    <p>Select a category to view/edit from the dropdown or create a new one.</p>
                    <Button variant="contained" color="primary" onClick={handleAddCategory} style={{ height: '35px' }}>
                      Create a Category
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
    </>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default TrainingCatalogTreeViewer;

import React, { useEffect } from "react";
import PageHeader from "../../shared/components/page/page-header/PageHeader";
import EmployeeList from "./components/employee-list/EmployeeList";
import { Employee } from "../../shared/models/employee.model";
import useAppStore from "../../appStore";
import EditEmployeePage from "./components/edit-employee/EditEmployeePage";
import { Tabs, Tab } from "@mui/material";

const EmployeesPage = () => {
  const { loggedInUser } = useAppStore();
  const [employeeToEdit, setEmployeeToEdit] = React.useState<Employee | null>(null);
  const [open, setOpen] = React.useState(false);
  const [refreshList, setRefreshList] = React.useState(false);
  const [tab, setTab] = React.useState(1);

  const canEdit = loggedInUser?.permissions.hasAccess("Employees", 15);

  const handleNewEmployee = () => {
    let newEmployee = new Employee();
    setEmployeeToEdit(newEmployee);
    setOpen(true);
  };

  const handlerCloseEditModal = (action) => {
    setOpen(false);
    setEmployeeToEdit(null);
    if (action === 'save') {
      setRefreshList(prev => !prev);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setRefreshList(prev => !prev);
  };

  useEffect(() => {
    setRefreshList(prev => !prev);
  }, [tab]);

  return (
    <>
      <PageHeader
        title="Employees"
        actionButtonName="New Employee"
        onActionClicked={handleNewEmployee}
        showAction={canEdit}
      />

      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label="Selected Location" value={1} />
        <Tab label="All Locations" value={2} />
      </Tabs>

      <EmployeeList
        canEdit={canEdit}
        refreshList={refreshList}
        setRefreshList={setRefreshList}
        allLocations={tab === 2}
      />

      {open && (
        <EditEmployeePage
          isEdit={false}
          open={open}
          employee_id={employeeToEdit?.id}
          onActionClicked={handlerCloseEditModal}
        />
      )}
    </>
  );
};

export default EmployeesPage;


import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import SubHeader from "../../../../../../../shared/components/page/page-header/SubHeader";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/helpers";
import ComponentViewerSmall from "../../../../../../../shared/components/page/modal/ComponentViewerSmall";
import Suggestions from "./components/Suggestions";

const InspectionSuggestions = ({ suggestions }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {isMobile ? (
        <>
         <Suggestions suggestions={suggestions} />
        </>

      ):(
        <>
          <Card variant="outlined" sx={{ overflow: 'hidden', height: 150 }}>
            <CardContent sx={{ padding: '16px', display: 'flex', alignItems: 'center', height: '100%' }}>
              <Box sx={{ display: "flex", alignItems: "center", marginRight: "16px" }}>
                <img src={toAbsoluteUrl('/white-labeling/verde/media/menu-icons/suggestions-icon.png')} style={{ width: "70px", height: "95px", marginBottom: '5px' }} />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: 'center' }}>
                <SubHeader title={'Suggestions'} showAction={false} />
                <>
                  <Typography variant="body1" color="textSecondary" component="p" sx={{ marginTop: '13px', marginBottom: '2px' }}>
                    Sage has {suggestions.length} ideas
                  </Typography>
                  <Typography variant="body2" color="textPrimary" sx={{textDecoration: "underline", cursor: 'pointer'}} onClick={() => setOpen(true)} >
                    View Sage Suggestions
                  </Typography>
                </>
              </Box>
            </CardContent>
          </Card>

          {open && (
            <ComponentViewerSmall selectedPage={<Suggestions suggestions={suggestions} />} onActionClicked={() => setOpen(false)} />
          )}
        </>
      )}
    </>

  );
};

export default InspectionSuggestions;
import { WorkItem } from "./workItem.model";

export class WorkItemPrefillModel {
  type: string;
  work_item: WorkItem;

  constructor() {
    this.type = 'work_item';
    this.work_item = new WorkItem();
  }
}
import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import useAppStore from "../../../../../../../../../appStore";
import { Card, CardContent } from "@mui/material";

const WorkItemPieChart = ({ data }) => {

  const { whiteLabelProps } = useAppStore();

  const options: ApexOptions = {
    colors: [whiteLabelProps.primary_color, whiteLabelProps.primary_color_light, '#81C784'],
    labels: [`Unassigned (${data.pending_count})` , `In Progress (${data.assigned_count})`, `Closed (${data.closed_count})`],
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      offsetX: 0
    },
    chart: {
      width: '100%',
      toolbar: {
        show: false
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  const chartData = {
    series: [data.pending_count, data.assigned_count, data.closed_count]
  };

  return (
    <Card>
      <CardContent sx={{ p: 1 }}>
        <ReactApexChart
          options={options}
          series={chartData.series}
          type="pie"
        />
      </CardContent>
    </Card>
  );
};

export default WorkItemPieChart;


import { BaseSerializer } from "./baseSerializer";
import { OrganizationSerializer } from "./organizationSerializer";
import { Fundraiser } from "../fundRaiser.model";

export class FundraiserSerializer extends BaseSerializer {

    public deserialize( data ): any {

        let fundraiser = new Fundraiser();

        this._copyAttributes(fundraiser, data);

        let serializer = new OrganizationSerializer();
        const organization = serializer.deserialize(data.organization);
        fundraiser.organization = organization;

        return fundraiser;

    }

}
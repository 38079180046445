import { Employee } from "../../models/employee.model";
import { CommandSendScanData } from "./commands/cmdSendScanData";


export class ScannerService {

  async sendScanData(employee: Employee, data: string) {
    const cmd = new CommandSendScanData();
    let results = await cmd.run(employee, data);
    return results;
  }


}
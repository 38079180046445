import { BaseSerializer } from "./baseSerializer";
import { ServiceItemScore } from "../serviceItemScore.model";
import { ServiceItemSerializer } from "./serviceItemSerializer";

export class ServiceItemScoreSerializer extends BaseSerializer {

    public deserialize( data ): any {

        let service_item_score = new ServiceItemScore();

        this._copyAttributes(service_item_score, data);

        let serializer = new ServiceItemSerializer();
        const service_item = serializer.deserialize(data.service_item);
        service_item_score.service_item = service_item;
        service_item_score.inspection_points = data.inspection_points;
        service_item_score.score = parseInt(data.score);

        return service_item_score;

    }

}
import React, { useState } from "react";
import ActionButton from "../../../../../shared/components/buttons/ActionButton";
import { ValidationDialog } from "../../../../../shared/components/page/popup-dialog/ValidationDialog";

const EquipmentRow = (props) => {

  const {
    item,
    onEdit,
    onDelete,
    children,
    value,
    index,
    canEdit,
    isMobileView,
    ...other } = props;

  const [showErrorDialog, setShowErrorDialog] = useState(false);

  return (
    <>
      <tr>
        <td>
          <div className="d-flex align-items-center w-100">
            <div className="d-flex justify-content-start flex-column">
              <a className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                {item.name}
              </a>
              <span className="text-gray-400 fw-semibold d-block fs-7"></span>
            </div>
          </div>
        </td>

        {canEdit && (
          <td>
            <div className="d-flex justify-content-end">
              <ActionButton iconType="pencil" onClick={(e) => {
                e.preventDefault();
                onEdit(item);
              }} />
              <ActionButton iconType="trash" onClick={(e) => {
                e.preventDefault();
                setShowErrorDialog(true)
              }} />
            </div>
          </td>
        )}

      </tr>
      <ValidationDialog open={showErrorDialog} onClose={() => setShowErrorDialog(false)} title="Not implemented yet" description="Equipment cannot be deleted at this time" />
    </>
  )
}

export default EquipmentRow


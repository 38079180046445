import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";

export class CommandGetEmpDepartments {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/reporting_groups/group_entries/employees/`;
  }

  async run(provider_id, employee_id) {

    const url = this.getUrl()

    const tracer = useTracer.getState()
    tracer.trace('Equipment', 'Api', `POST ${url}`)

    const params = {
      params: {
        provider: provider_id,
        employee: employee_id
      }
    }

    try {
      const response = await httpClient.get(url, params);
      return response.data;
    } catch (error) {
      tracer.trace('Department', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    } finally {
      tracer.trace('Department', 'Api', 'Success')
    }

  }

}

import { BaseSerializer } from "./baseSerializer";
import { SiteHealthModel, SiteHealthDetailModel } from "../siteHealth.model";

export class LocationHealthSerializer extends BaseSerializer {


    public deserialize( data ): any {

        const locationHealth = new SiteHealthModel();

        this._copyAttributes(locationHealth, data);

        return locationHealth;

    }

}

export class LocationHealthDetailSerializer extends BaseSerializer {


    public deserialize( data ): any {

        const locationHealthDetail = new SiteHealthDetailModel();

        this._copyAttributes(locationHealthDetail, data);

        return locationHealthDetail;

    }

}
import React, {useState, useEffect} from "react";
import "yet-another-react-lightbox/styles.css";
import clsx from "clsx";
import {KTIcon, toAbsoluteUrl} from "../../../../../../../../_metronic/helpers";
import {Dropdown1} from "../../../../../../../../_metronic/partials";

const FundraiserProgress = (props) => {

    const {
    } = props

    const [index, setIndex] = useState(-1);

    return (
       <>
           {/* begin:Stats */}
           <div className='d-flex flex-wrap align-items-end w-100 mt-5 mb-2'>
               <div className='text-dark me-2 fw-bold fs-2'>$12,000</div>
               <div className='text-dark me-2 fw-bold pb-1'>pledged of $20,000 goal</div>
           </div>

           <div className='progress h-5px w-100'>
               <div
                   className={`progress-bar bg-primary`}
                   role='progressbar'
                   style={{width: 350}}
               ></div>
           </div>
           {/* end:Stats */}
       </>
    )

}

export {FundraiserProgress}

import { Person } from "./person.model";

export class User {
  id: number;
  person: Person;


  constructor() {
    this.id = 0;
    this.person = new Person();
  }

}
import React, { useEffect, useState } from "react";
import EmployeeTable from "./components/EmployeeTable";
import useAppStore from "../../../../appStore";
import { EmployeeService } from "../../../../shared/services/employee/employeeService";
import LoadMoreButton from "../../../../shared/components/buttons/LoadMoreButton";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import { TextField } from "@mui/material";
import { TextFieldButton } from "../../../../shared/components/buttons/TextFieldButton";

const EmployeeList = (props) => {
    const {
        canEdit,
        refreshList,
        setRefreshList,
        allLocations=false
    } = props;
    const { selectedLocation, loggedInUser } = useAppStore();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [employees, setEmployees] = useState<any>([]);
    const [searchResult, setSearchResult] = useState<any>([]);
    const [searchQuery, setSearchQuery] = useState<any>('');
    const [searchFor, setSearchFor] = useState<any>('');
    const [currentPage, setCurrentPage] = useState(0);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const pageSize = 10;

    const fetchEmployees = async (page) => {
        setIsLoading(true);
        try {
            const service = new EmployeeService();
            const offset = page * pageSize;
            const newEmployees = await service.getEmployees(loggedInUser.organization.id, allLocations ? undefined : selectedLocation.id, offset, pageSize);
            setEmployees(prev => [...prev, ...newEmployees]);
            setShowLoadMore(newEmployees.length === pageSize);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAllEmployeesUpToCurrentPage = async () => {
        setIsLoading(true);
        try {
            const service = new EmployeeService();
            const totalEmployeesToFetch = (currentPage + 1) * pageSize;
            const newEmployees = await service.getEmployees(loggedInUser.organization.id, allLocations ? undefined : selectedLocation.id, 0, totalEmployeesToFetch);
            setEmployees(newEmployees);
            setShowLoadMore(newEmployees.length % pageSize === 0 && newEmployees.length !== 0);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchSearchResult = async () => {
            setIsLoading(true);
            try {
                const service = new EmployeeService();
                const employees = await service.searchEmployees(loggedInUser.organization.id, searchFor);
                setSearchResult(employees);
                setShowLoadMore(false);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                }
            } finally {
                setIsLoading(false);
            }
        };

        if (searchFor !== '') {
            fetchSearchResult().then();
        } else {
            fetchEmployees(0).then();
        }

    }, [searchFor, loggedInUser.organization.id]);

    useEffect(() => {
        setEmployees([]);
        setCurrentPage(0);
        fetchEmployees(0);
        setRefreshList(false);
    }, [selectedLocation]);

    useEffect(() => {
        if (refreshList) {
            fetchAllEmployeesUpToCurrentPage();
        }
    }, [refreshList]);

    const loadMoreHandler = () => {
        const nextPage = currentPage + 1;
        fetchEmployees(nextPage);
        setCurrentPage(nextPage);
    };

    const handleSearch = () => {
        setSearchFor(searchQuery);
    };

    if (isLoading) {
        return <CustomLoadingDots />;
    }
    if (error) {
        return <CustomError error={error} />;
    }

    return (
      <>
          <div className="d-flex flex-row mt-5">
              <TextField
                variant="outlined"
                placeholder="Find employees..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{
                    width: '90%',
                    '& .MuiOutlinedInput-root': {
                        borderTopLeftRadius: '8px',
                        borderBottomLeftRadius: '8px',
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                        height: '45px',
                        '& .MuiInputBase-input': {
                            height: '100%',
                            padding: '16.5px 14px',
                        },
                    },
                }}
              />
              <TextFieldButton onClick={handleSearch}>
                  Search
              </TextFieldButton>
          </div>

          <EmployeeTable employees={searchFor !== '' ? searchResult : employees} canEdit={canEdit}
                         setRefreshList={setRefreshList} />
          {showLoadMore && <LoadMoreButton onLoadMore={loadMoreHandler} />}
      </>
    );
};

export default EmployeeList;
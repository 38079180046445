import { Organization } from "../organization.model";
import { Position } from "../position.model";
import { BaseSerializer } from "./baseSerializer";
import { Role } from "../role.model";

export class RoleSerializer extends BaseSerializer {


    public deserialize( data ): any {

        const role = new Role();

        this._copyAttributes(role, data);

        return role;

    }

}
import React, { useState } from 'react';
import classNames from 'classnames';
import { Avatar, Button, Chip, TextField } from "@mui/material";
import useServiceStore from "../../../../../../../serviceStore";
import CustomLoadingDots from "../../../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../../../../shared/components/page/popup-dialog/CustomError";
import StarIcon from "@mui/icons-material/Star";
import { InspectionPointModel } from "../../../../../../../../../shared/models/inspectionPoint.model";

const InspectionPoints = (props) => {
    const { classes = '', isEditing = false } = props;
    const { selectedServiceItem } = useServiceStore();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const [itemPoints, setItemPoints] = useState<InspectionPointModel[]>(selectedServiceItem?.inspection_points || []);
    const [isEditingPoint, setIsEditingPoint] = useState<InspectionPointModel | null>(null);

    if (isLoading) {
        return <CustomLoadingDots />;
    }
    if (error) {
        return <CustomError error={error} />;
    }

    const handleNewPoint = () => {
        let point = new InspectionPointModel();
        point.name = '';
        setIsEditingPoint(point);
        setItemPoints([...itemPoints, point]);
    };

    const handleSavePoint = (point) => {
        setIsEditingPoint(null);
    };

    const handleNameChange = (e, point) => {
        const updatedPoints = itemPoints.map(p => {
            if (p === point) {
                return { ...p, name: e.target.value };
            }
            return p;
        });
        setItemPoints(updatedPoints);
    };

    const handleRemovePoint = (key) => {
        const updated = itemPoints.filter(x => x?.id !== key);
        setItemPoints(updated);
    };

    const handleKeyPress = (e, point) => {
        if (e.key === 'Enter') {
            handleSavePoint(point);
        }
    };

    const handleChipClick = (point) => {
        setIsEditingPoint(point);
    };

    return (
      <>
          <div className={classNames("flex-column-fluid", classes)}>
              <div className="flex-row-fluid">
                  <div className="d-flex flex-row align-items-center justify-content-between" style={{ paddingLeft: 0 }}>
                      <h3 className="card-title align-items-center flex-row">
                          <span className="card-label fw-bold text-gray-600">Inspection Points</span>
                      </h3>
                      {isEditing && (
                        <Button variant="contained" color="primary" onClick={handleNewPoint} sx={{ marginRight: '10px' }}>
                            {"+ POINT"}
                        </Button>
                      )}
                  </div>
              </div>

              <div className="table-responsive flex-row-fluid mt-5">
                  <table className="table table-row-solid align-middle gs-0 gy-3 my-0">
                      <tbody>
                      {itemPoints.length === 0 &&
                          <>
                              <Chip avatar={<Avatar><StarIcon fontSize={"small"} /></Avatar>} label="Example" sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }} />
                              <Chip avatar={<Avatar><StarIcon fontSize={"small"} /></Avatar>} label="Example" sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }} />
                              <Chip avatar={<Avatar><StarIcon fontSize={"small"} /></Avatar>} label="Example" sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }} />
                          </>
                      }
                      {itemPoints.map(point => (
                        <Chip
                          avatar={<Avatar><StarIcon fontSize={"small"} /></Avatar>}
                          key={point?.name || point?.id}
                          label={
                              isEditingPoint === point ? (
                                <TextField
                                  value={point.name}
                                  onChange={(e) => handleNameChange(e, point)}
                                  onKeyPress={(e) => handleKeyPress(e, point)}
                                  autoFocus
                                />
                              ) : (
                                point?.name
                              )
                          }
                          sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
                          onDelete={() => handleRemovePoint(point?.id)}
                          onClick={() => handleChipClick(point)}
                        />
                      ))}
                      </tbody>
                  </table>
              </div>
          </div>
      </>
    );
};

export default InspectionPoints;
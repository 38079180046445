import { Employee } from "./employee.model";

export class OrgChartModel {
    id: number | null;
    name: string;
    children: OrgChartModel[];
    employee: Employee | null;
    leaves: OrgChartModel[];
    node_type: string;
    className: string;
    location: string;
    expanded: boolean;
    selectable: boolean;
    childrenCount: number;

    constructor() {
        this.id = null;
        this.name = '';
        this.children = [];
        this.leaves = [];
        this.employee = new Employee();
        this.className = "";
        this.location = 'verde';
        this.node_type = "";
        this.expanded = true;
        this.selectable = true;
        this.childrenCount = 0;
    }
}



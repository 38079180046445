import { EntitySerializer } from "../../../models/serializers/entitySerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";


export class CommandGetEntities{

    ENTITY_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl(location_id) {

        if(location_id===null)
            return  `${this.ENTITY_SERVICE}/entities/`;
        else
            return  `${this.ENTITY_SERVICE}/entities/?${location_id}`;
    }

    async run(location_id, parent_id: number | null=null, hier=false) {

        const url = this.getUrl(location_id)

        const params = {
            params: {
                location: location_id,
                parent: parent_id,
                kind_of: 'Place',
                hier: hier
            }
        }

        try {
            const response = await httpClient.get(url, params);
            return this.deserialize(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }

    }

    deserialize(data) {

        const serializer = new EntitySerializer()
        const entities = serializer.deserializeList(data)

        return entities
    }

}
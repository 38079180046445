import React from "react";
import {
  getCaseAssignee,
  getDaysAgo,
  getTimeDifferenceString
} from "../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";
import { format } from "date-fns";
import useAppStore from "../../../../../../appStore";
import DuotoneCustomIcon from "../../../../../../shared/components/page/row-icons/DuotoneCustomIcon";

const CaseRow = (props) => {
  const {
    item, onEdit
  } = props;

  const {appImages} = useAppStore();
  const formattedDate = format(new Date(item.create_date), 'MMM d, yyyy');
  const rowImage = item.department.name === 'Human Resources' ? appImages.hrCase : item.department.name === 'Marketing' ? appImages.marketingCase : appImages.genericCase;
  const urgent = getDaysAgo(item.create_date) > 3;


  return (
    <tr>
      <td>
        <div className="d-flex align-items-center ps-5">
          <DuotoneCustomIcon photo={rowImage} photoHeight={'45px'} photoWidth={'50px'} colorClass={'bg-light-success'} itemNumber={item.case_number} />
          <div className="d-flex justify-content-start flex-column">
            <a className="text-gray-800 fw-bold mb-1 fs-6">{item.case_type.name}</a>
            <span style={{ color: "gray" }} className="fw-semibold d-block fs-7">Case #{item.case_number}</span>
          </div>
        </div>

      </td>

      <td>
        <div className="d-flex justify-content-start flex-column">
          <a className="fw-bold mb-1 fs-6" style={{color: urgent ? '#E54C38' : 'inherit'}}>{getTimeDifferenceString(item.create_date)}</a>
          <span style={{ color: "gray" }} className="fw-semibold d-block fs-7">{formattedDate}</span>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column align-items-center">
            <div className="d-flex justify-content-start flex-column">
              <a href="../../demo1/dist/pages/user-profile/overview.html"
                 className="text-gray-800 fw-bold fs-6">
                {getCaseAssignee(item)}
              </a>
              <span style={{ color: "gray" }} className="fw-semibold d-block fs-7">{item.department.name}</span>
            </div>
          </div>
        </div>
      </td>

      <td className="text-center">
        {item.status === "Unassigned" && (
          <a className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" onClick={(e) => {
            e.preventDefault();
          }}>Assign</a>
        )}
      </td>
      <td>
        <div className="d-flex justify-content-end flex-shrink-0 me-5">
          <ActionButton iconType="view" onClick={(e) => {
            e.preventDefault();
            onEdit(item);
          }} />
        </div>
      </td>
    </tr>
  );
};

export default CaseRow;
import { Person } from "./person.model";
import { Work } from "./work.model";
import { Organization } from "./organization.model";
import { LoggedInUser } from "./loggedInUser.model";
import { EmployeeUserModel } from "./employeeUser.model";

export class Employee {
    id: number;
    uuid: string;
    external_id: string;
    organization: Organization;
    person: Person;
    user: EmployeeUserModel;
    works_at: Work[];

    constructor() {
        this.id = -1;
        this.uuid = '';
        this.external_id = '';
        this.organization = new Organization();
        this.person = new Person();
        this.user = new EmployeeUserModel();
        this.works_at = [];
    }

    setPosition(position_id){

        if( this.works_at.length == 0 ){
            this.works_at.push(new Work())
        }

        this.works_at[0].position.id = position_id

    }

    setLocation(location_id){

        if( this.works_at.length == 0 ){
            this.works_at.push(new Work())
        }

        this.works_at[0].location.id = location_id

    }

}

import { create } from 'zustand';
import { ServicesService } from "../../shared/services/services/servicesService";
import { Service } from "../../shared/models/service.model";
import { ServiceItem } from "../../shared/models/serviceItem.model";

interface ServiceStoreState {
  services: Service[];
  selectedService: Service | null;
  selectedServiceItem: ServiceItem | null;
  servicesLoading: boolean;
  serviceDetailsLoading: boolean;
  error: string | null;
  setServices: (services: Service[]) => void;
  setSelectedService: (selectedService: Service | ((prevState: Service | null) => Service) | null) => void;
  setSelectedServiceItem: (selectedServiceItem: ServiceItem | ((prevState: ServiceItem | null) => ServiceItem) | null) => void;
  fetchServices: (organization_id: number) => Promise<void>;
  fetchService: (service_id: number) => Promise<void>;
}

const useServiceStore = create<ServiceStoreState>((set) => ({
  services: [],
  selectedService: null,
  selectedServiceItem: null,
  servicesLoading: false,
  serviceDetailsLoading: false,
  error: null,
  setServices: (services) => set(() => ({ services })),
  setSelectedService: (selectedService) =>
    set((state) => {
      if (typeof selectedService === 'function') {
        return { selectedService: selectedService(state.selectedService) };
      }
      return { selectedService };
    }),

  setSelectedServiceItem: (selectedServiceItem) =>
    set((state) => {
      if (typeof selectedServiceItem === 'function') {
        return { selectedServiceItem: selectedServiceItem(state.selectedServiceItem) };
      }
      return { selectedServiceItem };
    }),

  fetchServices: async (organization_id) => {
    set(() => ({ servicesLoading: true }));
    try {
      const servicesService = new ServicesService();
      const services = await servicesService.getServiceHierarchy(organization_id, 1, 50);
      set(() => ({ services, servicesLoading: false }));
    } catch (error) {
        set(() => ({ error: 'An unknown error occurred', servicesLoading: false }));
    }
  },

  fetchService: async (service_id) => {
    set(() => ({ serviceDetailsLoading: true }));
    try {
      const servicesService = new ServicesService();
      const service = await servicesService.getService(service_id);

      if (service instanceof Service || service === null) {
        set(() => ({ selectedService: service, serviceDetailsLoading: false }));
      } else {
        set(() => ({ error: 'Fetched service is not of the expected type', serviceDetailsLoading: false }));
      }

    } catch (error) {
      console.error("Error in fetchService:", error);
      set(() => ({ error: 'An unknown error occurred', serviceDetailsLoading: false }));
    }
  },


}));

export default useServiceStore;


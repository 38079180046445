import React from "react";
import useAppStore from "../../../../../appStore";

const PositionRow = (props) => {

  const {
    item,
    onEdit,
    onDelete,
    children,
    value,
    index,
    canEdit,
    ...other } = props;

  const {whiteLabelProps} = useAppStore();

  return (
    <>
      <tr>
        {/* NAME */}
        <td>
          <div className="d-flex align-items-center">
            <div className="d-flex justify-content-start flex-column">
              <a className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                {item.name}
              </a>
              <span className="text-gray-400 fw-semibold d-block fs-7"></span>
            </div>
          </div>
        </td>

        {/* DESCRIPTION */}
        <td>
          <div className="d-flex align-items-center">
            {item.description}
          </div>
        </td>

        {/* ACTIONS */}
        {canEdit && (
          <td>
            <div className="d-flex justify-content-center flex-shrink-0">
              {/*<ActionButton iconType="pencil" onClick={(e) => {*/}
              {/*  e.preventDefault();*/}
              {/*  onEdit(item);*/}
              {/*}} />*/}
              {/*<ActionButton iconType="trash" onClick={(e) => {*/}
              {/*  e.preventDefault();*/}
              {/*  onDelete(item);*/}
              {/*}} />*/}
            </div>
          </td>
        )}

      </tr>
    </>
  )
}

export default PositionRow


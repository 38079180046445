import { localDb } from "../db";
import useTracer from "../../tracer/tracer";


export class MediaDbStore {

  storeImage(imageFile) {

      return new Promise((resolve, reject) => {

        const reader = new FileReader();

        reader.onload = () => {
            const imageData = new Blob([reader.result as ArrayBuffer], { type: imageFile.type });

            // Store the image data in the Dexie database
            localDb.media.add({ imageData }).then((insertedPrimaryKey) => {
              resolve(insertedPrimaryKey);
            }).catch((error) => {
              reject(error);
            });
        };

        reader.onerror = (error) => {
            reject(error);
        };

        // Read the image file as a data URL
        reader.readAsArrayBuffer(imageFile);
      });
  }

  getImageUrl(id) {

    return localDb.media.get(id).then((mediaRecord) => {

      if (mediaRecord === undefined) {
        console.log('Media not found in db');
        return null; // You can return null or another appropriate value when the media is not found
      }

      const imageUrl = URL.createObjectURL(mediaRecord.imageData);
      return imageUrl;

    }).catch((error) => {
      console.error('Error retrieving image:', error);
      throw error; // Rethrow the error so it can be caught elsewhere if needed
    });

  }

}
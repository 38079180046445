import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { WorkItemSerializer } from "../../../models/serializers/workItemSerializer";
import { CaseSerializer } from "../../../models/serializers/caseSerializer";

export class CommandUpdateCase {
  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(id) {
    return `${this.SERVICE}/cases/${id}/`;
  }

  async run(item) {
    const url = this.getUrl(item.id);
    const tracer = useTracer.getState();
    tracer.trace('Cases', 'Api', `PUT ${url}`);

    try {
      const data = CaseSerializer.serialize(item);
      const response = await httpClient.put(url, data);
      tracer.trace('Cases', 'Api', 'Success');
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Cases', 'Error', `${error}`);
      console.error('Error:', error);
      throw error;
    }
  }

  deserialize(data) {
    const serializer = new CaseSerializer();
    const item = serializer.deserialize(data);
    return item;
  }
}

import React, { useCallback, useEffect, useState } from "react";
import SiteHealthCard from './components/card/SiteHealthCard';
import useAppStore from "../../../../../../../appStore";
import NoDataCard from "../../../../../../../shared/components/page/info-cards/NoDataCard";
import { getCardClass, getTitleClass } from "../../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import CustomLoadingDots from "../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../../shared/components/page/popup-dialog/CustomError";
import { LocationService } from "../../../../../../../shared/services/locations/locationService";
import { LocationModel } from "../../../../../../../shared/models/location.model";
import { useMediaQuery, useTheme } from "@mui/material";

const CorpOverviewAnalytics = () => {
  const { loggedInUser, selectedLocation } = useAppStore();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [allLocations, setAllLocations] = useState<LocationModel[]>([]);
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));


  const fetchLocations = useCallback(async () => {
    try {
      setIsLoading(true)
      const service = new LocationService();
      const locations = await service.getLocations(loggedInUser.organization.id);
      setAllLocations(locations);
    } catch (err) {
      console.log("error fetching locations");
    } finally {
      setIsLoading(false)
    }
  }, [loggedInUser?.organization?.id]);

  useEffect(() => {
    fetchLocations().then();
  }, [fetchLocations]);

  const renderRows = (locationsList: LocationModel[]) => {
    if (locationsList.length === 0) {
      return (
        <tr>
          <td>
            <NoDataCard title="Issues" tooltipMessage={`No issues at this time`}/>
          </td>
        </tr>
      );
    }
    return locationsList.map((location) => (
      <tr key={location.id}>
        <td>
          <SiteHealthCard key={location.id} location={location} />
        </td>
      </tr>
    ));
  }

  if (isLoading) {
    return <CustomLoadingDots />;
  }
  if (error) {
    return <CustomError error={error} />;
  }

  return (
    <div className={getCardClass(true)}>
      <div className="card-header">
        <h3 className={getTitleClass(true)}>
          <span className="card-label fw-bold text-gray-800">Sites</span>
        </h3>
        <div className="card-toolbar">
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive" style={{width: isMobile ? '100%' : '50%'}}>
          <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
            <tbody>
            {renderRows(allLocations)}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CorpOverviewAnalytics;


import { CommandGetLocations } from "../locations/commands/cmdGetLocations";
import { CommandGetServiceLogAreas } from "./commands/cmdGetLogAreas";
import { CommandGetLog } from "./commands/cmdGetLog";
import { CommandGetLogOptions } from "./commands/cmdGetLogOptions";
import { CommandCreateManualLogEntry } from "./commands/cmdCreateManualLogEntry";


export class ServiceLogService {

  async getLogList(location_id) {
    const cmd = new CommandGetServiceLogAreas()
    const results = cmd.run(location_id)
    return results
  }

  async getLogOptions(location_id, space_id, space_type_id) {
    const cmd = new CommandGetLogOptions()
    const results = cmd.run(location_id, space_id, space_type_id)
    return results
  }

  async getLog(area_id) {
    const cmd = new CommandGetLog()
    const results = cmd.run(area_id)
    return results
  }

  async createManualEntry(log, employee) {
    const cmd = new CommandCreateManualLogEntry()
    const results = cmd.run(log, employee)
    return results
  }

}
import { BaseSerializer } from "./baseSerializer";
import { Case } from "../case.model";

export class CaseSerializer extends BaseSerializer {
  public deserialize(data): any {
    let the_case = new Case();
    this._copyAttributes(the_case, data);

    if (!the_case.title) {
      the_case.title = `Case #${the_case.id || ''}`;
    }

    return the_case;
  }
}

import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { EmployeeSerializer } from "../../../models/serializers/employeeSerializer";


export class CommandGetEmployee {

    SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl(id) {
        return  `${this.SERVICE}/employees/${id}/`;
    }

    async run(id) {

        const url = this.getUrl(id)
        const tracer = useTracer.getState()
        tracer.trace('Employees', 'Api', `GET ${url}`)

        try {
            const response = await httpClient.get(url);
            return this.deserialize(response.data);
        } catch (error) {
            tracer.trace('Employees', 'Error', `${error}`)
            console.error('Error fetching data:', error);
            throw error;
        }finally {
            tracer.trace('Employees', 'Api', 'Success')
        }

    }

    deserialize(data) {

        const serializer = new EmployeeSerializer()
        const employee = serializer.deserialize(data)
        return employee
    }

}

export class Trace {

    id: number;
    module: string;
    condition: string;
    message: string;

    constructor() {
        this.id = 0;
        this.module = '';
        this.condition = 'Trace'
        this.message = '';

    }

}


import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";

export class CommandDeleteEmployee {

    EMPLOYEES_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl(employeeId) {
        return `${this.EMPLOYEES_SERVICE}/employees/${employeeId}/`;
    }

    async run(employeeId) {
        const url = this.getUrl(employeeId);

        const tracer = useTracer.getState();
        tracer.trace('Employees', 'Api', `DELETE ${url}`);

        try {
            const response = await httpClient.delete(url);
            tracer.trace('Employees', 'Api', 'Success');
            return response;
        } catch (error) {
            tracer.trace('Employees', 'Error', `${error}`);
            console.error('Error:', error);
            throw error;
        }
    }
}
import React from "react";
import useInspectionsStore from "../../../../../inspectionsStore";
import SubHeader from "../../../../../../../shared/components/page/page-header/SubHeader";
import InspectedEntityCard from "./InspectedEntityCard";
import { Typography } from "@mui/material";

const InspectedEntitiesMobileView = (props) => {

  const {
    onAddInspectionsClick,
    onEditInspectedEntityClicked,
    onDeleteInspectedEntityClicked,
    isEditing,
  } = props;

  const {inspection, capPrefill} = useInspectionsStore();
  const capLength = capPrefill?.inspected_entities?.length


  return (
    <>
      <div style={{ marginBottom: isEditing ? '0' : '20px'}}>
        <SubHeader
          title={capLength > 0 ? 'Inspect CAP spaces'  : 'Inspected Spaces'}
          subtitle={capLength > 0 ? `${capLength} spaces in plan` : null}
          showAction={isEditing ? true : false}
          actionButtonName={'Inspect...'}
          onActionClicked={onAddInspectionsClick}
        />
      </div>
      <div style={{marginBottom: '60px'}}>
        {inspection.inspected_entities.length > 0 ? inspection.inspected_entities.map((space) =>
          space.id !== null ? (
            <InspectedEntityCard
              key={space.id}
              onEdit={onEditInspectedEntityClicked}
              inspected_entity={space}
              isEditing={isEditing}
            />
          ) : null
        ) : <Typography sx={{textAlign: 'center', marginTop: '25px'}}>No spaces inspected yet</Typography>}
      </div>
    </>
  );
}

export default InspectedEntitiesMobileView
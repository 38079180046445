// import httpClient from "../../../utils/http-clients/djangoHttpClient";


export class CommandDeletePost {

    POSTS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl(postId) {

        return `${this.POSTS_SERVICE}/posts/${postId}/`;
    }

    async run(postId) {

        try {
            // const url = this.getUrl(postId)

            // const response = await httpClient.delete(url);

            return 'success'
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }

    }

}
import { Media } from "./media.model";
import { AppDate } from "../utils/date-time/AppDate";


export class LocationContractsModel {

  id: number;
  name: string;
  description: string;
  start_date: string;
  end_date: string;
  media: Media[];


  constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.start_date = new AppDate().dateTime;
    this.end_date = new AppDate().dateTime;
    this.media = [];

  }

}


import React from "react";

const SpaceTypesRow = (props) => {
  const {
    space,
    onSelect,
    isSelected
  } = props;

  return (
    <>
      <tr
        onClick={(e) => {
          e.preventDefault();
          onSelect(space);
        }}
        className={isSelected ? "bg-light-success" : ""}
        style={{cursor: "pointer"}}
      >
        <td>
          <div className="d-flex align-items-center">
            <div className="d-flex justify-content-start flex-column">
              <a className="text-gray-800 mb-1 ms-2 fs-6">
                {space.name}
              </a>
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}

export default SpaceTypesRow;

import React from "react";
import useAppStore from "../../../../appStore";
import { getBadge } from "../../../utils/table-modifier-util/tableDataUtil";

const ScoreRow = (props) => {
  const { row, columns } = props;

  return (
    <tr>
      {columns.map((column, index) => (
        <td key={column} style={{ maxWidth: "400px" }}>
          <div className={`d-flex ${index === columns.length - 1 ? 'justify-content-end' : 'justify-content-between'}`}>
            <div className="d-flex flex-column">
              <a
                className= {column === "avg_score" ? getBadge(row[column]) : "mb-1 fs-6 text-black"}
              >
                {row[column] === 0 ? 'No response' : row[column]}
              </a>
            </div>
          </div>
        </td>
      ))}
    </tr>
  );
};

export default ScoreRow;





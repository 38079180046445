
export class WhiteLabelProps {

    id: number;
    organization_id: number;
    appName: string;
    appUrl: string;
    appImageBackgroundColor: string;
    primary_color: string;
    primary_color_light: string;
    contrast_color: string;
    kt_icon_color: string;
    default_page_size: number;



    constructor() {
        this.id = 0;
        this.organization_id = 0;
        this.appName = 'SageConnect';
        this.appUrl = 'https://app.sageconnect.io';
        this.appImageBackgroundColor = '#333333'
        this.primary_color = '#15aa78'
        this.primary_color_light = '#5ec3a1'
        this.contrast_color = '#fff'
        this.kt_icon_color = 'text-white'
        this.default_page_size = 10;
    }

    // constructor() {
    //     this.id = 0;
    //     this.organization_id = 0;
    //
    //     this.appName = 'SageConnect';
    //     this.appUrl = 'https://app.sageconnect.io';
    //     this.companyLogo = toAbsoluteUrl('/white-labeling/surefire/longWhite.png')
    //     this.appImage = toAbsoluteUrl('/white-labeling/verde/media/logos/sage_v_sign.png')
    //     this.appImageBackgroundColor = '#333333'
    //
    //     this.ratingIcon = toAbsoluteUrl('/white-labeling/verde/media/images/inspections/inspection-rating.png');
    //     this.ratingIconBlank = toAbsoluteUrl('/white-labeling/verde/media/images/inspections/inspection-rating-empty.png');
    //     this.ratingIconHalf = toAbsoluteUrl('/white-labeling/verde/media/images/inspections/inspection-rating-half.png');
    //
    //     this.primary_color = '#E25822'
    //     this.primary_color_light = '#FF7726'
    //
    //     this.contrast_color = '#fff'
    //     this.kt_icon_color = 'text-white'
    //
    //     this.default_page_size = 10;
    //
    // }

}


import React, { useEffect, useState } from "react";
import {Button, ButtonGroup, ToggleButton, ToggleButtonGroup} from "@mui/material";
import SearchableTreeView
    from "../../../../../../../../shared/components/forms/widgets/searchable-tree-view/SearchableTreeView";



const CategorySelector = (props) => {

    const {
        courseCategories=[],
        onCategorySelected
    } = props


    const [buttonSelection, setButtonSelection] = React.useState('Company');

    const handleButtonChange = (
        event: React.MouseEvent<HTMLElement>,
        newSelection: string,
    ) => {
        setButtonSelection(newSelection);
    };

    const handleServiceSelected = (category) => {
        onCategorySelected('Service', category)
    }

    useEffect(() => {

        // Need to wait until additional-requests are provided before we switch over to Service.
        if (courseCategories !== undefined && courseCategories.length > 0) {
            setButtonSelection('Service')
        }

    }, [courseCategories]);

    const displayTypeSelector = () => {

        if (buttonSelection == 'Company'){
           // onCategorySelected('Company', null)
            return null
        }

        if (buttonSelection == 'Level'){
            return (
                <select
                    className="form-select form-control form-control-solid mb-3 mt-4"
                    name="level"
                    placeholder="Select level"
                    //onChange={formik.handleChange}
                    //value={formik.values.about}
                >
                    <option value='0'>Employee</option>
                    <option value='1'>Lead</option>
                    <option value='2'>Manager</option>
                    <option value='3'>Site Manager</option>
                    <option value='4'>Account Manager</option>
                </select>
            )
        }

        if (buttonSelection == 'Location'){
            return (
                <select
                    className="form-select form-control form-control-solid mb-3 mt-4"
                    name="service"
                    placeholder="Select level"
                    //onChange={formik.handleChange}
                    //value={formik.values.about}
                >
                    <option value='0'>ASU</option>
                    <option value='1'>Chandler</option>
                    <option value='2'>TSMC</option>
                </select>
            )
        }

        if (buttonSelection == 'Service'){

            return (
                <SearchableTreeView data={courseCategories}
                                    onItemSelected={handleServiceSelected}
                                    classes="mt-5"/>
            )
        }

    }

    return (
         <>
             <ToggleButtonGroup
                 color="primary"
                 value={buttonSelection}
                 exclusive
                 onChange={handleButtonChange}
                 aria-label="Platform"
             >
                 <ToggleButton value="Company">Company</ToggleButton>
                 <ToggleButton value="Level">Level</ToggleButton>
                 <ToggleButton value="Location">Location</ToggleButton>
                 <ToggleButton value="Service">Service</ToggleButton>
             </ToggleButtonGroup>

             {
                 displayTypeSelector()
             }
         </>
    )

}

export default CategorySelector

import { Organization } from "./organization.model";
import { Employee } from "./employee.model";
import { AppDate } from "../utils/date-time/AppDate";
import { ActionPlanItem } from "./actionPlanItem.model";
import { SalesContact } from "./salesContact.model";

export class Relationship {
  id: number;
  sales_contact: SalesContact;
  create_date: string;
  start_date: string;
  description: string;
  what_to_convey: string;
  questions_to_ask: string;
  provider: Organization;
  created_by: Employee;
  assigned_to_employees: Employee[];
  action_entries: ActionPlanItem[];
  latest_comment: string | null;


  constructor() {
    this.id = 0;
    this.sales_contact = new SalesContact();
    this.create_date = new AppDate().dateTime;
    this.start_date = new AppDate().dateTime;
    this.description = "";
    this.what_to_convey = "";
    this.questions_to_ask = "";
    this.provider = new Organization();
    this.created_by = new Employee();
    this.assigned_to_employees = [];
    this.action_entries = [];
    this.latest_comment = null;
  }

}
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { EntitySerializer } from "../../../models/serializers/entitySerializer";


export class CommandGetEntityDetails {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(id) {
    return  `${this.SERVICE}/entities/${id}/`;
  }

  async run(id) {

    const url = this.getUrl(id)
    const tracer = useTracer.getState()
    tracer.trace('Entity', 'Api', `GET ${url}`)

    try {
      const response = await httpClient.get(url);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Entity', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    }finally {
      tracer.trace('Entity', 'Api', 'Success')
    }

  }

  deserialize(data) {
    const serializer = new EntitySerializer()
    const workItem = serializer.deserialize(data)
    return workItem
  }

}
import { CommandGetFundraisers } from "./commands/cmdGetFundraisers";


export class FundraiserService {

    async getFundraisers(org_id=8227, offset=0, limit=10, search=null) {

        const cmd = new CommandGetFundraisers()
        const results = cmd.run(org_id, offset, limit, search)

        return results
    }
}
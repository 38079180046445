import { ItemStatusCountModel } from "../itemStatusCount.model";
import { BaseSerializer } from "./baseSerializer";

export class WorkItemStatusSerializer extends BaseSerializer {

    public deserialize(data): any {
        if (data && data.status_counts && Array.isArray(data.status_counts) && data.status_counts.length > 0) {
            // Assuming the structure { status_counts: [{ ...counts }] } and taking the first element of the array.
            return new ItemStatusCountModel(
              data.status_counts[0].pending_count,
              data.status_counts[0].assigned_count,
              data.status_counts[0].closed_count
            );
        }
        return new ItemStatusCountModel();
    }
}

import React from "react";
import { Button } from "@mui/material";
import ComingSoon from "../../../../../../shared/components/page/coming-soon/ComingSoon";

const LocationNotifications = () => {

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h3>Notifications</h3>
        <Button variant="contained" color="primary" onClick={() => console.log("Clicked")} style={{ height: "45px" }}>
          Edit
        </Button>
      </div>
      <div className="p-10">
        <ComingSoon />
      </div>
    </>
  );

}

export default LocationNotifications
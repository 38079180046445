
export class AccessLevel {

    id: number;
    feature: string;
    access_level: number;

    constructor() {
        this.id = 0;
        this.feature = '';
        this.access_level = 0;
    }

}


import React, { useState } from "react";
import { Typography } from "@mui/material";
import useAppStore from "../../../appStore";
import { ContactService } from "../../../shared/services/location-contacts/contactService";

const ContactsModal= () => {

  const [contacts, setContacts] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const contactService = new ContactService();

  const fetchContacts = async () => {
    setIsLoading(true);
    try {
      // const contactsData = await contactService.getContacts();
      // setContacts(contactsData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Typography variant="h6">Contacts</Typography>
      <Typography variant="body1">Coming Soon</Typography>
    </div>
  );

};

export default ContactsModal;



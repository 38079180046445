import React, { useEffect, useState } from "react";
import { Employee } from "../../../../../../../shared/models/employee.model";
import { Box, Button, Chip, Divider } from "@mui/material";
import EmployeeQuickSelect
    from "../../../../../../../shared/components/forms/widgets/selection-forms/EmployeeQuickSelect";
import CancelIcon from "@mui/icons-material/Cancel";

const RelatedToEmployees = (props) => {
    const {
        caseToEdit,
        setCaseToEdit,
    } = props;

    const [isEmployeeDialogOpen, setEmployeeDialogOpen] = useState(false);
    const [relatedEmployees, setRelatedEmployees] = useState<Employee[]>(caseToEdit?.related_to_employees);

    useEffect(() => {
        if (caseToEdit.related_to_employees) {
            setRelatedEmployees(caseToEdit.related_to_employees);
        }
    }, [caseToEdit.related_to_employees]);

    const handleSaveEmployees = async (item, employees) => {
        item.related_to_employees = employees;
        setRelatedEmployees(employees)
    };

  const handleRemoveEmployee = (employeeId) => {
    const updatedAssignedEmployees = relatedEmployees.filter(e => e?.id !== employeeId);
    setCaseToEdit(prevWorkItem => ({ ...prevWorkItem, related_to_employees: updatedAssignedEmployees }));
    setRelatedEmployees(relatedEmployees.filter(e => e?.id !== employeeId));
  };

  const renderSelectedEmployees = () => {
    return (
      <div>
        {relatedEmployees.map(employee => (
          <Chip
            key={employee?.id}
            label={employee?.person.first_name + " " + employee?.person.last_name}
            onDelete={caseToEdit?.id === 0 ? () => handleRemoveEmployee(employee?.id) : undefined}
            deleteIcon={caseToEdit?.id === 0 ? <CancelIcon /> : undefined}
            sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
          />
        ))}
        {caseToEdit?.id === 0 &&
          <Chip
            label={'+'}
            onClick={() => setEmployeeDialogOpen(true)}
            sx={{ m: 0.7, fontSize: '1.7rem', backgroundColor: '#c5c5c5', color: 'white', justifyContent: 'center' }}
          />
        }
      </div>
    );
  };


  return (
    <>
      <div className="d-flex flex-column">
        <span className="text-gray-700">Related To</span>
        <Box sx={{ border: '1px solid #c7c7c7', borderRadius: '5px' }}>
          <div style={{ padding: '15px' }}>
            {relatedEmployees.length > 0 && renderSelectedEmployees()}

            {relatedEmployees.length === 0 && (
              caseToEdit?.id === 0 ? (
                <div className="d-flex flex-row align-items-center justify-content-center text-center" onClick={() => setEmployeeDialogOpen(true)}>
                  <i className="fas fa-user-plus fs-2"></i>
                </div>
              ) : (
                <div className="d-flex flex-row">
                  No Related Employees
                </div>
              )
            )}
          </div>
        </Box>
      </div>
      <EmployeeQuickSelect
        open={isEmployeeDialogOpen}
        onClose={() => setEmployeeDialogOpen(false)}
        handleSave={handleSaveEmployees}
        item={caseToEdit}
        caseEmployees={true}
      />
    </>
  );

}

export default RelatedToEmployees;
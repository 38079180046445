import React, { useEffect, useState } from "react";
import useAppStore from "../../../../../../../../appStore";
import { ServiceItem } from "../../../../../../../../shared/models/serviceItem.model";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ServicePlanService } from "../../../../../../../../shared/services/service-plans/servicePlanService";

const ServiceItemChoices = ({ search, onItemSelected }) => {
  const { whiteLabelProps } = useAppStore();
  const [availableItems, setAvailableItems] = useState<ServiceItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchServiceItems = async () => {
      try {
        setIsLoading(true);
        const service = new ServicePlanService();
        const available = await service.searchServiceItems(search);
        setAvailableItems(available);
      } catch (error) {
        console.error('Failed to fetch service items:', error);
      } finally {
        setIsLoading(false);
      }
    }

    if (search !== '') {
      fetchServiceItems().then();
    }

  }, [search]);

  return (
    <div style={{marginTop: '20px'}}>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        availableItems.length === 0 ? (
          <div style={{ flex: 1, textAlign: "center", padding: "20px", marginTop: "25px" }}>
            <p style={{
              fontSize: "15px",
              fontWeight: "semibold",
              marginBottom: "0px",
              color: whiteLabelProps.primary_color
            }}>No Service Items</p>
            <p style={{ fontSize: "12px", color: "gray" }}>this service has no items</p>
          </div>
        ) : (
          availableItems.map((item, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px", borderBottom: '1px dashed lightgray', justifyContent: "space-between" }}>
              <div className="d-flex flex-column">
                <span style={{ flex: 1, fontWeight: 'bold' }}>{item?.entity_type?.name}</span>
                <span style={{ flex: 1 }}>{item?.service?.path}</span>
              </div>
              <IconButton
                edge="start"
                color="primary"
                onClick={() => onItemSelected(item)}
                aria-label="remove"
              >
                <AddIcon />
              </IconButton>
            </div>
          ))
        )
      )}
    </div>
  );

};

export default ServiceItemChoices;

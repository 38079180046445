import React, { useEffect, useState } from "react";
import { Box, Chip, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import classNames from "classnames";
import { EntityType } from "../../../../../../../shared/models/entityType.model";
import useAppStore from "../../../../../../../appStore";
import { EntityTypeService } from "../../../../../../../shared/services/entity-types/entityTypeService";
import SelectableTile
  from "../../../../../../inspections/components/edit-inspection/components/edit-inspected-entity/components/space-selection/components/selectable-tiles/SelectableTile";
import CancelIcon from "@mui/icons-material/Cancel";


const TypeAndSubTypes = ({ spaceType, setSpaceType, subTypes, setSubTypes }) => {
  const { selectedLocation, whiteLabelProps } = useAppStore();
  const service = new EntityTypeService();
  const [allSpaceTypes, setAllSpaceTypes] = useState<EntityType[]>([]);
  const [availableSpaceTypes, setAvailableSpaceTypes] = useState<EntityType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {
    setIsLoading(true);
    service.getEntityTypes(selectedLocation.id)
      .then(data => {
        setAllSpaceTypes(data);
        setAvailableSpaceTypes(data.filter((type) => !subTypes.some((locType) => locType.id === type.id)));
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error.message);
        setIsLoading(false);
      });
  }, [selectedLocation.id]);

  const handleChange = (event) => {
    const selectedType = allSpaceTypes.find(type => type.id === event.target.value);
    setSpaceType(selectedType);
  };

  const handleNewSubSpace = (spaceType) => {
    setSubTypes([...subTypes, spaceType]);
    setAvailableSpaceTypes(availableSpaceTypes.filter(e => e?.id !== spaceType.id));
  };

  const handleRemoveSubSpace = (spaceType) => {
    const updated = subTypes.filter(e => e?.id !== spaceType.id);
    setSubTypes(updated);
    setAvailableSpaceTypes([...availableSpaceTypes, spaceType]);
  };

  return (
    <div className={classNames("flex-column")} style={{ width: '100%', marginBottom: '20px' }}>
      <FormControl fullWidth>
        <Select
          labelId="type-label"
          required
          name="space_type_id"
          value={spaceType?.id || ""}
          onChange={handleChange}
          disabled={isLoading}
        >
          {allSpaceTypes.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              {type.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#3a3b3c', marginTop: "20px" }}>Space Types Serviced</Typography>
      <Box sx={{border: '1px solid #c7c7c7', borderRadius: '5px', padding: '10px'}}>
        {subTypes.map(type => (
          <Chip
            key={type?.id}
            label={type?.name}
            onDelete={() => handleRemoveSubSpace(type)}
            deleteIcon={<CancelIcon />}
            sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
          />
        ))}
        <Chip
          label='+ Add Types'
          onClick={() => setExpanded(true)}
          sx={{ m: 0.5, fontSize: '1rem', backgroundColor: whiteLabelProps.primary_color, color: 'white', fontWeight: 'bold' }}
        />
        {expanded &&
          <div className="p-5 d-flex flex-row flex-wrap">
            {availableSpaceTypes.map((type, index) => (
              <div style={{ cursor: 'pointer', margin: '5px' }}
                   onMouseEnter={(e) => {
                     e.currentTarget.style.backgroundColor = whiteLabelProps.primary_color;
                   }}
                   onMouseLeave={(e) => {
                     e.currentTarget.style.backgroundColor = 'inherit';
                   }}
                   key={index}>
                <SelectableTile
                  key={index}
                  disabled={false}
                  data={type}
                  onTileClicked={handleNewSubSpace}
                />
              </div>
            ))}
          </div>
        }

      </Box>

    </div>
  );
};

export default TypeAndSubTypes;

import { CommandGetComments } from "../comments/commands/cmdGetComments";
import { CommandPostComment } from "../comments/commands/cmdPostComment";

export class CommentsService {

  async getComments(item, type='workitems') {
    const cmd = new CommandGetComments()
    const results = cmd.run(item, type)
    return results
  }

  async postComment(item, comment, type='workitems') {
    const cmd = new CommandPostComment()
    const results = cmd.run(item, comment, type)
    return results
  }

}

import React, { useState, useEffect } from "react";
import { AppBar, Dialog, IconButton, Typography, Toolbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { EmployeeService } from "../../../../shared/services/employee/employeeService";
import EmployeeForm from "./components/form/EmployeeForm";
import useAppStore from "../../../../appStore";
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import { Employee } from "../../../../shared/models/employee.model";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import ErrorBoundary from "../../../../shared/components/error-boundary/ErrorBoundary";

const EditEmployeePage = (props) => {
  const {
    isEdit,
    open,
    employee_id,
    onActionClicked,
    profileMode=false,
    quickCreate=false,
  } = props;

  const [error, setError] = useState('');
  const employeeService = new EmployeeService();
  const [positions, setPositions] = useState<any>([]);
  const [employeeToEdit, setEmployeeToEdit] = useState<Employee | null>(null);
  const { loggedInUser, selectedLocation } = useAppStore();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      setIsLoading(true);
      try {
        if (employee_id === -1) {
          setEmployeeToEdit(new Employee());
        } else {
          const service = new EmployeeService();
          const employee = await service.getEmployee(employee_id);
          console.log('Fetched employee:', employee); // Logging
          setEmployeeToEdit(employee);
        }
      } catch (error) {
        console.error('Failed to fetch employee:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchPositions = async () => {
      try {
        const positionsData = await employeeService.getPositions(loggedInUser.organization?.id);
        setPositions(positionsData);
      } catch (error) {
        console.error('Error fetching positions:', error);
      }
    };

    fetchEmployeeDetails().then();
    fetchPositions().then();
  }, [employee_id, loggedInUser.organization]);

  const handleSave = async () => {
    try {
      if (!employeeToEdit) {
        throw new Error("No employee data to save");
      }

      let response;

      if (isEdit) {
        response = await employeeService.updateEmployee(employeeToEdit);
        onActionClicked('save');
      } else {
        if (loggedInUser?.organization) {
          employeeToEdit.organization = loggedInUser.organization;
          response = await employeeService.createEmployee(employeeToEdit, loggedInUser.organization.id, selectedLocation.id);
          onActionClicked('save', response);
        } else {
          throw new Error("Organization data is missing for the new employee");
        }
      }
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : JSON.stringify(e);
      setError(`Failed to update employee: ${errorMessage}`);
    }
  };

  if (isLoading) {
    return <CustomLoadingDots />;
  }

  console.log('Rendering component with employee:', employeeToEdit); // Logging

  return (
    <ErrorBoundary>
      <Dialog fullScreen open={open} onClose={() => onActionClicked('close')} TransitionComponent={ModalTransition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {profileMode ? 'Edit Profile' : 'Edit Employee'}
            </Typography>
          </Toolbar>
        </AppBar>
        {employeeToEdit !== null &&
          <EmployeeForm
            employeeToEdit={employeeToEdit}
            handleSave={handleSave}
            positions={positions}
            error={error}
            isEdit={isEdit}
            profileMode={profileMode}
            quickCreate={quickCreate}
          />
        }
      </Dialog>
    </ErrorBoundary>
  );
};

export default EditEmployeePage;


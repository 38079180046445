import React from 'react';
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { ArrowBackIos, Close } from "@mui/icons-material";
import ModalTransition from "../transitions/ModalTransition";
import Dialog from "@mui/material/Dialog";

const ComponentViewer = ({ selectedPage, onActionClicked, title='', color='#e9e9e9' }) => {
  return (

      <Dialog fullScreen open={selectedPage} onClose={onActionClicked} TransitionComponent={ModalTransition} PaperProps={{ style: { overflowX: 'hidden', backgroundColor: color } }}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onActionClicked} aria-label="close">
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{marginTop: '5px', padding: '15px'}}>
          <Box sx={{ marginBottom: "15px" }}>
            {selectedPage}
          </Box>
        </Box>
      </Dialog>

  );
};

export default ComponentViewer;

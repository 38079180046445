import React, { useEffect, useState, useCallback, useRef } from "react";
import PageHeader from "../../shared/components/page/page-header/PageHeader";
import useInspectionsStore from "./inspectionsStore";
import EditAreaInspectionPage from "./components/edit-inspection/EditAreaInspection";
import { Inspection } from "../../shared/models/inspection.model";
import { Button } from "@mui/material";
import InspectionSummaryTiles from "./components/inspections-list/components/summary/InspectionSummaryTiles";
import { InspectionService } from "../../shared/services/inspections/inspectionService";
import useAppStore from "../../appStore";
import { InspectionSummary } from "../../shared/models/inspectionSummary.model";
import CustomLoadingDots from "../../shared/components/page/popup-dialog/CustomLoadingDots";
import InspectionsCardList from "./components/inspections-list/InspectionsCardList";

const InspectionsPage = () => {
    const { loggedInUser, selectedLocation } = useAppStore();
    const service = new InspectionService();
    const { reset, setHandleEditItem } = useInspectionsStore();

    const [state, setState] = useState<any>({
        inspections: [],
        inspectionToEdit: null,
        open: false,
        isLoading: false,
        summary: new InspectionSummary(),
        refresh: false,
        page: 0
    });

    const showMoreButtonRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setHandleEditItem(handleEditItem);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const limit = 10;
            const offset = state.page * limit;

            setState(prevState => ({ ...prevState, isLoading: true }));
            try {
                const summaryData = await service.getInspectionSummary(loggedInUser.organization.id, selectedLocation.id);
                const inspectionsData = await service.getInspections(loggedInUser.organization.id, selectedLocation.id, offset, limit);

                setState(prevState => ({
                    ...prevState,
                    summary: summaryData,
                    inspections: state.page === 0 ? inspectionsData : [...prevState.inspections, ...inspectionsData],
                    isLoading: false
                }));
            } catch (error) {
                console.error(error);
                setState(prevState => ({ ...prevState, isLoading: false }));
            }
        };

        if (selectedLocation.id !== 0) {
            fetchData().then();
        }

    }, [loggedInUser, selectedLocation.id, state.refresh, state.page]);

    const handleNewInspectionClicked = useCallback(() => {
        reset();
        setState(prevState => ({ ...prevState, inspectionToEdit: new Inspection(), open: true }));
    }, [reset]);

    const handleEditItem = useCallback((inspection) => {
        setState(prevState => ({ ...prevState, inspectionToEdit: inspection, open: true }));
    }, []);

    const handleShowMore = useCallback(() => {
        setState(prevState => ({ ...prevState, page: prevState.page + 1 }));
    }, []);

    const handleDeleteItem = useCallback(async (inspectionToDelete) => {
        if (inspectionToDelete) {
            try {
                await service.deleteInspection(inspectionToDelete.id);
                setState(prevState => ({ ...prevState, refresh: !prevState.refresh }));
            } catch (error) {
                console.error(error);
            }
        }
    }, [service]);

    return (
      <div>
          <PageHeader
            title='Inspections'
            actionButtonName='New Inspection'
            onActionClicked={handleNewInspectionClicked}
          />

          {selectedLocation.id === 0 || (state.isLoading && state.inspections.length === 0) ? (
            <div style={{ minHeight: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CustomLoadingDots />
            </div>
          ) : (
            <>
                <div style={{marginBottom: '120px', width: '100%'}} ref={showMoreButtonRef}>
                    <InspectionSummaryTiles summary={state.summary} />
                    <InspectionsCardList inspections={state.inspections} />
                    {state.inspections.length % 10 === 0 &&
                      <Button
                        sx={{
                            width: '100%',
                            padding: '12px',
                            marginBottom: '20px',
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            color: '#ffffff',
                            background: 'linear-gradient(45deg, #15aa78 30%, #5ec3a1 90%)',
                            borderRadius: '5px',
                            boxShadow: '0 3px 5px 2px rgba(.5, .5, .5, .3)',
                            textTransform: 'none',
                        }}
                        onClick={handleShowMore}
                      >
                          Load More
                      </Button>

                    }
                </div>
                <EditAreaInspectionPage
                  onDelete={handleDeleteItem}
                  state={state}
                  setState={setState}
                />
            </>
          )}
      </div>
    );
}

export default InspectionsPage;




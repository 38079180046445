import React from 'react';
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { ArrowBackIos, Close } from "@mui/icons-material";
import ModalTransition from "../transitions/ModalTransition";
import Dialog from "@mui/material/Dialog";

const ComponentViewerSmall = ({ selectedPage, onActionClicked }) => {
  return (

    <Dialog fullWidth maxWidth={"sm"} open={selectedPage} onClose={onActionClicked} TransitionComponent={ModalTransition} PaperProps={{ style: { overflowX: 'hidden', backgroundColor: 'white' } }}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onActionClicked} aria-label="close">
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{padding: '15px'}}>
        <Box sx={{ marginBottom: "15px" }}>
          {selectedPage}
        </Box>
      </Box>
    </Dialog>

  );
};

export default ComponentViewerSmall;
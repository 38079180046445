import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  AppBar,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import CommentsSection from "../../../../../../shared/components/comments/CommentsSection";

const CommentModal = ({ open, onClose, workItem, setWorkItem }) => {

  const [newComments, setNewComments] = useState<any>([]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Work Item Comments
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <CommentsSection
            item={workItem}
            newComments={newComments}
            setNewComments={setNewComments}
            type='workitems'
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommentModal;
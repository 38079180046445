import Dexie, { Table } from 'dexie';

class LocalDatabase extends Dexie {

  // Declare implicit table properties.
  // (just to inform Typescript. Instantiated by Dexie in stores() method)
  media!: Table<IMedia, number>; // number = type of the primkey
  //...other tables goes here...

  constructor () {
    super("LocalDatabase");
    this.version(1).stores({
      media: '++id, imageData',
      //...other tables goes here...
    });
  }
}

export const localDb = new LocalDatabase();


export interface IMedia {
  id?: number;
  imageData: Blob;
}
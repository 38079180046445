import { BaseSerializer } from "./baseSerializer";
import { EntityTypeSerializer } from "./entityTypeSerializer";
import { ServiceItem } from "../serviceItem.model";
import { ReasonSerializer } from "./reasonSerializer";
import { ServiceSerializer } from "./serviceSerializer";

export class ServiceItemSerializer extends BaseSerializer {

    public deserialize( data ): any {

        if (data === undefined || data === null || data === undefined) return;

        let service_item = new ServiceItem();

        this._copyAttributes(service_item, data);

        let serializer = new EntityTypeSerializer();
        const entity_type = serializer.deserialize(data.entity_type);
        service_item.entity_type = entity_type;

        serializer = new ReasonSerializer();
        const points = serializer.deserializeList(data.inspection_points);
        service_item.inspection_points = points;

        return service_item;

    }

}
import React from "react";

const DuotoneCategory = () => {

  return (
    <div className="symbol symbol-45px me-4">
      <span className="symbol-label bg-light-success">
                                <i className="ki-duotone ki-category fs-2x text-success">
                                    <span
                                      className="path1"></span>
                                    <span
                                      className="path2"></span>
                                    <span
                                      className="path3"></span>
                                </i>
                            </span>
    </div>
  );
};

export default DuotoneCategory;
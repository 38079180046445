import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { WorkItemAnalyticsSerializer } from "../../../models/serializers/workItemAnalyticsSerializer";

export class CommandGetWorkItemAnalytics {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/analytics/`;
  }

  async run(provider_id) {
    const url = this.getUrl();

    const params = {
      params: {
        provider: provider_id,
        module: 'workitems',
        charts: 'status_counts_all, progress_by_location, activity_by_location, aging_by_location'
      }
    }

    const tracer = useTracer.getState();
    tracer.trace('Analytics', 'Api', `GET ${url}`);

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Analytics', 'Error', `${error}`);
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      tracer.trace('Analytics', 'Api', 'Success');
    }
  }


  deserialize(data) {

    const serializer = new WorkItemAnalyticsSerializer()
    const workItemAnalytics = serializer.deserialize(data)

    return workItemAnalytics
  }

}


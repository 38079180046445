import { Position } from "./position.model";
import { LocationModel } from "./location.model"

export class Work {
  id: number;
  location: LocationModel;
  position: Position;

  constructor() {
    this.id = 0;
    this.location = new LocationModel();
    this.position = new Position();
  }
}

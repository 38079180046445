import { BaseSerializer } from "./baseSerializer";
import { WorkItemProgress } from "../workItemProgress.model";
import { WorkItemAge } from "../workItemAge.model";

export class WorkItemAgeSerializer extends BaseSerializer {


  public deserialize( data ): any {

    const workItemAge = new WorkItemAge();

    this._copyAttributes(workItemAge, data);

    return workItemAge;

  }

}
import React, { useState } from 'react';
import ScoresBySpaceType from './components/charts/ScoresBySpaceType';
import ScoresByTopLevelSpace from "./components/charts/ScoresByTopLevelSpace";
import IssuesByServiceItem from "./components/charts/IssuesByServiceItem";
import { Grid, Tabs, Tab, Box, useMediaQuery, useTheme } from "@mui/material";
import ScoresByLocation from "./components/charts/ScoresByLocation";
import PageHeader from "../../../../shared/components/page/page-header/PageHeader";
import { useAuth } from "../../../auth";
import ForwardIcon from '@mui/icons-material/Forward';
import useAppStore from "../../../../appStore";
import CustomDatePicker from "../../../../shared/components/forms/date-time/date-picker/CustomDatePicker";
import { DateTime } from 'luxon';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const InspectionAnalytics = () => {
  const { currentUser } = useAuth();
  const { selectedLocation } = useAppStore();
  const defaultLocationId = currentUser?.permissions.locations?.[0]?.id || 0;
  const selectedLocationId = selectedLocation.id || defaultLocationId;
  const [activeTab, setActiveTab] = useState(0);
  const [startDate, setStartDate] = useState(DateTime.now().minus({years: 1}));
  const [endDate, setEndDate] = useState(DateTime.now());
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));


  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue)
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue)
  }

  return (
    <>
      <div className='row mb-3'>
        <PageHeader title='Inspection Charts'
                    showAction={false}
        />


        <div className='d-flex pt-1' style={{flexDirection: isMobile ? 'column' : 'row', justifyContent: isMobile ? '' : 'space-between'}}>
          <Tabs value={activeTab} onChange={handleChange} aria-label="inspection tabs">
            <Tab label="Overall" />
            <Tab label="Selected Location" />
          </Tabs>
          {activeTab === 0 &&
            <div className="d-flex flex-row align-items-center"
                 style={{ maxWidth: "400px", paddingTop: isMobile ? "10px" : "" }}>
              <CustomDatePicker value={startDate} onChange={handleStartDateChange} quickPick={false} />
              <ForwardIcon sx={{ color: "#999" }} />
              <CustomDatePicker value={endDate} onChange={handleEndDateChange} quickPick={false} />
            </div>
          }
        </div>

        <TabPanel value={activeTab} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ScoresByLocation startDate={startDate} endDate={endDate} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ScoresBySpaceType
                location_id={0}
                sortOrder={"title"}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ScoresByTopLevelSpace
                location_id={selectedLocationId}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ScoresBySpaceType
                location_id={selectedLocationId}
                sortOrder={'asc'}
              />
            </Grid>
            <Grid item xs={12}>
              <IssuesByServiceItem
                location_id={selectedLocationId}
              />
            </Grid>
          </Grid>
        </TabPanel>
      </div>
    </>
  );
}

export default InspectionAnalytics;



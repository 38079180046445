import React, { useEffect, useState, useCallback } from 'react';
import {
  Dialog, DialogContent, DialogActions, Button, AppBar, IconButton, Typography,
  List, ListItem, ListItemText, CircularProgress, TextField, Box, Chip
} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import useAppStore from '../../../../../../../../appStore';
import { LocationService } from '../../../../../../../../shared/services/locations/locationService';
import { LocationModel } from '../../../../../../../../shared/models/location.model';

const LocationSelectionForm = ({ accessLocations, setAccessLocations, open, onClose }) => {
  const { loggedInUser } = useAppStore();
  const [locationList, setLocationList] = useState<LocationModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchLocations = useCallback(async () => {
    setIsLoading(true);
    try {
      const service = new LocationService();
      const locations = await service.getLocations(loggedInUser.organization.id);
      setLocationList(locations);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
    } finally {
      setIsLoading(false);
    }
  }, [loggedInUser.organization.id]);

  useEffect(() => {
    fetchLocations().then();
  }, [fetchLocations]);

  const toggleLocationSelection = useCallback((location: LocationModel) => {
    setAccessLocations((currentLocations) => {
      const isSelected = !!currentLocations.find(e => e.id === location.id);
      return isSelected
        ? currentLocations.filter(e => e.id !== location.id)
        : [...currentLocations, location];
    });
  }, [setAccessLocations]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Location Selection
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        {isLoading ? <CircularProgress /> : (
          <>
            <TextField
              margin="dense"
              variant="outlined"
              fullWidth
              placeholder="Search locations..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <List>
              {locationList.map(location => (
                <ListItem
                  key={location.id}
                  button
                  selected={!!accessLocations.find(e => e.id === location.id)}
                  onClick={() => toggleLocationSelection(location)}
                >
                  <ListItemText primary={location.name} />
                </ListItem>
              ))}
            </List>
          </>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onClose} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocationSelectionForm;


import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { EntityTypeSerializer } from "../../../models/serializers/entityTypeSerializer";

export class CommandUpdateEntityType {
  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(id) {
    return `${this.SERVICE}/entity_types/${id}/`;
  }

  async run(type) {
    const url = this.getUrl(type.id);
    const tracer = useTracer.getState();
    tracer.trace('EntityType', 'Api', `PUT ${url}`);

    try {
      const data = EntityTypeSerializer.serialize(type);
      const response = await httpClient.put(url, data);
      tracer.trace('EntityType', 'Api', 'Success');
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('EntityType', 'Error', `${error}`);
      console.error('Error:', error);
      throw error;
    }
  }

  deserialize(data) {
    const serializer = new EntityTypeSerializer();
    const item = serializer.deserialize(data);
    return item;
  }
}
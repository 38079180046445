import React from "react";
import { Button, Divider} from "@mui/material";
import SpaceHierarchy from "./components/left-panel/SpaceHierarchy";
import SpaceDetailsView from "./components/right-panel/SpaceDetailsView";

const SpacesDualViewer = (props) => {
  const {
    selectedSpace,
    onAdd,
    onEdit
  } = props;

  const [selectedLevel, setSelectedLevel] = React.useState<number>(0);

  return (
    <>
      <div className="card">
        <div className="card-body d-flex flex-row" style={{ alignItems: 'flex-start' }}>
          <div className="d-flex flex-column flex-row-auto w-250px" style={{ minHeight: '600px', overflow: 'auto' }}>
            <div className="d-flex justify-content-between align-items-center mb-6">
              <h3>Spaces</h3>
              <Button variant="contained" color="primary" onClick={onAdd}>
                {(selectedLevel === 0) ? '+ Building' : selectedLevel === 1 ? '+ Floor' : '+ Room'}
              </Button>
            </div>
            <SpaceHierarchy setSelectedLevel={setSelectedLevel} />
          </div>

          <Divider orientation="vertical" flexItem
                   style={{ backgroundColor: 'gray', marginRight: '10px', marginLeft: '10px' }} />


          <div className="d-flex flex-column flex-row-fluid ms-5"
               key={selectedSpace ? selectedSpace.id : "no-service"}>
            {selectedSpace?.name ? (
              <>
                  <SpaceDetailsView onEdit={onEdit} />
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center"
                   style={{ flex: 1, textAlign: "center", padding: "20px", marginTop: "50px" }}>
                <div>
                  <p style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "15px" }}>
                    No Building Selected
                  </p>
                  <p>Select an option from the left panel or add a new building</p>
                  <Button color="primary" onClick={onAdd} style={{ height: '35px' }}>
                    + Building
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SpacesDualViewer;

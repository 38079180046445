import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button, AppBar, IconButton,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import useServices from "../../../../shared/custom-hooks/data-fetch/useServices";
import useAppStore from "../../../../appStore";
import SearchableTreeView from "../../../../shared/components/forms/widgets/searchable-tree-view/SearchableTreeView";


const ParentSelection = ({ serviceToEdit, open, onSelect, onClose }) => {
  const { loggedInUser } = useAppStore()
  const {services} = useServices(loggedInUser)

  const onServiceSelected = (service) => {
    onSelect(service);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{ width: '80%', maxWidth: '800px', mx: 'auto' }}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <SearchableTreeView
          data={services}
          onItemSelected={onServiceSelected}
          itemToEdit={serviceToEdit}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ParentSelection;

import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { ActionPlanItemSerializer } from "../../../models/serializers/actionPlanItemSerializer";


export class CommandCreateActionItem {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(id) {

    return `${this.SERVICE}/crm/relationships/${id}/action_entries/`;
  }

  async run(relationship_id, actionItem) {

    const url = this.getUrl(relationship_id)

    const tracer = useTracer.getState()
    tracer.trace('Relationships', 'Api', `POST ${url}`)

    try {

      let data = ActionPlanItemSerializer.serialize(actionItem)
      const response = await httpClient.post(url, data);

      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Relationships', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    }finally {
      tracer.trace('Relationships', 'Api', 'Success')
    }

  }

  deserialize(data) {
    const serializer = new ActionPlanItemSerializer()
    const item = serializer.deserialize(data)
    return item
  }

}
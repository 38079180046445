import { CommandGetEmployees } from "./commands/cmdGetEmployees";
import { CommandGetPositions } from "./commands/cmdGetPositions";
import { CommandCreateEmployee } from "./commands/cmdCreateEmployee";
import { CommandUpdateEmployee } from "./commands/cmdUpdateEmployee";
import { CommandDeleteEmployee } from "./commands/cmdDeleteEmployee";
import { CommandGetEmployee } from "./commands/cmdGetEmployee";
import { Employee } from "../../models/employee.model";
import { CommandGetOrgChartEmployees } from "./commands/cmdGetOrgChartEmployees";
import { CommandSearchEmployees } from "./commands/cmdSearchEmployees";
import { CommandPostDepartment } from "./commands/cmdPostUserDepartment";
import { CommandGetEmpDepartments } from "./commands/cmdGetEmpDepartments";
import { CommandDeleteUserDepartment } from "./commands/cmdDeleteUserDepartment";

export class EmployeeService {

    async getEmployees(provider_id, location_id, offset=0, limit=Number.MAX_SAFE_INTEGER, search=null) {
        const cmd = new CommandGetEmployees();
        const results = await cmd.run(provider_id, location_id, offset, limit, search);
        return results;
    }

    async searchEmployees(provider, search) {
        const cmd = new CommandSearchEmployees();
        const results = await cmd.run(provider, search);
        return results;
    }

    async getOrgChartEmployees(provider_id, location_id, offset=0, limit=Number.MAX_SAFE_INTEGER) {
        const cmd = new CommandGetOrgChartEmployees();
        const results = await cmd.run(provider_id, location_id, offset, limit);
        return results;
    }

    async getEmployee(id) {
        if (id !== 0) {
            const cmd = new CommandGetEmployee();
            const result = await cmd.run(id);
            return result;
        } else {
            return new Employee();
        }
    }

    async getPositions(provider_id, offset=0, limit=10) {
        const cmd = new CommandGetPositions();
        const positions = await cmd.run(provider_id, offset, limit);
        return positions;
    }

    async getUserDepartments(provider_id, employee_id) {
        const cmd = new CommandGetEmpDepartments();
        const dep = await cmd.run(provider_id, employee_id);
        return dep;
    }

    async createEmployee(employee, provider_id, location_id) {
        const cmd = new CommandCreateEmployee();
        const createdEmployee = await cmd.run(employee, provider_id, location_id);
        return createdEmployee;
    }

    async linkDepartment(employee_id, department_id) {
        const cmd = new CommandPostDepartment();
        const dept = await cmd.run(employee_id, department_id);
        return dept;
    }

    async unlinkDepartment(department_id, employee_id) {
        const cmd = new CommandDeleteUserDepartment();
        const dept = await cmd.run(department_id, employee_id);
        return dept;
    }

    async updateEmployee(employee) {
        const cmd = new CommandUpdateEmployee();
        const updatedEmployee = await cmd.run(employee);
        return updatedEmployee;
    }

    async deleteEmployee(employeeId) {
        const cmd = new CommandDeleteEmployee();
        const result = await cmd.run(employeeId);
        return result;
    }
}

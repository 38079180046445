import React, { useEffect, useState } from "react";
import { Employee } from "../../../../../../shared/models/employee.model";
import { Button, Chip, Divider } from "@mui/material";
import EmployeeQuickSelect
    from "../../../../../../shared/components/forms/widgets/selection-forms/EmployeeQuickSelect";
import CancelIcon from "@mui/icons-material/Cancel";
import OrgChartSelect from "../../../../../../shared/components/forms/widgets/selection-forms/OrgChartSelect";

const AssignedTo = (props) => {
    const {
        workItemToEdit,
        setWorkItemToEdit,
        isEditing
    } = props;

    const [isEmployeeDialogOpen, setEmployeeDialogOpen] = useState(false);
    const [assignedEmployees, setAssignedEmployees] = useState<Array<Employee | null>>(
      workItemToEdit?.assigned_to_employees || []
    );

    useEffect(() => {
        if (workItemToEdit.assigned_to_employees) {
            setAssignedEmployees(workItemToEdit.assigned_to_employees);
        }
    }, [workItemToEdit.assigned_to_employees]);

    const handleSaveEmployees = async (workItem, employees) => {
        workItem.assigned_to_employees = employees;
        setAssignedEmployees(employees)
    };

  const handleRemoveEmployee = (employeeId) => {
    const updatedAssignedEmployees = assignedEmployees.filter(e => e?.id !== employeeId);
    setWorkItemToEdit(prevWorkItem => ({ ...prevWorkItem, assigned_to_employees: updatedAssignedEmployees }));
    setAssignedEmployees(assignedEmployees.filter(e => e?.id !== employeeId));
  };

  const renderSelectedEmployees = () => {
    return assignedEmployees.map(employee => (
      <Chip
        key={employee?.id}
        label={employee?.person.first_name + " " + employee?.person.last_name}
        onDelete={isEditing ? () => handleRemoveEmployee(employee?.id) : undefined}
        deleteIcon={isEditing ? <CancelIcon /> : undefined}
        sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
      />
    ));
  };


  return (
      <>
          <div className="flex-column">
            <div style={{marginBottom: '15px'}}>
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-700">Assigned to</span>
                </h3>
            </div>
              {assignedEmployees.length > 0 &&
                renderSelectedEmployees()
              }
            {isEditing &&
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setEmployeeDialogOpen(true)}
                  sx={{ width: '100%' }}
                >
                  {"SELECT ..."}
                </Button>
              </div>
            }
          </div>
        <EmployeeQuickSelect open={isEmployeeDialogOpen} onClose={() => setEmployeeDialogOpen(false)}
                             handleSave={handleSaveEmployees} item={workItemToEdit} />
      </>
    );
}

export default AssignedTo;
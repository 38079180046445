import React from 'react';
import ServiceCatalogTree from "./components/ServiceCatalogTree";
import useServiceStore from "../../../../serviceStore";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";

const ServiceCatalog = () => {
    const {
        services,
        fetchService,
        setSelectedServiceItem,
        servicesLoading,
        error
    } = useServiceStore();

    const onServiceSelected = async (selected) => {
        setSelectedServiceItem(null);
        try {
            await fetchService(selected.id);
        } catch (error) {
            console.error("Error fetching service:", error);
        }
    };

    if (servicesLoading) {
        return <div>Loading services...</div>;
    }

    if (error) {
        return <CustomError error={error} />;
    }

    return (
      <div>
          <ServiceCatalogTree
            data={services}
            onItemSelected={onServiceSelected}
          />
      </div>
    );
};

export default ServiceCatalog;


import { BaseSerializer } from "./baseSerializer";
import { StatusCounts } from "../statusCounts.model";
import { Activity } from "../activityModel";

export class ActivitySerializer extends BaseSerializer {


  public deserialize( data ): any {

    const activity = new Activity();

    this._copyAttributes(activity, data);

    return activity;

  }

}
import React, { useEffect, useState } from "react";
import { Employee } from "../../../../../../../shared/models/employee.model";
import { Box, Chip, Typography } from "@mui/material";
import EmployeeQuickSelect
    from "../../../../../../../shared/components/forms/widgets/selection-forms/EmployeeQuickSelect";
import CancelIcon from "@mui/icons-material/Cancel";


const CaseAssignees = (props) => {
    const {
        item,
        setItem,
    } = props;

    const [isEmployeeDialogOpen, setEmployeeDialogOpen] = useState(false);
    const [assignedEmployees, setAssignedEmployees] = useState<Employee[]>(item?.assigned_to_employees);

    useEffect(() => {
        if (item.assigned_to_employees) {
            setAssignedEmployees(item.assigned_to_employees);
        }
    }, [item.assigned_to_employees]);

    const handleSaveEmployees = async (item, employees) => {
        item.assigned_to_employees = employees;
        setAssignedEmployees(employees)
    };

  const handleRemoveEmployee = (employeeId) => {
    const updatedAssignedEmployees = assignedEmployees.filter(e => e?.id !== employeeId);
    setItem(prevWorkItem => ({ ...prevWorkItem, assigned_to_employees: updatedAssignedEmployees }));
    setAssignedEmployees(assignedEmployees.filter(e => e?.id !== employeeId));
  };

  const renderSelectedEmployees = () => {
    return (
      <div>
        {assignedEmployees.map(employee => (
          <Chip
            key={employee?.id}
            label={employee?.person.first_name + " " + employee?.person.last_name}
            onDelete={() => handleRemoveEmployee(employee?.id)}
            deleteIcon={<CancelIcon />}
            sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
          />
        ))}
        <Chip
          label={'+'}
          onClick={() => setEmployeeDialogOpen(true)}
          sx={{ m: 0.7, fontSize: '1.7rem', backgroundColor: '#c5c5c5', color: 'white', justifyContent: 'center' }}
        />
      </div>
    );
  };


  return (
      <>
          <div className="d-flex flex-column">
              <span className="text-gray-700">Assigned To</span>
            <Box sx={{border: '1px solid #c7c7c7', borderRadius: '5px'}}>
              <div style={{padding: '15px'}}>
                {assignedEmployees.length > 0 &&
                  renderSelectedEmployees()
                }
                {assignedEmployees.length === 0 &&
                  <div className="d-flex flex-row align-items-center justify-content-center text-center" onClick={() => setEmployeeDialogOpen(true)}>
                    <i className="fas fa-user-plus fs-2"></i>
                  </div>
                }
              </div>
            </Box>
          </div>
        <EmployeeQuickSelect open={isEmployeeDialogOpen} onClose={() => setEmployeeDialogOpen(false)}
                             handleSave={handleSaveEmployees} item={item} caseEmployees={false} />
      </>
  );
}

export default CaseAssignees;
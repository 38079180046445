import React from "react";
import UserItemRow from "./UserItemRow";


const UserItemsTable = (props) => {
  const { items, type='case' } = props;

  return (
    <>
      <table className={"w-100"}>
        <tbody>
        {items.map((item, index) => (
          <UserItemRow key={index} item={item} type={type} />
        ))}
        </tbody>
      </table>
    </>

  );
};

export default UserItemsTable;


import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { InspectionSummarySerializer } from "../../../models/serializers/inspectionSummarySerializer";

export class CommandGetInspectionSummary {

    INSPECTIONS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {

        return  `${this.INSPECTIONS_SERVICE}/analytics/`;
    }

    async run(provider_id, location_id) {

        const url = this.getUrl()

        const params = {
            params: {
                provider: provider_id,
                location: location_id,
                module: 'inspections',
                charts: 'inspection_summary'
            }
        }

        const tracer = useTracer.getState()

        tracer.trace('Inspections', 'Api', `GET ${url}`)

        try {
            const response = await httpClient.get(url, params);
            return this.deserialize(response.data);
        } catch (error) {
            tracer.trace('Inspections', 'Error', `${error}`)
            console.error('Error fetching data:', error);
            throw error;
        }finally {
            tracer.trace('Inspections', 'Api', 'Success')
        }

    }

    deserialize(data) {

        const serializer = new InspectionSummarySerializer()
        const summary = serializer.deserialize(data)

        return summary
    }

}
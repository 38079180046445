import { Box, Button } from "@mui/material";
import React from "react";

const SortOptions = ({ sortBy, setSortBy, sortItems, workItems, setWorkItems }) => {
  const handleClick = (sortCriterion) => {
    setSortBy(sortCriterion);
    const sortedItems = sortItems(workItems, sortCriterion);
    setWorkItems(sortedItems);
  };

  return (
    <Box sx={{ marginTop: '50px', display: 'flex', alignItems: 'center' }}>
      <span style={{marginLeft: '5px'}}>View By: </span>
      <Button sx={{ marginLeft: '8px', marginRight: '4px', color: (sortBy === 'urgency') ? '#888888' : '' }} size="small" onClick={() => handleClick('create_date')}>Most Recent</Button>
      <Button sx={{color: (sortBy === 'urgency') ? '' : '#888888' }} size="small" onClick={() => handleClick('urgency')}>Urgency</Button>
    </Box>
  );
};

export default SortOptions;
import { PostSerializer } from "../../../models/serializers/postSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";


export class CommandGetPosts {

    POSTS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {

        return  `${this.POSTS_SERVICE}/posts/`;
    }

    async run(org_id=8227, offset=0, limit=10, search=null) {

        const url = this.getUrl()

        const params = {
            params: {
                organization_id: org_id,
                offset: offset,
                limit: limit,
                search: search
            }
        }

        try {
            const response = await httpClient.get(url, params);
            return this.deserializePosts(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }

    }

    deserializePosts(data) {

        const serializer = new PostSerializer()
        const posts = serializer.deserializeList(data)

        return posts
    }

}
import React from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import IconButton from "@mui/material/IconButton";
import { styled } from '@mui/material/styles';
import MobileCamera from "./mobile-camera/MobileCamera";


const StyledSelectorContainer = styled('div')`
    display: flex;
    align-items: center;
    align-text: center;
    justify-content: center;
    padding: 8px;
    border: 1px solid #c7c7c7;
    margin-bottom: 2px;
    border-radius: 5px;
`;


const MediaSelector = ( props ) => {

    const {
        module
    } = props

    if (module==='profile' || module==='employees' || module==='locations') {
        return (
          <>
              <MobileCamera alt={true} size={'25px'}/>
          </>
        )
    }

    return (
        <>
            {(module === 'inspections' || module === 'workitems') &&
              <StyledSelectorContainer>
                  <label className='fw-bold fs-6' style={{ color: 'grey' }}>Add Media</label>
                  <span>
                    <MobileCamera />
                    <IconButton
                      color="inherit"
                      className="d-none"
                    >
                        <UploadFileIcon />
                    </IconButton>
                </span>
                  <span className="ms-3 border-dashed py-2 px-5 d-none">
                    + Drop Zone
                </span>
              </StyledSelectorContainer>
            }
            {(module === 'cases' || module === 'supplies') &&
              <>
                  <StyledSelectorContainer>
                      {module === "supplies" &&
                        <label className="fw-bold fs-6" style={{ color: "grey" }}>Upload SDS</label>
                      }
                      <MobileCamera alt={true} />
                  </StyledSelectorContainer>
              </>
            }
        </>
    )
}
export default MediaSelector;


import React, {useEffect, useState,} from "react";
import {Post} from "../../../../shared/components/social/post/Post";
import { PostsService } from "../../../../shared/services/posts/postService";
import {MixedWidget4, MixedWidget5} from "../../../../../_metronic/partials/widgets";
import {Fundraiser} from "./components/fundraiser/FundRaiser";
import { FundraiserService } from "../../../../shared/services/fundraisers/fundraiserService";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";

const FundRaiserList = (props) => {

    const {
        fundraisers,
        setFundraisers,
        onEditPostClicked,
        onDeletePostClicked
    } = props

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');

    useEffect(() => {

        const fetchFundraisers = async () => {

            setIsLoading(true);

            try {

                const service = new FundraiserService();
                const fundraisers = await service.getFundraisers(8227, 1, 50);

                setFundraisers(fundraisers);
                // resetScrollPosition();

            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                }
            }finally {
                setIsLoading(false);
            }
        };

        fetchFundraisers().then();

    }, []);
    if (isLoading) {
        return <CustomLoadingDots />;
    }
    if (error) {
        return <CustomError error={error} />;
    }

    return (
        <>
            <div className='row'>
                <div className='col-2'/>
                <div className='col-8'>
                    {fundraisers.map((fundraiser) => (
                        <Fundraiser fundraiser={fundraiser}
                                    image='https://hal-content-bucket.s3.us-east-2.amazonaws.com/1685122210551?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2IWJENXHC5VZKF4D%2F20230801%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20230801T052413Z&X-Amz-Expires=900&X-Amz-Signature=e6e56f16b3102582f4ab224fc672d03a77b25de0bf8c16e4f751b78e31a5e1a6&X-Amz-SignedHeaders=host'/>
                    ))}
                </div>
                <div className='col-2'/>
            </div>
        </>
    )

}

export default FundRaiserList
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { RelationshipSerializer } from "../../../models/serializers/relationshipSerializer";

export class CommandUpdateRelationship {
  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(id) {
    return `${this.SERVICE}/crm/relationships/${id}/`;
  }

  async run(relationship) {
    const url = this.getUrl(relationship.id);
    const tracer = useTracer.getState();
    tracer.trace('Relationships', 'Api', `PUT ${url}`);

    try {
      const data = RelationshipSerializer.serialize(relationship);
      const response = await httpClient.put(url, data);
      tracer.trace('Relationships', 'Api', 'Success');
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Relationships', 'Error', `${error}`);
      console.error('Error:', error);
      throw error;
    }
  }

  deserialize(data) {
    const serializer = new RelationshipSerializer();
    const item = serializer.deserialize(data);
    return item;
  }
}

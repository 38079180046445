import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";


export class CommandDeleteAssignment {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(node_id, employee_id) {
    return `${this.SERVICE}/reporting_groups/group_entries/${node_id}/employees/${employee_id}/`;
  }

  async run(node_id, employee_id) {

    const url = this.getUrl(node_id, employee_id)

    const tracer = useTracer.getState()
    tracer.trace('ReportingGroups', 'Api', `DELETE ${url}`)

    try {
      const response = await httpClient.delete(url);
    } catch (error) {
      tracer.trace('ReportingGroups', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    }finally {
      tracer.trace('ReportingGroups', 'Api', 'Success')
    }

  }


}
import { EntitySerializer } from "../../../models/serializers/entitySerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";


export class CommandGetPlaces{

    ENTITY_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {
        return  `${this.ENTITY_SERVICE}/entities/`;
    }

    async run(location_id, kind_of="Place", offset=0, limit=10) {

        const url = this.getUrl()

        const params = {
            params: {
                location: location_id,
                hier: true,
                kind_of: kind_of,
                offset: offset,
                limit: limit
            }
        }

        try {
            const response = await httpClient.get(url, params);
            return this.deserialize(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }

    }

    deserialize(data) {

        const serializer = new EntitySerializer()
        const entities = serializer.deserializeList(data)

        return entities
    }

}


export class StatusCounts {
  pending_count: number;
  assigned_count: number;
  closed_count: number;


  constructor() {
    this.pending_count = 0;
    this.assigned_count = 0;
    this.closed_count = 0;
  }

}
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { ServiceLogEntrySerializer } from "../../../models/serializers/serviceLogEntrySerializer";


export class CommandGetLog{

  ENTITY_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(id) {
    return  `${this.ENTITY_SERVICE}/service_logs/${id}/entries/`;
  }

  async run(area_id) {

    const url = this.getUrl(area_id)

    try {
      const response = await httpClient.get(url);
      return this.deserialize(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }

  }

  deserialize(data) {
    const serializer = new ServiceLogEntrySerializer()
    const log = serializer.deserializeList(data)
    return log
  }

}
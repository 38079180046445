import React, { useState, useEffect } from "react";
import {
  AppBar,
  Dialog,
  IconButton,
  Typography,
  Toolbar,
  DialogContent,
  Box,
  DialogActions, Button
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CustomLoadingDots from "../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { EmployeeService } from "../../../shared/services/employee/employeeService";
import { Employee } from "../../../shared/models/employee.model";
import useAppStore from "../../../appStore";
import OrgChartSelect from "../../../shared/components/forms/widgets/selection-forms/OrgChartSelect";

const EditOrgChartEmployee = (props) => {

  const {
    open,
    node,
    onActionClicked,
    onSave
  } = props

  const { selectedLocation, loggedInUser } = useAppStore();
  const [isLoading, setIsLoading] = useState(false);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(node.employee);


  useEffect(() => {
    const fetchEmployees = async () => {
      setIsLoading(true);
      try {
        const service = new EmployeeService();
        const employees = await service.getOrgChartEmployees(loggedInUser.organization.id, selectedLocation.id);
        setEmployees(employees);
      } catch (error) {
        if (error instanceof Error) {
          console.log(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchEmployees().then();
  }, []);


  const handleSave = async () => {
    onSave(node, selectedEmployee)
    onActionClicked();
  };

  const handleUnassign = () => {
    setSelectedEmployee(null);
  }

  const handleEmployeeSelect = (employee) => {
    setSelectedEmployee(employee);
  };

  return (
    <Dialog open={open} onClose={onActionClicked} fullWidth maxWidth="md">
      <AppBar position="relative">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onActionClicked} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ ml: 2, flex: 1, color: 'white' }}>
            Assign
          </Typography>
          <Button color="inherit" variant="contained" onClick={() => handleUnassign()} sx={{ color: '#444444' }}>
            Unassign
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        { isLoading ? (
          <CustomLoadingDots />
        ) : (
          <Box sx={{ mt: 1 }}>
            <div className={`node-container`} style={{marginBottom: 2}}>
              <div className="node-content">
                <img
                  src="/media/avatars/blank.png"
                  alt={`No Image`}
                  style={{ width: '75px', height: '75x', borderRadius: '50%', objectFit: 'cover' }}
                />
                <div className="mt-1 fw-bold">
                  {selectedEmployee && selectedEmployee.id !== -1
                    ? `${selectedEmployee.person.first_name} ${selectedEmployee.person.last_name}`
                    : "Open"}
                </div>
                <div className="text-muted fs-7">{node.name}</div>
              </div>
              <OrgChartSelect
                employees={employees}
                onEmployeeSelect={handleEmployeeSelect}
                selectedEmployee={selectedEmployee}
              />
            </div>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onActionClicked}>Close</Button>
        <Button
          onClick={handleSave}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditOrgChartEmployee;

import { create } from 'zustand'
import { ThreadTrace } from "../../models/ThreadTrace.model";
import { Trace } from '../../models/trace.model';


export interface Tracer {
  tracerThread: ThreadTrace;
  newThread: () => void;
  trace: (module, condition, message) => void;

}

const useTracer = create<Tracer>((set, get) => ({

  tracerThread: new ThreadTrace(),

  newThread: () => {

    const nt = new ThreadTrace()
    set({tracerThread: nt})

  },

  trace: (module, condition, message) => {

    let trace = new Trace()
    trace.module = module
    trace.condition = condition
    trace.message = message

    const thread = get().tracerThread;

    thread.traces.push(trace)

    set({ tracerThread: thread });
  },



}));

export default useTracer

import {toAbsoluteUrl} from "../../../_metronic/helpers";

export class Position {

    id: number;
    name: string;


    constructor() {
        this.id = 0;
        this.name = '';
    }

}


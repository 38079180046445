import React, { useEffect, useState } from "react";
import useAppStore from "../../../../appStore";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { LocationService } from "../../../../shared/services/locations/locationService";
import { Box, Divider, FormControlLabel, Switch, Typography, useMediaQuery, useTheme } from "@mui/material";
import MediaUploader from "../../../../shared/components/media/media-uploader/MediaUploader";
import { LocationContractsModel } from "../../../../shared/models/locationContracts.model";
import ContractDisplay from "./ContractDisplay";
import CustomDatePicker from "../../../../shared/components/forms/date-time/date-picker/CustomDatePicker";
import { format } from "date-fns";

const LocationContracts = ({readOnly=false}) => {
  const { loggedInUser, selectedLocation, whiteLabelProps } = useAppStore();
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const service = new LocationService();
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [contracts, setContracts] = useState<LocationContractsModel>(new LocationContractsModel());
  const uploadUrl = process.env.REACT_APP_API_FACILITIES_SERVICE_URL + '/media/upload/';
  const [keyDates, setKeyDates] = useState(true);
  const startDate = contracts.start_date
    ? format(new Date(contracts.start_date), 'MMM d, yyyy')
    : "--";
  const endDate = contracts.end_date
    ? format(new Date(contracts.end_date), 'MMM d, yyyy')
    : "--";

  useEffect(() => {
    const fetchDetails = async () => {
      setIsLoading(true);
      try {
        const location = await service.getContracts(selectedLocation?.id);
        setContracts(location);
      } catch (error) {
        console.error('Failed to fetch location media:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedLocation?.id) {
      fetchDetails().then();
    }
  }, [selectedLocation?.id, refresh]);

  const handleSave = async (startDate, endDate, updatedMedia = contracts.media) => {
    try {
      await service.updateContracts({
        ...contracts,
        media: updatedMedia,
        start_date: startDate,
        end_date: endDate,
      }, selectedLocation);
    } catch (e) {
      console.error('Error in saving location media:', e);
    } finally {
      setRefresh(!refresh);
    }
  };

  const handleDateChange = (startDate, endDate) => {
    handleSave(startDate, endDate);
  };

  const handleUploadComplete = (newItem, type) => {
    newItem.type = type;
    const updatedMedia = [...contracts.media];
    const existingIndex = updatedMedia.findIndex(media => media.type === type);

    if (existingIndex !== -1) {
      updatedMedia[existingIndex] = newItem;
    } else {
      updatedMedia.push(newItem);
    }

    handleSave(contracts.start_date, contracts.end_date, updatedMedia).then();
  };

  return (
    <div className="card w-100">
      <div className="card-body" style={{ minHeight: '600px', width: '100%' }}>
        <div>
          <>
            <div className="row mb-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div className="col" style={{ flex: 1 }}>
                <h2 style={{ margin: 0 }}>Location Contracts</h2>
              </div>
              {!readOnly && (
                <div className="col text-end" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <FormControlLabel
                    control={<Switch checked={keyDates} onChange={() => setKeyDates(!keyDates)} name="isUserToggle" color="primary" />}
                    label="Start/End Dates"
                  />
                </div>
              )}
            </div>
            <Divider sx={{marginTop: 2}}/>
          </>
          <div className="d-flex flex-column">
            {isLoading ? (
              <CustomLoadingDots />
            ) : (
              <>
                <Box className="d-flex flex-column mt-6" sx={{
                  backgroundColor: '#g4g4g4',
                  borderRadius: '5px',
                  justifyContent: 'space-between',
                  border: '1px solid #e2e2e2',
                  margin: 1,
                  width: !isMobile ? '315px' : '275px'
                }}>
                  <Box className="p-4 d-flex flex-column">
                    {keyDates &&
                      <>
                        {readOnly ? (
                          <>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between'  ,marginBottom: "13px" }}>
                              <Typography variant="subtitle1" sx={{ fontSize: "1.3rem", width: '150px' }}>
                                Contract Start:
                              </Typography>
                              <Typography variant="subtitle1" sx={{ fontSize: "1.3rem", fontWeight: 'bold', color: whiteLabelProps.primary_color }}>
                                {startDate || "--"}
                              </Typography>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
                              <Typography variant="subtitle1" sx={{ fontSize: "1.3rem", width: '150px'}}>
                                Contract End:
                              </Typography>
                              <Typography variant="subtitle1" sx={{ fontSize: "1.3rem", fontWeight: 'bold', color: whiteLabelProps.primary_color }}>
                                {endDate || "--"}</Typography>
                            </div>
                          </>
                        ) : (
                          <>
                            <Box>
                              <Typography variant="h6" sx={{ marginBottom: '6px' }}>Contract Start Date</Typography>
                              <CustomDatePicker
                                value={contracts.start_date}
                                onChange={(date) => handleDateChange(date.split('T')[0], contracts.end_date.toString().split('T')[0])}
                                quickPick={false}
                                prefill={false}
                              />
                            </Box>
                            <Box sx={{ mt: 3, mb: 3 }}>
                              <Typography variant="h6" sx={{ marginBottom: '6px' }}>Contract End Date</Typography>
                              <CustomDatePicker
                                value={contracts.end_date}
                                onChange={(date) => handleDateChange(contracts.start_date.toString().split('T')[0], date.split('T')[0])}
                                quickPick={false}
                                prefill={false}
                              />
                            </Box>
                          </>
                        )}
                      </>
                    }

                    <div className="d-flex flex-column">
                      {!readOnly &&
                        <>
                          <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px", marginTop: "10px" }}>
                            <Typography sx={{ fontSize: "1.3rem", marginRight: "10px" }}>Upload New SOW: </Typography>
                            <MediaUploader
                              uploadUrl={uploadUrl}
                              onUploadComplete={(item) => handleUploadComplete(item, "SOW")}
                              organization_id={3}
                              person_id={loggedInUser?.employee.person.id}
                              module={"locations"} />
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ fontSize: "1.3rem", marginRight: "10px" }}>Upload New MSA: </Typography>
                            <MediaUploader
                              uploadUrl={uploadUrl}
                              onUploadComplete={(item) => handleUploadComplete(item, "MSA")}
                              organization_id={3}
                              person_id={loggedInUser?.employee.person.id}
                              module={"locations"} />
                          </Box>
                        </>
                      }
                    </div>
                  </Box>
                </Box>
                <ContractDisplay itemMedia={contracts.media} readOnly={readOnly} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationContracts;

import { buildHierarchyPath } from "../utils/hierarchy/buildHierarchyPath";
import { ServiceMethod } from "./serviceMethods.model";
import { Organization } from "./organization.model";

export class Service {

    id: number | null;
    organization: Organization;
    name: string;
    description: string;
    children: Service[];
    service_path: any[];
    parent: Service | undefined;
    service_methods: ServiceMethod[];
    path: string;

    constructor() {
        this.id = null;
        this.organization = new Organization();
        this.name = '';
        this.description = '';
        this.children = [];
        this.service_path = [];
        this.service_methods = [];
        this.parent = undefined;
        this.path = '';
    }

    getPath(){
        if (this.id) {
            return buildHierarchyPath(this.service_path)
        }
    }


}


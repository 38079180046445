import React, { useEffect, useState } from 'react';
import useAppStore from '../../../../../../appStore';
import DualBarChartComponent from "./components/DualBarChartComponent";
import { fetchScoresByType } from "../../../../../../shared/utils/data-fetch/analyticDataFetchHelper";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";

type ChartHeaderRow = [string, string, string];
type ChartDataRow = [string, number, number];
type ChartDataState = Array<ChartHeaderRow | ChartDataRow>;

const ScoresBySpaceType = ({ location_id, sortOrder, startDate=null, endDate=null }) => {
  const { whiteLabelProps, loggedInUser, selectedLocation } = useAppStore();
  const [chartData, setChartData] = useState<ChartDataState>([['Space Type', 'Average', 'Joint Average']]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    setIsLoading(true);
    setError('');
    fetchScoresByType(loggedInUser.organization.id, location_id, startDate, endDate)
      .then(data => {
        setChartData([...data]);
      })
      .catch(error => {
        setError(error instanceof Error ? error.message : String(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [loggedInUser.organization.id, location_id, startDate, endDate]);

  if (isLoading) {
    return <CustomLoadingDots />
  }

  return (
    <DualBarChartComponent
      chartData={chartData}
      chartTitle={
        location_id === 0
          ? "Space Type Scores"
          : (selectedLocation && selectedLocation.name ? selectedLocation.name : "") + " Space Type Scores"
      }
      primaryColor={whiteLabelProps.primary_color}
      secondaryColor={location_id === 0 ? whiteLabelProps.primary_color_light : undefined}
      max={5}
      sortOrder={sortOrder}
  />
  );
};

export default ScoresBySpaceType;


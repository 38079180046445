
export class ReportingGroupScore {
  id: number;
  name: string;
  avg_score: number;

  constructor() {
    this.id = 0;
    this.name = "";
    this.avg_score = 0.0;
  }

}
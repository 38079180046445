import React, { useState } from "react";
import { Box, Chip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import EmployeeSelect from "../../../../../shared/components/forms/widgets/selection-forms/EmployeeSelect";


const RelationshipAssignees = (props) => {
  const {
    assignedTo,
    setAssignedTo,
  } = props;

  const [isEmployeeDialogOpen, setEmployeeDialogOpen] = useState(false);

  const handleRemoveEmployee = (employeeId) => {
    const updatedAssignedEmployees = assignedTo.filter(e => e?.id !== employeeId);
    setAssignedTo(updatedAssignedEmployees);
  };

  const renderSelectedEmployees = () => {
    return (
      <div>
        {assignedTo.map(employee => (
          <Chip
            key={employee?.id}
            label={
              (!employee?.person.first_name && !employee?.person.last_name)
                ? 'Me'
                : `${employee?.person.first_name || ''} ${employee?.person.last_name || ''}`
            }
            onDelete={() => handleRemoveEmployee(employee?.id)}
            deleteIcon={<CancelIcon />}
            sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
          />
        ))}
        <Chip
          label={'+'}
          onClick={() => setEmployeeDialogOpen(true)}
          sx={{ m: 0.7, fontSize: '1.7rem', backgroundColor: '#c5c5c5', color: 'white', justifyContent: 'center' }}
        />
      </div>
    );
  };


  return (
    <>
      <div className="d-flex flex-column">
        <Box sx={{border: '1px solid #c7c7c7', borderRadius: '5px'}}>
          <div style={{padding: '15px'}}>
            {assignedTo.length > 0 &&
              renderSelectedEmployees()
            }
            {assignedTo.length === 0 &&
              <div className="d-flex flex-row align-items-center justify-content-center text-center" onClick={() => setEmployeeDialogOpen(true)}>
                <i className="fas fa-user-plus fs-2"></i>
              </div>
            }
          </div>
        </Box>
      </div>
      <EmployeeSelect open={isEmployeeDialogOpen} onClose={() => setEmployeeDialogOpen(false)} selection={assignedTo} setSelection={setAssignedTo} />
    </>
  );
}

export default RelationshipAssignees;
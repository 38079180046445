
export class Activity {
  id: number;
  name: string
  first_name: string
  last_name:string
  count_last_3_days: number;
  count_3_to_7_days: number;
  count_8_to_30_days: number;

  constructor() {
    this.id = 0;
    this.name = '';
    this.first_name = '';
    this.last_name = '';
    this.count_last_3_days = 0;
    this.count_3_to_7_days = 0;
    this.count_8_to_30_days = 0;
  }
}
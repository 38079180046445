import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import React, {useEffect, useState,} from "react";
import PrioritizeProjectsTab from './prioritize-projects/PrioritizeProjectsTab';
import PlanProjectsTab from './plan-projects/PlanProjectsTab';
import TrackProjectsTab from './track-projects/TrackProjectsTab';
import CloseProjectsTab from './close-projects/CloseProjectsTab';
import ExploreProjectsTab from './explore-projects/ExploreProjectsTab';
import LaunchProjectsTab from "./launch-projects/LaunchProjectsTab";
import { fetchProjects, useProjectStore } from "../../projectsStore";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";


const ProjectsList = (props) => {

    const {
        onEdit,
        onDelete,
    } = props

    const { projects, setProjects } = useProjectStore();

    const [value, setValue] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const projects = await fetchProjects();
                setProjects(projects);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [setProjects]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if (isLoading) {
        return <CustomLoadingDots />;
    }
    if (error) {
        return <CustomError error={error} />;
    }

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={'mt-5'}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Prioritize" {...a11yProps(0)} />
                            <Tab label="Plan" {...a11yProps(1)} />
                            <Tab label="Launch (3)" {...a11yProps(2)} />
                            <Tab label="Track (1)" {...a11yProps(3)} />
                            <Tab label="Close (2)" {...a11yProps(4)} />
                            <Tab className="ms-12" label="Explore" {...a11yProps(5)} />
                        </Tabs>
                    </Box>
                    <PrioritizeProjectsTab value={value} index={0} onEdit={onEdit}>
                        Prioritize
                    </PrioritizeProjectsTab>
                    <PlanProjectsTab value={value} index={1}>
                        Plan
                    </PlanProjectsTab>
                    <LaunchProjectsTab value={value} index={2}>
                        Launch
                    </LaunchProjectsTab>
                    <TrackProjectsTab value={value} index={3}>
                        Track
                    </TrackProjectsTab>
                    <CloseProjectsTab value={value} index={4}>
                        Close
                    </CloseProjectsTab>
                    <ExploreProjectsTab value={value} index={5}>
                        Explore
                    </ExploreProjectsTab>
                </div>
            </div>
        </>
    )

}


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default ProjectsList
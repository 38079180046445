import { CommandGetRoles } from "./commands/cmdGetRoles";

export class UserService {
  async getRoles(provider_id) {
    const cmd = new CommandGetRoles();
    const positions = await cmd.run(provider_id);
    return positions;
  }

}

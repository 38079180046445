import { Media } from "../media.model";
import { BaseSerializer } from "./baseSerializer";
import { GroupEntry } from "../groupEntry.model";

export class GroupEntrySerializer extends BaseSerializer {


    public deserialize( data ): any {

        const entry = new GroupEntry();

        this._copyAttributes(entry, data);

        return entry;

    }

}
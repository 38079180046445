import React, { useEffect, useState } from "react";
import {
  AppBar, Dialog, Grid, IconButton, Toolbar, Typography
} from "@mui/material";
import { CloseIcon } from "yet-another-react-lightbox";
import ModalTransition from "../../../../../../shared/components/page/transitions/ModalTransition";
import SpaceDetailsEdit from "./components/SpaceDetailsEdit";


const EditSpace = ({ spaceToEdit, setSpaceToEdit, open, onClose, onSave }) => {

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={ModalTransition}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Edit Space
          </Typography>
        </Toolbar>
      </AppBar>

      <div style={{padding: '20px', paddingLeft: '50px', paddingRight: '50px'}}>
        <SpaceDetailsEdit onSave={onSave} spaceToEdit={spaceToEdit} setSpaceToEdit={setSpaceToEdit} />
      </div>


    </Dialog>
  );
}

export default EditSpace;
import React from "react";
import { Divider } from "@mui/material";

const ClosingComment = (props) => {

  const {
    closingComment,
    setClosingComment,
  } = props

  const handleChange = (event) => {
    setClosingComment(event.target.value);
  }

  return (
    <>
      <div className="flex-column-fluid mb-20">
        <div className="flex-row-fluid">
          <div style={{ marginBottom: '25px' }}>
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-gray-700 mb-5">Closing Comment</span>
            </h3>
            <Divider />
          </div>
          <div>
            <input
              type="text"
              value={closingComment}
              onChange={handleChange}
              className="form-control"
              placeholder="Enter your closing comment here..."
            />
          </div>
        </div>
      </div>
    </>
  )

}

export default ClosingComment

/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {AuthModel} from '../core/_models'
import {UserSerializer} from "../../../shared/models/serializers/userSerializer";
import InputMask from 'react-input-mask';
import useAppStore from "../../../appStore";
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import { Button, CircularProgress } from "@mui/material";


const loginSchema = Yup.object().shape({
  phone_number: Yup.string()
    .min(10, 'Minimum 10 digits')
    .max(14, 'Maximum 10 digits')
    .required('Phone number is required'),
  password: Yup.string()
    .min(0, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
})

const initialValues = {
  phone_number: '',
  password: '',
}

const sanitizePhoneNumber = (number) => {
  return number.replace(/\D/g, '');
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const { whiteLabelProps } = useAppStore();
  const getOtpValue = () => otp.join('');



  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setStatus('')
      try {
        if (step === 1) {
          const cleanPhoneNumber = sanitizePhoneNumber(values.phone_number);
          const response = await login(cleanPhoneNumber, null)
          setPhone(cleanPhoneNumber)
          setStep(2)
        }
        else {
          const response = await login(phone, getOtpValue())
          const serializer = new UserSerializer()
          const user = serializer.deserialize(response.data.user)

          let auth_model: AuthModel = {
            token: response.data.token,
            user: user
          }

          if (user.external_id == '' || user.external_id == undefined){
            user.external_id = 'service_connect_' + user.id
          }

          saveAuth(auth_model)
          setCurrentUser(auth_model.user)
        }

      } catch (error: any) {
        console.error(error);
        saveAuth(undefined);
        setSubmitting(false);
        setLoading(false);

        if (error.message === "Network Error" || !error.response) {
          setStatus('Unable to connect to the server. Please check your internet connection and try again.');
        } else {
          const statusCode = error.response ? error.response.status : null;
          if (statusCode === 400) {
            setStatus('Login details incorrect. Please go back and try again.');
          } else {
            setStatus('No user with the provided phone number exists.');
          }
        }
      } finally {
        setLoading(false);
      }
    },
  })

  const handleOtpChange = (element, index) => {
    if (element.value === '' || /^[0-9]$/.test(element.value)) {
      const newOtp = [...otp];
      newOtp[index] = element.value;
      setOtp(newOtp);

      if (element.nextSibling && element.value) {
        element.nextSibling.focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && event.target.value === '' && index > 0) {
      event.preventDefault();
      const newOtp = [...otp];
      newOtp[index - 1] = '';
      setOtp(newOtp);
      event.target.previousSibling.focus();
    }
  };

  const handlePaste = (event) => {
    const paste = event.clipboardData.getData('text').trim();

    if (paste.length === otp.length && /^[0-9]+$/.test(paste)) {
      event.preventDefault();

      const newOtp = paste.split('');
      const promises = newOtp.map((digit, index) => {
        return new Promise(resolve => {
          setOtp(prevOtp => {
            const updatedOtp = [...prevOtp];
            updatedOtp[index] = digit;
            resolve(updatedOtp);
            return updatedOtp;
          });
        });
      });

      Promise.all(promises).then(() => {
        const lastInput = document.querySelector(`input[name='otp-${otp.length - 1}']`) as HTMLInputElement;
        if (lastInput) {
          lastInput.focus();
        }
      });
    }
  };




  const handleGoBack = () => {
    setStep(1);
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center align-content-center'>
        {step === 2 &&
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            backgroundColor: whiteLabelProps.primary_color,
            margin: 'auto',
            marginBottom: '15px',
          }}>
            <LocalPhoneRoundedIcon />
          </div>
        }
        <h1 className='text-dark fw-bolder mb-3'>{step === 1 ? 'Sign In' : 'Verify your phone number'}</h1>
        {step === 2 &&
          <p className='text-muted fs-6'>Enter the 6-digit code that was sent to your phone number ending in <strong>{phone.slice(-4)}</strong></p>
        }
        <div className='text-gray-500 fw-semibold fs-6 invisible'>Your Social Campaigns</div>
      </div>
      {/* begin::Heading */}

      {step === 1 &&
        <div className='fv-row mb-4'
             style={{
              width: '75%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: 'auto'
              }}>
          <label className='form-label fs-6 fw-bolder text-muted'>Phone Number</label>
          <InputMask
            mask="(999) 999-9999"
            maskChar=" "
            placeholder="(123) 456-7890"
            value={formik.values.phone_number}
            onChange={formik.handleChange('phone_number')}
            onBlur={formik.handleBlur('phone_number')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.phone_number && formik.errors.phone_number},
              {
                'is-valid': formik.touched.phone_number && !formik.errors.phone_number && step === 1,
              }
            )}
          />
          {formik.touched.phone_number && formik.errors.phone_number && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.phone_number}</span>
            </div>
          )}
        </div>
      }

      {step === 2 && (
        <>
          <label className='form-label fw-bolder text-dark fs-6 mb-0 mt-0'></label>
          <div
            onPaste={handlePaste}
            style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '5px',
            marginBottom: '5px',
          }}>
            {otp.map((data, index) => {
              return (
                <input
                  key={index}
                  name={`otp-${index}`}
                  id={`otp-${index}`}
                  type='text'
                  maxLength={1}
                  value={data}
                  onChange={(e) => handleOtpChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  style={{
                    width: '38px',
                    height: '40px',
                    textAlign: 'center',
                    fontSize: '1rem',
                    border: '1px solid #ccc',
                    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                    borderRadius: '10%',
                  }}
                  inputMode="numeric"
                />

              );
            })}
          </div>

          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </>
      )}

      {formik.status && (
        <div className="alert alert-danger" role="alert">
          {formik.status}
        </div>
      )}

      {/* begin::Action */}
      <div className='d-grid mb-10 mt-10'
           style={{
            width: '75%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 'auto'
          }}>
        <Button
          type='submit'
          id='kt_sign_in_submit'
          variant='contained'
          color='primary'
          disabled={formik.isSubmitting || !formik.isValid}
          style={{ backgroundColor: whiteLabelProps.primary_color }}
        >
          {loading ? (
            <>
              Please wait...
              <CircularProgress size={24} style={{ marginLeft: '10px' }} />
            </>
          ) : (
            "Continue"
          )}
        </Button>
        {step === 2 && (
          <button
            type="button"
            onClick={handleGoBack}
            style={{
              background: 'none',
              border: 'none',
              padding: '0',
              color: 'black',
              textDecoration: 'underline',
              cursor: 'pointer',
              marginTop: '10px'
            }}
          >
            Go Back
          </button>
        )}
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6 invisible'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div>
    </form>
  )
}

import { BaseSerializer } from "./baseSerializer";
import { Respondent } from "../respondent.model";

export class RespondentSerializer extends BaseSerializer {

  public deserialize(data): Respondent {
    const respondent = new Respondent();
    this._copyAttributes(respondent, data);

    respondent.name = data.recipient_first_name + " " + data.recipient_last_name;

    return respondent;
  }

}

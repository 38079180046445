import useTracer from "../../../framework/tracer/tracer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { ServiceMethodSerializer } from "../../../models/serializers/serviceMethodSerializer";

export class CommandUpdateServiceMethod {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(service_id, service_method_id) {

    return `${this.SERVICE}/services/${service_id}/service_methods/${service_method_id}/`;
  }

  async run(service, service_method) {

    const url = this.getUrl(service.id, service_method.id)

    const tracer = useTracer.getState()
    tracer.trace('Services', 'Api', `PATCH ${url}`)

    try {

      const data = ServiceMethodSerializer.serialize(service_method)
      const response = await httpClient.patch(url, data);

      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Services', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    } finally {
      tracer.trace('Services', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new ServiceMethodSerializer()
    const item = serializer.deserialize(data)

    return item
  }

}


import { BaseSerializer } from "./baseSerializer";
import { AccessLevel } from "../accessLevel.model";

export class AccessLevelSerializer extends BaseSerializer {


    public deserialize( data ): any {

        let access_level = new AccessLevel();

        this._copyAttributes(access_level, data);

        return access_level;

    }

}
/* eslint-disable jsx-a11y/anchor-is-valid */
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import React from "react";
import { KTIcon } from "../../../../../../../../_metronic/helpers/components/KTIcon";


const ActionMenu = (props) => {

    const {
        onActionClicked
    } = props

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleActionClicked = (actionName) => {
        onActionClicked(actionName);
        setAnchorEl(null);
    }

    return (
        <>
            {/* begin::Menu */}
            <div className='my-0'>
                <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    //data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={handleClick}
                >
                    <KTIcon iconName='category' className='fs-2' />
                </button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem onClick={() => {handleActionClicked('edit')}}>Edit</MenuItem>
                    <MenuItem onClick={() => {handleActionClicked('delete')}}>Delete</MenuItem>
                </Menu>

            </div>
            {/* end::Menu */}
        </>

    )
}

export {ActionMenu}

import React from 'react';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';


const InfoTile = ({ title, value, onClick, selected }) => (
  <Card
    variant="outlined"
    sx={{
      height: '50px',
      marginRight: '10px',
      cursor: 'pointer'
    }}
    onClick={onClick}
  >
    <CardContent
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: selected ? '#e8fff3' : 'white',
        height: '100%',
        padding: '16px',
        paddingRight: selected ? '2px' : '16px',
        '&:last-child': { paddingBottom: '16px' }
      }}
    >
      <Typography variant="h6" sx={{ textAlign: 'center', flexGrow: 1 }}>
        {title}: {value}
      </Typography>

      {selected && (
        <IconButton
          sx={{
            color: 'black',
            marginLeft: '8px',
          }}
        >
          <CancelTwoToneIcon fontSize="medium" sx={{color: '#c4c4c4'}} />
        </IconButton>
      )}
    </CardContent>
  </Card>
);

export default InfoTile;

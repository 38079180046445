import { useState, useEffect } from 'react';
import { EntityService } from "../../services/entities/entityService";
import { EntityTypeService } from "../../services/entity-types/entityTypeService";
import { ServicePlanService } from "../../services/service-plans/servicePlanService";
import useAppStore from "../../../appStore";
import { ServiceItem } from "../../models/serviceItem.model";
import { EntityType } from "../../models/entityType.model";
import { Entity } from "../../models/entity.model";

const useEntityService = new EntityService();
const useEntityTypeService = new EntityTypeService();
const servicePlanService = new ServicePlanService();

export const useEntityFetch = (spaceId , floorId, activeStep) => {
  const {selectedLocation} = useAppStore();
  const [entities, setEntities] = useState<Entity[]>([]);
  const [entityTypes, setEntityTypes] = useState<EntityType[]>([]);
  const [floors, setFloors] = useState<Entity[]>([]);
  const [relevantServices, setRelevantServices] = useState<ServiceItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [floorsLoading, setFloorsLoading] = useState(false);
  const [typesLoading, setTypesLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);


  useEffect(() => {
    if (selectedLocation.id === 0 || activeStep === 1) return;

    const fetchEntities = async () => {
      setIsLoading(true);
      try {
        const data = await useEntityService.getEntities(selectedLocation.id);
        setEntities(data);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    setEntities([])
    fetchEntities();
  }, [selectedLocation.id]);


  useEffect(() => {

    if (selectedLocation.id === 0 || spaceId === 0 || floorId === 0 || activeStep === 1) return;

    const fetchEntityTypes = async () => {
      setTypesLoading(true);
      try {
        const types = await useEntityTypeService.getEntityTypes(selectedLocation.id, 'Place', floorId);
        setEntityTypes(types);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setTypesLoading(false);
      }
    };

    setEntityTypes([]);
    fetchEntityTypes().then();
  }, [selectedLocation.id, floorId]);



  useEffect(() => {
    if (selectedLocation.id === 0 || spaceId === 0 || activeStep === 1) return;

    const fetchFloors = async () => {
      setFloorsLoading(true);
      try {
        const floorData = await useEntityService.getEntities(selectedLocation.id, spaceId);
        setFloors(floorData);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setFloorsLoading(false);
      }
    };

    setFloors([]);
    fetchFloors();
  }, [selectedLocation.id, spaceId]);


  useEffect(() => {

    if (spaceId !== 0 && activeStep === 1) {
      const fetchRelevantServices = async () => {
        setIsLoading(true);
        try {
          const items = await servicePlanService.getServiceItems(spaceId);
          setRelevantServices(items);
        } catch (error: any) {
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      };

      setRelevantServices([]);
      fetchRelevantServices();
    }
  }, [spaceId]);

  return { entities, entityTypes, floors, relevantServices, isLoading, floorsLoading, typesLoading, error };
};


import { InspectionSerializer } from "../../../models/serializers/inspectionSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";


export class CommandCreateInspection {

    INSPECTIONS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {

        return `${this.INSPECTIONS_SERVICE}/inspections/`;
    }

    async run(inspection) {

        const url = this.getUrl()

        const tracer = useTracer.getState()
        tracer.trace('Inspections', 'Api', `GET ${url}`)

        try {

            let data = InspectionSerializer.serialize(inspection, ["entity_inspection", "entity_path", "uuid"])
            const response = await httpClient.post(url, data);

            return this.deserialize(response.data);
        } catch (error) {
            tracer.trace('Inspections', 'Error', `${error}`)
            console.error('Error:', error);
            throw error;
        }finally {
            tracer.trace('Inspections', 'Api', 'Success')
        }

    }

    deserialize(data) {
        // if (data === null) {
        //     return {};
        // }
        const serializer = new InspectionSerializer();
        const item = serializer.deserialize(data);
        return item;
    }


}
import { Entity } from "../entity.model";
import { BaseSerializer } from "./baseSerializer";

export class EntitySerializer extends BaseSerializer {


    public deserialize( data ): any {

        let entity = new Entity();

        this._copyAttributes(entity, data);

        let childEntities = this.deserializeList(data.children)

        entity.children = childEntities

        return entity;

    }

}
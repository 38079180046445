import React from "react";
import CloseProjectsTable from "./components/CloseProjectsTable";

const CloseProjectsTab = (props) => {

    const {
        children,
        value,
        index,
        ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
          <CloseProjectsTable />

    )}
    </div>
);
}

export default CloseProjectsTab
import { BaseSerializer } from "./baseSerializer";
import { Supply } from "../supply.model";

export class SupplySerializer extends BaseSerializer {


    public deserialize( data ): any {

        const supply = new Supply();

        this._copyAttributes(supply, data);

        return supply;

    }

}
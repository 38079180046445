import { Media } from "../media.model";
import { BaseSerializer } from "./baseSerializer";

export class MediaSerializer extends BaseSerializer {


    public deserialize( data ): any {

        const media = new Media();

        this._copyAttributes(media, data);

        return media;

    }

}
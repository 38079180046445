/*
        Iterate across the tree.
        Check each node to see if it matches.
        If it does mark it as a match.
        If not, mark as not match.

        In the TreeView rendering, for each node, check the match indicator
        * If a parent matches, all children automatically match.
        * If a child matches, all ancestors automatically match.
     */
export function filterTree(root, searchString) {


    function dfs(node, parentMatched=false) {

        if (!node)
            return false;

        let foundInCurrentNode = node.name.toLowerCase().includes(searchString.toLowerCase());

        if (parentMatched) foundInCurrentNode = true;

        node.matched = foundInCurrentNode;

        let foundInChildren = false;
        let tmpFound = false;

        // If node has children, search through them.
        if (node.children && node.children.length) {

            // Check any of the children, if any have a match then trigger foundInChildren.
            for (let childNode of node.children) {
                tmpFound = dfs(childNode, foundInCurrentNode);

                foundInChildren = foundInChildren || tmpFound;
            }

            // Even if this node does not have a match if any children do, we need to keep it.
            if (foundInChildren) {
                node.matched = true;
                foundInCurrentNode = true;
            }
        }

        return foundInCurrentNode
    }

    dfs(root);

    return root;
}

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  AppBar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  TextField,
  Box,
  Chip,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import { Employee } from "../../../../models/employee.model";
import { EmployeeService } from "../../../../services/employee/employeeService";
import useAppStore from "../../../../../appStore";
import EditEmployeePage from "../../../../../modules/employees/components/edit-employee/EditEmployeePage";

const EmployeeQuickSelect = ({ open, onClose, handleSave, item, caseEmployees=false }) => {
  const { loggedInUser, selectedLocation} = useAppStore();
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>(caseEmployees ? item.related_to_employees : item.assigned_to_employees);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [employeeToEdit, setEmployeeToEdit] = useState<Employee | null>(null);
  const [newEmployeeOpen, setNewEmployeeOpen] = useState(false);
  const [employeeSearchResult, setEmployeeSearchResult] = useState<any>([]);
  const [searchFor, setSearchFor] = useState<any>('');


  useEffect(() => {
    const fetchEmployees = async () => {
      setIsLoading(true);
      try {
        const service = new EmployeeService();
        const employees = await service.searchEmployees(loggedInUser.organization.id, searchFor);
        setEmployeeSearchResult(employees);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (searchFor !== '') {
      fetchEmployees();
    }

  }, [searchFor, loggedInUser.organization.id]);

  const handleSearch = () => {
    setSearchFor(searchQuery);
  };

  const handleRemoveEmployee = (employeeId) => {
    setSelectedEmployees(selectedEmployees.filter(e => e.id !== employeeId));
  };

  const handleNewEmployee = () => {
    let newEmployee = new Employee();
    setEmployeeToEdit(newEmployee);
    setNewEmployeeOpen(true);
  }

  const handlerCloseEditModal = (action, newEmployee) => {
    setNewEmployeeOpen(false);
    setEmployeeSearchResult([]);
    setSearchFor('');
    setSearchQuery('');
    setEmployeeToEdit(null);
    if (action === 'save' && newEmployee) {
      setSelectedEmployees(prevEmployees => [...prevEmployees, newEmployee]);
    }
  }

  const renderSelectedEmployees = () => {
    return selectedEmployees.map(employee => (
      <Chip
        key={employee.id}
        label={employee.person.first_name + " " + employee.person.last_name}
        onDelete={() => handleRemoveEmployee(employee.id)}
        deleteIcon={<CancelIcon />}
        sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
      />
    ));
  };

  const handleListItemClick = (employee) => {
    if (selectedEmployees.find((e) => e.id === employee.id)) {
      setSelectedEmployees(selectedEmployees.filter((e) => e.id !== employee.id));
    } else {
      setSelectedEmployees([...selectedEmployees, employee]);
    }
  };

  const handleSubmit = () => {
    handleSave(item, selectedEmployees);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <div style={{ flexGrow: 1 }}></div>
          <Button color="inherit" variant="contained" onClick={handleNewEmployee} sx={{ color: '#444444' }}>
            Create Employee
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Box sx={{ border: '1px solid lightgrey', p: 2 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Assigned Employees:</Typography>
              {selectedEmployees.length > 0 ? (
                <Box>
                  {renderSelectedEmployees()}
                </Box>
              ) : (
                <Typography>No employees selected.</Typography>
              )}
            </Box>
              <>
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  placeholder="Search employees..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => { if (e.key === 'Enter') handleSearch(); }}
                />
                <Button variant="contained" onClick={handleSearch} style={{width: '100%'}}>Search</Button>
                {employeeSearchResult.length > 0 &&
                  <List>
                    {employeeSearchResult.map(employee => (
                      <ListItem
                        key={employee.id}
                        button
                        selected={!!selectedEmployees.find(e => e.id === employee.id)}
                        onClick={() => handleListItemClick(employee)}
                      >
                        <ListItemText primary={employee.person.first_name + " " + employee.person.last_name} />
                      </ListItem>
                    ))}
                  </List>
                }
              </>
          </>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </DialogContent>
      {newEmployeeOpen && (
        <EditEmployeePage
          isEdit={false}
          open={open}
          employee_id={employeeToEdit?.id}
          onActionClicked={handlerCloseEditModal}
          quickCreate={true}
        />
      )}
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeQuickSelect;

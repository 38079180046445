import React, { useEffect, useState } from 'react';
import { Rating } from '@mui/material';
import useInspectionsStore from '../../../../../../inspectionsStore';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import appStore from '../../../../../../../../appStore';
import InspectionPoints from './components/InspectionPoints';
import { useAuth } from '../../../../../../../auth';
import { ValidationResult } from '../../useEditInspectedEntity';
import PhotoUploaderViewer from '../../../../../../../../shared/components/media/media-viewers/photos/PhotoUploaderViewer';
import useAppStore from "../../../../../../../../appStore";

const labels = {
  1: 'Yuck (totally dirty)',
  2: 'Missed the Mark (needs work)',
  3: 'Meh (meets expectations)',
  4: 'Looking Good! (exceeds expectations)',
  5: 'Sparkling (this place is Verde Vibed)',
};

const FractionalScoreSelector = ({ selectedFraction, setSelectedFraction }) => {
  const fractions = [0.25, 0.5, 0.75];
  const { whiteLabelProps } = useAppStore();

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {fractions.map((fraction) => (
        <button
          key={fraction}
          onClick={() => setSelectedFraction(fraction)}
          style={{
            margin: '0 5px',
            padding: '5px 10px',
            background: selectedFraction === fraction ? whiteLabelProps.primary_color : '#fff',
            color: selectedFraction === fraction ? 'white' : '',
            border: '1px solid #ccc',
            borderRadius: '4px',
            width: '28%',
            maxWidth: '80px'
          }}
        >
          {fraction}
        </button>
      ))}
    </div>
  );
};

const Scoring = (props) => {
  const { biRef, isEditing } = props;

  const { inspectedEntity, setScore, setMedia, setComments } = useInspectionsStore();

  const { appImages } = appStore();
  const [hover, setHover] = useState(-1);
  const [inspectionMedia, setInspectionMedia] = useState<any[]>([]);
  const [isValid, setIsValid] = useState(true);
  const [selectedFraction, setSelectedFraction] = useState(0);

  const handleFractionChange = (newFraction) => {
    setSelectedFraction(prevFraction => (prevFraction === newFraction ? 0 : newFraction));
  };

  useEffect(() => {
    let newArray = inspectedEntity.media.map((obj) => ({
      ...obj,
      src: obj.url,
    }));

    setInspectionMedia(newArray);
  }, [inspectedEntity.media]);

  useEffect(() => {
    if (selectedFraction > 0) {
      const newScore = Math.floor(inspectedEntity.score) + selectedFraction
      setScore(newScore);
    }
  }, [selectedFraction, setScore]);

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }

  const handleOnChange = (event) => {
    const currentValue = event.target.value;
    setComments(currentValue);
  };

  const validate = () => {
    let valInfo: ValidationResult = {passed: true, messages: []}

    if (inspectedEntity.score <= 0) {
      setIsValid(false);
      valInfo.passed = false;
      valInfo.messages.push('Rating is Required.');
    } else {
      setIsValid(true);
    }

    return valInfo;
  };

  biRef.scorePage.validate = validate;

  return (
    <div className="d-flex flex-column flex-row-fluid">
      <h1>Score Space</h1>

      {/* INSPECTION POINTS */}
      <div className="mt-10">
        <h3>Inspection Points</h3>
        <InspectionPoints />
      </div>

      {/* PHOTOS */}
      <div className="mt-10">
        <h3>Photos</h3>
        <PhotoUploaderViewer
          item={inspectedEntity}
          setMedia={setMedia}
          captions={false}
          module={'inspections'}
        />
      </div>

      {/* COMMENTS */}
      <div className="mt-8">
        <h3>Comments</h3>
        <textarea
          name="description"
          placeholder="Type comments here"
          className="form-control mb-3 mb-lg-0"
          style={{ height: '150px' }}
          onChange={handleOnChange}
          value={inspectedEntity.comments}
        />
      </div>

      {/* RATING */}
      <div className="mb-10 mt-8" >
        <h3 className="mb-5">Rating</h3>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
          <Rating
            name="hover-feedback"
            size="large"
            sx={{
              fontSize: '4rem',
              padding: 1,
            }}
            style={{
              border: !isValid ? 'solid 1px red' : 'none',
              borderRadius: '4px',
            }}
            icon={
              <img
                src={appImages.ratingIcon}
                alt="filled star"
                style={{ width: '48px', height: '48px' }}
              />
            }
            emptyIcon={
              <img
                src={appImages.ratingIconBlank}
                alt="filled star"
                style={{ width: '48px', height: '48px' }}
              />
            }
            value={Math.floor(inspectedEntity.score)}
            precision={1.0}
            getLabelText={getLabelText}
            onChange={(event, newValue) => {
              setScore(newValue == null ? 0 : newValue);
              setSelectedFraction(0);
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
          />
          {!isValid && <div style={{ color: 'red' }}>Please enter a score.</div>}
        </div>
        {inspectedEntity.score !== null && inspectedEntity.score !== -1 && (
          <>
            {inspectedEntity.score !== 5 &&
              <FractionalScoreSelector
                selectedFraction={selectedFraction}
                setSelectedFraction={handleFractionChange}
              />
            }
            <div className="mt-5" style={{ display: 'flex', justifyContent: 'center'}}>
              <b>{labels[hover !== -1 ? hover : Math.floor(inspectedEntity.score)]}</b>
            </div>
          </>
  )
}
</div>
</div>
)
  ;
};

export default Scoring;

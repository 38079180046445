import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmationDialog = (props) => {

    const {
        isOpen,
        setIsOpen,
        message,
        description,
        onConfirmClicked
    } = props


    const handleClose = () => {
        setIsOpen(false);
    };

    const handleConfirmed = () => {
        setIsOpen(false);
        onConfirmClicked()
    }

    return (
      <React.Fragment>
          <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
              <DialogTitle id="alert-dialog-title" style={{color: 'black'}}>
                  {message}
              </DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      {description}
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleConfirmed} autoFocus>
                      Ok
                  </Button>
              </DialogActions>
          </Dialog>
      </React.Fragment>
    );
}

export {ConfirmationDialog}
import { Employee } from "../employee.model";
import { BaseSerializer } from "./baseSerializer";
import { PersonSerializer } from "./personSerializer";
import { WorkSerializer } from "./workSerializer";
import { OrganizationSerializer } from "./organizationSerializer"; // Make sure to create and import this

export class EmployeeSerializer extends BaseSerializer {

    public deserialize(data): Employee {
        const employee = new Employee();
        this._copyAttributes(employee, data);

        let personSerializer = new PersonSerializer();
        employee.person = personSerializer.deserialize(data.person);

        let organizationSerializer = new OrganizationSerializer();
        employee.organization = organizationSerializer.deserialize(data.organization)

        employee.works_at = data.works_at

        if (Array.isArray(data.works_at)) {
            let workSerializer = new WorkSerializer();
            employee.works_at = data.works_at.map(workData => workSerializer.deserialize(workData));
        }

        return employee;
    }


}

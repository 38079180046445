import React, {useState} from "react";
import { useFormik } from 'formik';
import {Button} from "react-bootstrap";
import MediaUploader from "../../../../shared/components/media/media-uploader/MediaUploader";
import { Media } from "../../../../shared/models/media.model";
import { Post } from "../../../../shared/models/post.model";
import { useAuth } from "../../../../modules/auth/core/Auth";
import PostFormFields from "../../../../modules/feed/components/edit-post/components/PostFormFields";



const EmtpyEditPage = (props) => {

    const {
        inputItem,
        onActionClicked,
    } = props

    // Initialize postToUpdate as a state variable
    const [itemToUpdate, setItemToUpdate] = useState(inputItem || new Post());

    const buttonName = inputItem == null ? 'Create' : 'Update'

    const {currentUser} = useAuth();

    const formik = useFormik({
        initialValues: {
            name: '',
            description: 'itemToUpdate.description',
            visibility: 'itemToUpdate.visibility',
            about: 'postToUpdate.about'
        },
        onSubmit: async (values) => {
            /*
                if postToUpdate is null, create a new Post
                populate the Post fields
                Get the logged in user. set created_by
                Call the Post Service to create the Post.
            */

            const newItem = {...itemToUpdate};

            /*
            postToUpdate.description = values.description
            postToUpdate.visibility = values.visibility
            postToUpdate.organization_id = 8227
            // postToUpdate.about = values.about
            postToUpdate.created_by = currentUser

            const service = new PostsService()

             */

            if (inputItem == null ) {
                // const post: Post = await service.createPost(postToUpdate)
                onActionClicked('create', itemToUpdate)
            }else{
                //const post: Post = await service.updatePost(postToUpdate)
                onActionClicked('edit', itemToUpdate)
            }

        },
    });

    const mediaUploadCompleteHandler = (item) => {

        let media = new Media()

        media.url = item.url
        media.id = item.id
        media.file_name = item.file_name

        const newPost = {...itemToUpdate};
        newPost.media = [...newPost.media, media];

        setItemToUpdate(newPost);

    }

    const createPostHandler = () => {
        /*
            This is called when the user clicks Create.
            The call formik.submitForm() will result in the onSubmit being called above
            as part of useFormik.
         */

        formik.submitForm().then()

    }

    const saveDraftHandler = () => {

        formik.submitForm().then()
        onActionClicked('save_draft')
    }

    const uploadUrl = process.env.REACT_APP_API_FACILITIES_SERVICE_URL + '/media/upload/';

    return (

        <div className='row'>

        </div>

    )

}

export default EmtpyEditPage

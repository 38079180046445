import React from "react";
import {Avatar, Chip} from "@mui/material";
import ActionButton from "../../../../../shared/components/buttons/ActionButton";

const CourseRow = (props) => {

    const {
        course,
        onEditCourseClicked,
        onDeleteCourseClicked,
    } = props;

    /*
    badge-light-warning
    badge-light-primary
    badge-light-success
     */

    const handleDeleteCourse = () =>{
        onDeleteCourseClicked(course)
    }



    return (
        <>
            <tr>
                <td>
                    <div className="d-flex align-items-center">
                        <div className="symbol symbol-20px me-4">
                            {/* primary success info warning danger*/}
                            <span className="symbol-label bg-light-primary">
                                <i className="ki-duotone ki-category fs-2x text-primary">
                                    <span
                                        className="path1"></span>
                                    <span
                                        className="path2"></span>
                                    <span
                                        className="path3"></span>
                                </i>
                            </span>
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                            <a href="src/app/modules/work-items/components/edit-work-item/components/additional-requests/components/ServicesRow#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{course.name}</a>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="d-flex justify-content-center flex-shrink-0">
                        <ActionButton iconType="pencil" onClick={(e) => {
                            e.preventDefault();
                            onEditCourseClicked(course);
                        }} />
                        <ActionButton iconType="trash" onClick={(e) => {
                            e.preventDefault();
                        }} />
                    </div>
                </td>
            </tr>
        </>
    )
}

export default CourseRow
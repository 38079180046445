import { Organization } from "./organization.model";
import { LocationModel } from "./location.model"
import { AccessLevel } from "./accessLevel.model";
import { Role } from "./role.model";
import { GroupEntry } from "./groupEntry.model";

export class UserPermissions {
    customer_orgs: Organization[];
    role: Role;
    locations: LocationModel[];
    departments: GroupEntry[];
    access_levels: AccessLevel[];
    is_super_user : undefined | boolean;

    constructor() {
        this.customer_orgs = [];
        this.role = new Role();
        this.locations = [];
        this.departments = [];
        this.access_levels = [];
        this.is_super_user = undefined;

    }

    public hasAccess(feature, level=1){

        this.setIsSuperUser()

        if(this.is_super_user)
            return true

        const filteredArray = this.access_levels.filter(obj => obj.feature.toLowerCase() === feature.toLowerCase());

        if (filteredArray.length === 0)
            return false

        if( filteredArray[0].access_level < level )
            return false

        return true
    }

    private setIsSuperUser(){

        if (this.is_super_user !== undefined){
            return
        }

        const filteredArray = this.access_levels.filter(obj => obj.feature === '*');

        if(filteredArray.length > 0){
            this.is_super_user = true;
        }else{
            this.is_super_user = false;
        }

    }

}


import { getAuth } from "../../modules/auth/core/AuthHelpers";
import { Trace } from "./trace.model";
import { LoggedInUser } from "./loggedInUser.model";


export class ThreadTrace {

    id: number;
    user: LoggedInUser | null | undefined;
    traces: Trace[]

    constructor() {
        this.id = 0;
        this.user = new LoggedInUser();
        this.traces = [];

        const auth = getAuth();

        if (auth === undefined || auth.user.employee === undefined){
            return
        }

        this.user.id = auth.user.id
        this.user.employee.id = auth.user.employee.id
        this.user.first_name = auth.user.first_name
        this.user.last_name = auth.user.last_name
        this.user.photo_url = auth.user.photo_url

    }

}


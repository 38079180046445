import { BaseSerializer } from "./baseSerializer";
import { WorkItemAnalytics } from "../workItemAnaytics.model";
import { ActivitySerializer } from "./activitySerializer";
import { WorkItemProgressSerializer } from "./workItemProgressSerializer";
import { StatusCountsSerializer } from "./statusCountsSerializer";
import { WorkItemAgeSerializer } from "./workItemAgeSerializer";

export class WorkItemAnalyticsSerializer extends BaseSerializer {


  public deserialize( data ): any {

    const workItemAnalytics = new WorkItemAnalytics();
    this._copyAttributes(workItemAnalytics, data);

    if (Array.isArray(data.activity_by_location)) {
      let activitySerializer = new ActivitySerializer();
      workItemAnalytics.activity_by_location = data.activity_by_location.map(activity => activitySerializer.deserialize(activity));
    }

    if (Array.isArray(data.progress_by_location)) {
      let progressSerializer = new WorkItemProgressSerializer();
      workItemAnalytics.progress_by_location = data.progress_by_location.map(progress => progressSerializer.deserialize(progress));
    }

    if (Array.isArray(data.aging_by_location)) {
      let ageSerializer = new WorkItemAgeSerializer();
      workItemAnalytics.aging_by_location = data.aging_by_location.map(age => ageSerializer.deserialize(age));
    }

    let statusSerializer = new StatusCountsSerializer();
    workItemAnalytics.status_counts_all = statusSerializer.deserialize(data.status_counts_all[0]);

    return workItemAnalytics;

  }


}
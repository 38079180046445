import React, { useEffect, useState, useCallback } from 'react';
import { Typography } from "@mui/material";
import useInspectionsStore from "../../../../inspectionsStore";
import { InspectedEntity } from "../../../../../../shared/models/inspectedEntity.model";

const InspectionSummary = () => {
  const { inspection } = useInspectionsStore();
  const [totalScore, setTotalScore] = useState("0");
  const [totalSpaces, setTotalSpaces] = useState(0);
  const [totalTypes, setTotalTypes] = useState(0);

  const calcTotalScore = useCallback(() => {
    let inspectedEntities: InspectedEntity[] = inspection?.inspected_entities || [];
    const uniqueInspectedEntities = inspectedEntities.reduce<InspectedEntity[]>((acc, current) => {
      if (current.id && !acc.some(entity => entity.id === current.id)) {
        acc.push(current);
      }
      return acc;
    }, []);

    if (uniqueInspectedEntities.length < 1) {
      setTotalScore("0.0");
      setTotalSpaces(0);
      setTotalTypes(0);
      return;
    }

    const types = new Set(
      uniqueInspectedEntities
        .map(entity => entity.entity_type?.id)
        .filter(id => id !== undefined)
    ).size;


    let count = 0;
    const sum = uniqueInspectedEntities.reduce((accumulator, currentObject) => {
      if (currentObject.score && !isNaN(Number(currentObject.score))) {
        const scoreAsDecimal = parseFloat(currentObject.score.toString());
        if (scoreAsDecimal >= 0) {
          count++;
          return accumulator + scoreAsDecimal;
        }
      }
      return accumulator;
    }, 0);

    const average = count > 0 ? sum / count : 0;
    const roundedAverage = average.toFixed(1);
    setTotalScore(roundedAverage);
    setTotalSpaces(uniqueInspectedEntities.length);
    setTotalTypes(types);
  }, [inspection]);

  useEffect(() => {
    calcTotalScore();
  }, [calcTotalScore, inspection?.inspected_entities.length]);


  return (
    <div className="card-header d-flex justify-content-between align-items-center px-7" style={{ marginBottom: '20px' }}>
      <div className="d-flex align-items-center">
        <Typography variant="h6" className="fw-bold text-gray-700" style={{ marginRight: '20px' }}>
          Spaces: <span className="badge py-3 px-4 fs-5 justify-content-center">{totalSpaces}</span>
        </Typography>
        <Typography variant="h6" className="fw-bold text-gray-700" style={{ marginRight: '20px' }}>
          Types: <span className="badge py-3 px-4 fs-5 justify-content-center">{totalTypes}</span>
        </Typography>
        <Typography variant="h6" className="fw-bold text-gray-700">
          Score: <span className="badge py-3 px-4 fs-5 badge-light-success justify-content-center">{totalScore}</span>
        </Typography>
      </div>
    </div>
  );
};

export default InspectionSummary;

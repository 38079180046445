import { InspectionRecommendation } from "./inspectionRecommendation.model";

export class InspectionSummary {

  location_id: number;
  latest_inspection: string | null;
  avg_score: number;
  total_inspected_spaces: number;
  recommendations: InspectionRecommendation[];


  constructor() {
    this.location_id = 0;
    this.latest_inspection = null;
    this.avg_score = 0;
    this.total_inspected_spaces = 0;
    this.recommendations = [];
  }

}

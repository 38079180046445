import React, { useState } from "react";
import { useItemFinalizeListener,
         useItemFinishListener,
         useItemStartListener,
         useItemProgressListener} from "@rpldy/uploady";
import useTracer from "../../../../../framework/tracer/tracer";
import { LinearProgress } from "@mui/material";



const UploadProgress = (props) => {

  const {
    onUploadComplete
  } = props

  const [status, setStatus] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const tracer = useTracer();

  // Called every time progress data is received for this file upload
  useItemStartListener(() => {
    tracer.trace('Uploader', 'Trace', 'Media Upload Started.')
    setStatus("Uploading...")
    setIsUploading(true);
  });

  // Called every time progress data is received for this file upload
  useItemProgressListener((item) => {

    setProgress(item.completed); // The completed property is a percentage of the upload

  });

  // Called when item finished uploading
  useItemFinishListener((item) => {
    if (item.state === "finished") {
      tracer.trace('Uploader', 'Trace', 'Media Upload Completed.')
      onUploadComplete(item)
      setStatus("Finished.")
    }
  });

  // Called for item when uploading is done due to: finished, error, cancel or abort
  useItemFinalizeListener(() => {
    setIsUploading(false);
    setProgress(0)
  });


  return (
    <>
      { isUploading && (
        <div className='border p-3'>
          <label><b>{status}</b> </label>
          <LinearProgress variant="determinate" value={progress} />
        </div>
      )}
    </>
  )
};

export default UploadProgress
import React, { useState } from "react";
import PageHeader from "../../shared/components/page/page-header/PageHeader";
import SuppliesList from "./components/supplies-list/SuppliesList";
import { Supply } from "../../shared/models/supply.model";
import { SupplyService } from "../../shared/services/supplies/supplyService";
import ItemEditDialog from "../../shared/components/page/popup-dialog/ItemEditDialog";
import useAppStore from "../../appStore";
import EditSupply from "./components/edit-supply/EditSupply";

const SuppliesPage = () => {
  const { loggedInUser } = useAppStore();
  const [supplyToEdit, setSupplyToEdit] = useState<Supply | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [refreshList, setRefreshList] = useState(false);

  const handleDialogClose = (action = '') => {
    setIsDialogOpen(false);
    setSupplyToEdit(null);
    if (action === 'save') {
      setRefreshList(prev => !prev);
    }
  };

  const handleNewSupply = () => {
    let newSupply = new Supply();
    newSupply.organization = loggedInUser?.organization;
    setSupplyToEdit(newSupply);
    setIsDialogOpen(true);
  };

  const handleEditSupply = (supply) => {
    setSupplyToEdit(supply);
    setIsDialogOpen(true);
  };


  const handleSubmit = async (supply) => {
    try {
      if (supply?.id) {
        await new SupplyService().updateSupply(supply);
      } else {
        await new SupplyService().createSupply(supply);
      }
      handleDialogClose('save');
    } catch (e) {
      console.error('Error in handleSubmit:', e);
    }
  };


  return (
    <>
      <PageHeader
        title="Supplies"
        actionButtonName="New Supply"
        onActionClicked={handleNewSupply}
      />
      <SuppliesList
        refreshList={refreshList}
        onEdit={handleEditSupply}
      />
      {isDialogOpen && (
        <EditSupply
          open={isDialogOpen}
          onClose={() => handleDialogClose()}
          supply={supplyToEdit}
          setSupply={setSupplyToEdit}
          onSubmit={(updatedSupply) => handleSubmit(updatedSupply)}
        />
      )}
    </>
  );
}

export default SuppliesPage;



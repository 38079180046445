import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { LocationSerializer } from "../../../models/serializers/locationSerializer";
import { LocationModel } from "../../../models/location.model";


export class CommandUpdateLocationInfo {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(id) {
    return  `${this.SERVICE}/locations/${id}/`;
  }

  async run(location: LocationModel) {

    const url = this.getUrl(location.id)
    const tracer = useTracer.getState()
    tracer.trace('Location', 'Api', `PUT ${url}`)

    try {
      let data = LocationSerializer.serialize(location, ["is_qr_scanning_enabled"])
      const response = await httpClient.put(url, data);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Location', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    }finally {
      tracer.trace('Location', 'Api', 'Success')
    }

  }

  deserialize(data) {
    const serializer = new LocationSerializer()
    const location = serializer.deserialize(data)
    return location
  }

}
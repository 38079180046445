import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { LocationHealthSerializer } from "../../../models/serializers/locationHealthSerializer";


export class CommandGetLocationHealthData {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/analytics/location_health/`;
  }

  async run(provider_id, location_id?) {
    const url = this.getUrl();

    const params: { params: { provider: string; location?: string } } = {
      params: {
        provider: provider_id,
        location: location_id,
      }
    };

    if (location_id) {
      params.params.location = location_id;
    }

    const tracer = useTracer.getState();
    tracer.trace('LocationHealth', 'Api', `GET ${url}`);

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('LocationHealth', 'Error', `${error}`);
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      tracer.trace('LocationHealth', 'Api', 'Success');
    }
  }


  deserialize(data) {

    const serializer = new LocationHealthSerializer()
    const health_data = serializer.deserializeList(data)

    return health_data
  }

}


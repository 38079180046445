import { Activity } from "./activityModel";
import { WorkItemProgress } from "./workItemProgress.model";
import { StatusCounts } from "./statusCounts.model";
import { WorkItemAge } from "./workItemAge.model";

export class WorkItemAnalytics {

    status_counts_all: StatusCounts | null;
    activity_by_location: Activity[];
    progress_by_location: WorkItemProgress[];
    aging_by_location: WorkItemAge[];


    constructor() {
        this.status_counts_all = null;
        this.activity_by_location = [];
        this.progress_by_location = [];
        this.aging_by_location = [];
    }

}


import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, useTheme } from "@mui/material";
import {
  calculateProgress,
  getCaseAssignee, getDaysAgo,
  getTimeDifferenceString
} from "../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import DuotoneCategory from "../../../../../../shared/components/page/row-icons/DuotoneCategory";
import ProgressBar
  from "../../../../../analytics/components/site-health/components/tabs/corp-overview/components/card/components/ProgressBar";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";
import React from "react";
import useAppStore from "../../../../../../appStore";
import DuotoneCustomIcon from "../../../../../../shared/components/page/row-icons/DuotoneCustomIcon";

const MobileCaseRow = (props) => {
  const {
    item, onEdit
  } = props;

  const {appImages} = useAppStore();
  const rowImage = item.department.name === 'Human Resources' ? appImages.hrCase : item.department.name === 'Marketing' ? appImages.marketingCase : appImages.genericCase;
  const urgent = getDaysAgo(item.create_date) > 3;


  return (
    <tr>
      <td>
        <div className="d-flex align-items-center ps-5">

          <DuotoneCustomIcon photo={rowImage} photoHeight={'45px'} photoWidth={'50px'} colorClass={'bg-light-success'} itemNumber={item.case_number} />


          <div className="d-flex justify-content-start flex-column">
            <a className="text-gray-800 fw-bold fs-6">{item.case_type.name}</a>
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column align-items-center">
                <div className="d-flex align-items-center">
                  <span className="text-gray-900" style={{ fontSize: "0.9rem" }}>
                    {getCaseAssignee(item)}
                  </span>
                </div>
              </div>
            </div>
            <span style={{ fontSize: "0.9rem", color: urgent ? "#E54C38" : "inherit" }}>
              {getTimeDifferenceString(item.create_date)}
            </span>
          </div>
        </div>

      </td>

      <td className="text-center">
        {item.status === "Unassigned" && (
          <a className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" onClick={(e) => {
            e.preventDefault();
          }}>Assign</a>
        )}
      </td>
      <td>
        <div className="d-flex justify-content-end flex-shrink-0 me-5">
        <ActionButton iconType="view" onClick={(e) => {
            e.preventDefault();
            onEdit(item);
          }} />
        </div>
      </td>
    </tr>
  );
};

export default MobileCaseRow;
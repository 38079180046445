import React, { useState, useRef, useEffect } from "react";
import { MediaDbStore } from "../../../../../framework/offline-sync/media-db-store/MediaDbStore";
import IconButton from "@mui/material/IconButton";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CameraRollIcon from '@mui/icons-material/CameraRoll';
import { useUploady } from "@rpldy/uploady";
import useTracer from "../../../../../framework/tracer/tracer";
import { FileUploadRounded } from "@mui/icons-material";


interface TypeImageObject{

  imagePreview: string
  imageFile: string

}


const MobileCamera = ({alt = false, size='28px'}) => {

  const [imageObject, setImageObject] = useState<TypeImageObject | null>(null );
  const [hasPermission, setHasPermission] = useState(true);
  const {trace} = useTracer()

  const fileInputCameraRef = useRef<HTMLInputElement | null>(null);
  const fileInputGalleryRef = useRef<HTMLInputElement | null>(null);

  const uploady = useUploady();

  const getCameraPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      setHasPermission(true);
    } catch (error) {
      setHasPermission(false);
      console.error('Camera permission denied:', error);
      trace('Shared: Mobile Camera', 'trace', `Camera permission denied. Error: ${error}`)
    }
  };

  const isMobileDevice = () => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    trace('Shared: Mobile Camera', 'trace', `Checking is mobile device: ${isMobile}`)

    return isMobile;
  };



  useEffect(() => {

    if (isMobileDevice()) {
      // Check for camera permission on mobile device
      // getCameraPermission();
    }

  }, []);

  const triggerFileInputCamera = () => {

    if (fileInputCameraRef !== null) {
      fileInputCameraRef.current?.click();
    }

  };

  const triggerFileInputGallery = () => {

    if (fileInputGalleryRef !== null) {
      fileInputGalleryRef.current?.click();
    }

  };

  const handleImageChange = (event) => {

    trace('Shared: Mobile Camera', 'trace', 'Image selected for upload.')

    const mediaStore = new MediaDbStore()

    trace('Shared: Mobile Camera', 'trace', 'Storing image in Indexed DB (Dexie)')
    mediaStore.storeImage(event.target.files[0]).then((mediaId) => {

      mediaStore.getImageUrl(mediaId).then((url) => {

            trace('Shared: Mobile Camera', 'trace', 'Getting image URL from the local db.')
            if (url) {

              setImageObject({
                imagePreview: url,
                imageFile: event.target.files[0],
              });

              trace('Shared: Mobile Camera', 'trace', 'Uploading file with Uploady.upload(...)')
              uploady.upload(event.target.files[0]);

            } else {
              trace('Shared: Mobile Camera', 'trace', 'Reading image url back from local db failed.')
            }
      }).catch((error) => {
            // Handle errors here if needed
            console.error('Error:', error);
            trace('Shared: Mobile Camera', 'trace', `Error reading image url back from local db failed. Error: ${error}`)
          });
    }).catch((error) => {
        // Handle errors here if needed
        console.error('Error:', error);
        trace('Shared: Mobile Camera', 'trace', `Error storing image in Indexed DB (Dexie). Error: ${error}`)
    });

  };

  if (!hasPermission) {
    return <div>Camera access was not allowed.</div>;
  }

  return (
    <>
      {/* Icon Button for Camera Access */}
      {!alt &&
        <>
          <IconButton color="inherit" onClick={triggerFileInputCamera}>
            <CameraAltIcon sx={{ fontSize: '28px', color: 'grey' }} />
          </IconButton>
          <input
            style={{ display: "none" }}
            type="file"
            accept="image/*,.pdf"
            capture="environment"
            ref={fileInputCameraRef}
            onChange={handleImageChange}
          />
        </>
      }

      {/* Icon Button for Gallery Access */}
      <IconButton color="inherit"
                  onClick={triggerFileInputGallery}
      >
        {alt ? (
          <i className="fa-solid fa-upload" style={{ fontSize: size }}></i>
        ) : (
          <CameraRollIcon sx={{ fontSize: "28px", color: "grey"}} />
          )
        }
      </IconButton>
      <input
        style={{ display: "none" }}
        type="file"
        accept="image/*,.pdf"
        ref={fileInputGalleryRef}
        onChange={handleImageChange}
      />
    </>
  );

};

  export default MobileCamera;
import React from "react";

const ExploreProjectsTab = (props) => {

    const {
        children,
        value,
        index,
        ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
          /* <InProgressWorkItemTable /> */
          <p>Explore Projects </p>
    )}
    </div>
);
}

export default ExploreProjectsTab
import React from "react";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";

const CloseProjectsRow = (props) => {

    const {
        children,
        value,
        index,
        ...other } = props;

    /*
    badge-light-warning
    badge-light-primary
    badge-light-success
     */

    return (
        <>
            <tr>
                <td>
                    <div className="d-flex align-items-center">
                        <div className="symbol symbol-50px me-4">
                            {/* primary success info warning danger*/}
                            <span className="symbol-label bg-light-primary">
                                <i className="ki-duotone ki-category fs-2x text-primary">
                                    <span
                                        className="path1"></span>
                                    <span
                                        className="path2"></span>
                                    <span
                                        className="path3"></span>
                                </i>
                            </span>
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">Wax and Polish Floors</a>
                            <span className="text-gray-400 fw-semibold d-block fs-7">Floor Care</span>
                        </div>
                    </div>
                </td>

                <td className="text-start pe-0">
                    <div className="d-flex justify-content-lg flex-column">
                        <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">Front Lobby</a>
                        <span className="text-gray-400 fw-semibold d-block fs-7">Building 1</span>
                    </div>
                </td>

                <td className="text-start">
                    <span className="badge py-3 px-4 fs-7 badge-light-primary">Scheduled</span>
                </td>

                <td className="text-center pe-0">
                    {/*<!--begin::Statistics-->*/}
                    <div className="d-flex align-items-center flex-column w-100">
                        <div
                            className="d-flex justify-content-between fw-bold fs-7 opacity-50 w-100 mb-2">
                            <span>Med/High</span>
                            <span>Start before: 3d</span>
                        </div>
                        <div className="h-8px mx-3 w-100 bg-light-danger rounded">
                            <div className="bg-primary rounded h-8px" role="progressbar" style={{width: '72%'}}></div>
                        </div>
                    </div>
                    {/*<!--end::Statistics-->*/}
                </td>

                <td className="text-center">
                    <a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-60px h-30px me-1">
                        Now
                    </a>
                    <a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-60px h-30px me-1">
                        Later
                    </a>
                    <a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
                        +
                    </a>
                </td>

                <td>
                    <div className="d-flex justify-content-center flex-shrink-0">
                        <ActionButton iconType="pencil" onClick={(e) => {
                            e.preventDefault();
                        }} />
                        <ActionButton iconType="trash" onClick={(e) => {
                            e.preventDefault();
                        }} />
                    </div>
                </td>

            </tr>
        </>
    )
}

export default CloseProjectsRow
import { Employee } from "./employee.model";
import { Organization } from "./organization.model";
import { Person } from "./person.model";

export class Comment {

    id: number;
    comment_text: string;
    comment_date: string;
    created_by: Person | undefined
    organization: Organization;

    constructor() {
        this.id = 0;
        this.comment_text = '';
        this.comment_date = '';
        this.created_by = new Person();
        this.organization = new Organization();
    }

}


import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import SelectableTile from "./SelectableTile";



const SelectableTilesGrid = (props) => {

    const {
      disabled,
      options,
      value,
      onChange
    } = props;

    const [selectedIndex, setSelectedIndex] = useState(-1)

    useEffect(() => {

      if (options?.length == 0){
        return
      }

      let foundIdx = -1

      if (value)
        foundIdx = options.findIndex(item => item.id === value.id);

      if( foundIdx !== -1 ) {
        handleTileClicked(options[foundIdx])
        setSelectedIndex(options[foundIdx].id)
      }else {
        handleTileClicked(options[0])
        setSelectedIndex(options[0].id)
      }

    }, [options])

    const handleTileClicked = (item) => {
      if (disabled) return;
      setSelectedIndex(item.id)
      onChange(null, item)
    }


    return (

        <div className="mt-10">
          <Grid container>
            {options.map((data, index) => (
              <SelectableTile
                key={index}
                disabled={disabled}
                data={data}
                selectedIndex={selectedIndex}
                onTileClicked={handleTileClicked}
              />
            ))}
          </Grid>
        </div>

    )
}

export default SelectableTilesGrid
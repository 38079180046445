/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import { KTIcon } from '../../../../../../_metronic/helpers/components/KTIcon'


const CommentsReply = (props) => {

    const {
        onSubmitComment
    } = props

    const [text, setText] = useState('');

    const handleTextareaChange = (event) => { // Update state when textarea changes
        setText(event.target.value);
    }

    const submitCommentHandler = () => {
        // Use text value here...

        onSubmitComment(text);

        // Then clear the textarea
        setText('');
    };

    /*
    const handleKeyUp = (event) => {
        // If the pressed key was 'Enter' and the Ctrl key was being held down
        if (event.key === 'Enter' && event.ctrlKey) {
            submitCommentHandler();
        }
    };
    */

    return (

        <form className='position-relative mb-6'>
            <textarea
                className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                rows={1}
                placeholder='Reply..'
                value={text}
                onChange={handleTextareaChange}
                /*onKeyUp={handleKeyUp}*/
            ></textarea>

            <div className='position-absolute top-0 end-0 me-n5'>
                <span className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-2'
                      onClick={submitCommentHandler}
                >
                    <KTIcon iconName='send' className='fs-3 mb-3' />
                </span>
                {/*
                <span className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                    <KTIcon iconName='geolocation' className='fs-2 mb-3' />
                </span>
                */}
            </div>
        </form>
    )

}

export {CommentsReply}

import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";

export class CommandDeleteEntity {

  EMPLOYEES_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(id) {
    return `${this.EMPLOYEES_SERVICE}/entities/${id}/`;
  }

  async run(id) {
    const url = this.getUrl(id);

    const tracer = useTracer.getState();
    tracer.trace('Entities', 'Api', `DELETE ${url}`);

    try {
      const response = await httpClient.delete(url);
      tracer.trace('Entities', 'Api', 'Success');
      return response;
    } catch (error) {
      tracer.trace('Entities', 'Error', `${error}`);
      console.error('Error:', error);
      throw error;
    }
  }
}


export function buildHierarchyPath(path: any[]){

  if( path === null || path.length === 0){
    return ''
  }

  let concatenatedNames = path.slice(1).map(entity => entity.name).join('/');

  return concatenatedNames

}


import Dialog from "@mui/material/Dialog";
import { AppBar, Box, Button, IconButton, Toolbar, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import ModalTransition from "../../../shared/components/page/transitions/ModalTransition";
import { CloseIcon } from "yet-another-react-lightbox";
import RelationshipDetails from "./components/RelationshipDetails";
import CommentsSection from "../../../shared/components/comments/CommentsSection";
import RelationshipReadOnlyDetails from "./components/RelationshipReadOnly";

const EditRelationship = ({ open, onClose, onSave, itemToEdit }) => {

  const [newComments, setNewComments] = useState<any>([]);
  const [isEditing, setIsEditing] = useState(itemToEdit.id === 0 ? true : false);

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={ModalTransition}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          {!isEditing &&
            <Button
              color="inherit"
              variant="contained"
              onClick={() => setIsEditing(true)}
              sx={{ ml: 'auto', color: '#444444' }}
            >
              {"Edit"}
            </Button>
          }
        </Toolbar>
      </AppBar>

      <div style={{marginBottom: '65px'}}>
        {isEditing ? (
          <div style={{ padding: "10px", paddingTop: "20px", paddingBottom: "0px" }}>
            <RelationshipDetails onSave={onSave} itemToEdit={itemToEdit} />
          </div>
        ) : (
          <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <RelationshipReadOnlyDetails itemToEdit={itemToEdit} />
          </div>
        )}


        {itemToEdit.id !== 0 &&
          <Box className="d-flex flex-column mb-15" sx={{
            borderRadius: "5px",
            justifyContent: "space-between",
            border: "1px solid #c7c7c7", margin: 1, padding: '15px', marginTop: isEditing ? '' : '20px' }}>
            <CommentsSection item={itemToEdit} newComments={newComments} setNewComments={setNewComments} type={'crm/relationships'} />
          </Box>
        }
      </div>


    </Dialog>

  );
};

export default EditRelationship;
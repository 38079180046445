import { Comment } from "../comment.model";
import { BaseSerializer } from "./baseSerializer";
import { EmployeeSerializer } from "./employeeSerializer";
import { PersonSerializer } from "./personSerializer";

export class CommentSerializer extends BaseSerializer {


    public deserialize( data ): any {

        let comment = new Comment();

        this._copyAttributes(comment, data);

        let serializer = new PersonSerializer();

        const person = serializer.deserialize(data.created_by);

        comment.created_by = person;

        return comment;

    }

}
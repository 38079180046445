import Card from "@mui/material/Card";
import React, { useState } from "react";
import QrCodeReader from "../../../qr-scanner/QrCodeReader";
import Box from "@mui/material/Box";
import { toAbsoluteUrl } from "../../../../..//_metronic/helpers";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ComponentViewer from "../../../../shared/components/page/modal/ComponentViewer";

type FeedbackMessage = {
  message: string;
  severity: 'success' | 'error';
};

const ServiceScanTile = () => {
  const [open, setOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState<FeedbackMessage | null>(null);

  const handleClose = (response: any) => {
    setOpen(false);

    if (response?.entity?.id && response?.entity?.id !== 0) {
      setFeedbackMessage({ message: `Thank you for scanning ${response?.entity?.name}!`, severity: "success" });
    } else {
      setFeedbackMessage({ message: "Scan failed! This sensor may not be set up.", severity: "error" });
    }
  }

  const handleSnackbarClose = () => {
    setFeedbackMessage(null);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
        textAlign: "center",
        margin: "auto",
        position: "relative",
        overflow: "hidden",
        height: '190px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        padding: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px',
          paddingBottom: '48px',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            textAlign: 'left',
            color: '#555',
            fontWeight: '700',
            flex: 1,
            marginRight: '20px',
            marginBottom: '10px',
            maxWidth: '190px'
          }}
        >
          Do you see a QR code nearby? Scan it to quickly log your work!
        </Typography>
        <Box
          component="img"
          src={toAbsoluteUrl('/white-labeling/verde/media/menu-icons/QR.png')}
          alt="QR Scanner Illustration"
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "150px",
            height: "auto",
            transform: "translate(7%, -20%)",
          }}
        />
      </Box>
      <Button
        variant={"contained"}
        onClick={() => setOpen(true)}
        sx={{
          fontWeight: "bold",
          fontSize: '1.4rem',
          width: '100%',
          borderRadius: 0,
          padding: '10px 0',
          zIndex: 900,
          backgroundColor: '#f5f5f5',
          color: '#555',
        }}
      >
        Scan Now
      </Button>
      {open && (
        <ComponentViewer
          selectedPage={<QrCodeReader onClose={handleClose} />}
          onActionClicked={() => setOpen(false)}
          title={'QR Code Reader'}
          color={'#c2c2c2'}
        />
      )}
      <Snackbar
        open={!!feedbackMessage}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={feedbackMessage?.severity}>
          {feedbackMessage?.message}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default ServiceScanTile;

import { BaseSerializer } from "./baseSerializer";
import { EquipmentModel } from "../equipment.model";

export class EquipmentSerializer extends BaseSerializer {


    public deserialize( data ): any {

        const equipment = new EquipmentModel();

        this._copyAttributes(equipment, data);

        return equipment;

    }

}
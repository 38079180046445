import React, { ReactElement, useState } from "react";
import useAppStore from "../../appStore";
import { useMediaQuery, useTheme, Grid, Box, Typography, Divider } from "@mui/material";
import NavigationTile from "../../shared/components/page/tiles/NavigationTile";
import ComponentViewer from "../../shared/components/page/modal/ComponentViewer";
import SafetyDataSheets from "./SafetyDataSheets";
import EquipmentPage from "../equipment/EquipmentPage";
import SageAssistantModal from "../sage-virtual-assistant/SageAssistantModal";
import PageHeader from "../../shared/components/page/page-header/PageHeader";
import HuddlesModal from "./tile-modals/HuddlesModal";
import ContactsModal from "./tile-modals/ContactsModal";
import SlipsModal from "./tile-modals/SlipsModal";
import SafetyProgramsModal from "./tile-modals/SafetyProgramsModal";

const SafetyPage: React.FC = () => {
  const { appImages } = useAppStore();
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const [selectedPage, setSelectedPage] = useState<ReactElement | null>(null);

  return (
    <div style={{padding: !isMobile ? '30px' : '5px'}}>
      <PageHeader title={"Safety"} showAction={false}/>
      {!isMobile &&
        <div style={styles.container}>
          <div style={styles.row}>
            <div style={styles.content}>
              <p style={styles.note}>
                At Verde, we prioritize the safety of our employees, clients, and visitors. Our commitment to safety is
                unwavering,
                ensuring a secure and healthy environment at all times. We believe that safety is not just a requirement
                but a
                fundamental value that guides our daily operations. Every team member is trained to uphold our stringent
                safety
                standards, fostering a culture where everyone is proactive about identifying and mitigating risks. We
                continuously
                invest in state-of-the-art safety equipment and protocols, demonstrating our dedication to creating a
                workplace where
                safety comes first.
              </p>
            </div>
          </div>
        </div>
      }
        <>
          <div style={styles.tilesContainer}>
            <Box style={styles.tileSection}>
              <Typography variant="h6" style={styles.sectionHeader}>
                Resources
              </Typography>
              <Divider sx={{marginBottom: '15px', backgroundColor: 'black'}}/>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} onClick={() => setSelectedPage(<SafetyDataSheets />)}>
                  <NavigationTile title="Safety Data Sheets" image={appImages.sdsIcon} width={40} height={50} />
                </Grid>
                <Grid item xs={12} md={6} onClick={() => setSelectedPage(<EquipmentPage />)}>
                  <NavigationTile title="Protective Equipment" image={appImages.equipmentIcon} width={55} height={40} />
                </Grid>
                <Grid item xs={12} md={6} onClick={() => setSelectedPage(<SafetyProgramsModal />)}>
                  <NavigationTile title="Safety Programs" image={appImages.menuSafetyIcon} width={50} height={50} />
                </Grid>
                <Grid item xs={12} md={6} onClick={() => setSelectedPage(<HuddlesModal />)}>
                  <NavigationTile title="Safety Huddles" image={appImages.huddleIcon} width={50} height={50} />
                </Grid>
                <Grid item xs={12} md={6} onClick={() => setSelectedPage(<SlipsModal />)}>
                  <NavigationTile title="Slips, Trips, Falls" image={appImages.slipsIcon} width={50} height={50} />
                </Grid>
                <Grid item xs={12} md={6} onClick={() => setSelectedPage(<ContactsModal />)}>
                  <NavigationTile title="Contacts" image={appImages.contactsIcon} width={50} height={50} />
                </Grid>
              </Grid>
            </Box>
            <Box style={styles.tileSection}>
              <Typography variant="h6" style={styles.sectionHeader}>
                Tell Sage
              </Typography>
              <Divider sx={{marginBottom: '15px', backgroundColor: 'black'}}/>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} onClick={() => setSelectedPage(<SageAssistantModal onClose={() => setSelectedPage(null)} fullScreen={true} />)}>
                  <NavigationTile title="Incident Reporting" image={null} />
                </Grid>
                <Grid item xs={12} md={6} onClick={() => setSelectedPage(<SageAssistantModal onClose={() => setSelectedPage(null)} fullScreen={true} />)}>
                  <NavigationTile title="Stop Work Authority" image={null} />
                </Grid>
                <Grid item xs={12} md={6} onClick={() => setSelectedPage(<SageAssistantModal onClose={() => setSelectedPage(null)} fullScreen={true} />)}>
                  <NavigationTile title="Workplace Violence" image={null} />
                </Grid>
                <Grid item xs={12} md={6} onClick={() => setSelectedPage(<SageAssistantModal onClose={() => setSelectedPage(null)} fullScreen={true} />)}>
                  <NavigationTile title="Safety Communications" image={null} />
                </Grid>
              </Grid>
            </Box>
          </div>
        </>
      { selectedPage && (
        <ComponentViewer selectedPage={selectedPage} onActionClicked={() => setSelectedPage(null)} />
      )}
    </div>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    fontFamily: "'Arial', sans-serif",
    color: "#333",
    border: '1px solid #f1f1f1',
    borderRadius: '2px',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    position: 'relative',
  },
  content: {
    textAlign: "left" as 'left',
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  note: {
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.2rem',
    padding: 20,
    margin: '0 auto',
    backgroundColor: 'white'
  },
  tilesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginTop: 30,
  },
  tileSection: {
    borderRadius: '5px',
    marginBottom: '20px'
  },
  sectionHeader: {
    marginBottom: '10px',
    fontWeight: 'bold',
    color: '#999'
  },
};

export default SafetyPage;

import React, { useState } from "react";
import SuppliesRow from "./SuppliesRow";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getCardClass } from "../../../../../shared/utils/table-modifier-util/tableDataUtil";



const SuppliesTable = (props) => {

  const{
    supplies,
    onEdit,
    readOnly=false
  } = props

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  return (
    <>
      <div className={getCardClass(isMobile)}>
        <div className={"card-body"}>
          <div className="table-responsive">
            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
              <thead>
              <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                {!isMobile && (
                  <>
                    <th className="p-0 pb-3 min-w-150px text-start">ITEM</th>
                    <th className="p-0 pb-3 min-w-250px text-start px-3">INFO</th>
                  </>
                )}
              </tr>
              </thead>
              <tbody>
                {supplies.map((item) => (
                  <SuppliesRow key={item.id}
                               item={item}
                               onEdit={onEdit}
                               isMobileView={isMobile}
                               readOnly={readOnly}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );

}

export default SuppliesTable
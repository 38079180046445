import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { LocationSerializer } from "../../../models/serializers/locationSerializer";


export class CommandGetLocations{

  ENTITY_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return  `${this.ENTITY_SERVICE}/locations/`;
  }

  async run(provider_id) {

    const url = this.getUrl()

    const params = {
      params: {
        provider: provider_id,
      }
    }

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }

  }

  deserialize(data) {

    const serializer = new LocationSerializer()
    const entities = serializer.deserializeList(data)

    return entities
  }

}
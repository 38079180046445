import React from "react";
import { Typography } from "@mui/material";

const HuddlesModal: React.FC = () => (
  <div>
    <Typography variant="h6">Huddles</Typography>
    <Typography variant="body1">Coming Soon</Typography>
  </div>
);

export default HuddlesModal;



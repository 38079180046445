import React, { useState } from 'react';
import PageHeader from "../../../../shared/components/page/page-header/PageHeader";
import CorpOverviewAnalytics from "./components/tabs/corp-overview/CorpOverviewAnalytics";
import WorkItemsAnalytics from "./components/tabs/work-items/WorkItemsAnalytics";
import InspectionOverviewAnalytics from "./components/tabs/inspections/InspectionOverviewAnalytics";
import { Box, Tab, Tabs } from "@mui/material";

const SiteHealthPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <PageHeader
        title='Overview'
        showAction={false}
      />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleChangeTab} aria-label="Site Health Tabs">
          <Tab label="Locations" />
          <Tab label="Work Items" />
          <Tab label="Inspections" />
        </Tabs>
      </Box>
      {selectedTab === 0 && <CorpOverviewAnalytics />}
      {selectedTab === 1 && <WorkItemsAnalytics />}
      {selectedTab === 2 && <InspectionOverviewAnalytics />}
    </>
  );
}

export default SiteHealthPage;


import {CommandGetTrainingCategoryHierarchy} from "./commands/cmdGetTrainingCategoryHierarchy";
import { CommandGetCourses } from "./commands/cmdGetCourses";
import { CommandCreateCourse } from "./commands/cmdCreateCourse";
import { CommandUpdateCourse } from "./commands/cmdUpdateCourse";
import { CommandGetCourseDetails } from "./commands/cmdGetCourseDetails";


export class TrainingService {

    getTrainingCategoryHierarchy(org_id=8227, offset=0, limit=10, search=null) {

        const cmd = new CommandGetTrainingCategoryHierarchy()
        const results = cmd.run(org_id, offset, limit, search)

        return results
    }

    async getCourses(org_id, category_id, offset=0, limit=10, search=null) {

        const cmd = new CommandGetCourses()
        const results = cmd.run(org_id, category_id, offset, limit, search)

        return results
    }

    async getCourseDetails(course_id) {

        const cmd = new CommandGetCourseDetails()
        const results = cmd.run(course_id)

        return results
    }

    async createCourse(course) {

        const cmd = new CommandCreateCourse()
        const results = cmd.run(course)

        return results
    }

    async updateCourse(course) {

        const cmd = new CommandUpdateCourse()
        const results = cmd.run(course)

        return results
    }

    async deleteService(serviceId) {

        return ''
    }

}
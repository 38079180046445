import { BaseSerializer } from "./baseSerializer";
import { ServiceItemSerializer } from "./serviceItemSerializer";
import { ServicePlanItem } from "../servicePlanItem.model";
import { FrequencySerializer } from "./frequencySerializer";
import { ServicePlan } from "../servicePlan.model";
import { ServicePlanItemSerializer } from "./servicePlanItemSerializer";

export class ServicePlanSerializer extends BaseSerializer {

  public deserialize( data ): any {

    if (data === undefined) return;

    let plan = new ServicePlan();

    this._copyAttributes(plan, data);

    let serializer = new ServicePlanItemSerializer();
    const items = serializer.deserializeList(data.items);
    plan.items = items;

    return plan;
  }

}
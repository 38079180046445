import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { EquipmentSerializer } from "../../../models/serializers/equipmentSerializer";


export class CommandCreateEquipment {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {

    return `${this.SERVICE}/equipment/`;
  }

  async run(equipment) {

    const url = this.getUrl()

    const tracer = useTracer.getState()
    tracer.trace('Equipment', 'Api', `POST ${url}`)

    try {

      let data = EquipmentSerializer.serialize(equipment)
      const response = await httpClient.post(url, data);

      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Equipment', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    }finally {
      tracer.trace('Equipment', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new EquipmentSerializer()
    const item = serializer.deserialize(data)

    return item
  }

}

export class Course {
    id: number;
    name: string;
    description: string;
    status: string;
    category_id: number;
    organization_id: number;
    html_content: string;

    constructor(
      id: number = 0,
      name: string = '',
      description: string = '',
      status: string = 'DRAFT',
      category_id: number = 0,
      organization_id: number = 0,
      html_content: string = ''
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.status = status;
        this.category_id = category_id;
        this.organization_id = organization_id;
        this.html_content = html_content;
    }
}



import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { CommentSerializer } from "../../../models/serializers/commentSerializer";


export class CommandGetComments {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(item, type) {
    return  `${this.SERVICE}/${type}/${item}/comments/`;
  }

  async run(item, type='workitems') {

    const url = this.getUrl(item.id, type)

    const params = {
      params: {}
    }

    const tracer = useTracer.getState()
    tracer.trace('Comments', 'Api', `GET ${url}`)

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Comments', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    }finally {
      tracer.trace('Comments', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new CommentSerializer()
    const comments = serializer.deserializeList(data)

    return comments
  }

}
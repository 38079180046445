import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  AppBar,
  IconButton,
  Typography,
  Toolbar,
  Box
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ToggleButton } from "@mui/material";
import InputMask from "react-input-mask";
import useAppStore from "../../../../../../../appStore";
import { Person } from "../../../../../../../shared/models/person.model";
import { Contact } from "../../../../../../../shared/models/contact.model";
import { ContactService } from "../../../../../../../shared/services/location-contacts/contactService";
import { Employee } from "../../../../../../../shared/models/employee.model";
import { LocationModel } from "../../../../../../../shared/models/location.model";
import { GroupEntry } from "../../../../../../../shared/models/groupEntry.model";
import CustomError from "../../../../../../../shared/components/page/popup-dialog/CustomError";

const EditLocationContact = ({ contactToEdit, open, onClose, onSave }) => {
  const { loggedInUser, selectedLocation } = useAppStore();
  const [id, setId] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedTier, setSelectedTier] = useState<GroupEntry | null>(null);
  const [availableTiers, setAvailableTiers] = useState<GroupEntry[]>([]);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (contactToEdit) {
      setError('');
      setId(contactToEdit?.id || 0);
      setFirstName(contactToEdit?.employee?.person?.first_name || '');
      setLastName(contactToEdit?.employee?.person?.last_name || '');
      setEmail(contactToEdit?.employee?.person?.email || '');
      setPhoneNumber(contactToEdit?.employee?.person?.phone_number || '');
      setSelectedTier(contactToEdit?.csat_reporting_group_entry || null);
    }
  }, [contactToEdit]);

  const fetchTiers = useCallback(async () => {
    setIsLoading(true);
    try {
      const service = new ContactService();
      const tiers = await service.getTiers(loggedInUser?.organization);
      setAvailableTiers(tiers);
    } catch (error) {
      console.error("Error fetching tiers:", error);
    } finally {
      setIsLoading(false);
    }
  }, [loggedInUser.organization]);

  useEffect(() => {
    fetchTiers();
  }, [fetchTiers]);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    const isValidPhoneNumber = value.replace(/\D/g, "").length === 10;
    setPhoneNumberError(!isValidPhoneNumber);
  };

  const handleToggleTier = (tier) => {
    setSelectedTier(selectedTier?.id === tier.id ? null : tier);
    setError('');
  };

  const handleSubmit = async () => {
    if (!selectedTier) {
      setError("Please select a tier before submitting.");
      return;
    }

    if (phoneNumberError) {
      return;
    }

    try {
      let employee = new Employee();
      let location = new LocationModel();
      let person = new Person();

      person.first_name = firstName;
      person.last_name = lastName;
      person.phone_number = phoneNumber;
      person.email = email;
      employee.person = person;
      location.id = selectedLocation.id;

      let contact = new Contact();
      contact.id = id;
      contact.employee = employee;
      contact.location = location;
      contact.csat_reporting_group_entry = selectedTier;

      const service = new ContactService();
      const response = id === 0
        ? await service.createContact(contact)
        : await service.updateContact(contact);
      onSave(response);
    } catch (error) {
      console.error("Failed to create or update contact:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>

        {error &&
          <CustomError error={error} />
        }

        <Typography sx={{ flex: 1, fontSize: "0.9rem", color: "#777", ml: "1px" }} component="div">
          Contact Tier
        </Typography>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '10px' }}>
          {availableTiers.map((tier) => (
            <ToggleButton
              key={tier.id}
              value={tier.id}
              selected={selectedTier?.id === tier.id}
              onChange={() => handleToggleTier(tier)}
            >
              {tier.name}
            </ToggleButton>
          ))}
        </Box>

        <TextField
          margin="dense"
          name="first_name"
          label="First Name"
          type="text"
          fullWidth
          variant="outlined"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          margin="dense"
          name="last_name"
          label="Last Name"
          type="text"
          fullWidth
          variant="outlined"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <InputMask
          mask="(999) 999-9999"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        >
          {(inputProps) => (
            <TextField
              {...inputProps}
              margin="dense"
              name="phone_number"
              label="Phone Number"
              type="tel"
              fullWidth
              variant="outlined"
              error={phoneNumberError}
              helperText={phoneNumberError ? "Invalid phone number" : ""}
            />
          )}
        </InputMask>
        <TextField
          margin="dense"
          name="email"
          label="Email Address"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          {id === 0 ? "Submit" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLocationContact;




import React from "react";
import SurveyCampaignRow from "./SurveyCampaignRow";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getCardClass } from "../../../../../shared/utils/table-modifier-util/tableDataUtil";


const SurveyCampaignTable = (props) => {

    const {
        campaigns,
        children,
        value,
        index,
        ...other } = props;

    const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

    const handleEditCampaignClicked = (employee) => {

    }

    const handleDeleteCampaignClicked = (employee) => {

    }

    return (
        <>
            {/*begin::Table widget 14*/}
            <div className={getCardClass(isMobile)}>
                {/*begin::Header*/}
                <div className="card-header pt-7">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">Surveys</span>
                    </h3>
                    {/*end::Title*/}
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="card-body pt-6">
                    {/*begin::Table container*/}
                    <div className="table-responsive ">
                        {/*begin::Table*/}
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                            {/*begin::Table head*/}
                            <thead>
                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                {!isMobile &&
                                  <>
                                      <th className="p-0 pb-3 min-w-215px text-start">NAME</th>
                                      <th className="p-0 pb-3 min-w-75px text-start px-3">STARTED</th>
                                      <th className="p-0 pb-3 min-w-200px text-start px-3">SENT TO</th>
                                      <th className="p-0 pb-3 min-w-25px text-center">RESPONDED</th>
                                      <th className="p-0 pb-3 min-w-25px text-center">LAST PUBLISHED</th>
                                  </>
                                }
                            </tr>
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody>
                            {campaigns.map((campaign) => (

                              <SurveyCampaignRow key={campaign.id}
                                                 campaign={campaign}
                                                 onEditEmployeeClicked={handleEditCampaignClicked}
                                                 onDeleteEmployeeClicked={handleDeleteCampaignClicked}
                              />
                            ))}
                            </tbody>
                            {/*end::Table body*/}
                        </table>
                    </div>
                    {/*end::Table*/}
                </div>
                {/*end: Card Body*/}
            </div>
            {/*end::Table widget 14*/}
         
        </>
    )
}

export default SurveyCampaignTable

export class GroupEntry {

  id: number;
  name: string;


  constructor() {

    this.id = 0;
    this.name = "";

  }

}

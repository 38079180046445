import React from "react";
import { Typography } from "@mui/material";

const SafetyProgramsModal: React.FC = () => (
  <div>
    <Typography variant="h6">Safety Programs</Typography>
    <Typography variant="body1">Coming Soon</Typography>
  </div>
);

export default SafetyProgramsModal;



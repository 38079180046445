import {toAbsoluteUrl} from "../../../_metronic/helpers";
import { Employee } from "./employee.model";
import { UserPermissions } from "./userPermissions.model";
import { Organization } from "./organization.model";
import { Role } from "./role.model";
import { Person } from "./person.model";

export class LoggedInUser {

    id: number;
    uuid: string;
    external_id: string;
    employee: Employee;
    person: Person;
    first_name: string;
    last_name: string;
    photo_url: string;
    email: string;
    is_active: boolean;
    role: Role;
    status: string;
    organization: Organization;
    permissions: UserPermissions;


    constructor() {
        this.id = 0;
        this.uuid = '';
        this.external_id = '';
        this.employee = new Employee();
        this.person = new Person();
        this.photo_url = toAbsoluteUrl('/media/avatars/blank.png');
        this.is_active = false;
        this.first_name = '';
        this.last_name = '';
        this.email = '';
        this.role = new Role;
        this.status = '';
        this.organization = new Organization();
        this.permissions = new UserPermissions();
    }

}


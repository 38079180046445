import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { WorkItemSerializer } from "../../../models/serializers/workItemSerializer";
import { CaseSerializer } from "../../../models/serializers/caseSerializer";


export class CommandGetCreatedCases {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return  `${this.SERVICE}/cases/`;
  }

  async run(provider_id, employee_id) {
    const url = this.getUrl();

    const params = {
      params: {
        provider: provider_id,
        created_by: employee_id,
      },
    };

    const tracer = useTracer.getState();
    tracer.trace('Cases', 'Api', `GET ${url}`);

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Cases', 'Error', `${error}`);
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      tracer.trace('Cases', 'Api', 'Success');
    }
  }

  deserialize(data) {

    const serializer = new CaseSerializer()
    const cases = serializer.deserializeList(data)

    return cases
  }

}
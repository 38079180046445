import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import useAppStore from "../../../../../../appStore";
import { CasesService } from "../../../../../../shared/services/cases/casesService";
import { GroupEntry } from "../../../../../../shared/models/groupEntry.model";
import { Media } from "../../../../../../shared/models/media.model";
import PhotoUploaderViewer from "../../../../../../shared/components/media/media-viewers/photos/PhotoUploaderViewer";
import RelatedToEmployees from "./employee-assignment/RelatedToEmployees";
import Button from "@mui/material/Button";
import CaseAssignees from "./employee-assignment/CaseAssignees";

const Details = (props) => {
  const {
    caseToEdit,
    setCaseToEdit,
    handleAction
  } = props;

  const { loggedInUser } = useAppStore();
  const service = new CasesService();
  const [departments, setDepartments] = useState<GroupEntry[]>([]);
  const [caseTypes, setCaseTypes] = useState<GroupEntry[]>([]);
  const [media, setMedia] = useState<Media[]>(caseToEdit.media);

  const [errors, setErrors] = useState({
    department: false,
    caseType: false
  });

  useEffect(() => {
    setCaseToEdit(prev => ({ ...prev, media: media }));
  }, [media]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const depts = await service.getDepartments(loggedInUser?.organization);
        setDepartments(depts);
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    const fetchTypes = async (dept) => {
      try {
        const types = await service.getCaseTypes(loggedInUser?.organization, dept);
        setCaseTypes(types);
      } catch (error) {
        console.error('Error fetching case types:', error);
      }
    };

    if (caseToEdit.department?.id !== 0) {
      fetchTypes(caseToEdit.department.id);
    }

    fetchDepartments();
  }, [loggedInUser?.organization?.id, caseToEdit.department]);

  const handleDescChange = (event) => {
    setCaseToEdit(prev => ({ ...prev, description: event.target.value }));
  };

  const handleCaseNumberChange = (event) => {
    setCaseToEdit(prev => ({ ...prev, case_number: event.target.value }));
  };

  const handleDepartmentChange = (event) => {
    const deptName = event.target.value;
    const selectedDepartment = departments.find(item => item.name === deptName);
    setCaseToEdit(prev => ({ ...prev, department: selectedDepartment, case_type: null }));
    setErrors(prevErrors => ({ ...prevErrors, department: !selectedDepartment }));
  };

  const handleCaseTypeChange = (event) => {
    const caseName = event.target.value;
    const selectedCase = caseTypes.find(item => item.name === caseName);
    setCaseToEdit(prev => ({ ...prev, case_type: selectedCase }));
    setErrors(prevErrors => ({ ...prevErrors, caseType: !selectedCase }));
  };

  const validateForm = () => {
    let valid = true;
    if (!caseToEdit.department || caseToEdit.department.name === '') {
      setErrors(prevErrors => ({ ...prevErrors, department: true }));
      valid = false;
    }
    if (!caseToEdit.case_type || caseToEdit.case_type.name === '') {
      setErrors(prevErrors => ({ ...prevErrors, caseType: true }));
      valid = false;
    }
    return valid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleAction("submit");
    }
  };

  return (
    <div className="flex-column-fluid" style={{ marginRight: '50px' }}>
      <div style={{ marginBottom: "15px" }}>
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-gray-700">Case Details</span>
        </h3>
      </div>
      <Box sx={{
        display: "flex",
        marginTop: 2,
        marginBottom: 2,
        width: "100%"
      }}>
        <Button
          variant="contained"
          sx={{ marginRight: '5px' }}
          onClick={handleSubmit}
        >
          Save
        </Button>
        {caseToEdit.id !== 0 && (
          <Button
            variant="contained"
            sx={{ marginRight: '5px' }}
            onClick={() => handleAction(caseToEdit.status === 'Closed' ? 'reopen' : 'complete')}
          >
            {caseToEdit.status === 'Closed' ? 'Re-Open' : 'Mark Completed'}
          </Button>
        )}
      </Box>

      <FormControl fullWidth style={{ marginBottom: 15 }} error={errors.department}>
        <span className="text-gray-700">Department</span>
        <Select
          value={caseToEdit.department?.name || ""}
          onChange={handleDepartmentChange}
        >
          {departments.map((dept) => (
            <MenuItem key={dept.id} value={dept.name}>{dept.name}</MenuItem>
          ))}
        </Select>
        {errors.department && <span style={{ color: 'red' }}>Department is required</span>}
      </FormControl>

      <FormControl fullWidth style={{ marginBottom: 15 }} disabled={caseToEdit.department?.name === ''}
                   error={errors.caseType}>
        <span className={caseToEdit.department?.name === '' ? "text-muted" : "text-gray-700"}>Case Type</span>
        <Select
          value={caseToEdit.case_type?.name || ""}
          onChange={handleCaseTypeChange}
        >
          {caseTypes.map((type) => (
            <MenuItem key={type.id} value={type.name}>{type.name}</MenuItem>
          ))}
        </Select>
        {errors.caseType && <span style={{ color: 'red' }}>Case Type is required</span>}
      </FormControl>

      <span className="text-gray-700">Case Number</span>
      <TextField
        name="Case Number"
        value={caseToEdit.case_number}
        onChange={handleCaseNumberChange}
        fullWidth
        sx={{marginBottom: '15px'}}
      />

      <span className="text-gray-700">Description</span>
      <TextField
        name="Description"
        value={caseToEdit.description}
        onChange={handleDescChange}
        fullWidth
        multiline
        rows={6}
      />

      <div style={{ marginTop: 15 }}>
        <RelatedToEmployees caseToEdit={caseToEdit} setCaseToEdit={setCaseToEdit} />
      </div>

      <div style={{ marginTop: 15 }}>
        <CaseAssignees item={caseToEdit} setItem={setCaseToEdit} />
      </div>

      <div style={{ marginTop: 15 }}>
        <span className="text-gray-700">Supporting Documents</span>
        <PhotoUploaderViewer
          item={caseToEdit}
          setMedia={setMedia}
          captions={caseToEdit.status === 'Closed'}
          module={'cases'}
        />
      </div>
    </div>
  );
};

export default Details;



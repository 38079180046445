import { Organization } from "./organization.model";
import { Media } from "./media.model";

export class Supply {

    id: number;
    name: string;
    description: string;
    media: Media[];
    organization:  Organization;

    constructor() {
        this.id = 0;
        this.name = '';
        this.description = '';
        this.media = [];
        this.organization = new Organization();
    }

}


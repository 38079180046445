import { CommandGetServicePlanServiceItems } from "./commands/cmdGetServicePlanServiceItems";
import { CommandPostServicePlanItem } from "./commands/cmdPostServicePlanItem";
import { CommandDeleteServicePlanItem } from "./commands/cmdDeleteServicePlanItem";
import { CommandUpdateServicePlanItem } from "./commands/cmdUpdateServicePlanItem";
import { CommandSearchServiceItems } from "./commands/cmdSearchServicePlanItems";
import { CommandUpdateItemFrequency } from "./commands/cmdUpdateServiceItemFrequency";


export class ServicePlanService {

    async getServiceItems(entity_type_id, offset=0, limit=10, search=null) {
        const cmd = new CommandGetServicePlanServiceItems()
        const results = cmd.run(entity_type_id, offset, limit, search)
        return results
    }

    async postServicePlanItem(servicePlan, servicePlanItem, location?) {

        const cmd = new CommandPostServicePlanItem();
        const result = cmd.run(servicePlan, servicePlanItem, location);

        return result
    }

    async deleteServicePlanItem(servicePlan, servicePlanItem) {

        const cmd = new CommandDeleteServicePlanItem();
        const result = cmd.run(servicePlan, servicePlanItem);
        return result

    }

    async updateServicePlanItem(servicePlan, servicePlanItem) {
        const cmd = new CommandUpdateServicePlanItem();
        const result = cmd.run(servicePlan, servicePlanItem);
        return result
    }

    async updateItemFrequency(servicePlan, servicePlanItem, frequency) {
        const cmd = new CommandUpdateItemFrequency();
        const result = cmd.run(servicePlan, servicePlanItem, frequency);
        return result
    }

    async searchServiceItems(search) {
        const cmd = new CommandSearchServiceItems()
        const results = cmd.run(search)
        return results
    }



}
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";

interface DuotoneCustomIconProps {
  photo: string;
  photoWidth: string;
  photoHeight: string;
  colorClass: string;
  itemNumber?: number;
}

const DuotoneCustomIcon: React.FC<DuotoneCustomIconProps> = ({
                                                               photo,
                                                               photoWidth,
                                                               photoHeight,
                                                               colorClass,
                                                               itemNumber
                                                             }) => {
  const footerHeight = '12px';
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));


  return (
    <div className={`symbol ${colorClass}`} style={{
      width: photoWidth,
      height: photoHeight,
      marginRight: "16px",
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <img src={photo} alt="Custom Icon" style={{
        width: '100%',
        height: isMobile ? `calc(100% - ${footerHeight})` : '100%',
        objectFit: 'cover'
      }} />
      {itemNumber !== undefined && isMobile && (
        <div style={{
          height: footerHeight,
          background: '#00693e',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '11px',
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
          width: '100%'
        }}>
          #{itemNumber}
        </div>
      )}
    </div>
  );
};

export default DuotoneCustomIcon;

import { User } from "./user.model";
import { AppDate } from "../utils/date-time/AppDate";

export class ServiceLogEntry {
  id: number;
  service_date: string;
  serviced_by: User;


  constructor() {
    this.id = 0;
    this.service_date = new AppDate().dateTime;
    this.serviced_by = new User();
  }

}
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from "@mui/material";
import ActivityModal from "../../../../inspections/components/components/ActivityModal";
import useAppStore from "../../../../../../../../../../appStore";

const ActivityTable = ({ title="", data, drillable=true }) => {
  const { whiteLabelProps } = useAppStore();
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [timeframe, setTimeframe] = React.useState(0);
  const [sourceId, setSourceId] = React.useState(0);

  const getName = (row) => {
    if (row.first_name) {
      return row.last_name ? `${row.first_name} ${row.last_name}` : row.first_name;
    }
    return row.name;
  };

  const getCellStyle = (): React.CSSProperties => {
    return drillable
      ? {
        textDecoration: "underline",
        color: whiteLabelProps.primary_color,
        textAlign: 'center',
        cursor: "pointer",
        fontSize: '1.25rem',
        fontWeight: 'bold',
      }
      : {};
  };


  const handleCellClick = (days, id) => {
    setTimeframe(days);
    setSourceId(id);
    setDetailsOpen(true);
  };

  const handleCloseModal = (action) => {
    setDetailsOpen(false);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Typography
          variant="h5"
          component="div"
          sx={{ padding: 2, textAlign: 'center', backgroundColor: 'primary.main', color: 'primary.contrastText' }}
        >
          {title + " Activity"}
        </Typography>
        <Table aria-label="Activity table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold', textAlign: 'center' }}>3 days</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold', textAlign: 'center' }}>7 days</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold', textAlign: 'center' }}>30 days</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row" style={{fontSize: drillable ? '1.1rem' : ''}} onClick={() => handleCellClick('Name', getName(row))}>{getName(row)}</TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleCellClick(3, row.id)}
                  style={getCellStyle()}>
                  {row.count_last_3_days}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleCellClick(7, row.id)}
                  style={getCellStyle()}>
                  {row.count_3_to_7_days}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleCellClick(30, row.id)}
                  style={getCellStyle()}>
                  {row.count_8_to_30_days}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {detailsOpen &&  (
        <ActivityModal
          open={detailsOpen}
          onActionClicked={handleCloseModal}
          timeframe={timeframe}
          sourceId={sourceId}
          title={title}
        />
      )}
    </>
  );
}

export default ActivityTable;

import React, {useState} from 'react'
import PageHeader from "../../shared/components/page/page-header/PageHeader";
import {PostsService} from "../../shared/services/posts/postService";
import EditFundRaiser from './components/edit-fund-raiser/EditFundRaiser';
import FundRaiserList from './components/fundraiser-list/FundRaiserList';


const FundRaisersPage = () => {

    // The spaces-relationship-card-list of Posts retrieved from the Server
    const [fundraisers, setFundraisers] = useState<any[]>([]);

    // The Post being edited.
    const [fundRaiserToEdit, setFundRaiserToEdit] = useState(null);
    const [pageTitle, setPageTitle] = useState('New Post');

    // Controls the display of the Edit form.
    const [isEditing, setEditing] = useState(false);


    //  Called when the User clicks the New Post button.
    const handlerNewFundRaiserButtonClicked = (e) => {
        setFundRaiserToEdit(null)
        setEditing(true)
        setPageTitle('New Fundraiser')
    }

    //  Called when the user Clicks the Edit Post action on a Post in the spaces-relationship-card-list.
    const handlerEditPost = (post) => {
        setFundRaiserToEdit(post)
        setEditing(true)
        setPageTitle('Edit Fundraiser')
    }

    // Called when the user Clicks the Delete Post action on a Post in the spaces-relationship-card-list.
    const handlerDeleteFundRaiser = async (postToDelete) => {
        const service = new PostsService()
        const response = await service.deletePost(postToDelete.id)
        setFundraisers(prevPosts => prevPosts.filter(post => post.id !== postToDelete.id));

    }

    //  Called from the Edit Post form when the user takes a final action {Create, Save as Draft, Cancel}
    const handlerPostEditorAction = (action) => {
        setEditing(false)
    }

    return (
        <>
            {!isEditing && (
                <>
                    <PageHeader title='Fundraisers'
                                buttonName='New Fundraiser'
                                onActionClicked = {handlerNewFundRaiserButtonClicked}
                    />
                    <FundRaiserList fundraisers={fundraisers}
                                    setFundraisers={setFundraisers}
                                    onEditPostClicked={handlerEditPost}
                                    onDeletePostClicked={handlerDeleteFundRaiser}
                    />
                </>
            )}
            {isEditing && (
                <>
                    <PageHeader title={pageTitle}
                                showAction={false}
                    />
                   <EditFundRaiser inputPost = {fundRaiserToEdit}
                                   onActionClicked = {handlerPostEditorAction}
                   />
                </>
            )}

        </>
    )

}

export default FundRaisersPage

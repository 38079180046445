import React from "react";
import PageHeader from "../../shared/components/page/page-header/PageHeader";
import SpaceTypesDualViewer from "./desktop/components/space-type-dual-viewer/SpaceTypesDualViewer";
import { useMediaQuery, useTheme } from "@mui/material";

const SpaceTypesPage = () => {

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  return (
    <>
      <PageHeader
        title="Space Types"
        showAction={false}
      />
      {!isMobile && <SpaceTypesDualViewer />}
    </>
  );
}

export default SpaceTypesPage;






import React from 'react';

const ActionButton = ({ iconType, onClick }) => {
  const renderIcon = () => {
    switch (iconType) {
      case "pencil":
        return (
          <i className="ki-duotone ki-pencil fs-2">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        );
      case "trash":
        return (
          <i className="ki-duotone ki-trash fs-2">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
            <span className="path5"></span>
          </i>
        );
      case "user-plus":
        return <i className="fas fa-user-plus fs-5"></i>;
      case "user-change":
        return <i className="fas fa-user-edit fs-5"></i>;
      case "view":
        return <i className="fas fa-eye fs-5"></i>;
      case "comment":
        return <i className="fa-solid fa-comment"></i>;
      case "calendar":
        return <i className="fa-solid fa-calendar fs-5"></i>;
      case "done":
        return <i className="fa-solid fa-check"></i>;
      case "pdf":
        return <i className="fa-solid fa-file-pdf fs-4"></i>;
      default:
        return null;
    }
  };

  return (
    <a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" onClick={onClick}>
      {renderIcon()}
    </a>
  );
};

export default ActionButton;


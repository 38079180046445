import React, { useEffect, useState } from "react";
import { TextField, Button, Typography, Box, Divider, Chip } from "@mui/material";
import { Employee } from "../../../../shared/models/employee.model";
import useAppStore from "../../../../appStore";
import { format } from "date-fns";
import RelationshipAssignees from "./assignees/RelationshipAssignees";
import RelationshipActionTimeline from "./action-items/RelationshipActionTimeline";
import SubHeader from "../../../../shared/components/page/page-header/SubHeader";
import ContactSelect from "./contact/ContactSelect";
import { SalesContact } from "../../../../shared/models/salesContact.model";
import CommentsSection from "../../../../shared/components/comments/CommentsSection";
import { AppDate } from "../../../../shared/utils/date-time/AppDate";
import CancelIcon from "@mui/icons-material/Cancel";


const RelationshipReadOnlyDetails = ({ itemToEdit }) => {
  const { loggedInUser, whiteLabelProps } = useAppStore();
  const createdBy = itemToEdit?.created_by?.id !== -1 ? itemToEdit.created_by.person : loggedInUser;
  const description = itemToEdit.description || '--';
  const questions = itemToEdit.questions_to_ask || '--';
  const whatToConvey = itemToEdit.what_to_convey || '--';
  const assignedTo = itemToEdit.assigned_to_employees.length > 0 ? itemToEdit.assigned_to_employees : [loggedInUser?.employee];
  const contact = itemToEdit.sales_contact;

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <Box sx={{
          color: "black",
          borderBottom: '1px solid #e2e2e2',
          padding: "15px 10px",
          borderRadius: "0px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#f9f9f9",
          width: '100%'
        }}>
          <Box sx={{
            width: '40px',
            height: '40px',
            backgroundColor: whiteLabelProps.primary_color,
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            fontSize: '1.2rem',
            borderRadius: '4px',
            marginRight: '10px'
          }}>
            {`${contact?.person?.first_name?.[0] || ''}${contact?.person?.last_name?.[0] || ''}`}
          </Box>
          <div className="d-flex flex-column">
            <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
              {contact?.person?.first_name} {contact?.person?.last_name}
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ fontSize: '0.9rem' }}>
              {contact?.company_name || '--'} / Level {contact?.relationship_level}
            </Typography>
          </div>
        </Box>

        <Box sx={{ margin: 1, marginTop: '20px', border: '1px solid #c7c7c7', borderRadius: '8px', backgroundColor: '#fff' }}>
          <div style={{padding: '10px'}}>
            <SubHeader title={'Overview'} showAction={false}/>
          </div>
          <Divider sx={{backgroundColor: '#b3b3b3'}} />

          <div style={{padding: '20px'}}>
            <Box sx={{ marginBottom: '15px' }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#777', marginBottom: '5px' }}>Description</Typography>
              <Typography variant="body1" sx={{ color: '#333', whiteSpace: 'pre-wrap' }}>{description}</Typography>
            </Box>

            <Divider sx={{ margin: '15px 0' }} />

            <Box sx={{ marginBottom: '15px' }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#777', marginBottom: '5px' }}>What to Convey</Typography>
              <Typography variant="body1" sx={{ color: '#333', whiteSpace: 'pre-wrap' }}>{whatToConvey}</Typography>
            </Box>

            <Divider sx={{ margin: '15px 0' }} />

            <Box sx={{ marginBottom: '15px' }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#777', marginBottom: '5px' }}>Questions to Ask</Typography>
              <Typography variant="body1" sx={{ color: '#333', whiteSpace: 'pre-wrap' }}>{questions}</Typography>
            </Box>
          </div>

        </Box>

        {itemToEdit?.id !== 0 && (
          <Box sx={{marginTop: '20px'}}>
            <RelationshipActionTimeline relationship={itemToEdit} isEditing={false} />
          </Box>
        )}

        <Box sx={{ margin: 1, marginTop: '20px', border: '1px solid #c7c7c7', borderRadius: '8px', backgroundColor: '#fff' }}>
          <div style={{ padding: "10px" }}>
            <SubHeader title={"Relationship Manager(s)"} showAction={false} />
          </div>
          <Divider sx={{ backgroundColor: "#b3b3b3" }} />
          <Box sx={{padding: '15px'}}>
            {assignedTo.map(employee => (
              <Chip
                key={employee?.id}
                label={employee?.person.first_name + " " + employee?.person.last_name}
                onDelete={undefined}
                deleteIcon={undefined}
                sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
              />
            ))}
          </Box>

        </Box>
      </div>
    </div>
  );
};

export default RelationshipReadOnlyDetails;


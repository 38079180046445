import React, { useEffect, useState } from "react";
import { Service } from "../../shared/models/service.model";
import useServiceStore from "./serviceStore";
import EditServicePage from "./components/edit-service/EditServicePage";
import useAppStore from "../../appStore";
import { ServicesService } from "../../shared/services/services/servicesService";
import ServiceCatalogDualViewer from "./components/catalog-tree-viewer/ServiceCatalogDualViewer";
import PageHeader from "../../shared/components/page/page-header/PageHeader";


const ServiceCatalogPage = () => {

    const { selectedService, setSelectedService, fetchServices, setSelectedServiceItem } = useServiceStore();
    const { loggedInUser } = useAppStore();
    const [open, setOpen] = useState(false);
    const [refreshCatalog, setRefreshCatalog] = useState(false);

    useEffect(() => {
        fetchServices(loggedInUser.organization?.id);
        setSelectedService(null);
    }, [refreshCatalog]);

    function handleAddService() {
        let newService = new Service();
        newService.organization = loggedInUser?.organization;
        setSelectedService(newService);
        setOpen(true);
    }

    function handleEditService() {
        setOpen(true);
    }

    function handleCloseModal() {
        setOpen(false);
        setSelectedServiceItem(null);
        setRefreshCatalog(!refreshCatalog);
    }

    async function handleSaveService() {
        try {
            const service = new ServicesService();
            let response;

            if (selectedService?.id === null && selectedService?.name != "") {
                response = await service.createService(selectedService);
            } else {
                response = await service.updateService(selectedService);
            }

            setSelectedService(response);
        } catch (e) {
            console.error('Error in handleSubmit:', e);
        }
    }

    return (
      <>
          <PageHeader title="Service Catalog"
                      showAction={false}
          />
        <ServiceCatalogDualViewer
          onAdd={handleAddService}
          onEdit={handleEditService}
        />
        {open && (
          <EditServicePage
            open={open}
            onClose={handleCloseModal}
            onSave={handleSaveService}
          />
        )}

      </>
    );

};

export default ServiceCatalogPage;

import { BaseSerializer } from "./baseSerializer";
import { WorkItemProgress } from "../workItemProgress.model";
import { StatusCounts } from "../statusCounts.model";

export class StatusCountsSerializer extends BaseSerializer {


  public deserialize( data ): any {

    const statusCounts = new StatusCounts();

    this._copyAttributes(statusCounts, data);

    return statusCounts;

  }

}
import { CommandGetServiceHierarchy } from "./commands/cmdGetServiceHierarchy";
import { CommandGetService } from "./commands/cmdGetService";
import { CommandCreateService } from "./commands/cmdCreateService";
import { CommandUpdateService } from "./commands/cmdUpdateService";
import { CommandUpdateServiceMethod } from "./commands/cmdUpdateServiceMethod";
import { CommandGetServicePlanItems } from "./commands/cmdGetServiceItems";


export class ServicesService {

    getServiceHierarchy(org_id, offset=0, limit=10, search=null) {

        const cmd = new CommandGetServiceHierarchy()
        const results = cmd.run(org_id, offset, limit, search)

        return results
    }

    getService(service_id) {

        const cmd = new CommandGetService()
        const results = cmd.run(service_id)

        return results

    }

    getServiceItems(service) {

        const cmd = new CommandGetServicePlanItems()
        const results = cmd.run(service)

        return results

    }

    async createService(service) {

        const cmd = new CommandCreateService()
        const results = cmd.run(service)

        return results
    }

    async updateService(service) {

        const cmd = new CommandUpdateService()
        const results = cmd.run(service)

        return results
    }

    async updateServiceMethod(service, service_method) {

        const cmd = new CommandUpdateServiceMethod();
        const results = cmd.run(service, service_method)

        return results
    }

}
import React from 'react';
import useTrainingStore from "../../../trainingStore";
import TrainingCatalogTree from "./TrainingCatalogTree";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";

const TrainingHierarchy = () => {
    const {
        trainingCategories,
        setSelectedCategory,
        fetchCourse,
        categoriesLoading: isLoading,
        error
    } = useTrainingStore();

    const onCategorySelected = async (selected) => {
        setSelectedCategory(selected);
        //fetch category?
        //fetch course?
    };

    if (isLoading) {
        return <CustomLoadingDots />
    }
    if (error) {
        return <CustomError error={error} />;
    }

    return (
      <div>
          <TrainingCatalogTree
            data={trainingCategories}
            onItemSelected={onCategorySelected}
          />
      </div>
    );
};

export default TrainingHierarchy;


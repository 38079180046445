import React from "react";
import useAppStore from "../../../appStore";
import { formatDate, getTitle } from "../../../shared/utils/table-modifier-util/tableDataUtil";
import { CSSProperties } from "react";
import ItemDetails from "../../dashboard/components/user-work-items/components/components/ItemDetails";

const UserItemRow = (props) => {
  const { item, type } = props;
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const { whiteLabelProps } = useAppStore();

  return (
    <>
      <tr style={styles.row as CSSProperties} onClick={() => setDetailsOpen(!detailsOpen)}>
        <td style={styles.column as CSSProperties}>
          <div style={styles.content as CSSProperties}>
            {type === "workitem" &&
              <a className="text-gray-800 fw-bold mb-1 fs-6">{getTitle(item)}</a>
            }
            {type === "case" &&
              <a className="text-gray-800 fw-bold mb-1 fs-6">{item.case_type?.name}</a>
            }
            <span style={styles.date as CSSProperties}>{formatDate(item.create_date)}</span>
          </div>
        </td>
        <td style={{ ...styles.column, ...styles.statusColumn } as CSSProperties}>
          <div style={styles.content as CSSProperties}>
            <a
              className="fw-bold mb-1 fs-6"
              style={{ color: item.status === "Closed" ? "red" : item.status === 'New' ? '#E4CD05' : whiteLabelProps.primary_color }}
            >
              {item.status === 'New' ? 'Unassigned' : (item.status || "Created")}
            </a>
          </div>
        </td>
      </tr>
      <ItemDetails item={item} open={detailsOpen} onClose={() => setDetailsOpen(false)} type={type} />
    </>

);
};

const styles = {
  row: {
    justifyContent: "center",
    borderBottom: "1px dashed #ccc",
    padding: "10px 0",
    horizontalAlign: 'middle'
  },
  column: {
    padding: "10px 20px",
    verticalAlign: "middle",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  date: {
    color: 'gray',
    fontWeight: '500',
    fontSize: '0.875rem',
  },
  statusColumn: {
    textAlign: "start",
  },
};

export default UserItemRow;

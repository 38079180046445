import React, { useEffect, useState } from "react";
import { Typography, Box, Button, Divider, Tabs, Tab, Snackbar, useTheme } from "@mui/material";
import useAppStore from "../../../../appStore";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import { CasesService } from "../../../../shared/services/cases/casesService";
import CaseCard from "../../../cases/components/case-list/components/mobile/CaseCard";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Case } from "../../../../shared/models/case.model";
import ItemDetails from "../user-work-items/components/components/ItemDetails";
import ComponentViewerSmall from "../../../../shared/components/page/modal/ComponentViewerSmall";

const ThingsIveToldSage = () => {
  const { loggedInUser, selectedLocation, appImages } = useAppStore();
  const navigate = useNavigate();
  const service = new CasesService();

  const [cases, setCases] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [tab, setTab] = useState(1);
  const [showMore, setShowMore] = useState(false);
  const [selectedCase, setSelectedCase] = useState<Case | null>(null);

  useEffect(() => {
    const fetchInitialData = async () => {
      const limit = !showMore ? 6 : Number.MAX_SAFE_INTEGER;

      setIsLoading(true);
      try {
        const items= await service.getCases(loggedInUser.organization?.id, loggedInUser?.employee.id, null, limit);
        setCases(items);
      } catch (error) {
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData().then();
  }, [selectedLocation, tab, showMore]);

  const handleShowMore = () => {
    setShowMore(true);
  };


  function renderCases() {
    return (
      <div>
        <div>
          {isLoading && (
            <div style={{ minHeight: "400px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CustomLoadingDots />
            </div>
          )}

            {cases.map((item) => (
              <CaseCard
                key={item.id}
                item={item}
                onEdit={() => setSelectedCase(item)}
              />
            ))}

          { selectedCase && (
            <ComponentViewerSmall selectedPage={<ItemDetails item={selectedCase} open={selectedCase !== null} onClose={() => setSelectedCase(null)} type={'case'} />} onActionClicked={() => setSelectedCase(null)} />
          )}


          {cases.length === 0 &&
            <Box textAlign="center" p={5} pt={2}>
              <img
                src={appImages.workDoneIcon}
                alt="None"
                style={{ width: "105px", height: "110px" }} />
              <Typography variant="subtitle1" color="text.secondary">
                No cases
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => navigate("/facilities/cases")}
              >
                View cases page
              </Button>
            </Box>
          }

        </div>

      </div>
    );
  }

  return (
  <div>

      {error && <CustomError error={error} />}

      {!error && (
        <>
          <div>
              <>
                {renderCases()}
                {cases.length === 6 && !showMore &&
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row"
                  }}>
                    <Button sx={{ width: "100%", padding: "10px", marginBottom: "20px", fontSize: "1.1rem" }}
                            onClick={handleShowMore}>Show More
                      <KeyboardArrowDownIcon />
                    </Button>
                  </div>}
              </>

          </div>

        </>
      )}
    </div>
  );
};

export default ThingsIveToldSage;

import useTracer, {Tracer} from "../../framework/tracer/tracer";
import { AppDate } from "../../utils/date-time/AppDate";
import { ThreadTrace } from "../ThreadTrace.model";

export abstract class BaseSerializer {


    public static serialize(model: any, omit: any[] = []) {

        const tracer = useTracer.getState();
        model.trace_thread = tracer.tracerThread

        const jsonString = JSON.stringify(model, (key, value) => {
            if (omit.includes(key)) {
                return undefined;
            }

            return value;
        });

        tracer.newThread()

        return jsonString;
    }

    abstract deserialize( data ): any;

    deserializeList( data ): any [] {

        if (data === undefined)
            return []

        const itemList:any[] = [];

        for (const dataItem of data) {

            const item = this.deserialize(dataItem);
            itemList.push(item);

        }

        return itemList;
    }

    _copyAttributes(target, source){

        if (source === null || source === undefined){
            return target;
        }

        Object.keys(target).forEach(key => {
            if (source.hasOwnProperty(key) && source[key] != null && source[key] !== '') {

                if (target[key] instanceof AppDate){
                    target[key].parseDate(source[key])
                }else {
                    target[key] = source[key];
                }
            }
        });
        return target;
    }

}
import { BaseSerializer } from "./baseSerializer";
import { ServiceMethod } from "../serviceMethods.model";

export class ServiceMethodSerializer extends BaseSerializer {

  public deserialize( data ): any {

    let serviceMethod = new ServiceMethod();

    this._copyAttributes(serviceMethod, data);

    return serviceMethod;

  }

}
import { CommentSerializer } from "../../../models/serializers/commentSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";


export class CommandCreatePostComment {

    POSTS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl(post_id) {

        return `${this.POSTS_SERVICE}/posts/${post_id}/comments/`;
    }

    async run(postId, comment) {

        try {
            const url = this.getUrl(postId)

            const commentData = CommentSerializer.serialize(comment)

            const copyiedComment = JSON.parse(commentData);

            // Only send external_id
            copyiedComment.created_by.uuid = null
            copyiedComment.created_by.id = null

            const response = await httpClient.post(url, copyiedComment);

            return this.deserialize(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }

    }



    deserialize(data) {

        const serializer = new CommentSerializer()
        const item = serializer.deserialize(data)

        return item
    }

}
import { PostSerializer } from "../../../models/serializers/postSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";


export class CommandUpdatePost {

    POSTS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl(postId) {

        return `${this.POSTS_SERVICE}/posts/${postId}/`;
    }

    async run(post) {

        try {
            const url = this.getUrl(post.id)

            const data = PostSerializer.serialize(post)

            const response = await httpClient.put(url, data);

            return this.deserialize(response.data);
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }

    }

    deserialize(data) {

        const serializer = new PostSerializer()
        const item = serializer.deserialize(data)

        return item
    }

}
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Link,
  Typography
} from "@mui/material";

export const SpaceTypeHelp = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Typography
        variant="h6"
        component="div"
        sx={{ padding: 2, textAlign: 'center', backgroundColor: 'primary.main', color: 'primary.contrastText' }}
      >
        Other?
      </Typography>
      <DialogContent>
        <DialogContentText>
          <strong>Not seeing the space type you are looking for?</strong> The list you are seeing is the global space types list. It can be edited from the
          <Link href="/admin/space-types" style={{ marginLeft: '5px', marginRight: '5px' }}>Space Types Page</Link>
          and re-used across locations.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SpaceTypeHelp;

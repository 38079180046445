
import { Entity } from "./entity.model";


export class ServiceLogArea {

  id: null | number;
  entity: Entity | null;

  constructor() {
    this.id = null;
    this.entity = null;
  }

}
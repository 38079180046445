import { AppDate } from "../utils/date-time/AppDate";

export class SurveySummary {
  avg_score: number;
  sent_count: number;
  received_count: number;
  last_received: AppDate;


  constructor() {
    this.avg_score = 0.0;
    this.sent_count = 0;
    this.received_count = 0;
    this.last_received = new AppDate();
  }

}

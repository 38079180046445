import { BaseSerializer } from "./baseSerializer";
import { ReportingGroupScore } from "../reportingGroupScore.model";

export class ReportingGroupScoreSerializer extends BaseSerializer {

  public deserialize(data): ReportingGroupScore {
    const rgs = new ReportingGroupScore();
    this._copyAttributes(rgs, data);

    return rgs;
  }

}
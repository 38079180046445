import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { SalesContactSerializer } from "../../../models/serializers/salesContactSerializer";


export class CommandCreateSalesContact {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/crm/sales_contact/`;
  }

  async run(contact) {

    const url = this.getUrl()
    const tracer = useTracer.getState()
    tracer.trace('Relationships', 'Api', `POST ${url}`)

    function omitDeep(obj, keysToOmit) {
      if (Array.isArray(obj)) {
        return obj.map(i => omitDeep(i, keysToOmit));
      } else if (typeof obj === 'object' && obj !== null) {
        return Object.keys(obj).reduce((acc, key) => {
          if (!keysToOmit.includes(key)) {
            acc[key] = omitDeep(obj[key], keysToOmit);
          }
          return acc;
        }, {});
      }
      return obj;
    }

    try {
      let sanitizedContact = omitDeep(contact, ['uuid']);
      let data = SalesContactSerializer.serialize(sanitizedContact);
      const response = await httpClient.post(url, data);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Relationships', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    }finally {
      tracer.trace('Relationships', 'Api', 'Success')
    }

  }

  deserialize(data) {
    const serializer = new SalesContactSerializer()
    const item = serializer.deserialize(data)
    return item
  }

}
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from "../core/Auth";
import { login } from "../core/_requests";
import { UserSerializer } from "../../../shared/models/serializers/userSerializer";
import { AuthModel } from "../core/_models";

export const DirectLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { saveAuth, setCurrentUser } = useAuth();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const phone = query.get('phone');
    const code = query.get('code');
    const destination = query.get('destination') || '/dashboard';

    const validateAndLogin = async () => {
      try {
        const response = await login(phone || '', code)
        const serializer = new UserSerializer()
        const user = serializer.deserialize(response.data.user)

        let auth_model: AuthModel = {
          token: response.data.token,
          user: user
        }

        if (user.external_id == '' || user.external_id == undefined){
          user.external_id = 'service_connect_' + user.id
        }

        saveAuth(auth_model)
        setCurrentUser(auth_model.user)
        navigate(destination);

      } catch (error) {
        console.error('Login validation failed', error);
        navigate('/auth/login', { replace: true });
      }
    };

    if (code) {
      validateAndLogin();
    }
  }, [location.search, navigate, saveAuth, setCurrentUser]);

  return null;
};

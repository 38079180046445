import React, { useState } from "react";
import PageHeader from "../../shared/components/page/page-header/PageHeader";
import PositionsList from "./components/equipment-list/PositionsList";

const PositionsPage = () => {

  const [refreshList, setRefreshList] = useState(false);

  return (
    <>
      <PageHeader title='Positions'
                  showAction={false}
      />
      <PositionsList canEdit={true}
                     refreshList={refreshList}
                     setRefreshList={setRefreshList}
      />
    </>
  );

}

export default PositionsPage
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { ServicePlanItemSerializer } from "../../../models/serializers/servicePlanItemSerializer";
import { ServiceItemSerializer } from "../../../models/serializers/serviceItemSerializer";


export class CommandGetServicePlanItems {

  SERVICE_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(id) {

    return  `${this.SERVICE_SERVICE}/services/${id}/service_items/`;
  }

  async run(service) {

    const url = this.getUrl(service.id)

    try {
      const response = await httpClient.get(url);
      return this.deserialize(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }

  }

  deserialize(data) {

    const serializer = new ServiceItemSerializer();
    const items = serializer.deserializeList(data)

    return items
  }

}
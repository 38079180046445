import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { SalesContactSerializer } from "../../../models/serializers/salesContactSerializer";
import { EmployeeSerializer } from "../../../models/serializers/employeeSerializer";


export class CommandGetRelationshipReps {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return  `${this.SERVICE}/crm/relationships/assigned_to/`;
  }

  async run(provider_id) {

    const url = this.getUrl()

    const params = {
      params: {
        provider: provider_id,
        limit: Number.MAX_SAFE_INTEGER
      }
    }

    const tracer = useTracer.getState()
    tracer.trace('Relationships', 'Api', `GET ${url}`)

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Relationships', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    }finally {
      tracer.trace('Relationships', 'Api', 'Success')
    }

  }

  deserialize(data) {
    const serializer = new EmployeeSerializer()
    const list = serializer.deserializeList(data)
    return list
  }

}
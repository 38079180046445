import { create } from 'zustand';
import { Entity } from "../../../../shared/models/entity.model";
import { EntityService } from "../../../../shared/services/entities/entityService";

interface SpaceStoreState {
  spaces: Entity[];
  selectedSpace: Entity | null;
  expandedNodes: any[];
  selectedNodeId: number | null;
  spacesLoading: boolean;
  refresh: boolean;
  error: string | null;
  setSpaces: (services: Entity[]) => void;
  setSelectedSpace: (
    selectedSpace: Entity | ((prevState: Entity | null) => Entity) | null
  ) => void;
  setExpandedNodes: (expandedNodes: any[]) => void; // Setter for expanded nodes
  setSelectedNodeId: (selectedNodeId: number | null) => void; // Setter for selected node ID
  fetchSpaces: (location_id: number) => Promise<void>;
  toggleRefresh: () => void;
}

const useSpaceStore = create<SpaceStoreState>((set) => ({
  spaces: [],
  selectedSpace: null,
  spacesLoading: false,
  refresh: false,
  error: null,
  expandedNodes: [], // Initialize with empty array
  selectedNodeId: null, // Initialize with null
  setSpaces: (spaces) => set(() => ({ spaces })),
  setSelectedSpace: (selectedSpace) =>
    set((state) => {
      if (typeof selectedSpace === "function") {
        return { selectedSpace: selectedSpace(state.selectedSpace) };
      }
      return { selectedSpace: selectedSpace };
    }),
  setExpandedNodes: (expandedNodes) =>
    set(() => ({ expandedNodes })), // Set the expanded nodes
  setSelectedNodeId: (selectedNodeId) =>
    set(() => ({ selectedNodeId })), // Set the selected node ID
  fetchSpaces: async (location_id) => {
    set(() => ({ spacesLoading: true }));
    try {
      const entityService = new EntityService();
      const spaces = await entityService.getEntities(location_id, null, true);
      set(() => ({ spaces, spacesLoading: false }));
    } catch (error) {
      set(() => ({ error: "An unknown error occurred", spacesLoading: false }));
    }
  },
  toggleRefresh: () => set((state) => ({ refresh: !state.refresh })),
}));

export default useSpaceStore;



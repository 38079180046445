import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Button,
  useTheme, useMediaQuery, Chip
} from "@mui/material";
import DepartmentSelectionForm from "../permissions/selectors/DepartmentSelectionForm";
import { GroupEntry } from "../../../../../../../shared/models/groupEntry.model";
import { EmployeeService } from "../../../../../../../shared/services/employee/employeeService";
import useAppStore from "../../../../../../../appStore";
import CancelIcon from "@mui/icons-material/Cancel";

const DepartmentPanel = ({ employee }) => {
  const {loggedInUser} = useAppStore();
  const [open, setOpen] = useState(false);
  const [userDepartments, setUserDepartments] = useState<GroupEntry[]>([]);
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const service = new EmployeeService();

  const fetchUserDepartments = async () => {

    setIsLoading(true);
    try {
      const items = await service.getUserDepartments(loggedInUser.organization.id, employee.id);
      setUserDepartments(items);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDepartments().then();
  }, []);

  const handleRemoveDept = async (deptId) => {
    const updated = userDepartments.filter(dep => dep?.id !== deptId);
    setUserDepartments(updated);
    try {
      await service.unlinkDepartment(deptId, employee.id);
    } catch (error) {
      console.error(error);
    }
  };



  return (
    <Box className="d-flex flex-column mt-6" sx={{ borderRadius: '5px', justifyContent: 'space-between', border: '1px solid #e8e8e8' }}>
      <Box className="d-flex flex-row" sx={{ justifyContent: 'space-between' }}>
        <Typography sx={{ fontWeight: 'bold', padding: '15px' }}>
          User Departments
        </Typography>
      </Box>
      <Divider />
      <Box className="d-flex flex-row p-5">
        <Box sx={{ p: 1, border: 0, borderRadius: 1, display: 'inline-block', minHeight: '50px', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '10px', marginBottom: '10px' }}>
          {isEditing ? (
            userDepartments.map(dept => (
              <Chip
                key={dept?.id}
                label={dept?.name}
                onDelete={() => handleRemoveDept(dept?.id)}
                deleteIcon={<CancelIcon />}
                sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
              />
            ))
          ) : (
            <Typography variant="subtitle1" sx={{fontSize: '1.3rem'}}>
              <strong>{userDepartments.length}</strong> Departments
            </Typography>
          )}

        </Box>
      </Box>
      <Box className="p-4 d-flex flex-row">
        <Button variant="contained" color="primary" onClick={() => setIsEditing(!isEditing)} style={{width: isMobile ? '40%' : '20%'}}>
          {isEditing ? 'DONE' : 'EDIT'}
        </Button>
        {isEditing &&
          <Button variant="contained" color="primary" onClick={() => setOpen(true)} style={{width: isMobile ? '60%' : '30%', marginLeft: '10px'}}>
            ADD DEPARTMENTS
          </Button>
        }
      </Box>
      {open && (
        <DepartmentSelectionForm employee_id={employee.id || 0} activeDepartments={userDepartments} setActiveDepartments={setUserDepartments} open={open} onClose={() => setOpen(false)} />
      )}
    </Box>
  );
};

export default DepartmentPanel;
import { IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useAppStore from "../../../../../../../../../appStore";
import StarIcon from "@mui/icons-material/Star";
import { StarBorderOutlined } from "@mui/icons-material";
import { InspectionPointModel } from "../../../../../../../../../shared/models/inspectionPoint.model";
import InspectionPointsSelector from "./InspectionPointsSelector";

const InspectionPointRow = (props) => {
  const { serviceItem, handleScoreChange, handleWowChange, handleReasonChange } = props;
  const { appImages } = useAppStore();
  const [value, setValue] = useState(serviceItem.score);
  const [isWow, setIsWow] = useState(!!serviceItem.is_wow);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [reasons, setReasons] = useState<InspectionPointModel[]>(serviceItem.inspection_points || serviceItem.service_item.inspection_points || []);

  useEffect(() => {
    handleReasonChange(serviceItem.id, reasons)
  }, [reasons]);

  const handleThumbClick = (newValue, event) => {
    setValue(newValue);
    setIsWow(false);
    serviceItem.score = newValue;
    serviceItem.is_wow = false;
    handleScoreChange(serviceItem.id, newValue);
    setAnchorEl(newValue === 1 ? event.currentTarget : null);
  };


  const handleWowClicked = (updated) => {
    setIsWow(updated);
    serviceItem.is_wow = updated;
    handleWowChange(serviceItem.id, updated);
  };

  return (
    <div className="mb-2 p-3 pe-0" style={{ backgroundColor: '#f5f5f5', borderRadius: '4px', width: '100%', justifyContent: 'center' }}>
      <div className="d-flex align-items-center" style={{ width: '100%' }}>
        <div className="d-flex flex-column" style={{ width: '33%', minHeight: '65px', justifyContent: 'center', paddingLeft: '5px' }}>
          <Typography variant="subtitle1" fontWeight="bold">{serviceItem.service_item.entity_type.name}</Typography>
          {value === 1 && reasons.length > 0 &&
            <InspectionPointsSelector reasons={reasons} setReasons={setReasons} open={open} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
          }
        </div>
        <div className="d-flex flex-row" style={{ justifyContent: 'space-around', width: '40%' }}>
          <img
            src={appImages.thumbsDown}
            alt="Needs Improvement"
            style={{ cursor: 'pointer', opacity: value === 1 ? 1 : 0.3, height: '40px', width: '35px' }}
            onClick={(event) => handleThumbClick(1, event)}
          />
          <img
            src={appImages.thumbsUp}
            alt="Pass"
            style={{ cursor: 'pointer', opacity: value === 2 ? 1 : 0.3, height: '40px', width: '35px' }}
            onClick={(event) => handleThumbClick(2, event)}
          />
        </div>
        <div className="d-flex justify-content-end pe-5" style={{ width: '27%' }}>
          <IconButton onClick={() => handleWowClicked(!isWow)} style={{ padding: 1 }} disabled={value === 1}>
            <div style={{ position: 'relative', width: 30, height: 30 }}>
              <StarIcon
                style={{
                  fontSize: 30,
                  color: (isWow && value === 2) ? '#FCE205' : 'transparent',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
              <StarBorderOutlined
                style={{
                  fontSize: 30,
                  color: 'gray',
                  opacity: value === 2 ? 1 : 0.3,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
            </div>
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default InspectionPointRow;


export class TrainingCategory {

    id: number;
    organization_id: number;
    name: string;
    children: TrainingCategory[];

    constructor() {
        this.id = 0;
        this.organization_id = 0;
        this.name = '';
        this.children = [];
    }

}


import React from "react";
import ToggleButton from '@mui/material/ToggleButton';
import StyledToggleButtonGroup from "../../../../../shared/components/buttons/StyledToggleButtonGroup";


const TimeframeSelector = ({timeframe, setTimeframe}) => {

  const handleTimeframeChange = (event, period) => {
    setTimeframe(period);
  };

  return (
    <div className='mt-5 mb-5'>
      <StyledToggleButtonGroup
      value={timeframe}
      exclusive
      onChange={handleTimeframeChange}
      aria-label="date selection"
      >
        <ToggleButton value="1 year">1y</ToggleButton>
        <ToggleButton value="6 months">6m</ToggleButton>
        <ToggleButton value="3 months">3m</ToggleButton>
        <ToggleButton value="30 days">1m</ToggleButton>
     </StyledToggleButtonGroup>
    </div>

  );
};

export default TimeframeSelector;

import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import classNames from "classnames";
import useServiceStore from "../../../../../../../serviceStore";

const Description = (props) => {

    const {
        classes='', isEditing=false
    } = props

    const { selectedService, setSelectedService } = useServiceStore();
    const [description, setDescription] = useState('');

    useEffect(() => {
        setDescription(selectedService?.description || '');
    }, [selectedService]);

    return (
      <>
          <div className={classNames("flex-column-fluid", classes)}>
              <div className="flex-row-fluid">
                  {/*begin::Header*/}
                  <div className="mt-10 mb-10" style={{ paddingLeft: 0 }}>
                      {/*begin::Title*/}
                      <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold text-gray-600">Description</span>
                      </h3>
                      {/*end::Title*/}
                      {/*begin::Toolbar*/}
                      {/*end::Toolbar*/}
                  </div>
                  {/*end::Header*/}

              </div>

              {isEditing ? (
                <>
                    <TextField
                      fullWidth
                      multiline
                      rows={5}
                      value={description}
                      sx={{padding: '20px', paddingTop: '0px', paddingLeft: '0px'}}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                </>
              ) : (
                <>
                    <div style={{minHeight: '150px'}}>{description}</div>
                </>
              )}
          </div>
      </>
    )
}

export default Description;

import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { RoleSerializer } from "../../../models/serializers/roleSerializer";

export class CommandGetRoles {

  POSITION_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.POSITION_SERVICE}/users/roles/`;
  }

  async run(provider_id) {

    const url = this.getUrl()

    const params = {
      params: {
        organization: provider_id,
      }
    }

    const tracer = useTracer.getState()
    tracer.trace('Users', 'Api', `GET ${url}`)

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Users', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      tracer.trace('Users', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new RoleSerializer()
    const roles = serializer.deserializeList(data)

    return roles
  }

}


import { BaseSerializer } from "./baseSerializer";
import { Respondent } from "../respondent.model";
import { Question } from "../question.model";

export class QuestionSerializer extends BaseSerializer {

  public deserialize(data): Question {
    const question = new Question();
    this._copyAttributes(question, data);

    return question;
  }

}

import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { SurveyCampaignSerializer } from "../../../models/serializers/surveyCampaignSerializer";


export class CommandSendSurveys {

    SURVEY_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {

        return  `${this.SURVEY_SERVICE}/surveys/send/`;
    }

    async run(location_id) {

        const url = this.getUrl()

        const data = {
            "locations":[
                {
                    "id": location_id
                }
            ],
            "positions": [],
            "recipients":[]
        }

        const tracer = useTracer.getState()
        tracer.trace('Send Surveys', 'Api', `POST ${url}`)

        try {
            const response = await httpClient.post(url, data);
            return this.deserialize(response.data);
        } catch (error) {
            tracer.trace('Surveys', 'Error', `${error}`)
            console.error('Error sending data:', error);
            throw error;
        }finally {
            tracer.trace('Surveys', 'Api', 'Success')
        }

    }

    deserialize(data) {

        return ''
    }

}
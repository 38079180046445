import { useState, useEffect } from 'react';
import { ServicesService } from "../../services/services/servicesService";

const useServices = (loggedInUser) => {
  const [services, setServices] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchServices = async () => {
      setIsLoading(true);
      try {
        const service = new ServicesService();
        const fetchedServices = await service.getServiceHierarchy(loggedInUser.organization?.id);
        setServices(fetchedServices);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchServices();
  }, [loggedInUser]);

  return { services, isLoading, error };
};

export default useServices;

import React, { useEffect, useState } from "react";
import CourseRow from './CourseRow';
import { TrainingService } from "../../../../../shared/services/training/trainingService";
import { useAuth } from "../../../../auth";
import { Course } from "../../../../../shared/models/course.model";
import { dummyCourses } from "./dummyCourse";



const CourseListTable = (props) => {
    const {
        onEditCourseClicked,
        onDeleteCourseClicked,
        selectedCategory
    } = props;

    // The spaces-relationship-card-list of Posts retrieved from the Server
    const [services, setServices] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const [courses, setCourses] = useState<Course[]>(dummyCourses);

    const { currentUser } = useAuth();

    useEffect(() => {
        // const fetchCourses = async () => {
        //     setIsLoading(true);
        //
        //     try {
        //         const orgId = currentUser?.organization?.id;
        //         if (!orgId) {
        //             console.error('Organization ID not found for the current user');
        //             setError('Organization ID not found');
        //             return;
        //         }
        //
        //         const service = new TrainingService();
        //         const courses = await service.getCourses(orgId, selectedCategory);
        //
        //         setCourses(courses);
        //     } catch (error) {
        //         if (error instanceof Error) {
        //             setError(error.message);
        //         }
        //     } finally {
        //         setIsLoading(false);
        //     }
        // };
        //
        // fetchCourses();
    }, [selectedCategory]);


    return (
        <>
            {/*begin::Table container*/}
            <div className="table-responsive flex-row-fluid mt-5">
                {/*begin::Table*/}
                <table className="table table-row-solid align-middle gs-0 gy-3 my-0">
                    {/*begin::Table head*/}
                    <thead>
                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                        <th className="p-0 pb-3 min-w-225px text-start">COURSE NAME</th>
                        <th className="p-0 pb-3 min-w100px text-center px-3">ACTIONS</th>
                    </tr>
                    </thead>
                    {/*end::Table head*/}
                    {/*begin::Table body*/}
                    <tbody>
                        {
                            courses.map((course) => (
                              <CourseRow course={course}
                                         onEditCourseClicked={onEditCourseClicked}
                                         onDeleteCourseClicked={onDeleteCourseClicked}
                              />
                            ))
                        }


                        {/*
                            // <CourseRow name="Two bucket mopping system"/>
                            // <CourseRow name="Spot cleaning"/>
                            // <CourseRow name="Shampooing"/>
                        */}
                    </tbody>
                    {/*end::Table body*/}
                </table>
            </div>
            {/*end::Table*/}
        </>
    )

}

export default CourseListTable

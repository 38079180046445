import React, { useCallback, useEffect, useState } from "react";
import { AppBar, Dialog, IconButton, Typography, Toolbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ModalTransition from "../../../../../../../../../shared/components/page/transitions/ModalTransition";
import InspectionsCardList
  from "../../../../../../../../inspections/components/inspections-list/InspectionsCardList";
import useAppStore from "../../../../../../../../../appStore";
import { InspectionService } from "../../../../../../../../../shared/services/inspections/inspectionService";
import EditAreaInspectionPage from "../../../../../../../../inspections/components/edit-inspection/EditAreaInspection";
import useInspectionsStore from "../../../../../../../../inspections/inspectionsStore";


const ActivityModal = ({ open, title, onActionClicked, timeframe, sourceId }) => {

  const { loggedInUser } = useAppStore();
  const service = new InspectionService();
  const { setHandleEditItem } = useInspectionsStore();

  const [state, setState] = useState<any>({
    inspections: [],
    inspectionToEdit: null,
    open: false,
    isLoading: false,
    refresh: false
  });

  useEffect(() => {
    setHandleEditItem(handleEditItem);
  }, []);

  const handleEditItem = useCallback((inspection) => {
    setState(prevState => ({ ...prevState, inspectionToEdit: inspection, open: true }));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const empId = title === 'Employee' ? sourceId : undefined;
      const locationId = title === 'Location' ? sourceId : undefined;
      try {
        setState(prevState => ({ ...prevState, isLoading: true }));
        const inspectionsData = await service.getInspections(loggedInUser.organization.id, locationId, 0, undefined, null, empId, timeframe);
        setState(prevState => ({ ...prevState, inspections: inspectionsData }));
      } catch (error) {
        console.error(error);
      } finally {
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };

    fetchData().then();
  }, [timeframe, sourceId]);

  return (
    <Dialog fullScreen open={open} onClose={() => onActionClicked('close')} TransitionComponent={ModalTransition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title} Inspections
          </Typography>
        </Toolbar>
      </AppBar>

      <div style={{padding:'2rem', paddingTop: '1px'}}>
        <InspectionsCardList inspections={state.inspections} title={"Last " + timeframe + " Days"} />
      </div>
      <EditAreaInspectionPage
        onDelete={null}
        state={state}
        setState={setState}
      />

    </Dialog>
  );
};

export default ActivityModal;

import { BaseSerializer } from "./baseSerializer";
import {PersonSerializer} from "./personSerializer";
import { Inspection } from "../inspection.model";
import { LocationSerializer } from "./locationSerializer";
import { InspectedEntitySerializer } from "./inspectedEntitySerializer";
import { localizedDateString } from "../../utils/date-time/localizedDateString";
import { SurveyCampaign } from "../surveyCampaign.model";

export class SurveyCampaignSerializer extends BaseSerializer {

    public deserialize( data ): any {

        let campaign = new SurveyCampaign();

        this._copyAttributes(campaign, data);

        let serializer = new LocationSerializer();
        const location = serializer.deserialize(data.location);
        campaign.location = location;

        return campaign;

    }

}
import React, { useState } from "react";
import { Uploady } from "@rpldy/uploady";
import UploadDropZone from "@rpldy/upload-drop-zone";
import UploadButton from "@rpldy/upload-button";
import { styled } from "@mui/material/styles";
import MediaSelector from "./components/MediaSelector";
import { CancelOutlined } from "@mui/icons-material";
import UploadProgress from "./components/upload-progress/UploadProgress";



const StyledDropZoneContent = styled('div')`
  display: flex;
  width: 100%;
  height: 200px;
  border: 1px solid #f1f1f1;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 20px;
`;




const MediaUploader = (props) => {

    const {
        uploadUrl,
        onUploadComplete,
        organization_id,
        person_id,
        module
    } = props

    const [uploaderOpen, setUploaderOpen] = useState(false);

    const onMediaTypeSelectorClicked = (type) => {
        setUploaderOpen(true);
    }

    let uploady_params = {
        organization: organization_id,
        person: person_id,
        module: 'inspections'
    }

    return (
        <>
            {uploaderOpen && (
               <div>
                   <div style={{ position: 'relative', paddingRight: '10px'}}>
                       <CancelOutlined onClick={() => setUploaderOpen(false)}
                                       style={{
                                           color: 'gray',
                                           position: 'absolute',
                                           right: 0,
                                           top: '15px',
                                           marginRight: '10px'
                                        }}
                       />
                   </div>
                    <Uploady
                        debug={false}
                        destination={{ url: uploadUrl }}
                        sendWithFormData={true}
                        params={uploady_params}
                    >
                        <UploadDropZone>
                            <StyledDropZoneContent >
                                <div className={"d-flex flex-column"}>
                                    <div>
                                        <label className='fw-bold fs-6'>Drop files here &nbsp;</label>
                                        <UploadProgress onUploadComplete={(info) => {onUploadComplete(info.uploadResponse.data)}}/>
                                    </div>
                                    <div>
                                        or
                                    </div>
                                    <div>
                                        <UploadButton className="swiper-button-next"
                                                      text="Click to Upload"/>
                                    </div>
                                </div>
                            </StyledDropZoneContent>

                        </UploadDropZone>
                    </Uploady>
               </div>
            )}
            {!uploaderOpen && (
              <Uploady
                debug={false}
                destination={{ url: uploadUrl }}
                sendWithFormData={true}
                params={uploady_params}
                accept=".jpg,.jpeg,.png,.pdf"
              >
                    <MediaSelector onSelectorClicked={onMediaTypeSelectorClicked} module={module}/>
                    <UploadProgress onUploadComplete={(info) => {onUploadComplete(info.uploadResponse.data)}}/>
              </Uploady>
            )}
        </>

    )
}
export default MediaUploader
import { BaseSerializer } from "./baseSerializer";
import { SalesContact } from "../salesContact.model";
import { PersonSerializer } from "./personSerializer";

export class SalesContactSerializer extends BaseSerializer {

  public deserialize(data): SalesContact {
    const contact = new SalesContact();
    this._copyAttributes(contact, data);

    let personSerializer = new PersonSerializer();
    contact.person = personSerializer.deserialize(data.person);

    return contact;
  }


}
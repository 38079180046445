import { BaseSerializer } from "./baseSerializer";
import { ServiceItemScore } from "../serviceItemScore.model";
import { ServiceItemSerializer } from "./serviceItemSerializer";
import { ServiceLogArea } from "../serviceLogArea";

export class ServiceLogAreaSerializer extends BaseSerializer {

    public deserialize( data ): any {

        let area = new ServiceLogArea();

        this._copyAttributes(area, data);

        return area;

    }

}
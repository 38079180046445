import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { EquipmentSerializer } from "../../../models/serializers/equipmentSerializer";
import { RelationshipSerializer } from "../../../models/serializers/relationshipSerializer";


export class CommandCreateRelationship {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {

    return `${this.SERVICE}/crm/relationships/`;
  }

  async run(relationship) {

    const url = this.getUrl()

    const tracer = useTracer.getState()
    tracer.trace('Relationships', 'Api', `POST ${url}`)

    try {

      let data = RelationshipSerializer.serialize(relationship)
      const response = await httpClient.post(url, data);

      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Relationships', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    }finally {
      tracer.trace('Relationships', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new RelationshipSerializer()
    const item = serializer.deserialize(data)

    return item
  }

}
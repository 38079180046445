import React, { useState } from 'react';
import { Divider, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import useAppStore from "../../appStore";
import LocationSettings from "./components/settings/LocationSettings";
import LocationSpaceTypes from "./components/space-types/LocationSpaceTypes";
import LocationBuildings from "./components/spaces/LocationBuildings";
import LocationContracts from "../location-summary/components/contracts/LocationContracts";
import LocationContacts from "./components/poc/LocationContacts";

const LocationProfilePage: React.FC = () => {
  const { whiteLabelProps } = useAppStore();
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const [tab, setTab] = useState(1)

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  if (isMobile) {
    return (
      <div style={{ flex: 1, textAlign: "center", padding: "20px", marginTop: "100px" }}>
        <p style={{
          fontSize: "21px",
          fontWeight: "semibold",
          marginBottom: "0px", color: whiteLabelProps.primary_color
        }}>
          Page Unavailable
        </p>
        <p style={{ fontSize: "14px", color: 'gray' }}>Location Profile is currently only available on larger screens. Try viewing on desktop.</p>
      </div>
    )
  }

  return (
    <>

      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label="Space Types" value={1} />
        <Tab label="Buildings" value={2} />
        <Tab label="Contracts" value={3} />
        <Tab label="POC Admin" value={4} />
        <Tab label="Settings" value={5} />

      </Tabs>
      <Divider sx={{ bgcolor: "#999", marginBottom: "15px" }} />

      {tab === 1 &&
        <LocationSpaceTypes />
      }
      {tab === 2 &&
        <LocationBuildings />
      }
      {tab === 3 &&
        <LocationContracts />
      }
      {tab === 4 &&
        <LocationContacts />
      }
      {tab === 5 &&
        <LocationSettings />
      }


    </>
  );
};

export default LocationProfilePage;

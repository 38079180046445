import { buildHierarchyPath } from "../utils/hierarchy/buildHierarchyPath";

export class ServiceMethod {
    id: number;
    name: string;
    equipment: any[];
    supplies: any[];
    positions: any[];
    procedures: any;

    constructor() {
        this.id = 0;
        this.name = '';
        this.equipment = [];
        this.supplies = [];
        this.positions = [];
        this.procedures = null;

    }


}


import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { GroupEntrySerializer } from "../../../models/serializers/groupEntrySerializer";

export class CommandGetDepartments {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/reporting_groups/`;
  }

  async run(provider) {

    const url = this.getUrl()

    const params = {
      params: {
        provider: provider.id,
        type: 'departments'
      }
    }

    const tracer = useTracer.getState()
    tracer.trace('Departments', 'Api', `GET ${url}`)

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Departments', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      tracer.trace('Departments', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new GroupEntrySerializer();
    const departments = serializer.deserializeList(data)
    return departments

  }

}


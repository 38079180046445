import React, {useState, useEffect} from "react";
import "yet-another-react-lightbox/styles.css";
import { useAuth } from "../../../../../auth/core/Auth";
import {FundraiserHeader} from "./components/FundraiserHeader";
import {FundraiserBody} from "./components/FundraiserBody";
import { FundraiserProgress } from "./components/FundraiserProgress";
import {FundraiserStats} from "./components/FundraiserStats";


const Fundraiser = (props) => {

    const {
        fundraiser,
        image
    } = props

    const {currentUser} = useAuth();
    const [index, setIndex] = useState(-1);

    return (
        <div className={`card card-xl-stretch mb-xl-8`}>
            {/* begin::Body */}
            <div className='card-body'>
                <FundraiserHeader fundraiser={fundraiser}
                                  image={image}/>
                <FundraiserBody fundraiser={fundraiser} />
                <FundraiserProgress />
                <FundraiserStats />
            </div>
        </div>
    )

}

export {Fundraiser}


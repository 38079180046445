import { CommandGetEntities } from "./commands/cmdGetEntities";
import { CommandGetPlaces } from "./commands/cmdGetPlaces";
import { CommandCreateEntity } from "./commands/cmdCreateEntity";
import { Entity } from "../../models/entity.model";
import { CommandUpdateEntity } from "./commands/cmdUpdateEntity";
import { CommandGetEntityDetails } from "./commands/cmdGetEntityDetails";
import { CommandDeleteEntity } from "./commands/cmdDeleteEntity";


export class EntityService {

    async getEntities(location_id=2, parent_id: number | null =null, hier=false) {
        const cmd = new CommandGetEntities()
        const results = cmd.run(location_id, parent_id, hier)
        return results
    }

    async createEntity(entity: Entity) {
        const cmd = new CommandCreateEntity()
        const results = cmd.run(entity)
        return results
    }

    async deleteEntity(id) {
        const cmd = new CommandDeleteEntity()
        const results = cmd.run(id)
        return results
    }

    async updateEntity(entity: Entity) {
        const cmd = new CommandUpdateEntity()
        const results = cmd.run(entity)
        return results
    }

    async getEntity(id) {
        const cmd = new CommandGetEntityDetails()
        const results = cmd.run(id)
        return results
    }

    async getPlaces(location_id=2, kind_of="Place", offset=0, limit=10) {
        const cmd = new CommandGetPlaces()
        const results = cmd.run(location_id, kind_of, offset, limit)
        return results
    }

}
import { ServiceItemSerializer } from "../../../models/serializers/serviceItemSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";



export class CommandGetServicePlanServiceItems {

    INSPECTIONS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {

        return  `${this.INSPECTIONS_SERVICE}/service_plans/service_items/`;
    }

    async run(entity_type_id, offset=0, limit=10, search=null) {

        const url = this.getUrl()

        const params = {
            params: {
                entity_type: entity_type_id,
                offset: offset,
                limit: limit
            }
        }

        try {
            const response = await httpClient.get(url, params);
            return this.deserialize(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }

    }

    deserialize(data) {

        const serializer = new ServiceItemSerializer()
        const inspections = serializer.deserializeList(data)

        return inspections
    }

}


export class ItemStatusCountModel {
  pending_count: number;
  assigned_count: number;
  closed_count: number;

  constructor(pending_count = 0, assigned_count = 0, closed_count = 0) {
    this.pending_count = pending_count;
    this.assigned_count = assigned_count;
    this.closed_count = closed_count;
  }
}





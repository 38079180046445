import { BaseSerializer } from "./baseSerializer";
import { Relationship } from "../relationship.model";

export class RelationshipSerializer extends BaseSerializer {


  public deserialize( data ): any {

    const relationship = new Relationship();

    this._copyAttributes(relationship, data);

    return relationship;

  }

}
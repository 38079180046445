import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";

export class CommandPostDepartment {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/reporting_groups/group_entries/employees/`;
  }

  async run(employee_id, department_id) {

    const url = this.getUrl()

    const tracer = useTracer.getState()
    tracer.trace('Equipment', 'Api', `POST ${url}`)

    try {
      let data = {
        "group_entry": {
          "id": department_id
        },
        "employee": {
          "id": employee_id
        }
      }

      const response = await httpClient.post(url, data);
      return response.data;
    } catch (error) {
      tracer.trace('Department', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    } finally {
      tracer.trace('Department', 'Api', 'Success')
    }

  }

}

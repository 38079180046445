import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Divider, IconButton, TextField } from "@mui/material";
import ServiceItemTable from "./ServiceItemTable";
import { EntityType } from "../../../../../../../shared/models/entityType.model";
import { EntityTypeService } from "../../../../../../../shared/services/entity-types/entityTypeService";
import SelectableTile
  from "../../../../../../inspections/components/edit-inspection/components/edit-inspected-entity/components/space-selection/components/selectable-tiles/SelectableTile";
import CustomLoadingDots from "../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { SpaceTypeHelp } from "../../../../../../../shared/components/page/popup-dialog/SpaceTypeHelp";
import useAppStore from "../../../../../../../appStore";
import { TextFieldButton } from "../../../../../../../shared/components/buttons/TextFieldButton";
import ActionButton from "../../../../../../../shared/components/buttons/ActionButton";

const EditSpaceType = ({ locationTypes, global, selectedSpaceType, setSelectedSpaceType, onChange, onSave, onDelete }) => {
  const {whiteLabelProps} = useAppStore();
  const existing = selectedSpaceType && selectedSpaceType.id !== 0;
  const service = useMemo(() => new EntityTypeService(), []);
  const [globalTypes, setGlobalTypes] = useState<EntityType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await service.getEntityTypes();

        // If locationTypes is empty, show all types
        const filteredData = locationTypes.length === 0
          ? data
          : data.filter(
            (type) => !locationTypes.some((locType) => locType.id === type.id)
          );

        setGlobalTypes(filteredData);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!global) {
      fetchData();
    }
  }, [global, locationTypes, service]);


  const handleNewLink = async (spaceType) => {
    onSave(spaceType)
  };

  const handleOtherClicked = () => {
    setOpen(true);
  };


  if (isLoading) {
    return <CustomLoadingDots />
  }

  return (
    <>
      { global | existing ? (
        <Box className={"mb-5"} style={{ position: "sticky", top: 0, zIndex: 100 }}>
            <div className="mb-5 d-flex flex-row align-items-center">
              <div className="input-group">
                <TextField
                  label={"Space Type Name"}
                  required
                  name="Space Type Name"
                  disabled={!global}
                  value={selectedSpaceType.name}
                  onChange={onChange}
                  sx={{ width: "99%", marginLeft: "15px", borderTopRightRadius: '0px', borderBottomRightRadius: '0px', '& .MuiOutlinedInput-root': {
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px',
                      borderTopRightRadius: '0px',
                      borderBottomRightRadius: '0px',
                      height: '45px',
                      '& .MuiInputBase-input': {
                        height: '100%',
                        padding: '16.5px 14px',
                      },
                    }, }}
                />
              </div>
              <TextFieldButton onClick={onSave}>
                {existing ? 'Save' : 'Create'}
              </TextFieldButton>
                {existing && global &&
                  <div className="d-flex flex-row-fluid justify-content-end ms-3">
                  <ActionButton iconType="trash" onClick={(e) => {
                    e.preventDefault();
                    onDelete();
                  }} />
                  </div>
                }
              {!global && existing &&
                <Button variant="contained" sx={{ ml: 1 }} onClick={(e) => {
                  e.preventDefault();
                  onDelete();
                }}>Unlink</Button>
              }
            </div>
          <ServiceItemTable spaceType={selectedSpaceType} setSpaceType={setSelectedSpaceType} global={global} />
        </Box>
      ) : (
        <Box className={"mb-5"} style={{ position: "sticky", top: 0, zIndex: 100 }}>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <h3>Existing Space Types</h3>
          </div>
          <div className="p-5 d-flex flex-row flex-wrap">
            {globalTypes.map((type, index) => (
              <div style={{ cursor: 'pointer', margin: '5px' }}
                   onMouseEnter={(e) => {e.currentTarget.style.backgroundColor = whiteLabelProps.primary_color;}}
                   onMouseLeave={(e) => {e.currentTarget.style.backgroundColor = 'inherit';}}
                   key={index}>
                <SelectableTile
                  key={index}
                  disabled={false}
                  data={type}
                  onTileClicked={handleNewLink}
                />
              </div>
            ))}
            <div style={{ cursor: 'pointer', margin: '5px' }}
                 onMouseEnter={(e) => {e.currentTarget.style.backgroundColor = whiteLabelProps.primary_color;}}
                 onMouseLeave={(e) => {e.currentTarget.style.backgroundColor = 'inherit';}}>
              <SelectableTile
                key={"other"}
                disabled={false}
                data={ {name: 'Other'}}
                onTileClicked={handleOtherClicked}
              />
            </div>
          </div>
        </Box>
      )}
      <SpaceTypeHelp open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default EditSpaceType;

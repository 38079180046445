import React, { useEffect, useState } from "react";
import { AppBar, Button, Dialog, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import useTracer from "../../../../shared/framework/tracer/tracer";
import EditInspectedEntity from "./components/edit-inspected-entity/EditInspectedEntity";
import InspectedEntitiesTable from "./components/inspected-entities-list/desktop-view/InspectedEntitiesTable";
import useInspectionsStore from "../../inspectionsStore";
import { InspectionService } from "../../../../shared/services/inspections/inspectionService";
import InspectionSummary from "./components/inspection-summary/InspectionSummary";
import JointInspectionTile from "./components/joint-inspection/JointInspectionTile";
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import InspectedEntitiesMobileView from "./components/inspected-entities-list/mobile-view/InspectedEntitiesMobileView";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { ConfirmationDialog } from "../../../../shared/components/page/popup-dialog/ConfirmationDialog";
import InspectionDatePicker from "./components/inspection-custom-date/InspectionDatePicker";
import useAppStore from "../../../../appStore";

const EditAreaInspectionPage = (props) => {
    const {
        onDelete,
        state,
        setState,
    } = props;

    const tracer = useTracer();

    const { inspection,
            setInspection,
            newInspectedEntity,
            setInspectedEntity,
            setBuilding,
            setFloor,
            capPrefill,
          } = useInspectionsStore();

    const {loggedInUser} = useAppStore()
    const dateChangePermission = loggedInUser?.permissions?.hasAccess( 'Change Inspection Date') || false;
    const [isAddInspectionOpen, setIsAddInspectionOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

    useEffect(() => {

        if (state.inspectionToEdit === null) {
            return;
        }
        const getInspectionDetails = async () => {
            setIsLoading(true);
            try {
                const service = new InspectionService();
                let newInspection = await service.getInspectionDetails(state.inspectionToEdit.id);
                setInspection(newInspection);
                newInspectedEntity();
            } catch (error) {
                tracer.trace('Inspections', 'Error', `Error: ${error}`);
                if (error instanceof Error) {
                    console.log(error.message);
                }
            } finally {
                setIsLoading(false);
            }
        };

        if (state.inspectionToEdit.id !== null) {
            tracer.trace('Inspections', 'Trace', 'Getting inspection details');
            setIsEditing(false);
            getInspectionDetails();
        } else {
            tracer.trace('Inspections', 'Trace', 'Creating new Inspection');
            setInspection(state.inspectionToEdit);
            newInspectedEntity();
            setIsEditing(true);
        }

    }, [state.inspectionToEdit]);

    const handleClose = () => {
        setIsAddInspectionOpen(false)
        setState(prevState => ({ ...prevState, open: false, inspectionToEdit: null }));
    }

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
    }

    const handleSave = async () => {
        try {
            const service = new InspectionService();
            await service.updateInspection(inspection);
            setInspection(inspection);
            setIsAddInspectionOpen(false);
            setState(prevState => ({ ...prevState, refresh: !prevState.refresh}));
            console.log('Inspection updated successfully.');
        } catch (error) {
            console.error('Error saving inspection:', error);
        }
        handleClose()
    };

    const handleInspectClicked = () => {
        newInspectedEntity()
        setIsNew(true)
        setBuilding(null)
        setFloor(null)
        setIsAddInspectionOpen(true)
    }

    const handleDeleteClicked = () => {
        setIsConfirmDialogOpen(true);
    };

    const handleConfirmDelete = async () => {
        handleClose();
        onDelete(state.inspectionToEdit);
    }

    const handleOnActionClicked = () => {
        setIsAddInspectionOpen(false)
    }

    const handleEditInspectedEntityClicked = (entity) => {
        setInspectedEntity(entity)
        setIsNew(false)
        setIsAddInspectionOpen(true)
    }

    if (isLoading) {
        return <CustomLoadingDots />;
    }

    return (
      <>
          <Dialog fullScreen open={state.open} onClose={handleClose} TransitionComponent={ModalTransition}>
              <AppBar sx={{ position: 'relative' }}>
                  <Toolbar>
                      <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                          <CloseIcon />
                      </IconButton>
                      <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
                          {capPrefill.inspected_entities.length > 0 ? 'CAP Inspection' : 'Inspection Tool'}
                      </Typography>
                      {!isEditing &&
                        <Button
                          color="inherit"
                          variant="contained"
                          onClick={toggleEditMode}
                          sx={{ ml: 'auto', color: '#444444' }}
                        >
                            {"Edit"}
                        </Button>
                      }
                      {!(capPrefill?.inspected_entities?.length > 0) && onDelete !== null &&
                        <Button
                          color="inherit"
                          variant="contained"
                          onClick={handleDeleteClicked}
                          sx={{
                          ml: !isEditing ? 1 : 'auto',
                          color: '#444444' }}
                          >
                      {"Delete"}
                          </Button>
                      }
                  </Toolbar>
              </AppBar>
              <EditInspectedEntity open={isAddInspectionOpen} onActionClicked={handleOnActionClicked} isNew={isNew} />
              <div className="d-flex flex-column flex-column-fluid mt-10 mx-5"
                   style={{ height: "calc(100vh - 100px)" }}>
                  <InspectionSummary />
                  <div style={{ marginBottom: dateChangePermission ? "5px" : "20px" }}>
                      <JointInspectionTile isEditing={isEditing} />
                  </div>
                  {dateChangePermission &&
                    <div style={{ marginBottom: "20px" }}>
                        <InspectionDatePicker isEditing={isEditing} />
                    </div>
                  }
                  <div>
                      <InspectedEntitiesMobileView
                        onEditInspectedEntityClicked={handleEditInspectedEntityClicked}
                        onAddInspectionsClick={handleInspectClicked}
                        isEditing={isEditing}
                      />
                  </div>
                  <div
                    className="mt-5 d-flex justify-content-end"
                    style={{
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 1000,
                        width: "100%"
                    }}
                  >
                      {isEditing &&
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSave}
                          sx={{ width: "100%" }}
                        >
                            SAVE
                        </Button>
                      }
                  </div>
              </div>
              <ConfirmationDialog
                isOpen={isConfirmDialogOpen}
                setIsOpen={setIsConfirmDialogOpen}
                message="Confirm Deletion"
                description="Are you sure you want to delete this inspection? This action cannot be undone."
                onConfirmClicked={handleConfirmDelete}
              />
          </Dialog>
      </>
    );
}

export default EditAreaInspectionPage

import React from 'react';
import { ScannerService } from "../../shared/services/qr-scanner/scannerService";
import { Scanner } from '@yudiel/react-qr-scanner';
import useAppStore from "../../appStore";



const QrCodeReader = ({ onClose }) => {
  const { loggedInUser } = useAppStore();


  async function handleScan(result) {
    let response = null;
    try {
      if (result.length > 0) {
        response = await new ScannerService().sendScanData(loggedInUser.employee, result[0].rawValue);
        console.log(response);
      }
    } catch (e) {
      console.error('Error while saving:', e);
    } finally {
      onClose(response);
    }
  }


  return (
    <div>
      <Scanner onScan={(result) => handleScan(result)} />
    </div>
  );
};

export default QrCodeReader;


import { Service } from "../service.model";
import { BaseSerializer } from "./baseSerializer";
import { ServiceMethodSerializer } from "./serviceMethodSerializer";

export class ServiceSerializer extends BaseSerializer {

    public deserialize( data ): any {

        let service = new Service();

        this._copyAttributes(service, data);

        let childServices = this.deserializeList(data.children)

        service.children = childServices

        if (Array.isArray(data.service_methods)) {
            let serviceMethodSerializer = new ServiceMethodSerializer();
            service.service_methods = data.service_methods.map(method => serviceMethodSerializer.deserialize(method));
        }

        return service;

    }

}
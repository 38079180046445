import { EntityType } from "./entityType.model";
import { InspectionPointModel } from "./inspectionPoint.model";
import { Service } from "./service.model";
import { LocationModel } from "./location.model";


export class ServiceItem {

    id: null | number;
    uuid: null | string;
    entity_type: EntityType;
    service: null | Service;
    inspection_points: InspectionPointModel[];
    location: LocationModel | null;


    constructor() {
        this.id = null;
        this.uuid = null;
        this.entity_type = new EntityType();
        this.inspection_points = [];
        this.service = null;
        this.location = null;
    }

}


import React from "react";
import ContactRow from "./ContactRow";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";



const ContactTable = (props) => {

  const{
    contacts,
    canEdit,
    onEdit,
  } = props

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));


  return (
    <>
      <div>
        <div>
          <div className="table-responsive w-100">
            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0 w-100">
              <thead>
              <tr className="fs-7 fw-bold text-gray-400 border-bottom-0 mt-5">
                <th className="p-0 pb-3 min-w-200px text-start">Name</th>
                <th className="p-0 pb-3 min-w-200px text-start">Contact Info</th>
                <th className="p-0 pb-3 min-w-200px text-start">Contact Level</th>
              </tr>
              </thead>

              <tbody>
              {contacts.map((item) => (
                <ContactRow key={item.id}
                            item={item}
                            onEdit={onEdit}
                            onDelete={null}
                            canEdit={canEdit}
                            isMobileView={isMobile}
                />
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );

}

export default ContactTable
import React from "react";
import WorkItemPieChart from "./components/WorkItemPieChart";
import UrgencyTable from "./components/tables/UrgencyTable";
import { Grid } from "@mui/material";
import AgingTable from "./components/tables/AgingTable";
import ProgressTable from "./components/tables/ProgressTable";
import ActivityTable from "./components/tables/ActivityTable";

const WorkItemAnalyticsCard = ({data}) => {

  return (
    <>
      <Grid container spacing={2} sx={{mt: 1}}>
        <Grid item xs={12} md={4}>
          {data.status_counts_all &&
            <WorkItemPieChart
              data={data.status_counts_all}
            />
          }
        </Grid>
        <Grid item md={8} lg={8} xl={8}
              sx={{
                display: { xl: 'block', lg: 'block', md: 'block', xs: 'none', sm: 'none' }
              }}>
          <UrgencyTable />
        </Grid>
        <Grid item xs={12} md={4}>
          <ProgressTable data={data.progress_by_location}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <ActivityTable data={data.activity_by_location} drillable={false} />
        </Grid>
        <Grid item xs={12} md={4}>
          <AgingTable data={data.aging_by_location}/>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkItemAnalyticsCard;


import React, { useEffect, useState } from "react";
import PageHeader from "../../../../shared/components/page/page-header/PageHeader";
import { SurveySummary } from "../../../../shared/models/surveySummary.model";
import SurveySummaryTiles from "./components/SurveySummaryTiles";
import TimeframeSelector from "./components/TimeframeSelector";
import { AnalyticsService } from "../../../../shared/services/analytics/analyticsService";
import useAppStore from "../../../../appStore";
import TierDualView from "./components/TierDualView";
import { ReportingGroupScore } from "../../../../shared/models/reportingGroupScore.model";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";

const SurveyAnalyticsPage = () => {

  const { loggedInUser, selectedLocation} = useAppStore();
  const service = new AnalyticsService();
  const [summary, setSummary] = React.useState(new SurveySummary());
  const [timeframe, setTimeframe] = useState('30 days')
  const [reportingGroups, setReportingGroups] = React.useState<ReportingGroupScore[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError('');
      try {
        const tileSummary = await service.getSurveySummary(
          loggedInUser.organization,
          selectedLocation.id,
          undefined,
          '30 days'
        );
        const tierData = await service.getReportingGroupScore(loggedInUser.organization, selectedLocation.id, timeframe)
        setReportingGroups(tierData)
        setSummary(tileSummary);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedLocation.id !== 0) {
      fetchData().then();
    }

  }, [loggedInUser, selectedLocation.id, timeframe]);


  if (isLoading) {
    return (
      <CustomLoadingDots />
    );
  }

  if (error) {
    return (
      <CustomError error={error} />
    );
  }


  return (
    <>
      <PageHeader
        title='Customer Surveys'
        showAction={false}
      />
      {/*<TimeframeSelector timeframe={timeframe} setTimeframe={setTimeframe}/>*/}
      <SurveySummaryTiles summary={summary} />
      <TierDualView data={reportingGroups} timeframe={timeframe}/>
    </>

  )

}

export default SurveyAnalyticsPage
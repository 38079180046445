// EntitySelector can be a Person, Place or Thing.
import { buildHierarchyPath } from "../utils/hierarchy/buildHierarchyPath";
import { EntityType } from "./entityType.model";
import { Organization } from "./organization.model";
import { LocationModel } from "./location.model";

export class Entity {

    id: number;
    location: LocationModel;
    organization: Organization;
    organization_id: number;
    location_id: number;
    name: string;
    short_name: string;
    description: string;
    kind_of: string;
    children: Entity[];
    entity_path: any[];
    in_service: boolean;
    parent: Entity | null;
    inspection_frequency: number;
    joint_inspection_frequency: number;
    entity_type: EntityType | null;
    entity_types_to_service: EntityType[];
    has_service_log: boolean;
    service_log_scan_frequency: number;
    has_work_item: boolean;
    work_item_closed: boolean;



    constructor() {
        this.id = 0;
        this.location = new LocationModel();
        this.organization = new Organization();
        this.organization_id = 0;
        this.location_id = 0;
        this.name = '';
        this.short_name = '';
        this.description = '';
        this.kind_of = '';
        this.children = [];
        this.entity_path = [];
        this.in_service = false;
        this.parent = null;
        this.inspection_frequency = 0;
        this.joint_inspection_frequency = 0;
        this.entity_type = null;
        this.entity_types_to_service = [];
        this.has_service_log = false;
        this.service_log_scan_frequency = 0;
        this.work_item_closed = false;
        this.has_work_item = false;
    }

    getPath(){
        return buildHierarchyPath(this.entity_path)
    }

}


import React, {useEffect, useState,} from "react";
import TraineeRow from "./components/TraineeRow";
import { getCardClass, getTitleClass } from "../../../../../shared/utils/table-modifier-util/tableDataUtil";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import CustomLoadingDots from "../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../shared/components/page/popup-dialog/CustomError";


const TraineeList = (props) => {

    const {
        onEditItem
    } = props

    const [value, setValue] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));


    useEffect(() => {

        const fetchPosts = async () => {

            setIsLoading(true);

            try {

            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                }
            }finally {
                setIsLoading(false);
            }
        };

        fetchPosts().then();

    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if (isLoading) {
        return <CustomLoadingDots />;
    }
    if (error) {
        return <CustomError error={error} />;
    }

    return (
      <>
          {/*begin::Table widget 14*/}
          <div className={getCardClass(isMobile)}>
              {/*begin::Header*/}
              <div className="card-header pt-7">
                  {/*begin::Title*/}
                  <h3 className={getTitleClass(isMobile)}>
                      <span className="card-label fw-bold text-gray-800">Assigned Training</span>
                      <span className="text-gray-400 mt-1 fw-semibold fs-6">in-progress items</span>
                  </h3>
                  {/*end::Title*/}
                  {/*begin::Toolbar*/}
                  <div className="card-toolbar">
                      {/*<a className="btn btn-sm btn-light">Sort By</a>*/}
                  </div>
                  {/*end::Toolbar*/}
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pt-6">
                  {/*begin::Table container*/}
                  <div className="table-responsive ">
                      {/*begin::Table*/}
                      <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                          {/*begin::Table head*/}
                          <thead>
                          <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                              <th className="p-0 pb-3 min-w-100px text-start">EMPLOYEE</th>
                              <th className="p-0 pb-3 min-w-100px text-start px-3">PROGRESS</th>
                              <th className="p-0 pb-3 min-w120px text-start px-3">URGENCY</th>
                              <th className="p-0 pb-3 min-w-25px text-center">NOTIFY</th>
                              <th className="p-0 pb-3 min-w-25px text-center">ACTIONS</th>
                          </tr>
                          </thead>
                          {/*end::Table head*/}
                          {/*begin::Table body*/}
                          <tbody>
                            <TraineeRow onEditItem={onEditItem}/>
                          </tbody>
                          {/*end::Table body*/}
                      </table>
                  </div>
                  {/*end::Table*/}
              </div>
              {/*end: Card Body*/}
          </div>
          {/*end::Table widget 14*/}

      </>
    )

}


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default TraineeList
import { CommandGetEntityTypes } from "./commands/cmdGetEntityTypes";
import { CommandGetEntityType } from "./commands/cmdGetEntityType";
import { CommandCreateEntityType } from "./commands/cmdCreateEntityType";
import { CommandUpdateEntityType } from "./commands/cmdUpdateEntityType";
import { CommandLinkEntityType } from "./commands/cmdLinkEntityType";
import { CommandDeleteEntityType } from "./commands/cmdDeleteEntityType";
import { CommandUnlinkEntityType } from "./commands/cmdUnlinkEntityType";


export class EntityTypeService {

    async getEntityTypes(location_id?, kind_of='Place', for_entity=0) {
        const cmd = new CommandGetEntityTypes()
        const results = cmd.run(location_id, kind_of, for_entity)
        return results
    }

    async getEntityType(id, location_id=0) {
        const cmd = new CommandGetEntityType()
        const result = cmd.run(id, location_id)
        return result
    }

    async deleteEntityType(id) {
        const cmd = new CommandDeleteEntityType()
        const result = cmd.run(id)
        return result
    }

    async createEntityType(type) {
        const cmd = new CommandCreateEntityType()
        const result = cmd.run(type)
        return result
    }

    async linkEntityType(type, location_id) {
        const cmd = new CommandLinkEntityType()
        const result = cmd.run(type, location_id)
        return result
    }

    async unlinkEntityType(type, location_id) {
        const cmd = new CommandUnlinkEntityType()
        const result = cmd.run(type, location_id)
        return result
    }

    async updateEntityType(type) {
        const cmd = new CommandUpdateEntityType()
        const result = cmd.run(type)
        return result
    }

}
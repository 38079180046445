import React, {useState} from "react";
import { useFormik } from 'formik';
import {Button} from "react-bootstrap";
import PostFormFields from "./components/PostFormFields";
import MediaUploader from "../../../../shared/components/media/media-uploader/MediaUploader";
import { Media } from "../../../../shared/models/media.model";
import { Post } from "../../../../shared/models/post.model";
import { useAuth } from "../../../../modules/auth/core/Auth";
import {PostsService} from "../../../../shared/services/posts/postService";


const EditFundRaiserPage = (props) => {

    const {
        inputPost,
        onActionClicked,
    } = props

    const [postToUpdate, setPostToUpdate] = useState(inputPost || new Post());

    const buttonName = inputPost == null ? 'Create' : 'Update'

    const {currentUser} = useAuth();

    const formik = useFormik({
        initialValues: {
            name: '',
            description: postToUpdate.description,
            visibility: postToUpdate.visibility,
            about: postToUpdate.about
        },
        onSubmit: async (values) => {
            /*
                if postToUpdate is null, create a new Post
                populate the Post fields
                Get the logged in user. set created_by
                Call the Post Service to create the Post.
            */

            const newPost = {...postToUpdate};

            postToUpdate.description = values.description
            postToUpdate.visibility = values.visibility
            postToUpdate.organization_id = 8227
            // postToUpdate.about = values.about
            postToUpdate.created_by = currentUser

            const service = new PostsService()

            if (inputPost == null ) {
                const post: Post = await service.createPost(postToUpdate)
                onActionClicked('create', post)
            }else{
                const post: Post = await service.updatePost(postToUpdate)
                onActionClicked('edit', post)
            }

        },
    });

    const mediaUploadCompleteHandler = (item) => {

        let media = new Media()

        media.url = item.url
        media.id = item.id
        media.file_name = item.file_name

        const newPost = {...postToUpdate};
        newPost.media = [...newPost.media, media];

        setPostToUpdate(newPost);

        console.log('Media uploaded')
        console.log(item)

    }

    const createPostHandler = () => {
        /*
            This is called when the user clicks Create.
            The call formik.submitForm() will result in the onSubmit being called above
            as part of useFormik.
         */

        formik.submitForm().then()

    }

    const saveDraftHandler = () => {

        formik.submitForm().then()
        onActionClicked('save_draft')
    }

    const uploadUrl = process.env.REACT_APP_API_FACILITIES_SERVICE_URL + '/media/upload/';

    return (

        <div className='row'>
            <div className='col-2'/>
            <div className='col-8'>
                <div className='card'>
                    <div className='card-body d-flex flex-column p-9'>
                        <PostFormFields formik={formik}/>
                        <MediaUploader uploadUrl={uploadUrl} onUploadComplete={mediaUploadCompleteHandler}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button variant="primary" className='btn btn-sm fw-bold me-3 mt-2' onClick={() => createPostHandler()}>{buttonName}</Button>
                            {/*<Button variant="primary" className='btn btn-sm fw-bold me-3 mt-2' onClick={() => saveDraftHandler()}>Save as Draft</Button> */}
                            <Button variant="secondary" className='btn btn-sm fw-bold mt-2' onClick={() => onActionClicked('close')} >Cancel</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-2'/>
        </div>

    )

}

export default EditFundRaiserPage

import {toAbsoluteUrl} from "../../../_metronic/helpers";
import { Media } from "./media.model";

export class Person {

    id: number;
    uuid: string;
    first_name: string;
    last_name: string;
    photo: Media | null;
    photo_url: string;
    phone_number: string;
    email: string;

    constructor() {
        this.id = 0;
        this.uuid = '';
        this.photo = null;
        this.photo_url = toAbsoluteUrl('/media/avatars/blank.png');
        this.first_name = '';
        this.last_name = '';
        this.phone_number = '';
        this.email = '';

    }

}


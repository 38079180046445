import { toAbsoluteUrl } from "../../../_metronic/helpers";

export class AppImages {

    verdeLogoWhite: string;
    sageWalking: string;
    sageAssistant: string;
    pdfIcon: string;
    launchMenuIcon: string;
    menuExitIcon: string;
    menuAdminIcon: string;
    menuBackIcon: string;
    menuCasesIcon: string;
    menuHeartIcon: string;
    menuHomeIcon: string;
    menuInspectionsIcon: string;
    menuOrgChartIcon: string;
    menuSafetyIcon: string;
    menuAnalyticsIcon: string;
    menuWorkItemsIcon: string;
    ratingIcon: string;
    thumbsUp: string;
    thumbsDown: string;
    ratingIconBlank: string;
    ratingIconHalf: string;
    workDoneIcon: string;
    pencilPaper: string;
    sdsIcon: string;
    equipmentIcon: string;
    contactsIcon: string;
    slipsIcon: string;
    huddleIcon: string;
    sentIcon: string;
    receivedIcon: string;
    inspectionAlt: string;
    avgScoreIcon: string;
    mopIcon: string;
    dropdownIcon: string;
    summaryIcon: string;
    relationshipsIcon: string;
    defaultProfileIcon: string;
    menuInspectionsAltIcon: string;
    noDataIcon: string;
    noPdfIcon: string;
    priorityIcon: string;
    genericCase: string;
    hrCase: string;
    marketingCase: string;



    constructor() {

        this.verdeLogoWhite = toAbsoluteUrl('/white-labeling/verde/media/logos/verde_logo_white.png');
        this.sageWalking = toAbsoluteUrl('/white-labeling/verde/media/logos/sage_v_sign.png');
        this.sageAssistant =  toAbsoluteUrl('/white-labeling/verde/media/menu-icons/virtual-assistant.png');
        this.pdfIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/pdf-icon.png');
        this.launchMenuIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/fab-menu.png');
        this.menuBackIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-back.png');
        this.menuExitIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/fab-exit.png');
        this.menuAdminIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-admin.png');
        this.menuCasesIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-cases.png');
        this.menuHeartIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-heart.png');
        this.menuHomeIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-home.png');
        this.menuInspectionsIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-inspections-2.png');
        this.menuOrgChartIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-org-chart.png');
        this.menuSafetyIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-safety.png');
        this.menuAnalyticsIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/menu-analytics.png');
        this.menuWorkItemsIcon = toAbsoluteUrl('/white-labeling/verde/media/logos/sage_safety.png');
        this.sdsIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/sds.png');
        this.workDoneIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/work-done.png');
        this.pencilPaper = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/pencilpaper.png');
        this.ratingIcon = toAbsoluteUrl('/white-labeling/verde/media/images/inspections/inspection-rating.png');
        this.ratingIconBlank = toAbsoluteUrl('/white-labeling/verde/media/images/inspections/inspection-rating-empty.png');
        this.thumbsDown = toAbsoluteUrl('/white-labeling/verde/media/logos/thumbsdown.png');
        this.thumbsUp = toAbsoluteUrl('/white-labeling/verde/media/logos/thumbsup.png');
        this.ratingIconHalf = toAbsoluteUrl('/white-labeling/verde/media/images/inspections/inspection-rating-half.png');
        this.equipmentIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/hardhat.png');
        this.contactsIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/contactsIcon.png');
        this.slipsIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/slipsIcon.png');
        this.huddleIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/huddle.png');
        this.sentIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/sent-icon.png');
        this.receivedIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/recieved-icon.png');
        this.inspectionAlt = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/inspection-alt.png');
        this.avgScoreIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/avg.png');
        this.mopIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/mopIcon.png');
        this.dropdownIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/dropdownIcon.png');
        this.summaryIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/summaryIcon.png');
        this.relationshipsIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/opportunitiesIcon.png');
        this.defaultProfileIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/profileFiller.png');
        this.menuInspectionsAltIcon = toAbsoluteUrl('/white-labeling/verde/media/logos/magnifyingMenuIcon.png');
        this.noDataIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/noDataIcon.png');
        this.noPdfIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/no-pdf-icon.png');
        this.priorityIcon = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/priorityIcon.png');
        this.genericCase = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/caseRowGeneric.png');
        this.hrCase = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/caseRowHR.png');
        this.marketingCase = toAbsoluteUrl('/white-labeling/verde/media/menu-icons/caseRowMarketing.png')

    }



}


import React from "react";

const BadgeStepIcon = ({ active, count }) => (

  <div style={{ width:27,
    height:27,
    borderRadius:'50%',
    backgroundColor: active ? '#15aa78' : 'lightgray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    cursor: 'pointer'
  }}>
    <b>{count}</b>
  </div>



);

export default BadgeStepIcon;
import { Organization } from "../organization.model";
import { BaseSerializer } from "./baseSerializer";

export class OrganizationSerializer extends BaseSerializer {


    public deserialize( data ): any {

        const organization = new Organization();

        this._copyAttributes(organization, data);

        return organization;

    }

}
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { timeAgo } from '../../../../utils/date-time/timeAgo';
import { Avatar } from "@mui/material";
import useAppStore from "../../../../../appStore";



const Comment = (props) => {

    const {
        comment
    } = props

  const {whiteLabelProps} = useAppStore();

    return (

        <div className='d-flex align-items-center mb-5'>
            {/* begin::Avatar */}
          <div className="me-5">
            <Avatar sx={{ bgcolor: whiteLabelProps.primary_color_light, width: 50, height: 50 }}
                    variant="rounded"
                    src={comment.created_by.photo_url}
                    alt={`${comment.created_by.first_name} ${comment.created_by.last_name}`}
            />
          </div>
            {/* end::Avatar */}

            {/* begin::Info */}
            <div className='d-flex flex-column flex-row-fluid'>
                {/* begin::Info */}
                <div className='d-flex align-items-center flex-wrap mb-1'>
                    <a href='#' className='text-gray-800 text-hover-primary fw-bold me-2'>
                        {comment.created_by.first_name} {comment.created_by.last_name}
                    </a>

                    <span className='text-gray-400 fw-semibold fs-7'>{timeAgo(comment.comment_date)}</span>

                    {/*  Hide for now.
                    <a href='#' className='ms-auto text-gray-400 text-hover-primary fw-semibold fs-7'>
                        Reply
                    </a>
                    */}
                </div>
                {/* end::Info */}

                {/* begin::Post */}
                <span className='text-gray-800 fs-7 fw-normal pt-1 ps-2'>
                   {comment.comment_text}
                  </span>
                {/* end::Post */}
            </div>
            {/* end::Info */}
        </div>
)

}

export {Comment}

import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";

export class CommandCreateAssignment {
  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/reporting_groups/group_entries/employees/`;
  }

  async run(node_id, employee_id) {
    const url = this.getUrl();

    const payload = {
      group_entry: { id: node_id },
      employee: { id: employee_id }
    };

    const tracer = useTracer.getState();
    tracer.trace('ReportingGroups', 'Api', `POST ${url}`);

    try {
      const response = await httpClient.post(url, payload);
      tracer.trace('ReportingGroups', 'Api', 'Success');
      return;

    } catch (error) {
      tracer.trace('ReportingGroups', 'Error', `${error}`);
      console.error('Error:', error);
      throw error;
    }
  }

}

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Button,
  AppBar,
  Toolbar, IconButton
} from "@mui/material";
import ModalTransition from "../../../../../../../../../shared/components/page/transitions/ModalTransition";
import { Close } from "@mui/icons-material";

const SuppliesListDialog = ({ isOpen, onClose, supplies, onSelect }) => {
  return (
    <Dialog fullWidth maxWidth={"sm"} open={isOpen} onClose={onClose} TransitionComponent={ModalTransition} PaperProps={{ style: { overflowX: 'hidden', backgroundColor: 'white' } }}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogTitle>Select Supply</DialogTitle>
      <DialogContent>
        <List>
          {supplies.map((supply) => (
            <ListItem button onClick={() => onSelect(supply)} key={supply.id}>
              <ListItemText primary={supply.name} />
            </ListItem>
          ))}
        </List>
        <Button onClick={onClose}>Close</Button>
      </DialogContent>
    </Dialog>
  );
};

export default SuppliesListDialog;

import { create } from 'zustand'
import { EntityType } from '../../shared/models/entityType.model';
import { Entity } from '../../shared/models/entity.model';
import { Inspection } from '../../shared/models/inspection.model';
import { Media } from '../../shared/models/media.model';
import { InspectedEntity } from "../../shared/models/inspectedEntity.model";
import { DateTime } from 'luxon';

interface InspectionsStore {

  tabIndex: number;
  inspection: Inspection;
  capPrefill: Inspection;
  inspectedEntity: InspectedEntity;
  building: Entity | null;
  floor: Entity | null;

  handleEditItem(inspection: Inspection): void;
  handleDeleteItem(inspection: Inspection): void;

  setInspection: (inspection: Inspection) => void;
  setCapPrefill: (capPrefill: Inspection) => void;
  setInspectedEntity: (inspectedEntity: InspectedEntity) => void;
  updateInspectedEntity: (inspectedEntity: InspectedEntity) => void;
  setTabIndex: (tabIndex: number) => void;
  setInspectionDate: (date: DateTime) => void;
  setBuilding: (place: Entity | null) => void;
  setFloor: (place: Entity | null) => void;
  setSpaceType: (placeType: EntityType) => void;
  setSpaceNumber: (number: string) => void;
  setScore: (inspectionScore: number) => void;
  setMedia: (media: Media[]) => void;
  setComments: (newComment: string) => void;
  setHandleEditItem: (func) => void;
  setHandleDeleteItem: (func) => void;
  reset: () => void;
  refresh: boolean;
  toggleRefresh: () => void;
  newInspectedEntity: () => void;
}


const useInspectionsStore = create<InspectionsStore>((set, get) => ({

  inspection: new Inspection(),
  capPrefill: new Inspection(),
  inspectedEntity: new InspectedEntity(),
  tabIndex: 0,
  building: null,
  floor: null,
  submitCount: 0,

  handleEditItem: (inspectedEntity)=>{},
  handleDeleteItem: (inspectedEntity)=>{},

  setInspection: (inspection) => set({inspection}),
  setCapPrefill: (capPrefill) => set({capPrefill}),
  setTabIndex: (tabIndex) => set({ tabIndex }),
  setInspectionDate: (date: DateTime) => {

    const insp = get().inspection;
    insp.create_date.dateTime = date;
    set({inspection: insp})

  },
  setBuilding: (space: Entity | null) => {
    set({ building: space });
    set({ floor: null });
  },
  setFloor: (space: Entity | null) => {
    if (space) {
      const ie = get().inspectedEntity;
      ie.entity = space;
      set({ inspectedEntity: ie });
    }

    set({ floor: space});
  },

  setSpaceType: (spaceType) => {
    const ie = get().inspectedEntity;
    ie.entity_type = spaceType
    set({ inspectedEntity: ie });
  },

  setSpaceNumber: (number) => {
    const ie = get().inspectedEntity;
    ie.space_number = number
    set({ inspectedEntity: ie });
  },

  setScore: (score) => {
    const ie = get().inspectedEntity;
    ie.score = score
    set({ inspectedEntity: ie });
  },

  setMedia: (media: Media[]) => {
    const ie = get().inspectedEntity;
    ie.media = media
    set({ inspectedEntity: ie });
  },

  setComments: (newComment: string) => {
    const ie = get().inspectedEntity;
    ie.comments = newComment;
    set({ inspectedEntity: ie });
  },

  setInspectedEntity: (inspectedEntity) => {
      if (inspectedEntity.entity_path.length > 0){
        set({building:inspectedEntity.entity_path[0]})
      }
      set({inspectedEntity})
  },

  updateInspectedEntity: (inspectedEntity) => {
    const currentInspection = get().inspection;

    if (currentInspection === null ){
      return
    }

    let ies = currentInspection.inspected_entities

    const idx = ies.findIndex(item => item.id === inspectedEntity.id);

    if (idx !== -1){
      ies[idx] = inspectedEntity
    }else{
      currentInspection.inspected_entities.push(inspectedEntity)
    }

    if (inspectedEntity.entity_path.length > 0){
      set({building:inspectedEntity.entity_path[0]})
    }

    set({inspectedEntity})

  },

  newInspectedEntity: () => {
    const ie = new InspectedEntity();

    const currentInspection = get().inspection;
    const currentInspectedEntity = get().inspectedEntity;

    ie.entity = currentInspectedEntity.entity
    ie.entity_type = currentInspectedEntity.entity_type

    if (currentInspection) {
      currentInspection.inspected_entities.push(ie);
    }

    // Set the current inspected things.
    set({ inspectedEntity: ie });

  },

  setHandleEditItem: (func) => {

    if (typeof func === 'function') {
      set({ handleEditItem: func });
    }
  },

  setHandleDeleteItem: (func) => {

    if (typeof func === 'function') {
      set({ handleDeleteItem: func });
    }

  },

  reset: () => {
    set({tabIndex: 0})
    set({building: null})
    set({floor: null})
    set({inspection: new Inspection()})
    set({capPrefill: new Inspection()})
    set({inspectedEntity:  new InspectedEntity()})
  },

  refresh: false,

  toggleRefresh: () => {
    set((state) => ({ refresh: !state.refresh }));
  }

}));

export default useInspectionsStore

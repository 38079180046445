import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import classNames from 'classnames';
import { TreeItem, TreeItemClasses } from '@mui/x-tree-view/TreeItem';
import { TreeView } from '@mui/x-tree-view/TreeView';
import useAppStore from "../../../../../../../../appStore";
import { Typography } from "@mui/material";
import useSpaceStore from "../../../../spaceStore";

const SpaceTree = (props) => {
  const {
    data = [],
    onItemSelected,
    onRenderLabel = null,
    classes = '',
    setSelectedLevel,
  } = props;

  const treeItemClasses = {
    root: '',
    group: '',
    content: '',
    expanded: '',
    selected: '',
    focused: '',
    disabled: '',
    iconContainer: '',
    label: 'fs-5'
  }

  const { selectedLocation } = useAppStore();
  const {
    expandedNodes,
    selectedNodeId,
    setExpandedNodes,
    setSelectedNodeId,
  } = useSpaceStore();

  const [selectedItem, setSelectedItem] = React.useState(null);

  const handleServiceSelected = (event, nodeId) => {
    if (event.target.closest('.MuiTreeItem-iconContainer')) {
      return;
    }

    let selectedItem = null;
    let selectedLevel = 0;

    const findTreeNodeWithLevel = (node, id, level = 1) => {
      if (node.id === id) {
        selectedLevel = level;
        return node;
      }
      if (Array.isArray(node.children)) {
        for (let child of node.children) {
          const foundNode = findTreeNodeWithLevel(child, id, level + 1);
          if (foundNode) {
            return foundNode;
          }
        }
      }
      return null;
    };

    for (let item of data) {
      selectedItem = findTreeNodeWithLevel(item, Number(nodeId));
      if (selectedItem !== null) {
        setSelectedItem(selectedItem);
        setSelectedLevel(selectedLevel);
        setSelectedNodeId(nodeId);  // Update the selected node ID in store
        onItemSelected(selectedItem, selectedLevel); // Pass both the item and its level
        return;
      }
    }
  };

  const handleToggle = (event, nodeIds) => {
    setExpandedNodes(nodeIds); // Update the expanded nodes in store
  };

  const handleFakeRootClick = () => {
    setSelectedItem(null);
    setSelectedLevel(0);
    setSelectedNodeId(null);  // Clear the selected node ID in store
    onItemSelected(null);
  };

  const renderLabel = (labelName) => {
    if (onRenderLabel !== null) {
      return onRenderLabel(labelName);
    }

    return (
      <div className='d-flex justify-content-between align-items-center'>
        <span className="fs-4">{labelName}</span>
      </div>
    );
  };

  const renderTree = (node, type) => {
    const displayName = node.name;
    const entityType = node?.entity_type ? `(${node.entity_type?.name})` : '';

    if (node.matched !== undefined && !node.matched) {
      return null;
    }

    return (
      <TreeItem
        key={node.id}
        nodeId={`${node.id.toString()}`}
        label={renderLabel(displayName + " " + entityType)}
        classes={treeItemClasses}
      >
        {Array.isArray(node.children)
          ? node.children.map((childNode) => renderTree(childNode, type))
          : null}
      </TreeItem>
    );
  };

  return (
    <>
      <div className={classNames("", classes)}>
        <TreeView
          aria-label="file system navigator"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeSelect={handleServiceSelected}
          onNodeToggle={handleToggle}
          expanded={expandedNodes} // Use expanded nodes from store
          selected={selectedNodeId !== null ? String(selectedNodeId) : undefined}
          sx={{ width: '100%', height: '100%' }}
        >
          <Typography
            onClick={handleFakeRootClick}
            sx={{ cursor: 'pointer', fontSize: '1.25rem', backgroundColor: selectedNodeId ? '' : '#eff8f5', paddingLeft: '5px' }}
          >
            {selectedLocation.name}
          </Typography>
          {data.map((item) => renderTree(item, item.name))}
        </TreeView>
      </div>
    </>
  );
};

export default SpaceTree;


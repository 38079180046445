import { EntityType } from "./entityType.model";
import { Entity } from "./entity.model";
import { Inspection } from "./inspection.model";
import { Media } from "./media.model";
import { InspectionServiceItemScore } from "./inspectionServiceItemScore.model";
import { buildHierarchyPath } from "../utils/hierarchy/buildHierarchyPath";


export class InspectedEntity {

    id: null | number;
    uuid: null | string;
    score: number;
    comments: string;
    inspection: Inspection;
    entity_type: EntityType;
    entity: Entity;
    space_number: string;
    service_item_scores: InspectionServiceItemScore[];
    entity_path: Entity[];
    media: Media[];

    constructor() {
        this.id = null;
        this.uuid = null;
        this.score = -1.0;
        this.comments = '';
        this.inspection = new Inspection();
        this.entity_type = new EntityType();
        this.entity = new Entity();
        this.space_number = '';
        this.service_item_scores = [];
        this.entity_path = [];
        this.media = [];
    }

    getRootSpace(){
        if( this.entity_path.length === 0){
            return ''
        }

        return this.entity_path[0]
    }

    getChildSpacePath(){
        return buildHierarchyPath(this.entity_path)
    }

}


import { create } from 'zustand';
import { TrainingCategory } from "../../../shared/models/training-category.model";
import { TrainingService } from "../../../shared/services/training/trainingService";
import { Course } from "../../../shared/models/course.model";

interface TrainingStore {
  trainingCategories: TrainingCategory[];
  selectedCategory: TrainingCategory | null;
  courseToEdit: Course | null;
  categoriesLoading: boolean;
  coursesLoading: boolean;
  isEditingCourse: boolean;
  setIsEditingCourse: (isEditing: boolean) => void;
  error: string | null;
  setTrainingCategories: (trainingCategories: TrainingCategory[]) => void;
  setSelectedCategory: (selectedCategory: TrainingCategory | ((prevState: TrainingCategory | null) => TrainingCategory) | null) => void;
  setCourseToEdit: (courseToEdit: Course | ((prevState: Course | null) => Course) | null) => void;
  fetchCategories: (organization_id: number) => Promise<void>;
  fetchCourse: (course_id: number) => Promise<void>;
}

const useTrainingStore = create<TrainingStore>((set) => ({
  trainingCategories: [],
  selectedCategory: null,
  courseToEdit: null,
  categoriesLoading: false,
  coursesLoading: false,
  isEditingCourse: false,
  error: null,
  setTrainingCategories: (categories) => set(() => ({ trainingCategories: categories })),
  setSelectedCategory: (selected) =>
    set((state) => {
      if (typeof selected === 'function') {
        return { selectedCategory: selected(state.selectedCategory) };
      }
      return { selectedCategory: selected };
    }),
  setCourseToEdit: (selected) =>
    set((state) => {
      if (typeof selected === 'function') {
        return { courseToEdit: selected(state.courseToEdit) };
      }
      return { courseToEdit: selected };
    }),
  setIsEditingCourse: (isEditing) => set(() => ({ isEditingCourse: isEditing })),

  fetchCategories: async (organization_id) => {
    set(() => ({ categoriesLoading: true }));
    try {
      const trainingService = new TrainingService();
      const categories = await trainingService.getTrainingCategoryHierarchy(organization_id);
      set(() => ({ trainingCategories: categories, categoriesLoading: false }));
    } catch (error) {
        set(() => ({ error: 'An unknown error occurred', categoriesLoading: false }));
    }
  },

  fetchCourse: async (course_id) => {
     //implement
  },


}));

export default useTrainingStore;


import React, { useEffect, useState } from "react";
import useAppStore from "../../../../../../appStore";
import DualBarChartComponent from "./components/DualBarChartComponent";
import { fetchIssuesChart } from "../../../../../../shared/utils/data-fetch/analyticDataFetchHelper";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";

type ChartHeaderRow = [string, string];
type ChartDataRow = [string, number];
type ChartDataState = Array<ChartHeaderRow | ChartDataRow>;

const IssuesByServiceItem = ({ location_id }) => {
  const { whiteLabelProps, loggedInUser, selectedLocation } = useAppStore();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [chartData, setChartData] = useState<ChartDataState>([['Service Item', 'Issue Count']]);
  const [maxValue, setMaxValue] = useState<number>(0);

  useEffect(() => {
    setIsLoading(true);
    setError('');
    fetchIssuesChart(loggedInUser.organization.id, location_id, 'inspections', 'issues_by_service_item')
      .then(({ transformedData, maxCount }) => {
        setChartData(transformedData);
        setMaxValue(maxCount);
      })
      .catch(error => {
        setError(error instanceof Error ? error.message : String(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [location_id, loggedInUser.organization.id]);

  if (isLoading) {
    return <CustomLoadingDots />
  }

  return (
    <DualBarChartComponent
      chartData={chartData}
      chartTitle={
        location_id === 0
          ? "Issue Count"
          : (selectedLocation && selectedLocation.name ? selectedLocation.name : "") + " Issue Count"
      }
      primaryColor={whiteLabelProps.primary_color}
      max={maxValue}
      sortOrder={'desc'}
    />
  );
};

export default IssuesByServiceItem;


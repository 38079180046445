import React from 'react';
import { AppBar, Dialog, IconButton, Typography, Toolbar, Box, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ModalTransition from "../../../../../../../../../../shared/components/page/transitions/ModalTransition";
import { getTextForPosition } from "../../../../../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import SageSuggestions from "./SageSuggestions";
import ErrorIcon from '@mui/icons-material/Error';
import SageScoreSlider
  from "../../../../../../../../../dashboard/site-manager-dashboard/components/sage-analytics/SageScoreSlider";
import SagePrompter from "../../../../../../../../../sage-virtual-assistant/SagePrompter";


const SageScoreModal = ({ open, healthData, failingCount, details, onActionClicked }) => {

  const sageScore = 100 - healthData.health_score
  const {text, message} = getTextForPosition(sageScore)
  const sageResponse = `Your location (${healthData?.location_name}) has a sage score of ${sageScore}/100. ` + message
  const [typing, setIsTyping] = React.useState(false);

  return (
    <Dialog fullScreen open={open} onClose={() => onActionClicked('close')} TransitionComponent={ModalTransition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Sage Score
          </Typography>
        </Toolbar>
      </AppBar>
      <AppBar sx={{ position: 'relative', color: '#777', bgcolor: '#e9e9e9' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit">
            <ErrorIcon sx={{fontSize: '3rem'}}/>
          </IconButton>
          <div className="d-flex flex-column">
            <Typography variant="subtitle1">Compliance Issue Count: {healthData?.compliance_count}</Typography>
            <Typography variant="subtitle1">Quality Issue Count: {healthData?.quality_count}</Typography>
          </div>
        </Toolbar>
      </AppBar>

      <SageScoreSlider score={healthData.health_score} hideText={true} />

      <SagePrompter sageResponse={sageResponse} setIsTyping={setIsTyping}/>

      <div className="ps-10 pe-10">
        <SageSuggestions failingCount={failingCount} details={details}/>
      </div>


    </Dialog>
  );
};

export default SageScoreModal;

import React, { useCallback, useEffect } from "react";
import { ServiceLogService } from "../../../../../shared/services/service-logs/serviceLogService";
import useAppStore from "../../../../../appStore";
import './styles/log-style.css';
import { ServiceLogArea } from "../../../../../shared/models/serviceLogArea";


const LogSelect = ({state, setState, selectedLog, setSelectedLog}) => {

  const {loggedInUser, selectedLocation} = useAppStore();
  const [options, setOptions] = React.useState<ServiceLogArea[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchLog = useCallback(async () => {
    setIsLoading(true);
    try {
      const service = new ServiceLogService();
      const locations = await service.getLogOptions(selectedLocation.id, state.space.id, state.spaceType.id);
      setOptions(locations);
    } catch (err) {
      console.log(err instanceof Error ? err.message : String(err));
    } finally {
      setIsLoading(false);
    }
  }, [loggedInUser.organization.id, selectedLocation.id, state.space.id, state.spaceType.id]);

  useEffect(() => {
    fetchLog().then();
  }, [fetchLog]);

  const handleLogSelected = (checked, item) => {
    if (checked) {
      setSelectedLog(item)
    } else {
      setSelectedLog(null)
    }
  };

  return (
    <div className="log-select-container">
      <h1>Select correct log option</h1>
      <ul className="log-list">
        {options.map((item) => (
          <li key={item.id} className="log-option">
            <label className="log-label">
              <input
                type="checkbox"
                className="log-checkbox"
                checked={selectedLog?.id === item.id}
                onChange={(e) => handleLogSelected(e.target.checked, item)}
              />
              <span className="custom-checkbox"></span>
              {item?.entity?.name}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LogSelect;

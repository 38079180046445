import React, { useEffect, useState } from "react";
import { Employee } from "../../../../shared/models/employee.model";
import useAppStore from "../../../../appStore";
import { RelationshipService } from "../../../../shared/services/relationships/relationshipService";

const RelationshipFilterDropdown = ({ filter, setFilter, refresh }) => {

  const { loggedInUser } = useAppStore();
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const service = new RelationshipService();

  const fetchEmployees = async () => {
    setIsLoading(true);
    try {
      const options = await service.getRelationshipReps(loggedInUser?.organization.id);
      setEmployees(options);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees().then();
  }, [refresh]);


  const handleContactSelected = (event) => {
    const selectedContactId = event.target.value;
    const selectedContact = employees.find(c => c.id === parseInt(selectedContactId));

    if (selectedContact) {
      setFilter(selectedContact);
    } else {
      setFilter(null);
    }
  };

  return (
    <div className="d-flex flex-column w-100">
      <div className="mb-5 mt-5">
        <div className="input-group d-flex flex-row align-items-center">
          <select
            className="form-select form-select-solid me-1"
            value={filter?.id || ""}
            onChange={handleContactSelected}
            style={{border: '1px solid #c7c7c7'}}
          >
            <option value="">Filter by manager...</option>
            {employees.map((c) => (
              <option key={c.id} value={c.id}>
                {`${c.person.first_name} ${c.person.last_name}`}
              </option>
            ))}
          </select>

        </div>
      </div>

    </div>
  );

}

export default RelationshipFilterDropdown;
import React from "react";
import { Box, Card, CardContent } from "@mui/material";
import {
  formatDate,
  getCaseAssignee, getCaseRelatedEmployee
} from "../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";
import useAppStore from "../../../../../../appStore";

const CaseCard = (props) => {
  const { item, onEdit } = props;

  const {appImages, whiteLabelProps} = useAppStore();

  return (
    <div className='d-flex flex-row w-100' style={{marginBottom: "15px"}}>
      <Card
        variant="outlined"
        sx={{
          height: "120px",
          display: "flex",
          flexDirection: "column",
          width: '100%',
          boxShadow: "0px 1px 2px rgba(0,0,0,0.1)"
          }}>
        <CardContent sx={{
          flex: "1 1 50%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px"
        }}>
        <div className="d-flex flex-row align-items-center">
          <div className="fs-7 d-flex flex-column">
                      <span className="fw-bold" style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        display: "block",
                        maxWidth: "200px"
                      }}>
                          {item.case_type.name}
                      </span>
            <span style={{ color: whiteLabelProps.primary_color }}>{item.department.name}</span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <ActionButton
            iconType={"view"}
            onClick={(e) => {
              e.preventDefault();
              onEdit(item);
            }} />
        </div>
      </CardContent>
      <div style={{
        flex: "1 1 50%",
        borderTop: "1px dashed #e7e7e7",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px"
      }}>
        <div className="d-flex flex-column"
             style={{ width: "55%", maxWidth: "145px", overflow: "hidden", textOverflow: "ellipsis" }}>
                  <span className="text-gray-800 fw-bold fs-7">
                      {(item.related_to_employees?.length) ? getCaseRelatedEmployee(item) : "0 employees"}
                  </span>
          <span className="text-muted fs-7">
                      related to
                  </span>
        </div>
        <div className="d-flex flex-column"
             style={{ width: "45%", maxWidth: "145px", overflow: "hidden", textOverflow: "ellipsis" }}>
                  <span className="text-gray-800 fw-bold fs-7">
                      {(item.assigned_to_employees?.length) ? getCaseAssignee(item) : "Queued"}
                  </span>
          <span className="text-muted fs-7">
                      {(item.assigned_to_employees?.length) ? `assigned ${formatDate(item.create_date)}` : `created ${formatDate(item.create_date)}`}
                  </span>
        </div>
      </div>
    </Card></div>
  );
};

export default CaseCard;


import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { LocationContractsSerializer } from "../../../models/serializers/locationContractsSerializer";


export class CommandGetLocationContracts {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(id) {
    return  `${this.SERVICE}/locations/${id}/contracts/`;
  }

  async run(id) {

    const url = this.getUrl(id)
    const tracer = useTracer.getState()
    tracer.trace('Location', 'Api', `GET ${url}`)

    try {
      const response = await httpClient.get(url);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Location', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    }finally {
      tracer.trace('Location', 'Api', 'Success')
    }

  }

  deserialize(data) {
    const serializer = new LocationContractsSerializer()
    const location = serializer.deserialize(data)
    return location
  }

}
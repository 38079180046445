import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { WorkItemSerializer } from "../../../models/serializers/workItemSerializer";


export class CommandCreateWorkItem {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/workitems/`;
  }

  async run(workitem) {

    const url = this.getUrl()

    const tracer = useTracer.getState()
    tracer.trace('WorkItems', 'Api', `POST ${url}`)

    try {

      let data = WorkItemSerializer.serialize(workitem);
      const response = await httpClient.post(url, data);

      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('WorkItems', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    }finally {
      tracer.trace('WorkItems', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new WorkItemSerializer()
    const item = serializer.deserialize(data)

    return item
  }

}
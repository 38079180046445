import { BaseSerializer } from "./baseSerializer";
import { WorkItemPrefillModel } from "../workItemPrefill.model";

export class WorkItemPrefillSerializer extends BaseSerializer {
  public deserialize(data): any {
    let prefill = new WorkItemPrefillModel();
    this._copyAttributes(prefill, data);
    return prefill;
  }
}

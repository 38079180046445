import React, { useEffect } from "react";
import { Divider, useMediaQuery, useTheme } from "@mui/material";
import useAppStore from "../../../../../../../appStore";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import BuildIcon from '@mui/icons-material/Build';
import { WorkItem } from "../../../../../../../shared/models/workItem.model";
import { useWorkItem } from "../../../../../../work-items/context/workItemContext";

const InspectionSpaces = ({ inspection }) => {
  const { whiteLabelProps, appImages } = useAppStore();
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const { setWorkItemToEdit, setOpen } = useWorkItem();


  const hasNonNullMedia = (mediaArray) => {
    return mediaArray.some(media => media.id !== null || media.url !== null || media.type !== null);
  };

  const openWorkItem = (id) => {
    let workItem = new WorkItem();
    workItem.id = id;
    setWorkItemToEdit(workItem);
    setOpen(true);
  }

  return (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <tbody>
      <tr>
        <td colSpan={2}>
          <Divider textAlign="center" className="text-muted pt-2" sx={{ borderStyle: 'dotted' }}>Spaces</Divider>
        </td>
      </tr>
      {inspection.spaces && inspection.spaces.map((space, spaceIndex) => (
        <tr key={spaceIndex}>
          <td colSpan={2} className="ps-3 pt-3">
            <span className="fw-bold fst-italic" style={{fontSize: isMobile ? '' : '1.1rem'}}>{space.name}</span>
            {space.areas.filter(a => a.score !== -1).map((area, areaIndex) => (
              <span key={areaIndex} className="d-flex justify-content-between align-items-center fst-italic ps-2 pe-7">
                  <span className="fst-italic" style={{fontSize: isMobile ? '' : '1.1rem'}}>{area.entity_path_text}</span>
                  <span className="d-flex align-items-center justify-content-end" style={{ width: '140px', gap: '10px' }}>
                    <span style={{ width: '30px', textAlign: 'center', cursor: 'pointer' }} onClick={() => openWorkItem(area.work_items[0]?.id || 0)}>
                      {area.has_work_item && !area.work_item_closed && <BuildOutlinedIcon sx={{ color: "gray", fontSize: '20px', cursor: 'pointer' }} />}
                      {area.work_item_closed && <BuildIcon sx={{ color: "gray", fontSize: '20px', cursor: 'pointer' }} />}
                    </span>
                    <span style={{ width: '30px', textAlign: 'center' }}>
                      {area.comments !== "" && <ChatBubbleIcon sx={{ color: "gray", fontSize: '18px' }} />}
                    </span>
                    <span style={{ width: '30px', textAlign: 'center' }}>
                      {hasNonNullMedia(area.media) && <CameraAltIcon sx={{ color: "gray", fontSize: '18px' }} />}
                    </span>
                    <span style={{
                      fontWeight: 'bold',
                      color: whiteLabelProps.primary_color,
                      fontSize: isMobile ? '14px' : '1.1rem',
                      textAlign: 'center',
                      width: '30px'
                    }}>
                      {area.score.toFixed(1)}
                    </span>
                  </span>
                </span>
            ))}
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  );
};

export default InspectionSpaces;

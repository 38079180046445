import React, { useEffect } from "react";
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import {
    Alert,
    AppBar, Box, Button,
    Dialog,
    DialogActions,
    IconButton, Snackbar,
    Typography
} from "@mui/material";
import SpaceSelection from "./components/space-selection/SpaceSelection";
import Scoring from "./components/scoring/Scoring"
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import ModalTransition from "../../../../../../shared/components/page/transitions/ModalTransition";
import EditWorkItem from "../../../../../work-items/components/edit-work-item/EditWorkItem";
import { useEditInspectedEntity } from "./useEditInspectedEntity";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";
import { useWorkItem } from "../../../../../work-items/context/workItemContext";

const EditInspectedEntity = (props) => {

    const {
        open,
        onActionClicked,
        isNew,
    } = props

    const {
        scrollableContainerRef, biRef,
        isLoading, error,
        activeStep, setActiveStep,
        isEditing, setIsEditing,
        workItem, setWorkItem,
        prefill,
        snackbarOpen, setSnackbarOpen,
        validationInfo,
        isPrevDisabled, isNextDisabled, isSubmitDisabled,
        workItemOpen,
        inspectedEntity,
        disableButtons,
        handleClose, handlePrevClicked, handleNextClicked,
        handleWorkItemAction, handleSubmitClicked
    } = useEditInspectedEntity(onActionClicked);

    useEffect(() => {
        const inspectedEntityNull = inspectedEntity.id === null;

        setIsEditing(!inspectedEntityNull && !isNew);

        if (inspectedEntityNull) {
            setActiveStep(0);
        } else {
            disableButtons(1);
            setActiveStep(1);
        }
    }, [inspectedEntity.id, isNew]);


    if (isLoading) {
        return <CustomLoadingDots />;
    }
    if (error) {
        return <CustomError error={error} />;
    }

    return (

      <>
          <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={ModalTransition}
          >
              <AppBar sx={{ position: 'relative' }}>
                  <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                      >
                          <CloseIcon />
                      </IconButton>
                      <Typography sx={{ flex: 1 }} variant="h5" component="div">
                          Inspect
                      </Typography>
                      <Button color="inherit" variant="contained" disabled={isSubmitDisabled} onClick={() => handleSubmitClicked(true)} sx={{ mr: 1, color: '#444444' }}>
                          + Work Item
                      </Button>
                      <Button color="inherit" variant="contained" onClick={handleClose} sx={{ color: '#444444' }}>
                          Exit
                      </Button>
                  </Toolbar>
              </AppBar>

              { !validationInfo.passed && (

                <Alert className="mt-5 mx-5"
                       variant="outlined"
                       severity="error"
                >
                    {validationInfo.messages.map((msg, index) => (
                      <>
                          <Box component="label" sx={{ fontSize: '16px' }}>
                              {msg}
                          </Box>
                          <br/>
                      </>

                    ))}
                </Alert>
              )}
              <div className='card mt-5'
                   style={{height: 'calc(100vh - 250px)'}}
              >
                  <div ref={scrollableContainerRef}
                       className="card-body d-flex flex-row-fluid"
                       style={{overflowY: 'scroll'}}
                  >
                      <div style={{width:'100%'}}>
                          <SwipeableViews
                            disabled={true}
                            index={activeStep}
                          >
                              <SpaceSelection biRef={biRef} isEditing={isEditing} />
                              <Scoring biRef={biRef} isEditing={isEditing} />
                          </SwipeableViews>
                      </div>
                  </div>
                  <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={4000}
                    onClose={() => setSnackbarOpen(false)}
                    message="Work Item Created"
                  />
              </div>
              <DialogActions sx={{ position: 'sticky',
                  bottom: 0,
                  backgroundColor: '#fafafa',
                  zIndex: 2
              }}>
                  <Button disabled={isPrevDisabled}
                          onClick={handlePrevClicked}>Prev</Button>
                  <Button disabled={isNextDisabled}
                          onClick={handleNextClicked}>Next</Button>
                  {!isEditing && (
                    <Button variant="contained" color="primary" onClick={() => handleSubmitClicked(false)} disabled={isSubmitDisabled}>
                        + Space
                    </Button>
                  )}
                  {isEditing && (
                    <Button variant="contained" color="primary" onClick={() => handleSubmitClicked(false)} disabled={isSubmitDisabled}>
                        Save
                    </Button>
                  )}
              </DialogActions>
          </Dialog>
          {workItemOpen && workItem && (
            <EditWorkItem
              prefill={prefill}
              open={workItemOpen}
              onActionClicked={handleWorkItemAction}
              inspectedEntity={inspectedEntity}
            />
          )}
      </>

    )

}

export default EditInspectedEntity


import { CommandGetPosts } from "./commands/cmdGetPosts";
import { CommandCreatePost } from "./commands/cmdCreatePost";
import { CommandCreatePostComment } from "./commands/cmdCreatePostComment";
import { CommandUpdatePost } from "./commands/cmdUpdatePost";
import {CommandDeletePost} from "./commands/cmdDeletePost";


export class PostsService {

    getPosts(org_id, offset=0, limit=10, search=null) {

        const cmd = new CommandGetPosts()
        const results = cmd.run(org_id, offset, limit, search)

        return results
    }

    async createPost(post) {

        const cmd = new CommandCreatePost()
        const results = cmd.run(post)

        return results
    }

    async updatePost(post) {

        const cmd = new CommandUpdatePost()
        const results = cmd.run(post)

        return results
    }

    async deletePost(postId) {

        const cmd = new CommandDeletePost()
        const results = cmd.run(postId)

        return results
    }

    async createPostComment(postId, comment) {

        const cmd = new CommandCreatePostComment()
        const results = cmd.run(postId, comment)

        return results
    }





}
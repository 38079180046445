import React, { useEffect, useState } from "react";
import useAppStore from "../../../../appStore";
import SpacesDualViewer from "./components/dual-viewer/SpacesDualViewer";
import useSpaceStore from "./spaceStore";
import { Entity } from "../../../../shared/models/entity.model";
import EditSpace from "./components/edit-space/EditSpace";
import { EntityService } from "../../../../shared/services/entities/entityService";


const LocationBuildings = () => {

    const { selectedSpace, setSelectedSpace, fetchSpaces, refresh, toggleRefresh } = useSpaceStore();
    const { selectedLocation } = useAppStore();
    const [open, setOpen] = React.useState(false);
    const [spaceToEdit, setSpaceToEdit] = React.useState<Entity>(new Entity())


    useEffect(() => {
        fetchSpaces(selectedLocation.id).then();
    }, [refresh, selectedLocation]);

    const handleClose = () => {
        setOpen(false);
    }

    const handleEdit = (space) => {
        setSpaceToEdit(space)
        setOpen(true);
    }

    const handleAdd = () => {
        setSpaceToEdit(new Entity())
        setOpen(true);
    }

    const handleSave = async (entity) => {
        try {
            const isCreating = entity.id === 0;
            const service = new EntityService();
            const response = isCreating
              ? await service.createEntity(entity)
              : await service.updateEntity(entity);
        } catch (e) {
            console.error('Error saving entity:', e);
        } finally {
            handleClose();
            toggleRefresh();
        }
    }

    return (
      <>
          <SpacesDualViewer
            selectedSpace={selectedSpace}
            onAdd={handleAdd}
            onEdit={handleEdit}
          />
          {open && (
            <EditSpace
              open={open}
              onClose={handleClose}
              spaceToEdit={spaceToEdit}
              setSpaceToEdit={setSpaceToEdit}
              onSave={handleSave}
            />
          )}
      </>
    );

};

export default LocationBuildings;

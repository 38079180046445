

export class InspectionPointModel {
  id: number;
  name: string;
  is_pass: boolean;
  is_other: boolean;
  comments: string;

  constructor() {
    this.id = 0;
    this.name = "";
    this.is_pass = false;
    this.is_other = false;
    this.comments = "";
  }

}
import React from "react";
import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";


const TraineeRow = (props) => {

    const {
        onEditItem,
        children,
        value,
        index,
        ...other } = props;

    return (
        <>
            <tr>
                <td>
                    <div className="d-flex align-items-center">
                        <div className="symbol symbol-50px me-3">
                            <img src={toAbsoluteUrl("/media/avatars/300-2.jpg")} className="" alt=""/>
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                            <a href="../../demo1/dist/pages/user-profile/overview.html"
                               className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">Jane Cooper</a>
                            <span className="text-gray-400 fw-semibold d-block fs-7">Floor Tech</span>
                        </div>
                    </div>
                </td>


                <td className="text-center pe-15">
                    {/*<!--begin::Statistics-->*/}
                    <div className="d-flex align-items-center flex-column w-100">
                        <div
                          className="d-flex justify-content-between fw-bold fs-7 opacity-50 w-100 mb-2">
                            <span>10 of 20 courses complete</span>
                            <span></span>
                        </div>
                        <div className="h-8px mx-3 w-100 bg-light-danger rounded">
                            <div className="bg-primary rounded h-8px" role="progressbar" style={{width: '72%'}}></div>
                        </div>
                    </div>
                    {/*<!--end::Statistics-->*/}
                </td>


                <td className="text-start pe-0">
                    {/*<!--begin::Statistics-->*/}
                    <span className="badge badge-light-success fs-base">
                        <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                        </i>
                        9.2%
                    </span>
                    {/*<!--end::Statistics-->*/}
                </td>


                <td className="text-center">
                    <a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-60px h-30px me-1">
                        Send
                    </a>
                </td>
                <td>
                    <div className="d-flex justify-content-center flex-shrink-0">
                        <ActionButton iconType="pencil" onClick={(e) => {
                            e.preventDefault();
                            onEditItem();
                        }} />
                        <ActionButton iconType="trash" onClick={(e) => {
                            e.preventDefault();
                        }} />
                    </div>
                </td>
            </tr>
        </>
    )
}

export default TraineeRow
import { Organization } from "./organization.model";

export class EquipmentModel {

    id: number;
    name: string;
    description: string;
    organization: Organization;

    constructor() {
        this.id = 0;
        this.name = '';
        this.description = '';
        this.organization = new Organization();
    }

}


import React, {useEffect, useState,} from "react";
import CustomLoadingDots from "../../page/popup-dialog/CustomLoadingDots";
import CustomError from "../../page/popup-dialog/CustomError";


const EmptyList = (props) => {

    const {
        listItems,
        setListItems,
        onEditItemClicked,
        onDeleteItemClicked
    } = props

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');

    useEffect(() => {

        const fetchItems = async () => {

            setIsLoading(true);

            try {

                //const service = new PostsService();
                //const listItems = await service.getPosts(8227, 1, 50);

                //setListItems(listItems);
                // resetScrollPosition();

            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                }
            }finally {
                setIsLoading(false);
            }
        };

        fetchItems().then();

    }, []);
    if (isLoading) {
        return <CustomLoadingDots />;
    }
    if (error) {
        return <CustomError error={error} />;
    }

    return (
        <>
            <div className='row'>
                <div className='col-2'/>
                <div className='col-8'>
                    {listItems.map((post) => (
                        <label>List item</label>
                    ))}
                </div>
                <div className='col-2'/>
            </div>
        </>
    )

}

export default EmptyList
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { EntityTypeSerializer } from "../../../models/serializers/entityTypeSerializer";


export class CommandGetEntityType {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(id, location_id) {

    if(location_id===0)
      return  `${this.SERVICE}/entity_types/${id}/`;
    else
      return  `${this.SERVICE}/entity_types/${id}/?location=${location_id}`;
  }

  async run(id, location_id=0) {

    const url = this.getUrl(id, location_id)
    const tracer = useTracer.getState()
    tracer.trace('EntityTypes', 'Api', `GET ${url}`)

    try {
      const response = await httpClient.get(url);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('EntityTypes', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    }finally {
      tracer.trace('EntityTypes', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new EntityTypeSerializer()
    const entityType = serializer.deserialize(data)
    return entityType
  }

}
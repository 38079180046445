import { CommandGetSupplies } from "./commands/cmdGetSupplies";
import { CommandCreateSupply } from "./commands/cmdCreateSupply";
import { CommandUpdateSupply } from "./commands/cmdUpdateSupply";


export class SupplyService {

  async getSupplies(provider_id) {
    const cmd = new CommandGetSupplies();
    const results = await cmd.run(provider_id);
    return results;
  }

  async createSupply(supply) {
    const cmd = new CommandCreateSupply();
    const response = await cmd.run(supply);
    return response;
  }

  async updateSupply(supply) {
    const cmd = new CommandUpdateSupply();
    const response = await cmd.run(supply);
    return response;
  }


}

import { CommandGetLocationHealthData } from "./commands/cmdGetLocationHealthData";
import { CommandGetLocationHealthDetails } from "./commands/cmdGetLocationHealthDetails";
import { CommandGetWorkItemAnalytics } from "./commands/cmdGetWorkItemAnalytics";
import { CommandGetLocationInspectionAnalytics } from "./commands/cmdGetInspectionAnalytics";

export class SiteHealthService {

  async getHealthData(provider_id: number | undefined, location_id: number | null) {
    const cmd = new CommandGetLocationHealthData();
    let results = await cmd.run(provider_id, location_id);
    return results;
  }

  async getHealthDetails(location_id) {
    const cmd = new CommandGetLocationHealthDetails();
    const results = await cmd.run(location_id);
    return results;
  }

  async getWorkItemAnalytics(provider_id: number | undefined) {
    const cmd = new CommandGetWorkItemAnalytics();
    const results = await cmd.run(provider_id);
    return results;
  }

  async getLocationInspectionAnalytics(provider_id: number | undefined) {
    const cmd = new CommandGetLocationInspectionAnalytics();
    const results = await cmd.run(provider_id);
    return results;
  }

}

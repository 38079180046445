const countryCodes = [
  { code: '1', flag: '🇺🇸', name: 'United States' },
  { code: '7', flag: '🇷🇺', name: 'Russia' },
  { code: '27', flag: '🇿🇦', name: 'South Africa' },
  { code: '30', flag: '🇬🇷', name: 'Greece' },
  { code: '31', flag: '🇳🇱', name: 'Netherlands' },
  { code: '32', flag: '🇧🇪', name: 'Belgium' },
  { code: '33', flag: '🇫🇷', name: 'France' },
  { code: '34', flag: '🇪🇸', name: 'Spain' },
  { code: '36', flag: '🇭🇺', name: 'Hungary' },
  { code: '39', flag: '🇮🇹', name: 'Italy' },
  { code: '40', flag: '🇷🇴', name: 'Romania' },
  { code: '41', flag: '🇨🇭', name: 'Switzerland' },
  { code: '43', flag: '🇦🇹', name: 'Austria' },
  { code: '44', flag: '🇬🇧', name: 'United Kingdom' },
  { code: '45', flag: '🇩🇰', name: 'Denmark' },
  { code: '46', flag: '🇸🇪', name: 'Sweden' },
  { code: '47', flag: '🇳🇴', name: 'Norway' },
  { code: '48', flag: '🇵🇱', name: 'Poland' },
  { code: '49', flag: '🇩🇪', name: 'Germany' },
  // Need to add more or handle different way
];

export default countryCodes;

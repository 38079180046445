/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import useAppStore from '../../../../../app/appStore'
import {SidebarMenuItem} from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {whiteLabelProps, loggedInUser} = useAppStore()


    /* ICONS
      Go here to see icons that can be used
      https://preview.keenthemes.com/html/metronic/docs/icons/keenicons
     */
  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={true}
      />
      <SidebarMenuItem
        to="/location-summary"
        icon="tablet-text-up"
        title="Location Summary"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Location Summary")}
      />

      <SidebarMenuItemWithSub
        to="/analytics"
        icon="chart-pie-3"
        title="Analytics"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Analytics")}
      >
        <SidebarMenuItem
          to="/site-health"
          icon="chart-line-up"
          title="Overview"
          fontIcon="bi-app-indicator"
          hasBullet={false}
          isVisible={loggedInUser.permissions.hasAccess("Analytics")}
        />
        <SidebarMenuItem
          to="/inspection-analytics"
          icon="chart-simple"
          title="Inspection Charts"
          fontIcon="bi-app-indicator"
          hasBullet={false}
          isVisible={loggedInUser.permissions.hasAccess("Analytics")}
        />
        <SidebarMenuItem
          to="/survey-analytics"
          icon="notepad"
          title="Survey Results"
          fontIcon="bi-app-indicator"
          hasBullet={false}
          isVisible={loggedInUser.permissions.hasAccess("Analytics")}
        />

      </SidebarMenuItemWithSub>

      {/* SOCIAL */}
      <div className="menu-item visually-hidden">
        <div className="menu-content pt-8 pb-2">
                <span className="menu-section text-uppercase fs-8 ls-1"
                      style={{ color: whiteLabelProps.contrast_color }}>Social</span>
        </div>
      </div>
      <SidebarMenuItem
        to="/social/feed"
        icon="abstract-21"
        title="Feed"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={false}
      />
      <SidebarMenuItem
        to="/crafted/pages/profile/connections"
        icon="people"
        title="Contributors"
        hasBullet={false}
        isVisible={false}
      />

      {/* FUND RAISING */}
      <div className="menu-item visually-hidden">
        <div className="menu-content pt-8 pb-2">
                <span className="menu-section text-uppercase fs-8 ls-1"
                      style={{ color: whiteLabelProps.contrast_color }}>Fundraising</span>
        </div>
      </div>
      <SidebarMenuItem
        to="/fund-raising/fundraisers"
        icon="award"
        title="Fundraisers"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={false}
      />
      <SidebarMenuItem
        to="/crafted/pages/profile/connections"
        icon="tablet-text-down"
        title="Planning"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={false}
      />

      {/* FACILITIES */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
                <span className="menu-section text-uppercase fs-8 ls-1"
                      style={{ color: whiteLabelProps.contrast_color }}>Facilities</span>
        </div>
      </div>
      <SidebarMenuItem
        to="/facilities/inspections"
        icon="note-2"
        title="Inspections"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Inspections")}
      />
      <SidebarMenuItem
        to="/facilities/work-items"
        icon="check-square"
        title="Work Items"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Work Items")}
      />
      <SidebarMenuItem
        to="/facilities/cases"
        icon="clipboard"
        title="Cases"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Cases")}
      />
      <SidebarMenuItem
        to="/facilities/projects"
        icon="tablet-text-down"
        title="Projects"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Projects")}
      />
      <SidebarMenuItem
        to="/facilities/surveys"
        icon="like-2"
        title="Surveys"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Surveys")}
      />

      {/* TEAM */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
                <span className="menu-section text-uppercase fs-8 ls-1"
                      style={{ color: whiteLabelProps.contrast_color }}>Team</span>
        </div>
      </div>
      <SidebarMenuItem
        to="/social/feed"
        icon="messages"
        title="Feed"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Feed")}
      />
      <SidebarMenuItem
        to="/team/employees"
        icon="people"
        title="Employees"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Employees")}
      />
      <SidebarMenuItem
        to="/team/org-chart"
        icon="ranking"
        title="Org Chart"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Org Chart")}
      />
      <SidebarMenuItem
        to="/team/training"
        icon="teacher"
        title="Training"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Training")}
      />
      <SidebarMenuItem
        to="/ehs/home"
        icon="shield-tick"
        title="Safety"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={true}
      />

      {/* SETTINGS */}
      <div className="menu-item visually-hidden">
        <div className="menu-content pt-8 pb-2">
                <span className="menu-section text-uppercase fs-8 ls-1"
                      style={{ color: whiteLabelProps.contrast_color }}>Settings</span>
        </div>
      </div>
      <SidebarMenuItem
        to="/apps/user-management/users"
        icon="clipboard"
        title="Users"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={false}
      />
      <SidebarMenuItem
        to="/apps/user-management/users"
        icon="element-7"
        title="Web Page"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={false}
      />

      {loggedInUser.permissions.hasAccess("Relationship Management") && (
        <div className="menu-item">
          <div className="menu-content pt-8 pb-2">
                  <span className="menu-section text-uppercase fs-8 ls-1"
                        style={{ color: whiteLabelProps.contrast_color }}>CRM</span>
          </div>
        </div>
      )}
      <SidebarMenuItem
        to="/crm/relationships"
        icon="diamonds"
        title="Relationships"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Relationship Management")}
      />


      {/* ADMIN */}
      {loggedInUser.permissions.hasAccess("Admin") && (
        <div className="menu-item">
          <div className="menu-content pt-8 pb-2">
                  <span className="menu-section text-uppercase fs-8 ls-1"
                        style={{ color: whiteLabelProps.contrast_color }}>Admin</span>
          </div>
        </div>
      )}
      <SidebarMenuItem
        to="/admin/location-profile"
        icon="menu"
        title="Location Profile"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Admin")}
      />
      <SidebarMenuItem
        to="/admin/space-types"
        icon="abstract-26"
        title="Global Space Types"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Admin")}
      />
      <SidebarMenuItem
        to="/admin/service-catalog"
        icon="book-open"
        title="Service Catalog"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Admin")}
      />
      <SidebarMenuItem
        to="/admin/supplies"
        icon="paintbucket"
        title="Supplies"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Admin")}
      />
      {/*<SidebarMenuItem*/}
      {/*    to='/admin/training-catalog'*/}
      {/*    icon='book-open'*/}
      {/*    title='Training Catalog'*/}
      {/*    fontIcon='bi-app-indicator'*/}
      {/*    hasBullet={false}*/}
      {/*    isVisible={loggedInUser.permissions.hasAccess('Training Catalog')}*/}
      {/*/>*/}
      <SidebarMenuItem
        to="/admin/positions"
        icon="clipboard"
        title="Positions"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Positions")}
      />
      <SidebarMenuItem
        to="/crafted/pages/profile/connections"
        icon="lock-2"
        title="Permissions"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Permissions")}
      />
      {/*<SidebarMenuItem*/}
      {/*  to='/facilities/floor-plans'*/}
      {/*  icon='user-tick'*/}
      {/*  title='Shifts'*/}
      {/*  fontIcon='bi-app-indicator'*/}
      {/*  hasBullet={false}*/}
      {/*  isVisible={loggedInUser.permissions.hasAccess('Shifts')}*/}
      {/*/>*/}
      {/*<SidebarMenuItem*/}
      {/*  to='/facilities/floor-plans'*/}
      {/*  icon='people'*/}
      {/*  title='Teams'*/}
      {/*  fontIcon='bi-app-indicator'*/}
      {/*  hasBullet={false}*/}
      {/*  isVisible={loggedInUser.permissions.hasAccess('Teams')}*/}
      {/*/>*/}
      <SidebarMenuItem
        to="/facilities/floor-plans"
        icon="element-10"
        title="Floor Plans"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={loggedInUser.permissions.hasAccess("Floor Plans")}
      />
      {/*<SidebarMenuItem*/}
      {/*    to='/facilities/floor-plans'*/}
      {/*    icon='element-10'*/}
      {/*    title='Service Plans'*/}
      {/*    fontIcon='bi-app-indicator'*/}
      {/*    hasBullet={false}*/}
      {/*    isVisible={loggedInUser.permissions.hasAccess('Service Plans')}*/}
      {/*/>*/}
    </>
  )
}

export { SidebarMenuMain };


/*
SUB MENU EXAMPLE

<SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>




 */
import { BaseSerializer } from "./baseSerializer";
import { EntityType } from "../entityType.model";
import { ServicePlanSerializer } from "./servicePlanSerializer";

export class EntityTypeSerializer extends BaseSerializer {


    public deserialize( data ): any {

        if (data === undefined) return;

        let entityType = new EntityType();

        this._copyAttributes(entityType, data);

        let serializer = new ServicePlanSerializer()
        const plan = serializer.deserialize(data.service_plan)

        entityType.service_plan = plan;

        return entityType;

    }

}
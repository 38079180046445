import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography
} from "@mui/material";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";
import useAppStore from "../../../../../../appStore";
import { DateTime } from 'luxon';
import { format } from "date-fns";


const RelationshipTimelineEntry = (props) => {
  const {
    actionItem,
    onEdit,
    isEditing
  } = props;

  const {whiteLabelProps} = useAppStore();
  const formattedDate = actionItem.due_date
    ? format(new Date(actionItem.due_date), 'MMM d, yyyy')
    : "--";

  function isPast(isoString: string): boolean {
    const dateTime = DateTime.fromISO(isoString).setZone('America/Los_Angeles'); // Set to Pacific Time
    const now = DateTime.now().setZone('America/Los_Angeles');
    return dateTime < now;
  }

  const dateIsPast = isPast(actionItem.due_date || '');

  return (
    <>
      <Box sx={{position: 'relative', paddingBottom: 0 }}>

        <CardContent sx={{ padding: 0, '&:last-child': { paddingBottom: 0 } }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            {/* Left Column */}
            <Box sx={{ width: "50%", paddingLeft: '20px'}}>
              <div className="d-flex flex-column" style={{ width: "350px" }}>
                <Box sx={{ display: "flex", alignItems: "center", position: "relative" }}>
                  <Box
                    sx={{
                      position: "absolute",
                      left: "7px",
                      top: 0,
                      bottom: 0,
                      width: "1.5px",
                      backgroundColor: "transparent",
                      borderLeft: "1.5px dashed #c9c9c9",
                    }}
                  />

                  <Box
                    sx={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      backgroundColor: actionItem.date_resolved ? whiteLabelProps.primary_color : 'white',
                      border: dateIsPast && !actionItem.date_resolved ? "2px solid red" : "2px solid #c3c3c3",
                      position: "absolute",
                      left: "0px",
                      top: "50%",
                      transform: "translateY(-50%)"
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (isEditing) {
                        onEdit(actionItem);
                      }
                    }}

                  />

                  <div className="d-flex flex-column ps-10 pb-10 pt-10 pe-10 ">
                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: "#555", maxWidth: '235px' }}>{actionItem.action_to_take}</Typography>
                    {actionItem.date_resolved ? (
                      <Typography variant="body2" sx={{color: whiteLabelProps.primary_color}}>Complete</Typography>
                    ) : (
                      <Typography variant="body2" color={dateIsPast ? "error" : "textSecondary"}>Due: {formattedDate}</Typography>
                    )}
                  </div>

                </Box>
              </div>
            </Box>

            {/* Right Column */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', textAlign: 'right', padding: '16px' }}>
              <Box sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
              }}>
                {isEditing &&
                  <div className="d-flex flex-row align-items-center">
                    <ActionButton
                      iconType={"pencil"}
                      onClick={(e) => {
                        e.preventDefault();
                        onEdit(actionItem);
                      }}
                    />
                  </div>
                }
              </Box>
            </Box>


          </Box>
        </CardContent>
      </Box>
    </>
  );
};

export default RelationshipTimelineEntry;


import { Course } from "../course.model";
import { BaseSerializer } from "./baseSerializer";

export class CourseSerializer extends BaseSerializer {

    public deserialize( data ): any {

        let course = new Course();

        this._copyAttributes(course, data);

        return course;

    }

}
import { Service } from "../service.model";
import { TrainingCategory } from "../training-category.model";
import { BaseSerializer } from "./baseSerializer";

export class TrainingCategorySerializer extends BaseSerializer {

    public deserialize( data ): any {

        let tc = new TrainingCategory();

        this._copyAttributes(tc, data);

        let childCats = this.deserializeList(data.children)

        tc.children = childCats

        return tc;

    }

}
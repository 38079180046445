// EntitySelector can be a Person, Place or Thing.
import { ServicePlan } from "./servicePlan.model";
import { Organization } from "./organization.model";
import { LocationModel } from "./location.model";

export class EntityType {

    id: number;
    name: string;
    organization: Organization;
    location: LocationModel;
    kind_of: string;
    service_plan: ServicePlan;

    constructor() {
        this.id = 0;
        this.name = '';
        this.organization = new Organization();
        this.location = new LocationModel();
        this.kind_of = '';
        this.service_plan = new ServicePlan();
    }

}


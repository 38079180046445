import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { ServicePlanItemSerializer } from "../../../models/serializers/servicePlanItemSerializer";
import { FrequencySerializer } from "../../../models/serializers/frequencySerializer";


export class CommandUpdateItemFrequency {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(planId, itemId, frequencyId) {

    return `${this.SERVICE}/service_plans/${planId}/items/${itemId}/frequencies/${frequencyId}/`;
  }

  async run(servicePlan, servicePlanItem, frequency) {

    const url = this.getUrl(servicePlan.id, servicePlanItem.id, frequency.id)

    const tracer = useTracer.getState()
    tracer.trace('ServicePlan', 'Api', `DELETE ${url}`)

    try {
      let data = FrequencySerializer.serialize(frequency)
      const response = await httpClient.put(url, data);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('ServicePlan', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    }finally {
      tracer.trace('ServicePlan', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new ServicePlanItemSerializer()
    const item = serializer.deserialize(data)

    return item
  }

}
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { EquipmentSerializer } from "../../../models/serializers/equipmentSerializer";
import { RelationshipSerializer } from "../../../models/serializers/relationshipSerializer";
import { EmployeeSerializer } from "../../../models/serializers/employeeSerializer";
import { ServiceLogAreaSerializer } from "../../../models/serializers/serviceLogAreaSerializer";


export class CommandCreateManualLogEntry {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(log_id) {

    return `${this.SERVICE}/service_logs/${log_id}/entries/`;
  }

  async run(log, employee) {

    const url = this.getUrl(log.id)
    const tracer = useTracer.getState()
    tracer.trace('Logs', 'Api', `POST ${url}`)

    try {
      let data = EmployeeSerializer.serialize(employee)
      const response = await httpClient.post(url, data);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Logs', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    }finally {
      tracer.trace('Logs', 'Api', 'Success')
    }

  }

  deserialize(data) {
    const serializer = new ServiceLogAreaSerializer()
    const item = serializer.deserialize(data)
    return item
  }

}
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { ServiceItemSerializer } from "../../../models/serializers/serviceItemSerializer";


export class CommandSearchServiceItems {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/services/service_items/`;
  }

  async run(search) {

    const url = this.getUrl()

    const params = {
      params: {
        entity_search: search,
      }
    }

    const tracer = useTracer.getState()
    tracer.trace('Service', 'Api', `GET ${url}`)

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Service', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      tracer.trace('Service', 'Api', 'Success')
    }

  }

  deserialize(data) {
    const serializer = new ServiceItemSerializer();
    const items = serializer.deserializeList(data)
    return items
  }

}


import React, { useEffect } from "react";
import useSpaceStore from "../../../spaceStore";
import useAppStore from "../../../../../../../appStore";
import SearchableTreeView from "../../../../../../../shared/components/forms/widgets/searchable-tree-view/SearchableTreeView";


const ParentSelect = ({ parent, setParent }) => {
  const { selectedLocation } = useAppStore();
  const {spaces, fetchSpaces} = useSpaceStore();

  useEffect(() => {
    fetchSpaces(selectedLocation.id).then();
  }, [selectedLocation]);


  const onSpaceSelected = (space) => {
    setParent(space);
  };

  return (
    <>
      <SearchableTreeView
        data={spaces}
        onItemSelected={onSpaceSelected}
        itemToEdit={parent}
        text={''}
      />
    </>

  );
};

export default ParentSelect;

import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { CaseSerializer } from "../../../models/serializers/caseSerializer";


export class CommandGetDetailCase {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(id) {
    return `${this.SERVICE}/cases/${id}/`;
  }

  async run(id) {
    const url = this.getUrl(id)
    const tracer = useTracer.getState()
    tracer.trace('Cases', 'Api', `GET ${url}`)

    try {
      const response = await httpClient.get(url);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Cases', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      tracer.trace('Cases', 'Api', 'Success')
    }

  }

  deserialize(data) {
    const serializer = new CaseSerializer()
    const workItem = serializer.deserialize(data)
    return workItem
  }

}


import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { WorkItemSerializer } from "../../../models/serializers/workItemSerializer";


export class CommandGetWorkItems {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return  `${this.SERVICE}/workitems/`;
  }

  async run(provider_id, location_id, activeStep, limit, offset) {
    const url = this.getUrl();

    let status;
    switch (activeStep) {
      case 0:
        status = "Pending";
        break;
      case 1:
        status = "Assigned";
        break;
      case 2:
        status = "Closed";
        break;
      default:
        status = null;
    }

    const params = {
      params: {
        provider: provider_id,
        location: location_id,
        limit: limit,
        offset: offset,
        ...(status ? { status: status } : {}),
      },
    };

    const tracer = useTracer.getState();
    tracer.trace('WorkItems', 'Api', `GET ${url}`);

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('WorkItems', 'Error', `${error}`);
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      tracer.trace('WorkItems', 'Api', 'Success');
    }
  }

  deserialize(data) {

    const serializer = new WorkItemSerializer()
    const workItems = serializer.deserializeList(data)

    return workItems
  }

}
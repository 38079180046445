import { ServiceSerializer } from "../../../models/serializers/serviceSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { OrgChartNodeSerializer } from "../../../models/serializers/orgChartNodeSerializer";


export class CommandGetOrgChart {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {

    return  `${this.SERVICE}/reporting_groups/org_chart/`;
  }

  async run(org_id) {

    const url = this.getUrl()

    const params = {
      params: {
        provider: org_id,
      }
    }

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }

  }

  deserialize(data) {

    const serializer = new OrgChartNodeSerializer()
    const nodes = serializer.deserializeList(data)

    return nodes
  }

}
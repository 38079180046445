import React from "react";
import {  Card, CardContent, Typography } from "@mui/material";
import ActionButton from "../../../../../../../shared/components/buttons/ActionButton";
import MediaDisplay from "../../../../../../../shared/components/media/media-viewers/photos/MediaDisplay";

const InspectedEntityCard = (props) => {
  const { inspected_entity, onEdit, isEditing } = props;

  const commentText = inspected_entity.comments && inspected_entity.comments.trim() !== ""
    ? inspected_entity.comments
    : "No comments";

  const handleEditClicked = () => {
    onEdit(inspected_entity)
  }

  return (
    <Card variant="outlined" sx={{marginTop: '15px', boxShadow: '0 1px 2px rgba(0,0,0,0.1)'}}>
      <CardContent>
        <Typography variant="h6" className="text-gray-700 fst-italic fw-bold" gutterBottom>
          {inspected_entity.getRootSpace().name + " / " + inspected_entity.getChildSpacePath()}
        </Typography>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
            <Typography variant="body1" className="fst-italic">
              {inspected_entity.entity_type.name}
            </Typography>
            <div className="badge py-3 fs-7 badge-light-success" style={{ width: "75px" }}>
              Score: {inspected_entity.score}
            </div>
          </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body2" className="fst-italic" sx={{maxWidth: '200px'}}>
            {commentText}
          </Typography>
          {isEditing &&
            <div>
              <ActionButton iconType="pencil" onClick={handleEditClicked} />
              <ActionButton iconType="trash" onClick={(e) => { e.preventDefault(); }} />
            </div>
          }
        </div>
        {inspected_entity.media && inspected_entity.media.length > 0 &&
          <MediaDisplay itemMedia={inspected_entity.media} captions={false} module={'inspections'} />
        }
      </CardContent>
    </Card>
  );
}

export default InspectedEntityCard;

import React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from "@mui/material";
import useAppStore from "../../../../appStore";
import { capitalizeFirst } from "../../../utils/table-modifier-util/tableDataUtil";

// Add a new prop for the tooltip message
const NoDataCard = ({ title, tooltipMessage }) => {
  const theme = useTheme();
  const { whiteLabelProps } = useAppStore(); // No need for selectedLocation here anymore

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: theme.palette.background.paper,
        position: 'relative', // For positioning the icon
        boxShadow: theme.shadows[1], // Adjust the shadow as desired
        height: '250px', // Adjust the height as needed
        display: 'flex', // Added for centering content
        justifyContent: 'center', // Added for centering content
        alignItems: 'center', // Added for centering content
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column', // Stack the text and icon button vertically
          alignItems: 'center', // Center-align the items horizontally
          padding: theme.spacing(3),
        }}
      >
        <Typography
          variant="h6"
          component="div"
          color="#263238"
          sx={{
            fontSize: '16px',
            textAlign: 'center', // Ensure text is centered
          }}
        >
          {capitalizeFirst(title)} has no data
        </Typography>
        <Tooltip title={tooltipMessage}>
          <IconButton
            sx={{
              position: 'absolute',
              top: theme.spacing(2),
              right: theme.spacing(2),
              color: whiteLabelProps.primary_color,
            }}
          >
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </CardContent>
    </Card>
  );
};

export default NoDataCard;

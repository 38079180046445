import { BaseSerializer } from "./baseSerializer";
import { OrganizationSerializer } from "./organizationSerializer";
import { UserPermissions } from "../userPermissions.model";
import { LocationSerializer } from "./locationSerializer";
import { AccessLevelSerializer } from "./accessLevelSerializer";

export class PermissionsSerializer extends BaseSerializer {


    public deserialize( data ): any {

        let permissions = new UserPermissions()

        // Get Ogs the user has access to
        let serializer = new OrganizationSerializer();

        const orgs = serializer.deserializeList(data.customer_orgs);

        permissions.customer_orgs = orgs

        // Get Locations the user has access to
        serializer = new LocationSerializer();

        const locations = serializer.deserializeList(data.locations);

        permissions.locations = locations


        // Get Access Levels the user has access to
        serializer = new AccessLevelSerializer();

        const access_levels = serializer.deserializeList(data.access_levels);

        permissions.access_levels = access_levels


        return permissions;

    }

}
import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useAppStore from "../../../appStore"; // Ensure this is correctly imported

const LoadMoreButton = ({ onLoadMore }) => {

  const { whiteLabelProps } = useAppStore();

  const goToBottom = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    window.scrollTo(0, scrollHeight);
  };

  const handleLoadMore = async (event) => {
    event.preventDefault();
    onLoadMore();
    goToBottom();
  };

  return (
    <div className="text-center mt-4">
      <button
        className="btn"
        onClick={handleLoadMore}
        style={{
          color: 'black',
          // borderColor: themeColor,
          // borderWidth: '1px',
          // borderStyle: 'solid',
          padding: '0.5rem 1rem',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '0.5rem'
        }}
      >
        Load More <ArrowDropDownIcon style={{ color: whiteLabelProps.primary_color }} />
      </button>
    </div>
  );

}

export default LoadMoreButton;

import { Employee } from "./employee.model";
import { AppDate } from "../utils/date-time/AppDate";

export class ActionPlanItem {
  id: number;
  date_created: string;
  due_date: string | null;
  created_by: Employee;
  action_to_take: string;
  resolution: string | null;
  resolved_by: Employee;
  date_resolved: string | null;

  constructor() {
    this.id = 0;
    this.date_created = new AppDate().dateTime;
    this.due_date = null;
    this.created_by = new Employee();
    this.action_to_take = '';
    this.resolution = '';
    this.resolved_by = new Employee();
    this.date_resolved = null;
  }

}
import React, { useEffect, useRef, useState } from "react";
import {
  AppBar, Button, Dialog, DialogActions, IconButton, Typography, Toolbar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Entity } from "../../../../../shared/models/entity.model";
import { EntityType } from "../../../../../shared/models/entityType.model";
import ModalTransition from "../../../../../shared/components/page/transitions/ModalTransition";
import CustomError from "../../../../../shared/components/page/popup-dialog/CustomError";
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import BuildingSelect from "./BuildingSelect";
import LogSelect from "./LogSelect";
import { ServiceLogArea } from "../../../../../shared/models/serviceLogArea";
import { ServiceLogService } from "../../../../../shared/services/service-logs/serviceLogService";
import useAppStore from "../../../../../appStore";

const ManualLogEntry = ({ open, onClose }) => {
  const {loggedInUser} = useAppStore();
  const [selectedLog, setSelectedLog] = React.useState<ServiceLogArea | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<any>({
    space: new Entity(),
    floor: new Entity(),
    spaceType: new EntityType(),
    servicesToPerform: [],
  });

  const [error, setError] = useState('');

  useEffect(() => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop = 0;
    }
  }, [activeStep, error]);

  const handleSubmit = async () => {
    try {
      await new ServiceLogService().createManualEntry(selectedLog, loggedInUser);
    } catch (e) {
      console.error('Error in handleSubmit:', e);
    } finally {
      resetStates();
      handleClose();
    }
  };


  const handleClose = (): void => {
    onClose();
    resetStates();
  };

  const resetStates = (): void => {
    setActiveStep(0);
    setState(prev => ({ ...prev, space: new Entity() }));
    setState(prev => ({ ...prev, floor: new Entity() }));
    setState(prev => ({ ...prev, spaceType: new Entity() }));
    setSelectedLog(null);
  };

  const handleNextClicked = (): void => {
    const conditions = [
      { check: state.space.id === 0, message: 'Building is required' },
      { check: state.space.children.length !== 0, message: 'Building set up incorrectly choose a new building' },
      { check: state.floor.id === 0, message: 'Floor is required' },
      { check: state.spaceType.id === 0, message: 'Space Type is required' }
    ];

    for (const condition of conditions) {
      if (condition.check) {
        setError(condition.message);
        return;
      }
    }

    setError('');
    setActiveStep(1);
  };


  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={ModalTransition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ flex: 1 }} variant="h5" component="div">
            {activeStep === 0 ? 'Where' : 'What'}
          </Typography>
          <Button color="inherit" variant="contained" onClick={onClose} sx={{ color: '#444444', display: 'none' }}>
            Exit
          </Button>
        </Toolbar>
      </AppBar>
      <div className='card mt-5' style={{ height: 'calc(100vh - 250px)' }}>
        <div ref={scrollableContainerRef} className="card-body" style={{ overflowY: 'auto' }}>
          {error &&
            <CustomError error={error} />
          }
          <SwipeableViews disabled={true} index={activeStep}>
            <BuildingSelect {...{ state, setState}} />
            <LogSelect {...{ state, setState, selectedLog, setSelectedLog }} />
          </SwipeableViews>
        </div>
      </div>
      <DialogActions sx={{ position: 'sticky', bottom: 0, backgroundColor: '#fafafa', zIndex: 2 }}>
        <Button disabled={activeStep === 0} onClick={() => setActiveStep(0)}>Prev</Button>
        <Button disabled={activeStep === 1} onClick={handleNextClicked}>Next</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={activeStep === 0 || selectedLog === null}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManualLogEntry;
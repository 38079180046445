import { CommandGetSurveyCampaigns } from "./commands/cmdGetSurveyCampaigns";
import { CommandSendSurveys } from "./commands/cmdSendSurveys";


export class SurveyService {

    async getSurveyCampaigns(provider_id, location_id, offset=0, limit=10, search=null) {

        const cmd = new CommandGetSurveyCampaigns()
        const results = cmd.run(provider_id, location_id, offset, limit, search)

        return results
    }

    async sendSurveys(location_id) {

        const cmd = new CommandSendSurveys()
        const results = cmd.run(location_id)

        return results
    }

   }
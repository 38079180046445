

export function findTreeNode(node, idToFind) {

    if (!node)
        return null;

    if (node.id == idToFind)
        return node;

    // If node has children, search through them.
    if (!node.children || node.children.length == 0)
        return null

    let foundNode = null;

    for (let childNode of node.children) {
        foundNode = findTreeNode(childNode, idToFind);

        if (foundNode !== null)
            return foundNode
    }

    return null
}

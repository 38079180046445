import { CommandGetCharts } from "./commands/cmdGetCharts";
import { CommandGetSurveySummary } from "./commands/cmdGetSurveySummary";
import { CommandGetSurveyQuestionAverages } from "./commands/cmdGetSurveyQuestionAverages";
import { CommandGetRespondents } from "./commands/cmdGetSurveyRespondants";
import { CommandGetReportingGroupScores } from "./commands/cmdGetReportingGroupScores";


export class AnalyticsService {

    async getCharts(provider_id, location_id, module_name, charts, startDate, endDate) {
        const cmd = new CommandGetCharts()
        const results = cmd.run(provider_id, location_id, module_name, charts, startDate, endDate)
        return results
    }

    async getSurveySummary(organization, location, group, interval) {
        const cmd = new CommandGetSurveySummary()
        const summary = cmd.run(organization, location, undefined, interval)
        return summary
    }

    async getRespondents(organization, location, interval, reportingGroupEntryId) {
        const cmd = new CommandGetRespondents()
        const respondents = cmd.run(organization, location, interval, reportingGroupEntryId)
        return respondents
    }

    async getQuestions(organization, location, interval, reportingGroupEntryId) {
        const cmd = new CommandGetSurveyQuestionAverages()
        const questions = cmd.run(organization, location, interval, reportingGroupEntryId)
        return questions
    }

    async getReportingGroupScore(organization, location, interval) {
        const cmd = new CommandGetReportingGroupScores()
        const scores = cmd.run(organization, location, interval)
        return scores
    }








}
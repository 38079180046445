import React, { useState } from "react";
import LocationNotifications from "./components/notifications/LocationNotifications";
import LocationDetails from "./components/details/LocationDetails";
import LocationPeriodicals from "./components/periodicals/LocationPeriodicals";
import { Button, Divider, Grid, TextField } from "@mui/material";

const LocationSettings = () => {
  const [tab, setTab] = useState(1);

  return (
    <>
      <div className="card">
        <div className="card-body d-flex flex-row" style={{ alignItems: 'flex-start' }}>
          {/* Left Panel */}
          <div className="d-flex flex-column flex-row-auto w-200px" style={{ overflow: "auto" }}>
            <div className="d-flex justify-content-between align-items-center mb-4 mt-2">
              <h3>Settings</h3>
            </div>
            <div className="table-responsive">
            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
              <tbody>
              <tr
                onClick={(e) => {
                  e.preventDefault();
                  setTab(1);
                }}
                className={tab === 1 ? "bg-light-success" : ""}
                style={{ cursor: "pointer" }}
              >
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <a className="text-gray-800 mb-1 ms-2 fs-6">
                        Details
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr
                onClick={(e) => {
                  e.preventDefault();
                  setTab(2);
                }}
                className={tab === 2 ? "bg-light-success" : ""}
                style={{ cursor: "pointer" }}
              >
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <a className="text-gray-800 mb-1 ms-2 fs-6">
                        Periodicals
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr
                onClick={(e) => {
                  e.preventDefault();
                  setTab(3);
                }}
                className={tab === 3 ? "bg-light-success" : ""}
                style={{ cursor: "pointer" }}
              >
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <a className="text-gray-800 mb-1 ms-2 fs-6">
                        Notifications
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            </div>
          </div>

          <Divider orientation="vertical" flexItem style={{ backgroundColor: "black", marginLeft: "15px" }} />

          {/* Right Panel */}
          <div
            className="d-flex flex-column flex-row-fluid ms-5"
          >
            {tab === 1 &&
              <LocationDetails />
            }
            {tab === 2 &&
              <LocationPeriodicals />
            }
            {tab === 3 &&
              <LocationNotifications />
            }

          </div>
        </div>
      </div>
    </>
  );

}

export default LocationSettings
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { ServicePlanItemSerializer } from "../../../models/serializers/servicePlanItemSerializer";


export class CommandDeleteServicePlanItem {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(planId, itemId) {

    return `${this.SERVICE}/service_plans/${planId}/items/${itemId}/`;
  }

  async run(servicePlan, servicePlanItem) {

    const url = this.getUrl(servicePlan.id, servicePlanItem.id)

    const tracer = useTracer.getState()
    tracer.trace('ServicePlan', 'Api', `DELETE ${url}`)

    try {
      const response = await httpClient.delete(url);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('ServicePlan', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    }finally {
      tracer.trace('ServicePlan', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new ServicePlanItemSerializer()
    const item = serializer.deserialize(data)

    return item
  }

}
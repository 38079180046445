import { Employee } from "./employee.model";
import { Media } from "./media.model";

export class Post {

    id: number;
    organization_id: number;
    title: string;
    description: string;
    visibility: string;
    about: number;
    created_by: Employee;
    post_date: string;
    media: Media[];
    comments: Comment[];

    constructor() {
        this.id = 0;
        this.organization_id = 1;
        this.title = '';
        this.description = '';
        this.visibility = 'Public';
        this.about = -1;
        this.media = [];
        this.comments = [];
        this.created_by = new Employee();
        this.post_date = '';

    }

}


import React, { useEffect, useState } from "react";
import { Divider, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import classNames from "classnames";
import { ServicesService } from "../../../../../../shared/services/services/servicesService";
import useServiceStore from "../../../../serviceStore";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";

const servicesService = new ServicesService();

const ServiceItemDropdown = () => {
  const { selectedService, selectedServiceItem, setSelectedServiceItem } = useServiceStore();

  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchServiceItems = async () => {
      try {
        setIsLoading(true);
        const fetched = await servicesService.getServiceItems(selectedService);
        setItems(fetched);
      } catch (error) {
        console.error("Failed to fetch service items:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedService && selectedService.id !== 0) {
      fetchServiceItems().then();
    }
  }, [selectedService]);

  const handleSelectionChange = (event) => {
    setSelectedServiceItem(event.target.value);
  };

  return (
    <>
      <div className={classNames("flex-column")}>
        <FormControl sx={{width: '300px'}}>
          <Select
            labelId="select-item-label"
            id="select-item"
            value={selectedServiceItem}
            onChange={handleSelectionChange}
            sx={{height: '40px'}}
          >
            {items.length === 0 ? (
              <MenuItem disabled>
                <em>This entity has no service items, add one with the plus button</em>
              </MenuItem>
            ) : (
              items.map((item) => (
                <MenuItem key={item?.id} value={item}>
                  {item?.entity_type?.name}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default ServiceItemDropdown;

import { BaseSerializer } from "./baseSerializer";
import { ServiceLogEntry } from "../serviceLogEntry.model";

export class ServiceLogEntrySerializer extends BaseSerializer {


    public deserialize( data ): any {

        const logEntry = new ServiceLogEntry();

        this._copyAttributes(logEntry, data);

        return logEntry;

    }

}
import React from "react";
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import MediaUploader from "../../media-uploader/MediaUploader";
import { Media } from "../../../../models/media.model";

const SinglePhotoUploaderViewer = ({ setMedia, currentUser, uploadUrl, setPhotoUrl }) => {

  const mediaUploadCompleteHandler = (item) => {
      let media = new Media();
      media.url = item.url;
      media.id = item.id;
      setPhotoUrl(item.url)
      setMedia(media)
  };

  return (
    <div>
      <MediaUploader
        uploadUrl={uploadUrl}
        onUploadComplete={mediaUploadCompleteHandler}
        organization_id={1}
        person_id={currentUser?.employee.person.id}
        module="employees"
        profileMode={true}
      />
    </div>
  );
};

export default SinglePhotoUploaderViewer;

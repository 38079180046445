import { Employee } from "./employee.model";
import { LocationModel } from "./location.model";
import { GroupEntry } from "./groupEntry.model";

export class Contact {
  id: number;
  location: LocationModel;
  employee: Employee;
  csat_reporting_group_entry: GroupEntry | null;


  constructor() {
    this.id = 0;
    this.location = new LocationModel();
    this.employee = new Employee();
    this.csat_reporting_group_entry = null;

  }
}
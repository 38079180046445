import { AppDate } from "../utils/date-time/AppDate";
import { LocationModel } from "./location.model";

export class SurveyCampaign {

    id: number;
    uuid: string;
    location: object;
    name: string;
    description: string;
    status: string;
    begin_date: AppDate;
    end_date: AppDate;
    sent_to_count: number;
    responded_count: number;
    last_published: AppDate;

    constructor() {
        this.id = 0;
        this.uuid = '';
        this.location = new LocationModel();
        this.name = '';
        this.description = '';
        this.status = '';
        this.begin_date = new AppDate();
        this.end_date = new AppDate();
        this.sent_to_count = 0;
        this.responded_count = 0;
        this.last_published = new AppDate();
    }

}


import React, {useState} from 'react'
import PageHeader from "../../shared/components/page/page-header/PageHeader";
import ProjectsList from "./components/project-list/ProjectsList";
import EditProject from "./components/edit-project/ProjectPlanningTool";
import { useProjectStore } from "./projectsStore";


const ProjectsPage = () => {

    const {
        projects, setProjects,
        selectedProject, setSelectedProject,
        open, setOpen,
        isEditing, setEditing
    } = useProjectStore();


    //  Called when the User clicks the New Post button.
    const handleNewProject = (e) => {
        setSelectedProject(null)
        setEditing(true)
    }

    //  Called when the user Clicks the Edit Post action on a Post in the spaces-relationship-card-list.
    const handleEditProject = (project) => {
        setSelectedProject(project);
        setEditing(true);
        setOpen(true);
    };

    // Called when the user Clicks the Delete Post action on a Post in the spaces-relationship-card-list.
    const handlerDeleteItem = async (itemToDelete) => {
        //implement this
    }

    //  Called from the Edit Post form when the user takes a final action {Create, Save as Draft, Cancel}
    const handlerItemEditorAction = (action) => {
        setEditing(false);
        setOpen(false);
    }

    return (
        <>
            {!isEditing && (
                <>
                    <PageHeader title='Projects'
                                actionButtonName='New Project'
                                showAction={false}
                                onActionClicked = {handleNewProject} />

                    <ProjectsList onEdit={handleEditProject}
                                  onDelete={handlerDeleteItem} />
                </>
            )}
            {open && (
              <EditProject
                open={open}
                onActionClicked={handlerItemEditorAction}
              />
            )}
        </>
    )

}

export default ProjectsPage

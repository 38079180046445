import React from 'react';
import useAppStore from "../../../../appStore";
import { ThreeDots } from "react-loader-spinner";

const CustomLoadingDots = ({fillScreen=true}) => {

  const { whiteLabelProps } = useAppStore();

  return (
    <div style={{minHeight: fillScreen ? '600px' : '65px', display: 'flex', justifyContent: 'center'}}>
      <ThreeDots
        visible={true}
        height="65"
        width="65"
        color={whiteLabelProps.primary_color}
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>

  );
};

export default CustomLoadingDots;



import React from "react";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";
import { getServiceName, getTitle } from "../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import DuotoneCategory from "../../../../../../shared/components/page/row-icons/DuotoneCategory";

const PrioritizeProjectsRow = (props) => {

    const {
        children,
        value,
        index,
        project,
        onEdit,
        ...other } = props;

    /*
    badge-light-warning
    badge-light-primary
    badge-light-success
     */

    return (
        <>
            <tr>
                {/* PROJECT NAME */}
                <td>
                    <div className="d-flex align-items-center"
                         onClick={() => onEdit(project)}
                    >
                       <DuotoneCategory />
                        <div className="d-flex justify-content-start flex-column">
                            <a href="#" className="text-gray-800 fw-bold mb-1 fs-6">{project.name}</a>
                        </div>
                    </div>
                </td>

                {/* DURATION */}
                <td className="text-end pe-0">
                    {project.duration}
                </td>

                {/* BUDGET */}
                <td className="text-end">
                    ${project.budget}
                </td>

                {/* PRIORITY */}
                <td className="text-end pe-0">
                    {project.priority}
                </td>

                {/* STATUS */}
                <td className="text-end">
                    <span className="badge py-3 fs-7 badge-light-primary">{project.status}</span>
                </td>

                {/* ACTIONS */}
                <td>
                    <div className="d-flex justify-content-center flex-shrink-0">
                        <ActionButton iconType="pencil" onClick={(e) => {
                            e.preventDefault();
                            onEdit(project);
                        }} />
                        <ActionButton iconType="trash" onClick={(e) => {
                            e.preventDefault();
                        }} />
                    </div>
                </td>

            </tr>
        </>
    )
}

export default PrioritizeProjectsRow
export class Role {

  id: number;
  name: string;
  description: string;
  permissions: any;


  constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.permissions = [];
  }

}
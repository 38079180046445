import React from "react";
import { Avatar, useTheme } from "@mui/material";
import useAppStore from "../../../../../appStore";
import ActionButton from "../../../../../shared/components/buttons/ActionButton";
import useMediaQuery from "@mui/material/useMediaQuery";

const SurveyCampaignRow = (props) => {

    const {
        campaign,
        onEditEmployeeClicked,
        onDeleteEmployeeClicked,
        children,
        value,
        index,
        ...other } = props;

    const {whiteLabelProps} = useAppStore();
    const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

    return (
      <>
          <tr>
              {/* EMPLOYEE */}
              <td>
                  <div className="d-flex align-items-center">
                      <div className="me-3">
                      </div>
                      <div className="d-flex justify-content-start flex-column">
                          <a href=''
                             className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{campaign.name}</a>
                          <span className="text-gray-400 fw-semibold d-block fs-7"></span>
                      </div>
                  </div>
              </td>

              {/* BEGIN DATE */}
              {!isMobile &&
                <>
                    <td>
                        <div className="d-flex align-items-center">
                            {campaign.begin_date.toString()}
                        </div>
                    </td>

                    {/* SENT TO */}
                    <td className="text-start">
                        <div className="d-flex align-items-center">
                            {campaign.sent_to_count}
                        </div>
                    </td>

                    {/* RESPONDED */}
                    <td className="text-start">
                        <div className="d-flex align-items-center">
                            {campaign.responded_count}
                        </div>
                    </td>

                    {/* LAST PUBLISHED */}
                    <td className="text-start">
                        <div className="d-flex align-items-center">
                            {campaign.last_published.toString()}
                        </div>
                    </td>
                </>
              }

              {/* ACTIONS */}
              <td>
                  <div className="d-flex justify-content-center flex-shrink-0">
                      <ActionButton iconType="pencil" onClick={(e) => {
                          e.preventDefault();
                          onEditEmployeeClicked();
                      }} />
                      <ActionButton iconType="trash" onClick={(e) => {
                          e.preventDefault();
                      }} />
                  </div>
              </td>

          </tr>
      </>
    )
}

export default SurveyCampaignRow


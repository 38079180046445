import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { SurveyCampaignSerializer } from "../../../models/serializers/surveyCampaignSerializer";


export class CommandGetSurveyCampaigns {

    SURVEY_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {

        return  `${this.SURVEY_SERVICE}/surveys/campaigns/`;
    }

    async run(provider_id, location_id, offset=0, limit=10, search=null) {

        const url = this.getUrl()

        const params = {
            params: {
                provider: provider_id,
                location: location_id,
                offset: offset,
                limit: limit
            }
        }

        const tracer = useTracer.getState()
        tracer.trace('Survey Campaigns', 'Api', `GET ${url}`)

        try {
            const response = await httpClient.get(url, params);
            return this.deserialize(response.data);
        } catch (error) {
            tracer.trace('Inspections', 'Error', `${error}`)
            console.error('Error fetching data:', error);
            throw error;
        }finally {
            tracer.trace('Inspections', 'Api', 'Success')
        }

    }

    deserialize(data) {

        const serializer = new SurveyCampaignSerializer()
        const campaigns = serializer.deserializeList(data)

        return campaigns
    }

}
import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

const ItemEditDialog = ({ isOpen, onClose, itemToEdit, setItemToEdit, onSubmit }) => {
  const [localItem, setLocalItem] = useState({ name: '', description: '', organization: itemToEdit?.organization });

  useEffect(() => {
    if (itemToEdit.id != 0) {
      setLocalItem(itemToEdit);
    } else {
      setLocalItem({ name: '', description: '', organization: itemToEdit?.organization });
    }
  }, [itemToEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalItem((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSubmit(localItem);
    handleClose();
  };


  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Name"
          type="text"
          fullWidth
          value={localItem.name}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemEditDialog;

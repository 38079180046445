import { BaseSerializer } from "./baseSerializer";
import { WorkItemProgress } from "../workItemProgress.model";

export class WorkItemProgressSerializer extends BaseSerializer {


  public deserialize( data ): any {

    const workItemProgress = new WorkItemProgress();

    this._copyAttributes(workItemProgress, data);

    return workItemProgress;

  }

}


export class SiteHealthModel {

    location_id: number;
    location_name: string;
    health_score: number;
    compliance_count: number;
    quality_count: number;

    constructor() {
        this.location_id = 0;
        this.location_name = '';
        this.health_score = 0;
        this.compliance_count = 0;
        this.quality_count = 0;

    }

}

export class SiteHealthDetailModel {
    name: string;
    message: string;

    constructor() {
        this.name = '';
        this.message = '';
    }

}


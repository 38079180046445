import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { EmployeeSerializer } from "../../../models/serializers/employeeSerializer";


export class CommandGetEmployees {
    EMPLOYEE_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {
        return  `${this.EMPLOYEE_SERVICE}/employees/`;
    }

    async run(provider_id, location_id, offset=0, limit=Number.MAX_SAFE_INTEGER, search=null) {

        const url = this.getUrl()

        const params = {
            params: {
                provider: provider_id,
                location: location_id,
                offset: offset,
                limit: limit
            }
        }

        const tracer = useTracer.getState()
        tracer.trace('Employees', 'Api', `GET ${url}`)

        try {
            const response = await httpClient.get(url, params);
            return this.deserialize(response.data);
        } catch (error) {
            tracer.trace('Employees', 'Error', `${error}`)
            console.error('Error fetching data:', error);
            throw error;
        }finally {
            tracer.trace('Employees', 'Api', 'Success')
        }

    }

    deserialize(data) {

        const serializer = new EmployeeSerializer()
        const employees = serializer.deserializeList(data)

        return employees
    }

}
import React from "react";
import PrioritizeProjectsRow from "./PrioritizeProjectsRow";
import { useProjectStore } from "../../../../projectsStore";
import useAppStore from "../../../../../../appStore";

const PrioritizeProjectsTable = (props) => {

    const {
        children,
        value,
        index,
        onEdit,
        onDelete,
        ...other } = props;

    const { projects, setProjects } = useProjectStore();
    const { whiteLabelProps } = useAppStore();

    return (
        <>
            {/*begin::Table widget 14*/}
            <div className="card card-flush h-md-100 mt-5">
                {/*begin::Header*/}
                <div className="card-header pt-7">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">Prioritize</span>
                        <span className="text-gray-400 mt-1 fw-semibold fs-6">prioritize projects</span>
                    </h3>
                    {/*end::Title*/}
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar">
                        {/*<a className="btn btn-sm btn-light me-2">Mass Assign</a> */}
                        <a className="btn btn-sm btn-light">Sort By</a>
                        <a className="btn btn-sm btn-primary ms-3" style={{ backgroundColor: whiteLabelProps.primary_color }}>+ Create Project</a>
                    </div>
                    {/*end::Toolbar*/}
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="card-body pt-6">
                    {/*begin::Table container*/}
                    <div className="table-responsive ">
                        {/*begin::Table*/}
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                            {/*begin::Table head*/}
                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <th className="p-0 pb-3 min-w-225px text-start">PROJECT NAME</th>
                                    <th className="p-0 pb-3 min-w-125px text-end">DURATION</th>
                                    <th className="p-0 pb-3 min-w-75px text-end">BUDGET</th>
                                    <th className="p-0 pb-3 min-w-75px text-end">PRIORITY</th>
                                    <th className="p-0 pb-3 pe-3 min-w-75px text-end">STATUS</th>
                                    <th className="p-0 pb-3 min-w-25px text-center">ACTIONS</th>
                                </tr>
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody>
                                {projects.map((project, index) => (
                                  <PrioritizeProjectsRow
                                    key={index}
                                    project={project}
                                    onEdit={onEdit}
                                    onDelete={onDelete}
                                  />
                                ))}
                            </tbody>
                            {/*end::Table body*/}
                        </table>
                    </div>
                    {/*end::Table*/}
                </div>
                {/*end: Card Body*/}
            </div>
            {/*end::Table widget 14*/}
         
        </>
    )
}

export default PrioritizeProjectsTable
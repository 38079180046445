/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTIcon } from '../../../../../../../../_metronic/helpers/components/KTIcon'



type Props = {
    //className: string
}

const FundraiserStats: React.FC<Props> = ({}) => {
    return (

        <div>
            <div className='d-flex align-items-center mt-7'>
                <a
                    href='#'
                    className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4'
                >
                    <KTIcon iconName='message-text-2' className='fs-2' />
                    120
                </a>

                <a
                    href='#'
                    className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'
                >
                    <KTIcon iconName='heart' className='fs-2' />
                    15
                </a>
            </div>
        </div>

    )

}

export {FundraiserStats}

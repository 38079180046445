import { UserPermissions } from "./userPermissions.model";
import { Role } from "./role.model";

export class EmployeeUserModel {
  role: Role;
  country_code: string;
  is_active: boolean;
  permission_profiles: UserPermissions[];


  constructor() {
    this.role = new Role();
    this.country_code = '';
    this.is_active = false;
    this.permission_profiles = [];
  }

}


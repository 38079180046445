import React from 'react'
import { Alert } from "react-bootstrap";
import { toast as toastify } from 'react-toastify';

const ErrorMessage = (props) => {

    const {
        apiError,
        error,
        toast,
        name
    } = props



    const apiErrors = () => {
        if (error === true && name === "" && toast === false) {
            return <Alert variant='danger'>{apiError}</Alert>
        }
    }
    const errorNotArray = () => {
        if (error === true && name !== "" && toast === false) {
            return <Alert variant='danger'>{name}</Alert>
        }
    }
    const error500 = () => {
        if (error === true && toast === true) {
            toastify.error(name, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    return (
        <>
            {apiErrors()}
            {errorNotArray()}
            {error500()}
        </>
    )
}

export {ErrorMessage}

import { CommandCreateEquipment } from "./commands/cmdCreateEquipment";
import { CommandGetEquipment } from "./commands/cmdGetEquipment";
import { CommandUpdateEquipment } from "./commands/cmdUpdateEquipment";

export class EquipmentService {

  async getEquipment(provider_id: number | undefined) {
    const cmd = new CommandGetEquipment();
    const results = await cmd.run(provider_id);
    return results;
  }

  async createEquipment(equipment) {
    const cmd = new CommandCreateEquipment();
    const response = await cmd.run(equipment);
    return response;
  }

  async updateEquipment(equipment) {
    const cmd = new CommandUpdateEquipment();
    const response = await cmd.run(equipment);
    return response;
  }

}

import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { ServicePlanItemSerializer } from "../../../models/serializers/servicePlanItemSerializer";

export class CommandPostServicePlanItem {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(id) {
    return `${this.SERVICE}/service_plans/${id}/items/`;
  }

  async run(servicePlan, servicePlanItem, location = null) {
    const url = this.getUrl(servicePlan.id);

    type PayloadType = {
      service_item: { id: number },
      frequency: { times_per_period: number, repeat_period: string },
      location?: { id: number }
    };

    const payload: PayloadType = {
      service_item: {
        id: servicePlanItem.id
      },
      frequency: {
        times_per_period: 1,
        repeat_period: "Day"
      }
    };

    if (location) {
      payload.location = {
        id: location
      };
    }

    const tracer = useTracer.getState();
    tracer.trace('ServicePlan', 'Api', `POST ${url}`);

    try {
      const response = await httpClient.post(url, payload);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('ServicePlan', 'Error', `${error}`);
      console.error('Error:', error);
      throw error;
    } finally {
      tracer.trace('ServicePlan', 'Api', 'Success');
    }
  }

  deserialize(data) {
    const serializer = new ServicePlanItemSerializer();
    return serializer.deserialize(data);
  }
}

import { Person } from "../person.model";
import { BaseSerializer } from "./baseSerializer";

export class PersonSerializer extends BaseSerializer {


    public deserialize( data ): any {

        const person = new Person();

        this._copyAttributes(person, data);

        return person;

    }

}
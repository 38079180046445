import { InspectionSerializer } from "../../../models/serializers/inspectionSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { ContactSerializer } from "../../../models/serializers/contactSerializer";


export class CommandUpdateContact {

    CONTACTS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl(id) {

        return `${this.CONTACTS_SERVICE}/locations/contacts/${id}/`;
    }

    async run(contact) {

        const url = this.getUrl(contact.id)

        const tracer = useTracer.getState()
        tracer.trace('Contacts', 'Api', `PUT ${url}`)

        try {

            let data = ContactSerializer.serialize(contact, )
            const response = await httpClient.put(url, data);

            return this.deserialize(response.data);
        } catch (error) {
            tracer.trace('Contacts', 'Error', `${error}`)
            console.error('Error:', error);
            throw error;
        }finally {
            tracer.trace('Contacts', 'Api', 'Success')
        }

    }

    deserialize(data) {

        const serializer = new ContactSerializer()
        const item = serializer.deserialize(data)

        return item
    }

}
import React, { useEffect, useState } from "react";
import ActionButton from "../../../../../../../shared/components/buttons/ActionButton";
import { Box, Popover } from "@mui/material";
import ServiceItemScheduler from "./components/ServiceItemScheduler";
import DuotoneCategory from "../../../../../../../shared/components/page/row-icons/DuotoneCategory";
import { FrequencyModel } from "../../../../../../../shared/models/frequency.model";

const ServiceItemRow = (props) => {

    const {
        item,
        onSchedule,
        onRemove,
        global
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [frequency, setFrequency] = useState(new FrequencyModel());

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <tr>
                <td>
                    <div className="d-flex align-items-center">
                       <DuotoneCategory />
                        <div className="d-flex justify-content-start flex-column">
                            <span
                              className="text-gray-800 fw-bold fs-6">{item?.service_item?.entity_type?.name || 'Undefined'}</span>
                            <span className="text-gray-800 mb-1 fs-6">{item?.service_item?.service?.path}</span>
                        </div>
                    </div>
                </td>

                {/* DAILY */}
                <td className="text-end pe-0">
                    {item?.frequency?.repeat_period === 'Day' &&
                      item?.frequency?.times_per_period + 'x'
                    }
                </td>

                {/* WEEKLY */}
                <td className="text-end">
                    {item?.frequency?.repeat_period === 'Week' &&
                        item?.frequency?.times_per_period + 'x'
                    }
                </td>

                {/* MONTHLY */}
                <td className="text-end pe-0">
                    {item?.frequency?.repeat_period === 'Month' &&
                      item?.frequency?.times_per_period + 'x'
                    }
                </td>

                {/* YEARLY */}
                <td className="text-end">
                    {item?.frequency?.repeat_period === 'Year' &&
                      item?.frequency?.times_per_period + 'x'
                    }
                </td>

                {/* ACTIONS */}
                <td>
                    <div className="d-flex justify-content-center flex-shrink-0">
                        <ActionButton iconType="calendar" onClick={(e) => {
                            handleOpen(e);
                            e.preventDefault();
                        }} />
                        {global &&
                          <ActionButton iconType="trash" onClick={(e) => {
                              onRemove(item);
                              e.preventDefault();
                          }} />
                        }
                    </div>
                </td>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                  }}
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                  }}
                >
                    <Box sx={{ p: 2 }}>
                        <ServiceItemScheduler item={item} onSchedule={onSchedule} />
                    </Box>
                </Popover>

            </tr>
        </>
    )
}

export default ServiceItemRow
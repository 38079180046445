import React, {useState, useEffect} from "react";
import "yet-another-react-lightbox/styles.css";
import { ActionMenu } from "./ActionMenu";


const FundraiserBody = (props) => {

    const {
        fundraiser
    } = props

    const [index, setIndex] = useState(-1);

    const onActionClicked= () => {

    }

    return (
        <>
        <div className='d-flex align-items-center mb-5 mt-5'>
            {/* begin::User */}
            <div className='d-flex align-items-center flex-grow-1'>
                {/* begin::Avatar */}
                <div className='symbol symbol-45px me-5'>
                    <img src={'https://hal-content-bucket.s3.amazonaws.com/1683212250474?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2IWJENXHC5VZKF4D%2F20230801%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20230801T060718Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=0bda917be4e5d1f83563f34b809ff52bdaa569d6e15658412f40cffd11676d26'} alt='' />
                </div>
                {/* end::Avatar */}

                {/* begin::Info */}
                <div className='d-flex flex-column'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-4 fw-bold'>
                        {fundraiser.name}
                    </a>
                    <span className='text-gray-400 fw-semibold'>{fundraiser.organization.name}</span>
                </div>
                {/* end::Info */}
            </div>
            {/* end::User */}
            <ActionMenu onActionClicked={onActionClicked} />
        </div>
        <p className='py-3' dangerouslySetInnerHTML={{__html: fundraiser.description}}></p>
        </>
    )

}

export {FundraiserBody}

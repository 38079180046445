import { BaseSerializer } from "./baseSerializer";
import { InspectionSummary } from "../inspectionSummary.model";
import { InspectionRecommendation } from "../inspectionRecommendation.model";

export class InspectionSummarySerializer extends BaseSerializer {
    public deserialize(data): any {
        const summaryData = data.inspection_summary; // Adjust based on actual response structure
        const summary = new InspectionSummary();

        if (summaryData) {
            summary.location_id = summaryData.location_id;
            summary.latest_inspection = summaryData.latest_inspection;
            summary.avg_score = parseFloat(summaryData.avg_score);
            summary.recommendations = summaryData.recommendations;
            summary.total_inspected_spaces = summaryData.total_inspected_spaces;
        }

        return summary;
    }
}
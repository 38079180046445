// DummyProjects.ts
import { Project } from './Project';

export const dummyProjects: Project[] = [
  {
    name: 'Project Alpha',
    duration: '6 months',
    budget: 50000,
    priority: 'High',
    status: 'In Progress',
    parts: [
      {
        name: 'Design Phase',
        tasks: [
          {
            name: 'Initial Design',
            subtasks: [
              { name: 'Sketch initial layout', type: 'subtask' },
              { name: 'Review with client', type: 'subtask' },
            ],
            type: 'task',
          },
          {
            name: 'Final Design',
            subtasks: [
              { name: 'Apply client feedback', type: 'subtask' },
              { name: 'Finalize layouts', type: 'subtask' },
            ],
            type: 'task',
          },
        ],
        type: 'part'
      },
      {
        name: 'Implementation Phase',
        tasks: [
          {
            name: 'Frontend Development',
            subtasks: [
              { name: 'Develop UI components', type: 'subtask' },
              { name: 'Implement responsive design', type: 'subtask' },
            ],
            type: 'task',
          },
          {
            name: 'Backend Development',
            subtasks: [
              { name: 'Set up server', type: 'subtask' },
              { name: 'Develop API endpoints', type: 'subtask' },
            ],
            type: 'task',
          },
        ],
        type: 'part'
      },
    ],
  },
  {
    name: 'Project Beta',
    duration: '12 months',
    budget: 75000,
    priority: 'Medium',
    status: 'Scheduled',
    parts: [
      {
        name: 'Research Phase',
        tasks: [
          {
            name: 'Market Analysis',
            subtasks: [
              { name: 'Competitor research', type: 'subtask' },
              { name: 'Target audience survey', type: 'subtask' },
            ],
            type: 'task',
          },
          {
            name: 'Feasibility Study',
            subtasks: [
              { name: 'Technical requirements assessment', type: 'subtask' },
              { name: 'Financial projections', type: 'subtask' },
            ],
            type: 'task',
          },
        ],
        type: 'part'
      },
      {
        name: 'Development Phase',
        tasks: [
          {
            name: 'Architecture Planning',
            subtasks: [
              { name: 'Define software layers', type: 'subtask' },
              { name: 'Select technology stack', type: 'subtask' },
            ],
            type: 'task',
          },
          {
            name: 'Database Design',
            subtasks: [
              { name: 'Design data schema', type: 'subtask' },
              { name: 'Set up database infrastructure', type: 'subtask' },
            ],
            type: 'task',
          },
        ],
        type: 'part'
      },
    ],
  },
  {
    name: 'Project Gamma',
    duration: '9 months',
    budget: 30000,
    priority: 'Low',
    status: 'Scheduled',
    parts: [
      {
        name: 'Conceptualization Phase',
        tasks: [
          {
            name: 'Idea Generation',
            subtasks: [
              { name: 'Brainstorming sessions', type: 'subtask' },
              { name: 'Idea validation', type: 'subtask' },
            ],
            type: 'task',
          },
          {
            name: 'Concept Design',
            subtasks: [
              { name: 'Prototype sketching', type: 'subtask' },
              { name: 'Concept approval', type: 'subtask' },
            ],
            type: 'task',
          },
        ],
        type: 'part'
      },
      {
        name: 'Prototyping Phase',
        tasks: [
          {
            name: '3D Modeling',
            subtasks: [
              { name: 'CAD Design', type: 'subtask' },
              { name: 'Model refinement', type: 'subtask' },
            ],
            type: 'task',
          },
          {
            name: 'Prototype Testing',
            subtasks: [
              { name: 'Functional tests', type: 'subtask' },
              { name: 'User feedback collection', type: 'subtask' },
            ],
            type: 'task',
          },
        ],
        type: 'part'
      },
    ],
  },
  {
    name: 'Project Delta',
    duration: '3 months',
    budget: 20000,
    priority: 'High',
    status: 'In Progress',
    parts: [
      {
        name: 'Preparation Phase',
        tasks: [
          {
            name: 'Resource Allocation',
            subtasks: [
              { name: 'Budget distribution', type: 'subtask' },
              { name: 'Staff assignment', type: 'subtask' },
            ],
            type: 'task',
          },
          {
            name: 'Risk Assessment',
            subtasks: [
              { name: 'Identify potential risks', type: 'subtask' },
              { name: 'Develop mitigation plans', type: 'subtask' },
            ],
            type: 'task',
          },
        ],
        type: 'part'
      },
      {
        name: 'Execution Phase',
        tasks: [
          {
            name: 'Task Implementation',
            subtasks: [
              { name: 'Task breakdown and scheduling', type: 'subtask' },
              { name: 'Task execution and monitoring', type: 'subtask' },
            ],
            type: 'task',
          },
          {
            name: 'Quality Control',
            subtasks: [
              { name: 'Define quality standards', type: 'subtask' },
              { name: 'Conduct quality audits', type: 'subtask' },
            ],
            type: 'task',
          },
        ],
        type: 'part'
      },
    ],
  }

];

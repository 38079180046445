import { InspectedEntitySerializer } from "../../../models/serializers/inspectedEntitySerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";


export class CommandCreateInspectedEntity {

    INSPECTIONS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl(inspectionId) {

        return `${this.INSPECTIONS_SERVICE}/inspections/${inspectionId}/inspected_entities/`;
    }

    async run(inspection, inspectedEntity) {

        const url = this.getUrl(inspection.id)

        const tracer = useTracer.getState()
        tracer.trace('Inspections', 'Api', `GET ${url}`)

        try {

            let data = InspectedEntitySerializer.serialize(inspectedEntity, ["entity_inspection", "entity_path", "uuid"])

            const response = await httpClient.post(url, data);
            inspectedEntity = this.deserialize(response.data);

            return inspectedEntity
        } catch (error) {
            tracer.trace('Inspections', 'Error', `${error}`)
            console.error('Error:', error);
            throw error;
        }finally {
            tracer.trace('Inspections', 'Api', 'Success')
        }

    }

    deserialize(data) {

        const serializer = new InspectedEntitySerializer()
        const item = serializer.deserialize(data)

        return item
    }

}
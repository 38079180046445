import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ActivityTable from "../work-items/components/components/tables/ActivityTable";
import useAppStore from "../../../../../../../appStore";
import CustomLoadingDots from "../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../../shared/components/page/popup-dialog/CustomError";
import { InspectionAnalytics } from "../../../../../../../shared/models/inspectionAnalytics.model";
import { SiteHealthService } from "../../../../../../../shared/services/site-health/siteHealthService";

const InspectionOverviewAnalytics = () => {

  const {loggedInUser} = useAppStore();
  const service = new SiteHealthService();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [locationAnalytics, setLocationAnalytics] = useState<InspectionAnalytics>(new InspectionAnalytics());

  useEffect(() => {
    setIsLoading(true);
    setError('');
    service.getLocationInspectionAnalytics(loggedInUser.organization?.id, )
      .then(analytics => {
        setLocationAnalytics(analytics);
      })
      .catch(error => {
        setError(error instanceof Error ? error.message : String(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [loggedInUser.organization?.id]);


  if (isLoading) {
    return <CustomLoadingDots />
  }
  if (error) {
    return <CustomError error={error} />;
  }

  return (
    <>
      <Grid container spacing={2} sx={{mt: 1}}>
        <Grid item xs={12} md={6}>
          <ActivityTable title={"Location"} data={locationAnalytics.inspection_activity_by_location} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ActivityTable title={"Employee"} data={locationAnalytics.inspection_activity_by_employee} />
          {/*<EmployeeInspectionsTable data={locationAnalytics.inspection_activity_by_employee} />*/}
        </Grid>
      </Grid>
    </>
  );
};


export default InspectionOverviewAnalytics;





import React from "react";
import GreetingDashboardCard from "../components/greeting/GreetingDashboardCard";
import { useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import useAppStore from "../../../appStore";
import DashboardSection from "../components/DashboardSection";
import ThingsIveToldSage from "../components/cases/ThingsIveToldSage";
import CustomLoadingDots from "../../../shared/components/page/popup-dialog/CustomLoadingDots";
import ClientSummary from "./monthly-summary/MonthClientSummary";

const ClientDashboard = () => {

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const {selectedLocation} = useAppStore();

  if (selectedLocation.id === 0) {
    return (<CustomLoadingDots />);
  }

  return (
    <div className={'mb-10'}>
      <GreetingDashboardCard />

      <div style={{display: 'flex', flexDirection: 'column', marginTop: isMobile ? '0' : '20px'}}>

        <div style={{ width: '100%' }}>
          <div>
            <Grid container>
              <Grid item xs={12} md={12}>
                <DashboardSection title={"Summary"} tile={<ClientSummary />} />
              </Grid>
              <Grid item xs={12} md={12}>
                <DashboardSection title={"Things I've Told Sage"} tile={<ThingsIveToldSage />} />
              </Grid>
            </Grid>
          </div>
        </div>

      </div>

    </div>
  );
};

export default ClientDashboard;
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import WorkItemMobileCard from "./components/mobile/WorkItemMobileCard";
import WorkItemDesktopCard from "./components/desktop/WorkItemDesktopCard";

interface WorkItemTableProps {
  workItems: any[];
  onEdit: (workItem: any) => void;
  onDelete: (workItem: any) => void;
  handleAssign: (workItem: any) => void;
}

const WorkItemTable: React.FC<WorkItemTableProps> = (props) => {
  const { workItems, onEdit, onDelete, handleAssign } = props;
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  return (
    <>
        <div className={"mt-5"}>
          {isMobile ? (
            <>
              {workItems.map((workItem, index) => (
                <WorkItemMobileCard
                  key={index}
                  workItem={workItem}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  handleAssign={handleAssign}
                />
              ))}
            </>
          ) : (
            <>
              {workItems.map((workItem, index) => (
                <WorkItemDesktopCard
                  key={index}
                  workItem={workItem}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  handleAssign={handleAssign}
                />
              ))}
            </>
          )}
        </div>
    </>
  );
};

export default WorkItemTable;

import React from "react";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";

const WhereAndWhatRow = (props) => {

  const {
    entry,
    onDelete,
    isMobile,
    isEditing
  } = props;

  const renderServiceItems = () => {
    if (entry.service_items && entry.service_items.length > 0) {
      return entry.service_items.map((item, index) => (
        <span key={index} className="text-primary fst-italic d-block fs-7">
          {item.entity_type?.name || item.name}
        </span>
      ));
    } else {
      return <span className="text-gray-500 fw-bold d-block fs-7"></span>;
    }
  };

  return (
    <>
      <tr>
        <td style={{ maxWidth: isMobile ? '120px' : 'auto' }}>
          <div className="d-flex align-items-center">
            <div className="d-flex justify-content-start flex-column">
              <a className="text-gray-800 fw-bold mb-1 fs-6">
                {entry.entity?.entity_path?.[0]?.name ? entry.entity.entity_path[0].name : 'N / A'}
              </a>
              <span className="text-gray-400 fw-semibold d-block fs-7">Floor {entry.entity?.name ? entry.entity?.name : ''} / {entry.entity_type.name ? entry.entity_type.name : ''}</span>
            </div>
          </div>
        </td>
        <td>
          <div className="d-flex justify-content-start flex-column">
            {renderServiceItems()}
          </div>
        </td>

        {onDelete !== null && isEditing &&
          <td>
            <div className="d-flex justify-content-end flex-shrink-0">
              <ActionButton iconType="trash" onClick={(e) => {
                e.preventDefault();
                onDelete();
              }} />
            </div>
          </td>
        }

      </tr>
    </>
  )
}

export default WhereAndWhatRow
import React, {useState} from 'react'
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import CustomLoadingDots from "../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../shared/components/page/popup-dialog/CustomError";
import { useEntityFetch } from "../../../../../shared/custom-hooks/where-and-what/useEntityFetch";
import SelectableTilesGrid
  from "../../../../inspections/components/edit-inspection/components/edit-inspected-entity/components/space-selection/components/selectable-tiles/SelectableTilesGrid";


const BuildingSelect = ({state, setState}) => {

  const { entities, entityTypes, floors, isLoading, floorsLoading, typesLoading, error } = useEntityFetch(state.space.id, state.floor.id, 0)
  const [spaceNumber, setSpaceNumber] = useState('');

  if (isLoading) {
    return <CustomLoadingDots />;
  }
  if (error) {
    return <CustomError error={error} />;
  }

  const handleBuildingChanged = (event, value) => {
    const selectedBuildingName = event.target.value;
    const selectedBuilding = entities.find(bldg => bldg.name === selectedBuildingName);
    setState(prevState => ({ ...prevState, space: selectedBuilding }));
  };

  const handleFloorSelectionChanged = (event, selectedEntity) => {
    setState(prevState => ({ ...prevState, floor: selectedEntity }));
  };

  const handleEntityTypeChanged = (event, spaceType) => {
    setState(prevState => ({ ...prevState, spaceType: spaceType }));
  };

  const handleSpaceNumberChanged = (event) => {
    setSpaceNumber(event.target.value)
  };


  return (

    <div className="d-flex flex-column flex-row-fluid" style={{paddingBottom:"300px"}}>
      <h1>Where are you?</h1>
      <div className='mt-10'>
        <h3 className="text-gray-700">Building</h3>
        <FormControl style={{ width: '100%' }} margin="normal">
          <Select
            value={state.space ? state.space.name : ''}
            onChange={handleBuildingChanged}
            MenuProps={{
              PaperProps: {
                style: {
                  width: '80%',
                  height: '70%',
                },
              },
            }}
          >
            {entities.map((bldg) => (
              <MenuItem key={bldg.id} value={bldg.name}>
                {bldg.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="mt-10">
        <h3 className="text-gray-700">Floor</h3>
        { floorsLoading ? (
          <div style={{marginTop: '15px'}}>
            <CustomLoadingDots />
          </div>
        ) : (
          floors.length > 0 ? (
            <SelectableTilesGrid
              disabled={false}
              options={floors}
              value={state.floor}
              onChange={handleFloorSelectionChanged}
            />
          ) : (
            <p>Choose a building to view floors</p>
          )
        )}
      </div>
      <div className="mt-10">
        <h3 className="text-gray-700">Type</h3>
        { typesLoading ? (
          <div style={{marginTop: '15px'}}>
            <CustomLoadingDots />
          </div>
        ) : (
          entityTypes.length > 0 ? (
            <SelectableTilesGrid disabled={false}
                                 options={entityTypes}
                                 value={state.spaceType}
                                 onChange={handleEntityTypeChanged}
            />
          ) : (
              <p>Choose a floor to view space types</p>
            )
        )}
      </div>
      <div className="mt-10">
        <h3 className="text-gray-700">Room number?</h3>
        <TextField placeholder='Room number'
                   variant="outlined"
                   onChange={handleSpaceNumberChanged}
                   value={spaceNumber}
        />
      </div>
    </div>

  )};

export default BuildingSelect;
import { BaseSerializer } from "./baseSerializer";
import { ServiceItemSerializer } from "./serviceItemSerializer";
import { ServicePlanItem } from "../servicePlanItem.model";
import { FrequencySerializer } from "./frequencySerializer";

export class ServicePlanItemSerializer extends BaseSerializer {

  public deserialize( data ): any {

    let plan_item = new ServicePlanItem();

    this._copyAttributes(plan_item, data);

    let serializer = new FrequencySerializer();
    const freq = serializer.deserialize(data.frequency);
    plan_item.frequency = freq;

    serializer = new ServiceItemSerializer();
    const service_item = serializer.deserialize(data.service_item);
    plan_item.service_item = service_item;

    return plan_item;

  }

}
import React from 'react'
import PageHeader from "../../shared/components/page/page-header/PageHeader";
import ComingSoon from "../../shared/components/page/coming-soon/ComingSoon";

const PermissionsPage = () => {

  return (
    <>
      <PageHeader
        title='Permissions'
        showAction={false}
      />
      <ComingSoon />
    </>

  )

}

export default PermissionsPage
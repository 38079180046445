import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { WorkItemStatusSerializer } from "../../../models/serializers/workItemStatusSerializer";


export class CommandGetStatusCounts {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return  `${this.SERVICE}/analytics/`;
  }

  async run(provider_id, location_id) {
    const url = this.getUrl();

    const params = {
      params: {
        provider: provider_id,
        location: location_id,
        module: 'workitems',
        charts: 'status_counts'
      },
    };

    const tracer = useTracer.getState();
    tracer.trace('WorkItems', 'Api', `GET ${url}`);

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('WorkItems', 'Error', `${error}`);
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      tracer.trace('WorkItems', 'Api', 'Success');
    }
  }

  deserialize(data) {

    const serializer = new WorkItemStatusSerializer()
    const statusCounts = serializer.deserialize(data)

    return statusCounts
  }

}
import React, { useEffect } from "react";
import { DateTime } from 'luxon';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:first-of-type)': {
      marginLeft: 0,
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
    '&:first-of-type': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
    '&:last-child': {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    '&:hover': {
      borderColor: theme.palette.action.hover,
    },
    '&.Mui-disabled': {
      borderColor: theme.palette.action.disabled,
    },
  },
}));

const CustomDatePicker = ({ value, onChange, quickPick=true, prefill=true }) => {

  const [selectedDate, setSelectedDate] = React.useState(() => {
    return value ? DateTime.fromISO(value) : (prefill ? DateTime.now().plus({ days: 1 }) : null);
  });

  useEffect(() => {
    if (value) {
      const newDate = DateTime.fromISO(value);
      if (newDate.isValid) {
        setSelectedDate(newDate);
      } else {
        setSelectedDate(null);
      }
    } else {
      setSelectedDate(prefill ? DateTime.now().plus({ days: 1 }) : null);
    }
  }, [value, prefill]);



  const [alignment, setAlignment] = React.useState('custom');

  const handlePreset = (event, newAlignment) => {

    if (newAlignment === null) {
      return
    }

    setAlignment(newAlignment);
    let newDate = DateTime.now();
    switch (newAlignment) {
      case 'today':
        break;
      case '24h':
        newDate = newDate.plus({ days: 1 });
        break;
      case '1w':
        newDate = newDate.plus({ weeks: 1 });
        break;
      case '1m':
        newDate = newDate.plus({ months: 1 });
        break;
      case 'custom':
        newDate = selectedDate || newDate;
        break;
      default:
        break;
    }
    setSelectedDate(newDate);
    onChange(newDate.toUTC().toISO());

  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {quickPick &&
        <>
          <Typography sx={{fontWeight: 'bold'}}>Due In </Typography>
          <StyledToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handlePreset}
            aria-label="date selection"
          >
            <ToggleButton value="today">Today</ToggleButton>
            <ToggleButton value="24h">24h</ToggleButton>
            <ToggleButton value="1w">1w</ToggleButton>
            <ToggleButton value="1m">1m</ToggleButton>
            <ToggleButton value="custom">Custom</ToggleButton>
          </StyledToggleButtonGroup>
        </>
      }

      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          value={selectedDate}
          onChange={(newValue) => {
            setSelectedDate(newValue);
            onChange(newValue.toISO());
          }}
          readOnly={alignment !== 'custom'}
          renderInput={(props) => (
            <TextField
              {...props}
              readOnly={alignment !== 'custom'}
              InputProps={{
                endAdornment: props.InputProps.endAdornment,
              }}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};


export default CustomDatePicker;


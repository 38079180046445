import { BaseSerializer } from "./baseSerializer";
import { ActionPlanItem } from "../actionPlanItem.model";

export class ActionPlanItemSerializer extends BaseSerializer {


  public deserialize( data ): any {

    let item = new ActionPlanItem();

    this._copyAttributes(item, data);

    return item;

  }

}
import {KTIcon} from "../../../../../_metronic/helpers";
import { Button, Divider } from "@mui/material";

const PageHeader = (props) => {

    const {
        title,
        actionButtonName='',
        onActionClicked=null,
        showAction = true,
        divider = true
    } = props

    // Custom CSS for the KTIcon
    const iconStyles = {
        color: 'white !important', // Set the icon color to white
    };


    return (
      <>
          <div className="row mb-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div className="col" style={{ flex: 1 }}>
                  <h2 style={{ margin: 0 }}>{title}</h2>
              </div>
              {showAction && (
                <div className="col text-end" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="primary" onClick={(e) => { onActionClicked(e); }}
                    sx={{maxHeight: '35px'}}>
                        <KTIcon iconName='plus' className='fs-2 text-light me-1'/>
                        {actionButtonName}
                    </Button>
                </div>
              )}
          </div>
        {divider &&
          <Divider sx={{marginTop: 2}}/>
        }
      </>
    );

}

export default PageHeader

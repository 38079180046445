import { ServiceSerializer } from "../../../models/serializers/serviceSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { InspectionSerializer } from "../../../models/serializers/inspectionSerializer";


export class CommandCreateService {

  SERVICE_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return  `${this.SERVICE_SERVICE}/services/`;
  }

  async run(service) {

    const url = this.getUrl()

    const tracer = useTracer.getState()
    tracer.trace('Services', 'Api', `GET ${url}`)

    try {

      let data = ServiceSerializer.serialize(service)
      const response = await httpClient.post(url, data);

      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Services', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    }finally {
      tracer.trace('Services', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new ServiceSerializer()
    const service = serializer.deserialize(data)

    return service
  }

}
import React, { useEffect } from 'react';
import useAppStore from "../../../appStore";

declare global {
  interface Window {
    hj: (command: string, userId?: string, attributes?: Record<string, any>) => void;
  }
}

const Hotjar = () => {
  const { loggedInUser } = useAppStore();

  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5108442,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `;

    document.head.appendChild(script);

    const checkHotjarReady = setInterval(() => {
      if (window.hj && loggedInUser) {
        clearInterval(checkHotjarReady);
        const userId = `${loggedInUser.id}`;
        window.hj('identify', userId, {
          'Name': loggedInUser.first_name + ' ' + loggedInUser.last_name,
          'Is Active': loggedInUser?.is_active.toString(),
          'Role': loggedInUser?.role?.name,
          'Organization': loggedInUser?.organization?.name,
          'Location': loggedInUser?.employee?.works_at?.length > 0
            ? loggedInUser.employee.works_at[0].location?.name
            : 'None',
        });
      }
    }, 100);

    return () => {
      document.head.removeChild(script);
      clearInterval(checkHotjarReady);
    };
  }, [loggedInUser]);


  return null;
};

export default Hotjar;

import React, { useEffect, useState } from "react";
import useAppStore from "../../../../appStore";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import CaseTable from "./components/desktop/CaseTable";
import { CasesService } from "../../../../shared/services/cases/casesService";
import InfoTile from "../../../../shared/components/page/tiles/InfoTile";
import { Button, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Case } from "../../../../shared/models/case.model";

const CasesList = ({ onEdit, refreshList }) => {
    const { loggedInUser, selectedLocation } = useAppStore();
    const service = new CasesService();

    const [activeStep, setActiveStep] = useState(0);
    const [cases, setCases] = useState<Case[]>([]);
    const [filteredCases, setFilteredCases] = useState<Case[]>([]);
    const [typeCounts, setTypeCounts] = useState<any>(null);
    const [selectedTypeID, setSelectedTypeID] = useState<number | null>(null);
    const [page, setPage] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    const fetchTypeCounts = async () => {
        try {
            const counts = await service.getCaseTypeCounts(loggedInUser.organization.id);
            setTypeCounts(counts);
        } catch (error) {
            setError(error instanceof Error ? error.message : String(error));
        }
    };

    const fetchCases = async () => {
        setIsLoading(true);
        const limit = 10;
        const offset = page * limit;

        try {
            const active = await service.getCases(
              loggedInUser.organization?.id,
              null,
              activeStep === 0 ? 'Waiting' : 'Completed',
              selectedTypeID,
              limit,
              offset
            );

            if (page === 0) {
                setCases(active);
            } else {
                setCases(prevCases => [...prevCases, ...active]);
            }

            const updatedCases = page === 0 ? active : [...cases, ...active];
            if (selectedTypeID) {
                setFilteredCases(updatedCases.filter(caseItem => caseItem.case_type.name === selectedTypeID));
            } else {
                setFilteredCases(updatedCases);
            }
        } catch (error) {
            setError(error instanceof Error ? error.message : String(error));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCases();
    }, [refreshList, selectedLocation, activeStep, page, selectedTypeID]);

    useEffect(() => {
        fetchTypeCounts();
    }, []);

    useEffect(() => {
        if (selectedTypeID) {
            setFilteredCases(cases.filter(caseItem => caseItem.case_type.id === selectedTypeID));
        } else {
            setFilteredCases(cases);
        }
    }, [selectedTypeID, cases]);

    const handleTileClick = (id: number) => {
        setSelectedTypeID(prev => (prev === id ? null : id));
        setPage(0);
    };

    const handleTabChange = (event, newValue) => {
        setActiveStep(newValue);
        setPage(0);
    };

    const handleCaseTypeChange = (event) => {
        setSelectedTypeID(event.target.value);
        setPage(0);
    };

    const handleShowMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    if (error) return <CustomError error={error} />;

    return (
      <>
          {typeCounts && !isMobile &&
            <div className="d-flex flex-row mt-5 mb-5">
                {typeCounts?.type_counts?.map((item) => (
                  <InfoTile
                    key={item?.case_type_id}
                    title={item?.case_type}
                    value={item?.count}
                    onClick={() => handleTileClick(item?.case_type_id)}
                    selected={selectedTypeID === item?.case_type_id}
                  />
                ))}
            </div>
          }

          {typeCounts && isMobile &&
            <FormControl variant="outlined" size="small" style={{ minWidth: 200, marginBottom: '20px' }}>
                <InputLabel>Case Type</InputLabel>
                <Select
                  value={selectedTypeID}
                  onChange={handleCaseTypeChange}
                  label="Case Type"
                >
                    <MenuItem value=""><em>All Types</em></MenuItem>
                    {typeCounts?.type_counts?.map((item) => (
                      <MenuItem key={item.case_type_id} value={item.case_type_id}>
                          {item.case_type} ({item.count})
                      </MenuItem>
                    ))}
                </Select>
            </FormControl>
          }

          <div style={{ marginBottom: '10px' }}>
              <Tabs value={activeStep} onChange={handleTabChange}>
                  <Tab label="Open" />
                  <Tab label="Closed" />
              </Tabs>

              {isLoading && page === 0 && (
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    <CustomLoadingDots />
                </div>
              )}
          </div>

          {!isLoading &&
            <CaseTable
              cases={filteredCases}
              onEdit={onEdit}
              isLoading={isLoading}
            />
          }


          {filteredCases.length % 10 === 0 && filteredCases.length > 0 && (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                <Button
                  sx={{
                      width: '100%',
                      padding: '12px',
                      marginBottom: '20px',
                      marginTop: '10px',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                      color: '#ffffff',
                      background: 'linear-gradient(45deg, #15aa78 30%, #5ec3a1 90%)',
                      borderRadius: '5px',
                      boxShadow: '0 3px 5px 2px rgba(.5, .5, .5, .3)',
                      textTransform: 'none',
                  }}
                  onClick={handleShowMore}
                >
                    Load More
                </Button>
            </div>
          )}
      </>
    );
};

export default CasesList;








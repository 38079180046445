import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";

export class CommandSendScanData {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/sensors/trigger/`;
  }

  async run(employee, data) {

    const url = this.getUrl()

    const tracer = useTracer.getState()
    tracer.trace('Equipment', 'Api', `POST ${url}`)

    try {
      let payload = {
        "employee": {
          "id": employee.id
        },
        "sensor_data": data
      }

      const response = await httpClient.post(url, payload);
      return response.data;
    } catch (error) {
      tracer.trace('EntityType', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    } finally {
      tracer.trace('EntityType', 'Api', 'Success')
    }

  }

}

import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import AttachmentIcon from '@mui/icons-material/Attachment';

const SubtaskForm = ({ selectedItem }) => {
  const [name, setName] = useState(selectedItem?.name || '');
  const [isEditingName, setIsEditingName] = useState(false);

  useEffect(() => {
    setName(selectedItem?.name || '');
    setIsEditingName(false);
  }, [selectedItem]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const toggleEditName = () => {
    setIsEditingName(!isEditingName);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box
        sx={{ ml:1, mb: 4, mt:2, fontWeight: 'bold', cursor: 'pointer' }}
        onClick={() => setIsEditingName(true)}
      >
        {isEditingName ? (
          <TextField
            autoFocus
            fullWidth
            variant="outlined"
            value={name}
            onChange={handleNameChange}
            onBlur={toggleEditName}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                toggleEditName();
              }
            }}
          />
        ) : (
          <Typography variant="h5" onClick={toggleEditName}>
            {name || 'Click to add a name'}
          </Typography>
        )}
      </Box>
      <TextField
        fullWidth
        multiline
        placeholder="Add a description..."
        minRows={3}
        variant="outlined"
      />
      <Button
        variant="outlined"
        startIcon={<AttachmentIcon />}
        sx={{ mb: 2, mt: 2 }}
      >
        Add Attachment
      </Button>
    </Box>
  );
};

export default SubtaskForm;

import React, { useState } from "react";
import { Box, CardMedia, Typography } from "@mui/material";
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import { Media } from "../../../../shared/models/media.model";
import { getMediaType } from "../../../../shared/utils/table-modifier-util/tableDataUtil";
import PdfViewer from "../../../../shared/components/media/media-viewers/PdfViewer";
import useAppStore from "../../../../appStore";

const ContractDisplay = ({ itemMedia, readOnly=false }) => {
  const { whiteLabelProps, appImages } = useAppStore();
  const [photoOpen, setPhotoOpen] = useState(false);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const handleOpen = (url) => {
    if (getMediaType(url) === 'pdf') {
      setPdfUrl(url);
      setPdfOpen(true);
    } else {
      setPhotoOpen(true);
    }
  };

  const handleClose = () => {
    setPhotoOpen(false);
  };

  // Filter itemMedia for MSA and SOW types
  const sowMedia = itemMedia.find(media => media.type === 'SOW') || new Media();
  const msaMedia = itemMedia.find(media => media.type === 'MSA') || new Media();

  // Array to display with SOW first and MSA second
  const displayPhotos = readOnly ? [sowMedia] : [sowMedia, msaMedia];

  return (
    <div className="mb-5">
      <Box display="flex">
        {displayPhotos.map((photo, index) => (
          <Box
            key={index}
            width={readOnly ? 315 : 150}
            height={readOnly ? 400 : 200}
            margin={1}
            border={1}
            borderColor={"grey.300"}
            onClick={() => photo.url && handleOpen(photo.url)}
            sx={{
              borderRadius: 2,
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              flexDirection: 'column',
              backgroundColor: '#f9f9f9'
            }}
          >
            {photo.url ? (
              <>
                <CardMedia
                  component="img"
                  image={getMediaType(photo.url) === 'image' ? photo.url : "/white-labeling/verde/media/menu-icons/pdf-icon.png"}
                  alt={`${index + 1}`}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </>
            ) : (
              <CardMedia
                component="img"
                image={appImages.noPdfIcon}
                alt="No data"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            )}
            <Typography
              variant="caption"
              sx={{
                position: 'absolute',
                bottom: 0,
                backgroundColor: whiteLabelProps.primary_color,
                color: 'white',
                width: '100%',
                textAlign: 'center',
                padding: '5px 0',
                fontSize: readOnly ? '1.5rem' : '',
              }}
            >
              {index === 0 ? 'SOW' : 'MSA'}
            </Typography>
          </Box>
        ))}
      </Box>
      {photoOpen && (
        <Lightbox
          open={photoOpen}
          close={() => handleClose()}
          plugins={[Captions]}
          slides={[...itemMedia].map(photo => ({ src: photo.url }))}
        />
      )}
      {pdfOpen && (
        <PdfViewer open={pdfOpen} onClose={() => setPdfOpen(false)} url={pdfUrl} />
      )}
    </div>
  );
};

export default ContractDisplay;

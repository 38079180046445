import { OrgChartModel } from "../orgChartNode.model";
import { BaseSerializer } from "./baseSerializer";

export class OrgChartNodeSerializer extends BaseSerializer {

    public deserialize(data: any): OrgChartModel {
        let node = new OrgChartModel();

        this._copyAttributes(node, data);

        if (Array.isArray(data.children)) {
            let newChildren: OrgChartModel[] = [];
            let newLeaves: OrgChartModel[] = [];

            data.children.forEach((childData: any) => {
                const childNode = this.deserialize(childData);

                if ((childNode.children && childNode.children.length > 0) || (childNode.leaves && childNode.leaves.length > 0)) {
                    newChildren.push(childNode);
                } else {
                    newLeaves.push(childNode);
                }
            });

            node.className = data.node_type || '';
            node.children = newChildren;
            node.leaves = newLeaves;

        }

        if (data.employee && data.employee.id != -1) {
            const locationName = data.employee.works_at && data.employee.works_at.length > 0
              ? data.employee.works_at[0].location.name.replace(/\s+/g, '').toLowerCase()
              : 'verde';

            node.location = locationName;
            node.className += ` ${locationName}`;
        }

        return node;
    }

}



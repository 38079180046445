/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CommentsToolbar} from "./components/CommentsToolbar";
import {CommentsReply} from "./components/CommentsReply";
import {Comment} from "./components/Comment";


const Comments = (props) => {

    const {
        comments,
        onSubmitComment
    } = props

    return (

        <div className='mb-7 flex-column-fluid me-10'>
            {/*<CommentsToolbar comments={comments}/>*/}
            {comments && comments.map((comment) => (
                <Comment key={comment.id}
                         comment={comment}
                />
            ))}
            <div className='separator mb-4'></div>
            <CommentsReply onSubmitComment={onSubmitComment}/>
        </div>
    )

}

export {Comments}

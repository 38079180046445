import { BaseSerializer } from "./baseSerializer";
import { InspectionPointModel } from "../inspectionPoint.model";

export class ReasonSerializer extends BaseSerializer {


  public deserialize( data ): any {

    if( data === undefined ) return;

    let reason = new InspectionPointModel();

    this._copyAttributes(reason, data);

    reason.id = parseInt(data.id)

    return reason;

  }

}
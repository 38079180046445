import { InspectionSerializer } from "../../../models/serializers/inspectionSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";


export class CommandGetInspections {

    INSPECTIONS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {

        return  `${this.INSPECTIONS_SERVICE}/inspections/`;
    }

    async run(provider_id, location_id, offset=0, limit=10, search=null, employee=null, last_x_days=null) {

        const url = this.getUrl()

        const params = {
            params: {
                provider: provider_id,
                location: location_id,
                offset: offset,
                limit: limit,
                employee: employee,
                last_x_days: last_x_days,
            }
        }

        const tracer = useTracer.getState()
        tracer.trace('Inspections', 'Api', `GET ${url}`)

        try {
            const response = await httpClient.get(url, params);
            return this.deserialize(response.data);
        } catch (error) {
            tracer.trace('Inspections', 'Error', `${error}`)
            console.error('Error fetching data:', error);
            throw error;
        }finally {
            tracer.trace('Inspections', 'Api', 'Success')
        }

    }

    deserialize(data) {

        const serializer = new InspectionSerializer()
        const inspections = serializer.deserializeList(data)

        return inspections
    }

}
import React from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { Box, useMediaQuery, useTheme } from "@mui/material";
import useAppStore from "../../../../appStore";

const ComingSoon = () => {
  const {whiteLabelProps} = useAppStore();

  return (
    <Box>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 2,
      }}>
        <CardContent>
          <div style={{ flex: 1, textAlign: "center", padding: "20px", marginTop: "25px" }}>
            <p style={{
              fontSize: "28px",
              fontWeight: "semibold",
              marginBottom: "0px",
              color: whiteLabelProps.primary_color
            }}>Nothing here yet!</p>
            <p style={{ fontSize: "16px", color: "gray" }}> We're working on something exciting and can't wait to share it with you</p>
          </div>
        </CardContent>
      </div>
    </Box>
  );
};

export default ComingSoon;


import { ServiceItem } from "./serviceItem.model";
import { InspectionPointModel } from "./inspectionPoint.model";


export class InspectionServiceItemScore {

    id: null | number;
    uuid: null | string;
    service_item: ServiceItem;
    inspection_points: InspectionPointModel[];
    score: number;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.service_item = new ServiceItem();
        this.inspection_points = [];
        this.score = 2;
    }

}


import { BaseSerializer } from "./baseSerializer";
import { WorkItemAnalytics } from "../workItemAnaytics.model";
import { InspectionAnalytics } from "../inspectionAnalytics.model";

export class InspectionAnalyticsSerializer extends BaseSerializer {


  public deserialize( data ): any {

    const locationInspectionAnalytics = new InspectionAnalytics();

    this._copyAttributes(locationInspectionAnalytics, data);

    return locationInspectionAnalytics;

  }

}
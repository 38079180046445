import { ServiceSerializer } from "../../../models/serializers/serviceSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";


export class CommandGetService {

    SERVICE_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl(service_id) {

        return  `${this.SERVICE_SERVICE}/services/${service_id}`;
    }

    async run(service_id) {

        const url = this.getUrl(service_id)

        const params = { }

        try {
            const response = await httpClient.get(url, params);
            return this.deserialize(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }

    }

    deserialize(data) {

        const serializer = new ServiceSerializer()
        const services = serializer.deserialize(data)

        return services
    }

}
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { WorkItemPrefillSerializer } from "../../../models/serializers/workItemPrefillSerializer";

export class CommandCreateWorkItemPrefill {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/workitems/prefill/`;
  }

  async run(source) {

    const url = this.getUrl()

    const tracer = useTracer.getState()
    tracer.trace('WorkItems', 'Api', `POST ${url}`)

    try {
      let data = WorkItemPrefillSerializer.serialize(source)
      const response = await httpClient.post(url, data);
      return response.data;
    } catch (error) {
      tracer.trace('WorkItems', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    }finally {
      tracer.trace('WorkItems', 'Api', 'Success')
    }

  }

}
import { CommandGetOrgChart } from "./commands/cmdGetOrgChart";
import { CommandDeleteAssignment } from "./commands/cmdDeleteAssignment";
import { CommandCreateAssignment } from "./commands/cmdCreateAssignment";


export class OrgChartService {

  getOrgChartNodes(org_id) {
    const cmd = new CommandGetOrgChart()
    const results = cmd.run(org_id)
    return results
  }

  async deleteAssignment(node_id, employee_id) {
    const cmd = new CommandDeleteAssignment()
    const result = cmd.run(node_id, employee_id)
    return result
  }

  async createAssignment(node_id, employee_id) {
    const cmd = new CommandCreateAssignment()
    const result = cmd.run(node_id, employee_id)
    return result
  }


}
import React, {useState} from 'react'
import EmptyEditPage from '../../shared/components/forms/empty/EmptyEditPage';
import EmptyList from '../../shared/components/forms/empty/EmptyList';
import PageHeader from "../../shared/components/page/page-header/PageHeader";
import DontSetStatePerson from "../../shared/components/examples/DontSetStatePerson";
import SetStatePerson from "../../shared/components/examples/SetStatePerson";
import icons from "../../../_metronic/helpers/icons-config/icons";
import { KTIcon } from "../../../_metronic/helpers";
import OrgChart from "../org-chart/components/OrgChart";


const FloorPlansPage = () => {
    const [items, setItems] = useState<any[]>([]);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [pageTitle, setPageTitle] = useState('New Work Item');
    const [isEditing, setEditing] = useState(false);

    const handlerNewPostButtonClicked = (e) => {
        setItemToEdit(null)
        setEditing(true)
        setPageTitle('New Post')
    }

    const handlerEditItem = (post) => {
        setItemToEdit(post)
        setEditing(true)
        setPageTitle('Edit Post')
    }

    const handlerDeleteItem = async (itemToDelete) => {
        //const service = new PostsService()
        //const response = await service.deletePost(postToDelete.id)
        //setItems(prevPosts => prevPosts.filter(post => post.id !== postToDelete.id));
    }

    const handlerItemEditorAction = (action) => {
        setEditing(false)
    }

    return (
        <>
            {!isEditing && (
                <>
                    <PageHeader title='Floor Plans'
                                buttonName='New Floor Plan'
                                onActionClicked = {handlerNewPostButtonClicked} />

                    <EmptyList listItems={items}
                               listListItems={setItems}
                               onEditItemClicked={handlerEditItem}
                               onDeleteDeleteClicked={handlerDeleteItem} />
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                        {Object.keys(icons).map((iconName) => (
                          <div key={iconName} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <KTIcon iconName={iconName} />
                              <span>{iconName}</span>
                          </div>
                        ))}
                    </div>
                </>
            )}
            {isEditing && (
                <>
                    <PageHeader title={pageTitle}
                                showAction={false} />

                    <EmptyEditPage inputItem = {itemToEdit}
                                   onActionClicked = {handlerItemEditorAction} />
                </>
            )}
        </>
    )


}

export default FloorPlansPage


import { BaseSerializer } from "./baseSerializer";
import { FrequencyModel } from "../frequency.model";

export class FrequencySerializer extends BaseSerializer {


  public deserialize( data ): any {

    const freq = new FrequencyModel();

    this._copyAttributes(freq, data);

    return freq;

  }

}
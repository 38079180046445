import React, { useState } from "react";
import useAppStore from "../../../../../../appStore";
import { TextField, Button, Grid, Typography} from "@mui/material";
import PopoverButton from "../../../../../../shared/components/buttons/PopoverButton";

const LocationDetails = () => {
  const { selectedLocation, whiteLabelProps } = useAppStore();
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h3>Details</h3>
        <PopoverButton message={'Editing location details hasn\'t been implemented yet. Coming soon!'} buttonLabel={'Edit'} />
      </div>
      <div className={"p-5 pe-0"} style={{minHeight: '500px'}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {isEditing ? (
              <TextField
                fullWidth
                label="Location Name"
                variant="outlined"
                value={selectedLocation.name || ""}
                onChange={(e) => console.log("Name change attempted")}
              />
            ) : (
              <Typography variant="body1">Location Name: <strong>{selectedLocation.name || "N/A"}</strong></Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {isEditing ? (
              <TextField
                fullWidth
                label="Location Address"
                variant="outlined"
                value={""}
                onChange={(e) => console.log("Address change attempted")}
              />
            ) : (
              <Typography variant="body1">Location Address: {""}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {isEditing ? (
              <TextField
                fullWidth
                label="Phone Number"
                variant="outlined"
                value={""}
                onChange={(e) => console.log("Phone number change attempted")}
              />
            ) : (
              <Typography variant="body1">Phone Number: {""}</Typography>
            )}
          </Grid>
          {/*<Grid item xs={4}>*/}
          {/*  <LocationPhoto />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={4}>*/}
          {/*  <LocationMap />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={4}>*/}
          {/*  <LocationHours />*/}
          {/*</Grid>*/}
        </Grid>
      </div>
    </>
  );
};

export default LocationDetails;


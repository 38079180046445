
import { InspectionSerializer } from "../../../models/serializers/inspectionSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";


export class CommandGetInspectionDetails {

    INSPECTIONS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl(inspection_id) {

        return  `${this.INSPECTIONS_SERVICE}/inspections/${inspection_id}/`;
    }

    async run(inspection_id) {

        const url = this.getUrl(inspection_id)

        const tracer = useTracer.getState()
        tracer.trace('Inspections', 'Api', `GET ${url}`)

        try {
            const response = await httpClient.get(url);
            return this.deserialize(response.data);
        } catch (error) {
            tracer.trace('Inspections', 'Error', `${error}`)
            console.error('Error fetching data:', error);
            throw error;
        }finally {
            tracer.trace('Inspections', 'Api', 'Success')
        }

    }

    deserialize(data) {

        const serializer = new InspectionSerializer()
        const inspection = serializer.deserialize(data)

        return inspection
    }

}
import { EntityTypeSerializer } from "../../../models/serializers/entityTypeSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";


export class CommandGetEntityTypes{

    ENTITY_TYPE_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {

        return `${this.ENTITY_TYPE_SERVICE}/entity_types/`;
    }

    async run(location_id, kind_of='Place', for_entity=0) {

        const url = this.getUrl()

        const params: { params: { location?: string, kind_of: string, for_entity?: number } } = {
            params: {
                kind_of: kind_of,
            }
        };

        if (location_id) {
            params.params.location = location_id;
        }
        if (for_entity !== 0) {
            params.params.for_entity = for_entity;
        }

        try {
            const response = await httpClient.get(url, params);
            return this.deserialize(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }

    }

    deserialize(data) {
        const serializer = new EntityTypeSerializer()
        const entityTypes = serializer.deserializeList(data)
        return entityTypes
    }

}
// useProjectStore.ts
import { create } from 'zustand'
import { Project } from "./Project";
import { dummyProjects } from "./DummyProjects";

interface ProjectState {
  projects: any[];
  selectedProject: any;
  open: boolean;
  isEditing: boolean;
  setProjects: (projects: any[]) => void;
  setSelectedProject: (project: any) => void;
  setOpen: (open: boolean) => void;
  setEditing: (isEditing: boolean) => void;
}

export const useProjectStore = create<ProjectState>((set) => ({
  projects: [],
  selectedProject: null,
  open: false,
  isEditing: false,
  setProjects: (projects) => set({ projects }),
  setSelectedProject: (project) => set({ selectedProject: project }),
  setOpen: (open) => set({ open }),
  setEditing: (isEditing) => set({ isEditing }),
}));

export const fetchProjects = async (): Promise<Project[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(dummyProjects); // Return dummy data after a delay
    }, 500); // Simulate network delay
  });
};

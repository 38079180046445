import { Work } from "../work.model";
import { LocationSerializer } from "./locationSerializer";
import { PositionSerializer } from "./positionSerializer";
import { BaseSerializer } from "./baseSerializer";

export class WorkSerializer extends BaseSerializer {

  public deserialize(data): Work {
    const work = new Work();
    this._copyAttributes(work, data);

    const locationSerializer = new LocationSerializer();
    work.location =  locationSerializer.deserialize(work.location)

    const positionSerializer = new PositionSerializer();
    work.position = positionSerializer.deserialize(work.position)

    return work;
  }

}

import React from 'react';
import SpaceTree from "./SpaceTree";
import CustomError from "../../../../../../../../shared/components/page/popup-dialog/CustomError";
import useSpaceStore from "../../../../spaceStore";
import CustomLoadingDots from "../../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";

const SpaceHierarchy = ({ setSelectedLevel }) => {
    const {
        spaces,
        setSelectedSpace,
        spacesLoading,
        error
    } = useSpaceStore();

    const handleSpaceSelected = async (selected) => {
        setSelectedSpace(selected)
    };

    if (spacesLoading) {
        return <CustomLoadingDots />;
    }

    if (error) {
        return <CustomError error={error} />;
    }

    return (
      <div>
          <SpaceTree
            data={spaces}
            onItemSelected={handleSpaceSelected}
            setSelectedLevel={setSelectedLevel}
          />
      </div>
    );
};

export default SpaceHierarchy;


import React from "react";
import { Typography } from "@mui/material";

const SlipsModal: React.FC = () => (
  <div>
    <Typography variant="h6">Slips, Trips, & Falls</Typography>
    <Typography variant="body1">Coming Soon</Typography>
  </div>
);

export default SlipsModal;



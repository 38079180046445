import { CourseSerializer } from "../../../models/serializers/courseSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";


export class CommandCreateCourse {

    POSTS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {

        return `${this.POSTS_SERVICE}/training/courses/`;
    }

    async run(course) {

        try {
            const url = this.getUrl()

            const data = CourseSerializer.serialize(course)

            const response = await httpClient.post(url, data);

            return this.deserialize(response.data);
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }

    }

    deserialize(data) {

        const serializer = new CourseSerializer()
        const item = serializer.deserialize(data)

        return item
    }

}
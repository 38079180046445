import { Person } from "./person.model";
import { Post } from "./post.model";
import {Organization} from "./organization.model";


export class Fundraiser {

    id: number;
    organization_id: number;
    name: string;
    description: string;
    start_date: string;
    end_date: string;
    contributions: number;
    progress: number;
    total_followers: number;
    total_contributors: number;
    organization: Organization;
    followers: Person[];
    contributors: Person[];
    posts: Post[];

    constructor() {
        this.id = 0;
        this.organization_id = 0;
        this.name = '';
        this.description = '';
        this.start_date = '';
        this.end_date = '';
        this.contributions = 0;
        this.progress = 0;
        this.total_followers = 0;
        this.total_contributors = 0;
        this.organization = new Organization();
        this.followers = [];
        this.contributors = [];
        this.posts = [];
    }

}


import { Person } from "./person.model";
import { Organization } from "./organization.model";

export class SalesContact {
  id: number;
  organization: Organization;
  company_name: string;
  person: Person;
  relationship_level: number;


  constructor() {
    this.id = 0;
    this.organization = new Organization();
    this.company_name = "";
    this.person = new Person();
    this.relationship_level = 1;
  }

}
import React, { useState, useRef, useEffect } from 'react';
import './Popover.css';
import { Button } from "@mui/material";
import SagePrompter from "../../../modules/sage-virtual-assistant/SagePrompter";

type PopoverProps = {
  message: string;
  buttonLabel: string;
};

const Popover: React.FC<PopoverProps> = ({ message , buttonLabel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  const togglePopover = () => setIsOpen(!isOpen);

  const handleClickOutside = (event: MouseEvent) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);


  return (
    <div className="popover-container">
      <Button variant="contained" color="primary" onClick={togglePopover} style={{ height: "45px" }}>
        {buttonLabel}
      </Button>
      {isOpen && (
        <div className="popover-content" ref={popoverRef}>
          <SagePrompter sageResponse={message} setIsTyping={setIsTyping} />
        </div>
      )}
    </div>
  );
};

export default Popover;

import useTracer from "../../../framework/tracer/tracer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";

export class CommandUnlinkEntityType {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(type_id, location_id) {
    return `${this.SERVICE}/entity_types/${type_id}/locations/${location_id}/`;
  }

  async run(entity_type, location_id) {

    const url = this.getUrl(entity_type.id, location_id);

    const tracer = useTracer.getState()
    tracer.trace('EntityType', 'Api', `DELETE ${url}`)

    try {
      const response = await httpClient.delete(url);
      return response.data;
    } catch (error) {
      tracer.trace('EntityType', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    } finally {
      tracer.trace('EntityType', 'Api', 'Success')
    }
  }

}

import React from 'react';
import {
  Box,
  Typography,
  FormControl,
  Switch,
  FormControlLabel,
  Divider, TextField
} from "@mui/material";


const ServicingPanel = ({ frequency, setFrequency, inService, setInService, jointFreq, setJointFreq }) => {

  const handleInServiceSwitch = (event) => {
    const result = event.target.checked;
    setInService(result);
  };

  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value);
  };

  const handleJointFrequencyChange = (event) => {
    setJointFreq(event.target.value);
  };

  return (
    <Box className="d-flex flex-column mt-6" sx={{ backgroundColor: inService ? '#ffffff' : '#F0F0F0', borderRadius: '5px', justifyContent: 'space-between', border: '1px solid #c7c7c7' }}>
      <Box className="d-flex flex-row" sx={{ justifyContent: 'space-between' }}>
        <Typography sx={{ fontWeight: 'bold', padding: '15px' }}>
          In Service?
        </Typography>
        <FormControlLabel
          control={<Switch checked={inService} onChange={handleInServiceSwitch} name="inServiceSwitch" color="primary" />}
          label=""
        />
      </Box>
      <Divider />
      <Box className="p-4">
        <FormControl fullWidth margin="normal" disabled={!inService}>
          <div style={{ marginBottom: "20px" }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", fontSize: "1.1rem", color: "#3a3b3c" }}>Inspection Frequency</Typography>
            <Box display="flex" alignItems="center" sx={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <Typography variant="body1"
                          sx={{ fontWeight: "bold", fontSize: "1.0rem", color: "#999" }}>Every</Typography>
              <TextField
                value={frequency}
                onChange={handleFrequencyChange}
                type="number"
                inputProps={{ min: 0 }}
                style={{ margin: "0 10px", width: "80px" }}
              />
              <Typography variant="body1"
                          sx={{ fontWeight: "bold", fontSize: "1.0rem", color: "#999" }}>days</Typography>
            </Box>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", fontSize: "1.1rem", color: "#3a3b3c" }}>Joint
              Inspection
              Frequency</Typography>
            <Box display="flex" alignItems="center" sx={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <Typography variant="body1"
                          sx={{ fontWeight: "bold", fontSize: "1.0rem", color: "#999" }}>Every</Typography>
              <TextField
                value={jointFreq}
                onChange={handleJointFrequencyChange}
                type="number"
                inputProps={{ min: 0 }}
                style={{ margin: "0 10px", width: "80px" }}
              />
              <Typography variant="body1"
                          sx={{ fontWeight: "bold", fontSize: "1.0rem", color: "#999" }}>days</Typography>
            </Box>
          </div>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ServicingPanel;

export class WorkItemProgress {
  id: number;
  name: string;
  unassigned: number;
  in_progress: number;
  closed: number;

  constructor() {
    this.id = 0;
    this.name = '';
    this.unassigned = 0;
    this.in_progress = 0;
    this.closed = 0;
  }
}
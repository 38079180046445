import React from "react";
import LaunchProjectsTable from "./components/LaunchProjectsTable";


const LaunchProjectsTab = (props) => {

    const {
        children,
        value,
        index,
        ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
          <LaunchProjectsTable />
    )}
    </div>
);
}

export default LaunchProjectsTab

export class Question {
  question_id: number;
  question: string;
  avg_score: number | null;

  constructor() {
    this.question_id = 0;
    this.question = "";
    this.avg_score = 0.0;
  }

}
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { SurveySummarySerializer } from "../../../models/serializers/surveySummarySerializer";

export class CommandGetSurveySummary {

    INSPECTIONS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {

        return `${this.INSPECTIONS_SERVICE}/analytics/`;
    }

    async run(organization, location_id, reporting_group_entry = 7, interval = '30 days') {

        const url = this.getUrl()

        const params = {
            params: {
                organization: organization.id,
                location: location_id,
                module: 'surveys',
                charts: 'survey_summary',
                interval: interval || '30 days',
                survey_type: 'CSat'
            }
        }

        const tracer = useTracer.getState()

        tracer.trace('Analytics', 'Api', `GET ${url}`)

        try {
            const response = await httpClient.get(url, params);
            return this.deserialize(response.data);
        } catch (error) {
            tracer.trace('Analytics', 'Error', `${error}`)
            console.error('Error fetching survey summary:', error);
            throw error;
        } finally {
            tracer.trace('Analytics', 'Api', 'Success')
        }

    }

    deserialize(data) {

        const serializer = new SurveySummarySerializer()
        const summary = serializer.deserialize(data)

        return summary
    }

}


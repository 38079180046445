import React, {useState, useEffect} from "react";
import { useFormik } from 'formik';
import {Button} from "react-bootstrap";
import { useAuth } from "../../../../auth/core/Auth";
import { Course } from "../../../../../shared/models/course.model";
import { TrainingService } from "../../../../../shared/services/training/trainingService";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";


const EmployeeTrainingPage = (props) => {

    const {
        courseToEdit=null,
        setCourseToEdit,
        courseCategories,
        onActionClicked
    } = props

    const [value, setValue] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const buttonName = courseToEdit == null ? 'Create' : 'Update'

    const {currentUser} = useAuth();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {


        const fetchCourse = async () => {

            /*
            setIsLoading(true);

            try {

                const service = new TrainingService();
                const course = await service.getCourseDetails(courseToEdit.id);

                setCourseToEdit(course);

                await formik.setValues({
                    name: course.name,
                    description: course.description,
                    status: course.status,
                    category_id: course.category_id,
                    html_content: course.html_content,
                });

            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                }
            }finally {
                setIsLoading(false);
            }

             */
        };

        /*
        if (courseToEdit == null){
            setCourseToEdit(new Course)
        }else{
            fetchCourse().then();
        }

         */


    }, []);


    const formik = useFormik({
        initialValues: {
            /*
            name: courseToEdit.name,
            description: courseToEdit.description,
            status: courseToEdit.status,
            category_id: courseToEdit.category_id,
            html_content: courseToEdit.html_content,

             */
        },
        onSubmit: async (values) => {
            /*
                if courseToUpdate is null, create a new Course
                populate the Course fields
                Get the logged in user. set created_by
                Call the Training Service to create the Course.
            */


            /*
            const newCourse = {...courseToEdit};

            courseToEdit.name = values.name
            courseToEdit.description = values.description
            courseToEdit.category_id = values.category_id
            courseToEdit.organization_id = currentUser?.organization.id
            courseToEdit.html_content = values.html_content


            const service = new TrainingService()

            if (courseToEdit == null ) {
                const course: Course = await service.createCourse(courseToEdit)
                onActionClicked('create', course)
            }else{
                const course: Course = await service.updateCourse(courseToEdit)
                onActionClicked('edit', course)
            }
             */



        },
    });


    const createCourseHandler = () => {
        /*
            This is called when the user clicks Create.
            The call formik.submitForm() will result in the onSubmit being called above
            as part of useFormik.
         */

        formik.submitForm().then()

    }


    return (

        <div className='card'>

                <div className="card-body d-flex flex-column">

                    <div className="d-flex flex-row">
                        <div className="symbol symbol-50px me-3">
                            <img src={toAbsoluteUrl("/media/avatars/300-2.jpg")} className="" alt=""/>
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                            <a href="../../demo1/dist/pages/user-profile/overview.html"
                               className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">Jane Cooper</a>
                            <span className="text-gray-400 fw-semibold d-block fs-7">Floor Tech</span>
                        </div>
                    </div>
                    <div className="d-flex flex-row">

                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={'mt-5'}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="In Progress (8)" {...a11yProps(0)} />
                                <Tab label="Complete" {...a11yProps(1)} />
                            </Tabs>
                        </Box>

                        {/* Main Body Start */}
                        <div className="d-flex flex-column flex-row-fluid">


                        </div>
                        {/* Main Body End */}

                    </div>

                </div>




                {/* Buttons Start */}
                <div className="d-flex justify-content-end my-5 mx-10">
                    <Button variant="primary" className='btn btn-sm fw-bold me-3 mt-2' onClick={() => createCourseHandler()}>{buttonName}</Button>
                    {/*<Button variant="primary" className='btn btn-sm fw-bold me-3 mt-2' onClick={() => saveDraftHandler()}>Save as Draft</Button> */}
                    <Button variant="secondary" className='btn btn-sm fw-bold mt-2' onClick={() => onActionClicked('close')} >Cancel</Button>
                </div>
                {/* Buttons End */}
        </div>

    )

}


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default EmployeeTrainingPage

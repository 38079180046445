import React from "react";
import PageHeader from "../../shared/components/page/page-header/PageHeader";
import OrgChart from "./components/OrgChart";

const OrgChartPage = () => {

    return (
      <>
        <PageHeader
          title="Org Chart"
          showAction={false}
        />
        <OrgChart />
      </>
    );

}

export default OrgChartPage

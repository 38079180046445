import React, {useState, useEffect} from "react";
import "yet-another-react-lightbox/styles.css";
import clsx from "clsx";
import {KTIcon, toAbsoluteUrl} from "../../../../../../../../_metronic/helpers";
import {Dropdown1} from "../../../../../../../../_metronic/partials";
import Box from "@mui/material/Box/Box";
import IconButton from "@mui/material/IconButton/IconButton";
import AddIcon from '@mui/icons-material/Add';

const FundraiserHeader = (props) => {

    const {
        fundraiser,
        image
    } = props

    const [index, setIndex] = useState(-1);

    return (

        <Box
            sx={{
                width: '100%', // adjust these dimensions as needed
                height: 300,
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                position: 'relative', // so the icon button is positioned relative to this box
                borderRadius: '5px'
            }}
        >
            {/*
            <IconButton
                aria-label="delete"
                sx={{
                    position: 'absolute',
                    top: 0, // adjust these values as needed
                    right: 0
                }}
            >
                <AddIcon />
            </IconButton>
            */}
        </Box>
    )
}

export {FundraiserHeader}


/*


    return (
       <>

<div className='d-flex flex-stack'>

    <div className='d-flex align-items-center'>

        <div className='symbol symbol-60px me-5'>
              <span className={clsx(`symbol-label`, `bg-danger-light`)}>
                <img src={toAbsoluteUrl('/media/svg/brand-logos/plurk.svg')} className='h-50 align-self-center' alt='' />
              </span>
        </div>



        <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
            <a href='#' className='text-dark fw-bold text-hover-primary fs-5'>
                Monthly Subscription
            </a>

            <span className='text-muted fw-semibold'>Due: 27 Apr 2020</span>
        </div>

    </div>



    <div className='ms-1'>
        <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
        >
            <KTIcon iconName='category' className='fs-2' />
        </button>
        <Dropdown1 />
    </div>

</div>

</>
)

 */

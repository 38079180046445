import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import { Avatar } from "@mui/material";
import React from "react";
import useAppStore from "../../../../app/appStore";
import { useAuth } from "../../../../app/modules/auth";

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

/*
    This is the toolbar in the header with the Account, Theme Selection, Search, Chat , etc.
    Several items are hidden for now.
 */
const Navbar = () => {
  const {config} = useLayout()
  const {whiteLabelProps} = useAppStore();
  const {currentUser} = useAuth()
  return (
    <div className="d-flex app-navbar me-5">

        {/*
            Hiding items for now.
        */}

      <div className={clsx('app-navbar-item align-items-stretch', itemClass)} style={{ display: 'none' }}>
        <Search />
      </div>

      <div className={clsx('app-navbar-item', itemClass)} style={{ display: 'none' }}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)} style={{ display: 'none' }}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div>

      <div className={clsx('app-navbar-item', itemClass)} style={{ display: 'none' }}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div>

      {/*<div className={clsx('app-navbar-item', itemClass)} style={{ display: 'default' }}>*/}
      {/*  <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />*/}
      {/*</div>*/}

      <div className={clsx('app-navbar-item', itemClass)}
          style={{paddingLeft: '10px'}}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <Avatar sx={{ bgcolor: whiteLabelProps.primary_color_light, width: 35, height: 35 }}
                  variant="rounded"
                  src={currentUser?.photo_url}
                  alt={`${currentUser?.first_name} ${currentUser?.last_name}`}
          />
        </div>
        <HeaderUserMenu />
      </div>

      {/* This is the InnerMenu that somehow gets moved copied here when the screen is small. */}
      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}

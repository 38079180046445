import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";

export class CommandGetPositions {

  POSITION_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.POSITION_SERVICE}/positions/`;
  }

  async run(provider_id, offset = 0, limit = 10) {
    const url = this.getUrl();

    const params = {
      params: {
        provider: provider_id,
        offset: offset,
        limit: limit
      }
    };

    const tracer = useTracer.getState();
    tracer.trace('Positions', 'Api', `GET ${url}`);

    try {
      const response = await httpClient.get(url, params);
      return response.data;
    } catch (error) {
      tracer.trace('Positions', 'Error', `${error}`);
      console.error('Error fetching positions:', error);
      throw error;
    } finally {
      tracer.trace('Positions', 'Api', 'Success');
    }
  }
}

import React from 'react';

const CustomError = ({ error }) => {

  return (
    <div style={{
      color: '#D8000C', // Red color for the error text
      backgroundColor: '#FFD2D2', // Light red/pink background for better visibility
      padding: '10px 20px', // Some padding to make it look nicer
      borderRadius: '5px', // Rounded corners for a softer look
      border: '1px solid #D8000C', // A red border to highlight the error box
      margin: '20px 0', // Add some margin to separate it from other content
      textAlign: 'center', // Center the text to make it more noticeable
      width: '100%', // Making the error box take up 80% of its parent container width
      boxSizing: 'border-box', // Ensures padding doesn't add to the total width
    }}>
      <strong>Error:</strong> {error}
    </div>
  );
};

export default CustomError;

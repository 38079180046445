import { LoggedInUser } from "../loggedInUser.model";
import { BaseSerializer } from "./baseSerializer";
import { EmployeeSerializer } from "./employeeSerializer";
import { PermissionsSerializer } from "./permissionsSerializer";
import { OrganizationSerializer } from "./organizationSerializer";

export class UserSerializer extends BaseSerializer {
    public deserialize(data): LoggedInUser {
        const user = new LoggedInUser();

        this._copyAttributes(user, data);

        if (data.employee) {
            let employeeSerializer = new EmployeeSerializer();
            user.employee = employeeSerializer.deserialize(data.employee);
        }

        if (data.permissions) {
            let permissionsSerializer = new PermissionsSerializer();
            user.permissions = permissionsSerializer.deserialize(data.permissions);
        }

        if (data.organization) {
            let organizationSerializer = new OrganizationSerializer();
            user.organization = organizationSerializer.deserialize(data.organization);
        }

        return user;
    }
}

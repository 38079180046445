import React from "react";
import { Box, Divider } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AttachFile } from "@mui/icons-material";
import MediaRowSquares from "../../../../../../shared/components/media/media-viewers/photos/MediaRowSquares";

const CaseReadOnly = (props) => {
  const { caseToEdit, headers=true } = props;

  const isOverdue = caseToEdit.is_overdue;
  const timeOverdue = caseToEdit.time_overdue;
  const timeRemaining = caseToEdit.time_remaining;
  const notes = caseToEdit.description;
  const status = caseToEdit.status;

  return (
    <div className="flex-column-fluid me-10">
      <div style={{ marginBottom: "15px" }}>
        {headers &&
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-700">Details</span>
          </h3>
        }
      </div>
      <div className="mb-3">
        <Box className="d-flex flex-row mt-8 w-100">
          <div className="d-flex flex-row align-items-start">
            <div className="d-flex" style={{ width: "150px" }}>
              <div className="symbol symbol-35px me-4">
                        <span className="symbol-label bg-light-primary">
                                  <i className="fa-solid fa-bars-progress" style={{ color: "gray" }}></i>
                        </span>
              </div>
              <div className="d-flex justify-content-start flex-column">
                <span className="text-gray-400 fw-semibold d-block fs-7">Status</span>
                <a href="src/app/modules/work-items/components/edit-work-item/components/properties/Properties#"
                   className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{status || 'Created'}</a>
              </div>
            </div>
            <div className="d-flex" style={{ width: "150px" }}>
              <div className="symbol symbol-35px me-4">
                        <span className="symbol-label bg-light-danger">
                                    <AccessTimeIcon sx={{ color: "gray" }} />
                        </span>
              </div>
              <div className="d-flex justify-content-start flex-column">
                <span className="text-gray-400 fw-semibold d-block fs-7">Urgency</span>
                <a href="src/app/modules/work-items/components/edit-work-item/components/properties/Properties#"
                   className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                  {isOverdue ? timeOverdue + " Overdue" : (timeRemaining === 0 ? "Just created" : timeRemaining + " Remaining")}
                </a>
              </div>
            </div>
          </div>
        </Box>
      </div>

      <Divider sx={{ marginBottom: "15px", width: "100%" }} />

      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Case Type:</span>
        <span className="fs-7">{caseToEdit.case_type?.name || '--'}</span>
      </div>
      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Department:</span>
        <span className="fs-7">{caseToEdit.department?.name || '--'}</span>
      </div>
      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Case Number:</span>
        <span className="fs-7">{caseToEdit.case_number || '--'}</span>
      </div>
      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Related To:</span>
        {caseToEdit.related_to_employees?.length > 0 ? (
          caseToEdit.related_to_employees
            .map(employee => employee?.person.first_name + " " + employee?.person.last_name)
            .join(", ")
        ) : (
          "--"
        )}
      </div>

      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Assigned Employees:</span>
        {caseToEdit.assigned_to_employees?.length > 0 ? (
          caseToEdit.assigned_to_employees
            .map(employee => employee?.person.first_name + " " + employee?.person.last_name)
            .join(", ")
        ) : (
          "--"
        )}
      </div>

      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Description:</span>
        <span className="fs-7">
          {notes || '--'}
        </span>
      </div>
      <div className="d-flex mt-10">
        <AttachFile sx={{ color: "gray", fontSize: '45px' }} />
        <div className="d-flex justify-content-start flex-column">
          <MediaRowSquares itemMedia={caseToEdit.media} captions={caseToEdit.status === 'Closed'} />
        </div>
      </div>


    </div>
  );
};

export default CaseReadOnly;

export class WorkItemAge {
  name: string;
  id: number;
  overdue_count: number;
  high_count: number;
  medium_count: number;

  constructor() {
    this.name = '';
    this.id = 0;
    this.overdue_count = 0;
    this.high_count = 0;
    this.medium_count = 0;

  }
}
import { Post } from "../post.model";
import { BaseSerializer } from "./baseSerializer";
import {UserSerializer} from "./userSerializer";
import {CommentSerializer} from "./commentSerializer";
import { MediaSerializer } from "./mediaSerializer";
import { EmployeeSerializer } from "./employeeSerializer";

export class PostSerializer extends BaseSerializer {

    public deserialize( data ): any {

        let post = new Post();

        this._copyAttributes(post, data);

        let serializer = new EmployeeSerializer();

        const employee = serializer.deserialize(data.created_by);
        post.created_by = employee;

        serializer = new MediaSerializer();
        const media = serializer.deserializeList(data.media);
        post.media = media;

        serializer = new CommentSerializer();
        const comments = serializer.deserializeList(data.comments);
        post.comments = comments;

        return post;

    }

}
import { BaseSerializer } from "./baseSerializer";
import { MediaSerializer } from "./mediaSerializer";
import { InspectedEntity } from "../inspectedEntity.model";
import { EntityTypeSerializer } from "./entityTypeSerializer";
import { EntitySerializer } from "./entitySerializer";
import { InspectionOnlySerializer } from "./inspectionOnlySerializer";
import { ServiceItemScoreSerializer } from "./serviceItemScoreSerializer";

export class InspectedEntitySerializer extends BaseSerializer {

    public deserialize( data ): any {

        let entity = new InspectedEntity();

        this._copyAttributes(entity, data);

        let serializer = new EntityTypeSerializer();

        const etype = serializer.deserialize(data.entity_type);
        entity.entity_type = etype;

        serializer = new EntitySerializer();
        const path = serializer.deserializeList(data.entity_path);
        entity.entity_path = path;

        serializer = new MediaSerializer();
        const media = serializer.deserializeList(data.media);
        entity.media = media;

        serializer = new ServiceItemScoreSerializer();
        const scores = serializer.deserializeList(data.service_item_scores);
        entity.service_item_scores = scores;

        serializer = new InspectionOnlySerializer();
        const inspection = serializer.deserialize(data.inspection)
        entity.inspection = inspection;





        return entity;

    }

}
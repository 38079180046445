import { BaseSerializer } from "./baseSerializer";
import {PersonSerializer} from "./personSerializer";
import { Inspection } from "../inspection.model";
import { LocationSerializer } from "./locationSerializer";

export class InspectionOnlySerializer extends BaseSerializer {

    public deserialize( data ): any {

        if (data === undefined) return null;

        let inspection = new Inspection();

        this._copyAttributes(inspection, data);

        let serializer = new PersonSerializer();
        const person = serializer.deserialize(data.inspected_by);
        inspection.inspected_by = person;

        serializer = new LocationSerializer();
        const location = serializer.deserialize(data.location);
        inspection.location = location;

        return inspection;

    }

}
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { ServiceLogAreaSerializer } from "../../../models/serializers/serviceLogAreaSerializer";


export class CommandGetServiceLogAreas {

  ENTITY_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return  `${this.ENTITY_SERVICE}/service_logs/`;
  }

  async run(location_id) {

    const url = this.getUrl()

    const params = {
      params: {
        location: location_id,
      }
    }

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }

  }

  deserialize(data) {

    const serializer = new ServiceLogAreaSerializer()
    const log = serializer.deserializeList(data)

    return log
  }

}
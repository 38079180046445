import React, { useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import useTrainingStore from "./trainingStore";
import useAppStore from "../../../appStore";
import { TrainingCategory } from "../../../shared/models/training-category.model";
import PageHeader from "../../../shared/components/page/page-header/PageHeader";
import TrainingCatalogTreeViewer from "./components/catalog-tree-viewer/TrainingCatalogTreeViewer";
import EditCoursePage from "./components/edit-course/EditCourse";

const TrainingCatalogPage = () => {

    const { selectedCategory, setSelectedCategory, fetchCategories, trainingCategories, setCourseToEdit, isEditingCourse, setIsEditingCourse } = useTrainingStore();
    const { loggedInUser } = useAppStore();
    const smallScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const [value, setValue] = React.useState(0);
    const [refreshCatalog, setRefreshCatalog] = useState(false);


    useEffect(() => {
        fetchCategories(loggedInUser.organization?.id);
        setSelectedCategory(null);
    }, [refreshCatalog]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function handleAddCategory() {
        let newCat = new TrainingCategory();
        setSelectedCategory(newCat);
    }

    function handleEditCourse(course=null) {
        setCourseToEdit(course);
        setIsEditingCourse(true);
    }

    function handleCloseModal() {
        setIsEditingCourse(false);
        setRefreshCatalog(!refreshCatalog);
    }

    async function handleSaveCourse() {

    }

    return (
      <>
          {!smallScreen ? (
            <>
                <PageHeader title="Training Catalog"
                            showAction={false}
                />
                <TrainingCatalogTreeViewer
                  selectedCategory={selectedCategory}
                  handleAddCategory={handleAddCategory}
                  handleEditCourse={handleEditCourse}
                  handleChange={handleChange}
                />
                {isEditingCourse && (
                      <EditCoursePage
                        open={isEditingCourse}
                        onClose={handleCloseModal}
                        onSave={handleSaveCourse}
                        onActionClicked={handleCloseModal} />
                )}
            </>
          ) : (
            <div style={{ textAlign: "center", margin: "20px", padding: "20px" }}>
                <p style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "15px" }}>
                    This page is currently not compatible with small screen sizes.
                </p>
                <p>Try viewing it on a different device.</p>
            </div>
          )}
      </>
    );

};


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

export default TrainingCatalogPage;

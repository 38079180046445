import React, { useState } from "react";
import SpaceTypesRow from "./SpaceTypesRow";

const SpaceTypesTable = (props) => {
  const {
    spaceTypes,
    onSelect
  } = props

  const [selectedId, setSelectedId] = useState(null);

  const handleSelect = (space) => {
    setSelectedId(space.id);
    onSelect(space);
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
          <tbody>
          {spaceTypes.map((space) => (
            <SpaceTypesRow
              key={space.id}
              space={space}
              onSelect={handleSelect}
              isSelected={selectedId === space.id}
            />
          ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default SpaceTypesTable;


import React, {useState} from 'react'
import EmptyEditPage from '../../../shared/components/forms/empty/EmptyEditPage';
import EmptyList from '../../../shared/components/forms/empty/EmptyList';
import PageHeader from '../../../shared/components/page/page-header/PageHeader';
import TraineeList from './components/employee-list/TraineeList';
import EmployeeTrainingPage from './components/employee-training-view/EmployeeTrainingPage';


const TeamTrainingPage = () => {

    // The spaces-relationship-card-list of Posts retrieved from the Server
    const [items, setItems] = useState<any[]>([]);

    // The Post being edited.
    const [itemToEdit, setItemToEdit] = useState(null);
    const [pageTitle, setPageTitle] = useState('New Work Item');

    // Controls the display of the Edit form.
    const [isEditing, setEditing] = useState(false);


    //  Called when the User clicks the New Post button.
    const handlerNewPostButtonClicked = (e) => {
        setItemToEdit(null)
        setEditing(true)
        setPageTitle('New Post')
    }

    //  Called when the user Clicks the Edit Post action on a Post in the spaces-relationship-card-list.
    const handleEditItem = (post) => {
        setItemToEdit(post)
        setEditing(true)
        setPageTitle('Employee Training')
    }

    // Called when the user Clicks the Delete Post action on a Post in the spaces-relationship-card-list.
    const handlerDeleteItem = async (itemToDelete) => {
        //const service = new PostsService()
        //const response = await service.deletePost(postToDelete.id)
        //setItems(prevPosts => prevPosts.filter(post => post.id !== postToDelete.id));

    }

    //  Called from the Edit Post form when the user takes a final action {Create, Save as Draft, Cancel}
    const handlerItemEditorAction = (action) => {
        setEditing(false)
    }

    return (
        <>
            {!isEditing && (
                <>
                    <PageHeader title='Team Training'
                                buttonName=''
                                onActionClicked = {handlerNewPostButtonClicked}
                                showAction={false}
                    />

                    <TraineeList  onEditItem={handleEditItem}/>
                </>
            )}
            {isEditing && (
                <>
                    <PageHeader title={pageTitle}
                                showAction={false} />

                    <EmployeeTrainingPage inputItem = {itemToEdit}
                                   onActionClicked = {handlerItemEditorAction} />
                </>
            )}
        </>
    )

}

export default TeamTrainingPage

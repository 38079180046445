import { Activity } from "./activityModel";

export class InspectionAnalytics {
    inspection_activity_by_location: Activity[];
    inspection_activity_by_employee: Activity[];


    constructor() {
        this.inspection_activity_by_location = [];
        this.inspection_activity_by_employee = [];
    }

}


import React, { useState } from "react";
import SiteManagerDashboard from "./site-manager-dashboard/SiteManagerDashboard";
import EmployeeDashboard from "./employee-dashboard/EmployeeDashboard";
import useAppStore from "../../appStore";
import ClientDashboard from "./client-dashboard/ClientDashboard";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Divider, useMediaQuery, useTheme } from "@mui/material";

const DashboardPage = () => {
  const { loggedInUser } = useAppStore();
  const managerMode = loggedInUser?.permissions?.hasAccess("Manager Home") || false;
  const dashSelector = loggedInUser?.permissions?.hasAccess("Dash Selector") || false;
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const [selectedOption, setSelectedOption] = useState(managerMode ? "Manager" : "Employee");


  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  let DashboardComponent;
  if (selectedOption === 'Client') {
    DashboardComponent = ClientDashboard;
  } else if (selectedOption === 'Manager') {
    DashboardComponent = SiteManagerDashboard;
  } else {
    DashboardComponent = EmployeeDashboard;
  }

  return (
    <div style={{ width: isMobile ? "100%" : '50%', maxWidth: '500px' }}>
      {dashSelector &&
        <div>
          <ToggleButtonGroup
            value={selectedOption}
            exclusive
            onChange={handleOptionChange}
            aria-label="stepSelection"
          >
            <ToggleButton value="Employee">Employee</ToggleButton>
            <ToggleButton value="Client">Client</ToggleButton>
            <ToggleButton value="Manager">Manager</ToggleButton>
          </ToggleButtonGroup>
          <Divider sx={{ backgroundColor: 'black', mt: 2, mb: 2 }} />
        </div>
      }


      <DashboardComponent />
    </div>
  );
};

export default DashboardPage;






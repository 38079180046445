import React from "react";
import SubHeader from "../../../../shared/components/page/page-header/SubHeader";
import InspectionDesktopCard from "./components/card/InspectionDesktopCard";
import { useMediaQuery, useTheme } from "@mui/material";
import InspectionMobileCard from "./components/card/InspectionMobileCard";
import { useWorkItem } from "../../../work-items/context/workItemContext";
import { WorkItem } from "../../../../shared/models/workItem.model";
import EditWorkItem from "../../../work-items/components/edit-work-item/EditWorkItem";

const InspectionsCardList = ({ inspections, title = 'Recent Inspections' }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  const {
    workItemToEdit, setWorkItemToEdit,
    setEditing, open, setOpen,
    setSnackbarOpen
  } = useWorkItem();

  const handlerWorkItemEditorAction = (action) => {
    setEditing(false);
    setOpen(false);
    setWorkItemToEdit(new WorkItem());
    if (action === 'save') {
      setSnackbarOpen(true);
    }
  }

  return (
    <>
      <div style={{ marginTop: '25px', marginBottom: '10px' }}>
        <SubHeader title={title} showAction={false} />
      </div>
      {inspections.length === 0 ? (
        <div style={{ marginTop: '25px', marginBottom: '10px' }}>Nothing to show</div>
      ) : (
        inspections.map((inspection) => (
          isMobile ?
            <InspectionMobileCard key={inspection.id} inspection={inspection} />
            :
            <InspectionDesktopCard key={inspection.id} inspection={inspection} />
        ))
      )}
      {open && workItemToEdit &&
        <EditWorkItem
          open={open}
          onActionClicked={handlerWorkItemEditorAction}
        />
      }
    </>
  );
};

export default InspectionsCardList;


import useAppStore from "../../../appStore";
import { CasesService } from "../../../shared/services/cases/casesService";
import React, { useEffect, useState } from "react";
import { GroupEntry } from "../../../shared/models/groupEntry.model";
import { Media } from "../../../shared/models/media.model";
import { Box, Divider, FormControl, TextField } from "@mui/material";
import PhotoUploaderViewer from "../../../shared/components/media/media-viewers/photos/PhotoUploaderViewer";
import Button from "@mui/material/Button";
import MediaDisplay from "../../../shared/components/media/media-viewers/photos/MediaDisplay";
import SageEmployeeSearch from "./SageEmployeeSearch";
import UserItemsTable from "../created-items-assist/UserItemsTable";

const AssistedCaseCreation = (props) => {
  const {
    caseToEdit,
    setCaseToEdit,
    step, setStep,
    setIsTyping
  } = props;

  const { loggedInUser, selectedLocation } = useAppStore();
  const service = new CasesService();
  const [departments, setDepartments] = useState<GroupEntry[]>([]);
  const [caseTypes, setCaseTypes] = useState<GroupEntry[]>([]);
  const [media, setMedia] = useState<Media[]>(caseToEdit.media);

  useEffect(() => {

    if (caseToEdit.id === null || caseToEdit.id === 0) {
      setCaseToEdit(prevWorkItem => ({
        ...prevWorkItem,
        created_by: loggedInUser.employee,
        location: {
          ...prevWorkItem.location,
          id: selectedLocation.id || 0
        },
        provider: {
          ...prevWorkItem.provider,
          id: loggedInUser.organization?.id || 0
        }
      }));
    }

  }, [caseToEdit.id]);

  useEffect(() => {
    setCaseToEdit(prev => {
      const updated = {
        ...prev,
        media: media
      };
      return updated;
    });

  }, [media]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const depts = await service.getDepartments(loggedInUser?.organization);
        setDepartments(depts);
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    const fetchTypes = async (dept) => {
      try {
        const types = await service.getCaseTypes(loggedInUser?.organization, dept);
        setCaseTypes(types);
      } catch (error) {
        console.error('Error fetching:', error);
      }
    }

    if (caseToEdit.department?.id !== 0) {
      fetchTypes(caseToEdit.department.id)
    }

    fetchDepartments();

  }, [loggedInUser?.organization?.id, caseToEdit.department]);

  const handleDescChange = (event) => {
    setCaseToEdit(prev => ({ ...prev, description: event.target.value }));
  };

  const handleDepartmentChange = (dept) => {
    setCaseToEdit(prev => ({ ...prev, department: dept }));
    handleNextStep();
  };

  const handleCaseTypeChange = (type) => {
    setCaseToEdit(prev => ({ ...prev, case_type: type }));
    handleNextStep();
  };

  const handleNextStep = () => {
    setIsTyping(true);
    setStep(step + 1);
  }

  return (
    <div className="flex-column-fluid">
      {step === 0 && (
        <FormControl fullWidth style={{ marginBottom: 15 }}>
          <Box>
            {departments.map((dept) => (
              <Button
                key={dept.id}
                onClick={() => handleDepartmentChange(dept)}
                fullWidth
                style={{ marginBottom: 10, fontSize: '1.1rem' }}
              >
                {dept.name}
              </Button>
            ))}
          </Box>
        </FormControl>
      )}
      {step === 1 && (
        <FormControl fullWidth style={{ marginBottom: 15 }}>
          <Box>
            {caseTypes.map((type) => (
              <Button
                key={type.id}
                onClick={() => handleCaseTypeChange(type)}
                fullWidth
                style={{ marginBottom: 10, fontSize: '1.1rem' }}
              >
                {type.name}
              </Button>
            ))}
          </Box>
        </FormControl>
      )}

      {step === 2 &&
        <div style={{ marginTop: 15 }}>
          <SageEmployeeSearch itemToEdit={caseToEdit} onSubmit={handleNextStep} />
        </div>
      }

      {step === 3 &&
        <div style={{ marginTop: 15, marginBottom: 25 }}>
          <TextField
            name="Description"
            label={'Details'}
            value={caseToEdit.description}
            onChange={handleDescChange}
            fullWidth
            multiline
            sx={{background: 'white'}}
            rows={6} />
        </div>
      }

      {step === 4 &&
        <div style={{ marginTop: 20 }}>
          <PhotoUploaderViewer
            item={caseToEdit}
            setMedia={setMedia}
            captions={caseToEdit.status === 'Closed'}
            module={'cases'}
          />
        </div>
      }

      {step === 5 &&
        <>
          <Divider sx={{ marginBottom: "15px", width: "100%" }} />
          <div className="mb-3">
            <span className="text-gray-400 fw-semibold d-block fs-7">Case Type:</span>
            <span className="fs-7">{caseToEdit.case_type?.name || "No case type"}</span>
          </div>
          <div className="mb-3">
            <span className="text-gray-400 fw-semibold d-block fs-7">Department:</span>
            <span className="fs-7">{caseToEdit.department?.name || "No department"}</span>
          </div>
          <div className="mb-3">
            <span className="text-gray-400 fw-semibold d-block fs-7">Details:</span>
            <span className="fs-7">{caseToEdit.description || "No details given"}</span>
          </div>
          <div className="mb-3">
            <span className="text-gray-400 fw-semibold d-block fs-7">Related to:</span>
            {caseToEdit.related_to_employees
              .map(employee => employee?.person.first_name + " " + employee?.person.last_name)
              .join(", ")}
          </div>
          <div className="mb-3">
            <MediaDisplay itemMedia={caseToEdit.media} captions={caseToEdit.status === "Closed"} module={"cases"} />
          </div>
        </>
      }

      {step === 6 &&
        <UserItemsTable items={[caseToEdit]} type={'case'} details={false} />
      }

    </div>
  );
};

export default AssistedCaseCreation;


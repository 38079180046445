import React, { useEffect, useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import { Entity } from "../../../../../../../shared/models/entity.model";
import { ValidationDialog } from "../../../../../../../shared/components/page/popup-dialog/ValidationDialog";
import ParentSelect from "./ParentSelect";
import useAppStore from "../../../../../../../appStore";
import TypeAndSubTypes from "./TypeAndSubTypes";
import { EntityType } from "../../../../../../../shared/models/entityType.model";
import useSpaceStore from "../../../spaceStore";
import ServicingPanel from "./ServicingPanel";
import LogPanel from "./LogPanel";


const SpaceDetailsEdit = ({ onSave, spaceToEdit, setSpaceToEdit }) => {
  const {selectedSpace} = useSpaceStore();
  const [name, setName] = useState(spaceToEdit.name);
  const [shortName, setShortName] = useState(spaceToEdit.short_name);
  const [description, setDescription] = useState(spaceToEdit.description);
  const [parent, setParent] = useState<Entity | null>(spaceToEdit.id ? spaceToEdit.parent : selectedSpace);
  const [spaceType, setSpaceType] = useState<EntityType | null>(spaceToEdit.entity_type);
  const [subTypes, setSubTypes] = useState<EntityType[]>(spaceToEdit.entity_types_to_service);
  const [inService, setInService] = useState(spaceToEdit.in_service);
  const [frequency, setFrequency] = useState(spaceToEdit.inspection_frequency);
  const [jointFrequency, setJointFrequency]  = useState(spaceToEdit.joint_inspection_frequency);
  const [hasLog, setHasLog] = React.useState(spaceToEdit.has_service_log);
  const [logFrequency, setLogFrequency] = React.useState(spaceToEdit.service_log_scan_frequency);

  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const {loggedInUser, selectedLocation} = useAppStore()


  const handleSave = () => {
    const updatedSpace = {
      ...spaceToEdit,
      name: name,
      short_name: shortName,
      description: description,
      parent: parent,
      location: selectedLocation,
      organization: loggedInUser?.organization,
      entity_type: spaceType,
      in_service: inService,
      inspection_frequency: frequency,
      kind_of: 'Place',
      entity_types_to_service: subTypes,
      joint_inspection_frequency: jointFrequency,
      has_service_log: hasLog,
      service_log_scan_frequency: logFrequency,
    };

    if (!name.trim()) {
      setShowErrorDialog(true);
      return;
    }

    onSave(updatedSpace);
  };



  return (
    <div>
      <div style={{marginBottom: '50px'}}>

        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#3a3b3c' }}>Space Type</Typography>
        <TypeAndSubTypes spaceType={spaceType} setSpaceType={setSpaceType} subTypes={subTypes} setSubTypes={setSubTypes}/>

        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#3a3b3c' }}>Name</Typography>
        <TextField value={name} onChange={(e) => setName(e.target.value)} fullWidth sx={{ marginBottom: "20px" }} />

        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#3a3b3c' }}>Short Name</Typography>
        <TextField value={shortName} onChange={(e) => setShortName(e.target.value)} fullWidth sx={{ marginBottom: "20px" }} />

        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#3a3b3c' }}>Parent</Typography>
        <ParentSelect parent={parent} setParent={setParent} />

        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#3a3b3c', marginTop: '20px' }}>Description</Typography>
        <TextField value={description} onChange={(e) => setDescription(e.target.value)} fullWidth multiline rows={3} sx={{ marginBottom: "20px" }} />

        <div className="d-flex flex-row">
          <div className='w-50 pe-5'>
            <ServicingPanel inService={inService} setInService={setInService} frequency={frequency} setFrequency={setFrequency} jointFreq={jointFrequency} setJointFreq={setJointFrequency} />
          </div>
          <div className='w-50 ps-5'>
            <LogPanel hasLog={hasLog} setHasLog={setHasLog} scanFreq={logFrequency} setScanFreq={setLogFrequency} />
          </div>
        </div>


      </div>

      <ValidationDialog open={showErrorDialog} onClose={() => setShowErrorDialog(false)} title="Missing Information"
                        description="All spaces require a name" />


        <div className="button-container"
             style={{
               position: 'fixed',
               bottom: 0,
               left: 0,
               right: 0,
               zIndex: 1000,
               width: '100%'
             }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ width: "100%", height: '50px', borderRadius: 0, borderTop: '1px solid #e7e7e7' }}
          >
            {'SAVE'}
          </Button>
        </div>


    </div>
  );
};

export default SpaceDetailsEdit;


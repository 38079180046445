import React from 'react';
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import useAppStore from "../../../../appStore";

const GreetingDashboardCard: React.FC = () => {
  const { loggedInUser } = useAppStore();
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour >= 6 && hour < 12) {
      return 'Good Morning';
    } else if (hour >= 12 && hour < 16) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };

  const getFormattedDate = () => {
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return new Date().toLocaleDateString('en-US', options);
  };

  const greeting = getGreeting();
  const today = getFormattedDate();

  return (

      <div>
        <Typography variant="h6" component="p" style={{ fontWeight: 'bold', fontSize: isMobile ? '1.1rem' : '1.4rem', margin: 0 }}>
          {greeting}, {loggedInUser?.first_name}!
        </Typography>
        <Typography variant="h6" component="p" style={{ fontWeight: 'bold', margin: '5px 0', fontSize: isMobile ? '1.1rem' : '1.4rem' }}>
          Today is {today}
        </Typography>
        <Typography variant="h6" component="p" style={{ fontSize: isMobile ? '0.9rem' : '1.2rem', margin: 0 }}>
          Have a great day, the Verde way
        </Typography>
      </div>
  );
};

export default GreetingDashboardCard;




import { ServiceSerializer } from "../../../models/serializers/serviceSerializer";
import { TrainingCategorySerializer } from "../../../models/serializers/trainingCategorySerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";


export class CommandGetTrainingCategoryHierarchy {

    TRAINING_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {

        return  `${this.TRAINING_SERVICE}/training/categories/`;
    }

    async run(org_id=8227, offset=0, limit=10, search=null) {

        const url = this.getUrl()

        const params = {
            params: {
                organization_id: org_id,
                offset: offset,
                limit: limit,
                search: search
            }
        }

        try {
            const response = await httpClient.get(url, params);
            return this.deserialize(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }

    }

    deserialize(data) {

        const serializer = new TrainingCategorySerializer()
        const services = serializer.deserializeList(data)

        return services
    }

}
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Details from "./components/details/Details";
import {
    AppBar,
    Button,
    Dialog,
    IconButton,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import useAppStore from "../../../../appStore";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import CaseReadOnly from "./components/details/CaseReadOnly";
import { CasesService } from "../../../../shared/services/cases/casesService";
import CommentsSection from "../../../../shared/components/comments/CommentsSection";


const EditCase = (props) => {

    const {
        caseToEdit,
        setCaseToEdit,
        editDefault,
        open,
        onActionClicked,
    } = props

    const {loggedInUser, selectedLocation} = useAppStore();
    const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

    const [error, setError] = useState('');
    const [isEditing, setIsEditing] = useState(editDefault);
    const [newComments, setNewComments] = useState<any>([])
    const type = 'cases'
    const item = caseToEdit;

    useEffect(() => {
        const fetchDetailCase = async () => {
            try {
                const service = new CasesService();
                const detailCase = await service.getDetailCase(caseToEdit.id);
                setCaseToEdit(detailCase);
            } catch (error) {
                console.error('Failed to fetch employee:', error);
            } finally {

            }
        }

        if (caseToEdit.id === null || caseToEdit.id === 0) {
            setCaseToEdit(prevWorkItem => ({
                ...prevWorkItem,
                created_by: loggedInUser.employee,
                location: {
                    ...prevWorkItem.location,
                    id: selectedLocation.id || loggedInUser?.employee?.works_at[0]?.location?.id || 0
                },
                provider: {
                    ...prevWorkItem.provider,
                    id: loggedInUser.organization?.id || 0
                }
            }));

        } else {
            fetchDetailCase().then();
        }

    }, [caseToEdit.id]);


    const handleAction = async (action) => {
        caseToEdit.action.name = action;
        try {
            const service = new CasesService();
            const isCreating = caseToEdit.id === 0;
            if (isCreating) {
                caseToEdit.status = 'Working';
            }
            const response = isCreating
              ? await service.createCase(caseToEdit)
              : await service.updateCase(caseToEdit);

            setNewComments([]);
            onActionClicked('close')
        } catch (e) {
            console.error('Error in handleSubmit:', e);
            setError(`Failed to update case: ${e instanceof Error ? e.message : JSON.stringify(e)}`);
        }
    };


    const renderCard = (Component, props = {}) => (
      <Card style={{boxShadow: '0 1px 2px rgba(0,0,0,0.1)', margin: '5px', marginBottom: '10px', borderRadius: '0px'}}>
          <Card.Body>
              <Component {...props} />
          </Card.Body>
      </Card>
    );


    if (error) {
        return <CustomError error={error} />;
    }

    return (
      <Dialog fullScreen open={open} onClose={() => onActionClicked('close')} TransitionComponent={ModalTransition} PaperProps={{ style: { overflowX: 'hidden', backgroundColor: '#e9e9e9' } }}>
          {/* AppBar and Toolbar */}
          <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
                      <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      {caseToEdit.id === 0 ? 'New Case' : ''}
                  </Typography>
                  {!isEditing &&
                    <Button
                      color="inherit"
                      variant="contained"
                      onClick={() => setIsEditing(true)}
                      sx={{ ml: 'auto', color: '#444444' }}
                    >
                        {"Edit"}
                    </Button>
                  }
              </Toolbar>
          </AppBar>

          {/* Main Content */}
          <Row className="edit-case-page">
              {isMobile ? (
                <Col xs={12}>
                    {isEditing ? (
                        renderCard(Details, { caseToEdit, setCaseToEdit, handleAction })
                        ) : (
                        renderCard(CaseReadOnly, { caseToEdit })
                        )}
                    {caseToEdit.id !== 0 &&
                      renderCard(CommentsSection, { item, newComments, setNewComments, type})
                    }
                </Col>
              ) : (
                <>
                    <Col xs={12}>
                        {isEditing ? (
                          renderCard(Details, { caseToEdit, setCaseToEdit, handleAction })
                        ) : (
                          renderCard(CaseReadOnly, { caseToEdit })
                        )}
                    </Col>
                    {caseToEdit.id !== 0 &&
                      renderCard(CommentsSection, { item, newComments, setNewComments, type})
                    }
                </>
              )}
          </Row>

          {error && <CustomError error={error} />}
      </Dialog>
    );

}

export default EditCase

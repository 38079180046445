import { InspectionSerializer } from "../../../models/serializers/inspectionSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";


export class CommandUpdateInspection {

    INSPECTIONS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl(inspectionId) {

        return `${this.INSPECTIONS_SERVICE}/inspections/${inspectionId}/`;
    }

    async run(inspection) {

        const url = this.getUrl(inspection.id)

        const tracer = useTracer.getState()
        tracer.trace('Inspections', 'Api', `GET ${url}`)

        try {

            const data = InspectionSerializer.serialize(inspection, ['inspected_entities'])
            const response = await httpClient.put(url, data);

            return this.deserialize(response.data);
        } catch (error) {
            tracer.trace('Inspections', 'Error', `${error}`)
            console.error('Error:', error);
            throw error;
        }finally {
            tracer.trace('Inspections', 'Api', 'Success')
        }

    }

    deserialize(data) {

        const serializer = new InspectionSerializer()
        const item = serializer.deserialize(data)

        return item
    }

}
import { create } from 'zustand'
import {LocationModel} from '../app/shared/models/location.model'
import { LoggedInUser } from './shared/models/loggedInUser.model';
import { WhiteLabelProps } from './shared/models/whiteLabelProps.model';
import { AppImages } from "./shared/models/appImages.model";

interface AppStore {
  loggedInUser: LoggedInUser;
  selectedLocation: LocationModel;
  setLoggedInUser: (user: LoggedInUser | undefined) => void;
  setSelectedLocation: (location: LocationModel | undefined) => void;
  whiteLabelProps: WhiteLabelProps;
  appImages: AppImages;
}

const useAppStore = create<AppStore>((set) => ({
  loggedInUser: new LoggedInUser(),
  selectedLocation: new LocationModel(),
  setLoggedInUser: (loggedInUser) => set({ loggedInUser }),
  setSelectedLocation: (selectedLocation) => set({ selectedLocation }),
  whiteLabelProps: new WhiteLabelProps(),
  appImages: new AppImages(),
}));

export default useAppStore
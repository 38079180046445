import React, { useState } from "react";
import { Box, Drawer, IconButton, Tab, Tabs } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import PartForm from "./components/PartForm";
import TaskForm from "./components/TaskForm";
import SubtaskForm from "./components/SubtaskForm";
import InsightsFeed from "./components/InsightsFeed";

const drawerWidth = '45%';
const EditProjectDrawer = ({ selectedItem, isDrawerOpen, handleCloseDrawer }) => {

  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderContentBasedOnType = () => {
    if (selectedItem) {
      switch (selectedItem.type) {
        case 'part':
          return <PartForm selectedItem={selectedItem} />;
        case 'task':
          return <TaskForm selectedItem={selectedItem} />;
        case 'subtask':
          return <SubtaskForm selectedItem={selectedItem} />;
        default:
          return null;
      }
    }
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={isDrawerOpen}
      onClose={handleCloseDrawer}
      sx={{
        width: drawerWidth,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          marginTop: '68px',
          marginRight: '8px',
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 68px)', // Adjust for the height of the AppBar or any other elements above
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <IconButton onClick={handleCloseDrawer} sx={{ position: 'absolute', right: 8, top: 8 }}>
        <CloseIcon />
      </IconButton>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="project item tabs">
          <Tab label="DETAILS" />
          <Tab label="INSIGHTS" />
        </Tabs>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        {activeTab === 0 && selectedItem && renderContentBasedOnType()}
        {activeTab === 1 && <InsightsFeed />}
      </Box>
    </Drawer>
  );
};

export default EditProjectDrawer;


import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { WorkItemSerializer } from "../../../models/serializers/workItemSerializer";
import { CaseSerializer } from "../../../models/serializers/caseSerializer";


export class CommandCreateCase {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/cases/`;
  }

  async run(item) {

    const url = this.getUrl()

    const tracer = useTracer.getState()
    tracer.trace('Cases', 'Api', `POST ${url}`)

    try {

      let data = CaseSerializer.serialize(item);
      const response = await httpClient.post(url, data);

      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Cases', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    }finally {
      tracer.trace('Cases', 'Api', 'Success')
    }

  }

  deserialize(data) {
    const serializer = new CaseSerializer()
    const item = serializer.deserialize(data)
    return item
  }

}
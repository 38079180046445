import React from 'react';
import { Box, Typography, TextField, Button } from "@mui/material";

const InsightsFeed = () => {

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ ml:1, mb: 2, mt: 2 }}>
        Insights Feed
      </Typography>

      <TextField
        fullWidth
        multiline
        placeholder="Share your insights..."
        minRows={3}
        margin="normal"
        variant="outlined"
        // onChange={} // Implement
      />
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        // onClick={} // Implement
      >
        Post
      </Button>
    </Box>
  );
};

export default InsightsFeed;

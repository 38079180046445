import httpClient from "../../../utils/http-clients/djangoHttpClient";

export class CommandGetCharts {

    SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {

        return  `${this.SERVICE}/analytics/`;
    }


    async run(provider_id: number, location_id: number, module_name: string, charts: string[], startDate: string | null, endDate: string | null) {
        const url = this.getUrl();

        const params: {
            params: {
                provider: number;
                location?: number;
                module: string;
                charts: string[];
                from_date: string | null;
                to_date: string | null;
            };
        } = {
            params: {
                provider: provider_id,
                module: module_name,
                charts: charts,
                from_date: startDate,
                to_date: endDate
            }
        };

        if (location_id !== 0) {
            params.params.location = location_id;
        }

        try {
            const response = await httpClient.get(url, params);
            return this.deserialize(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    }

    deserialize(data) {
        return data
    }

}
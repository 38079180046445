import React, { useEffect, useState } from 'react';
import useAppStore from '../../../../../../appStore';
import DualBarChartComponent from "./components/DualBarChartComponent";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";
import { fetchScoresOverallChart } from "../../../../../../shared/utils/data-fetch/analyticDataFetchHelper";

type ChartHeaderRow = [string, string, string];
type ChartDataRow = [string, number, number];
type ChartDataState = Array<ChartHeaderRow | ChartDataRow>;

const ScoresByLocation = ({startDate=null, endDate=null}) => {
  const { whiteLabelProps, loggedInUser } = useAppStore();
  const [chartData, setChartData] = useState<ChartDataState>([['Location', 'Average', 'Joint Average']]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError('');
      try {
        const data = await fetchScoresOverallChart(loggedInUser.organization.id, startDate, endDate);
        setChartData(data);
      } catch (error) {
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [loggedInUser.organization.id, startDate, endDate]);

  if (isLoading) {
    return <CustomLoadingDots />
  }

  return (
    <DualBarChartComponent
      chartData={chartData}
      chartTitle="Overall Location Averages"
      primaryColor={whiteLabelProps.primary_color}
      secondaryColor={whiteLabelProps.primary_color_light}
      max={5}
      sortOrder={'title'}
    />
  );
};

export default ScoresByLocation;


import { ServiceItem } from "./serviceItem.model";
import { Service } from "./service.model";
import { FrequencyModel } from "./frequency.model";

export class ServicePlanItem {

  id: number;
  service_item: ServiceItem;
  service: Service;
  frequency: FrequencyModel;

  constructor() {
    this.id = 0;
    this.service_item = new ServiceItem();
    this.service = new Service();
    this.frequency = new FrequencyModel();
  }

}

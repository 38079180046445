import React from "react";
import { Avatar } from "@mui/material";
import useAppStore from "../../../../../appStore";
import { formatPhoneNumber } from "../../../../../shared/utils/data-transform/dataUtil";
import ActionButton from "../../../../../shared/components/buttons/ActionButton";
import HowToRegIcon from '@mui/icons-material/HowToReg';

const EmployeeRow = (props) => {

    const {
        employee,
        onEditEmployeeClicked,
        onDeleteEmployeeClicked,
        children,
        value,
        index,
        canEdit,
        isMobileView,
        ...other } = props;

    const {whiteLabelProps} = useAppStore();

    return (
      <tr>
          {isMobileView ? (
            <>
                <td>
                    <div className="d-flex flex-column align-items-start justify-content-start">
                        <div className="d-flex justify-content-start flex-column">
                            <a className="text-gray-800 fw-bold fs-6">
                                {employee.person.first_name} {employee.person.last_name}
                            </a>
                            <span
                              className="text-gray-500 d-block fs-6 mb-1">{employee.works_at[0]?.location.name}</span>
                        </div>
                        <div className="d-flex text-gray-500 align-items-center">
                            {formatPhoneNumber(employee.person.phone_number)}
                        </div>
                    </div>
                </td>
                <td>
                    {employee.user.is_active ? (
                      <HowToRegIcon sx={{ color: "#15aa78", fontSize: "2.0rem" }} />
                    ) : (
                      <HowToRegIcon sx={{ color: "#e2e2e2", fontSize: "2.0rem" }} />
                    )}
                </td>

                {/* ACTIONS */}
                {canEdit && (
                  <td>
                      <div className="d-flex align-items-end justify-content-end">
                          <ActionButton iconType="pencil" onClick={(e) => {
                              e.preventDefault();
                              onEditEmployeeClicked(employee);
                          }} />
                          <ActionButton iconType="trash" onClick={(e) => {
                              e.preventDefault();
                              onDeleteEmployeeClicked(employee);
                          }} />
                      </div>
                  </td>
                )}
            </>
          ) : (
            // Original row content for larger screens
            <>
                <td>
                    <div className="d-flex align-items-center">
                        <div className="me-3">
                            <Avatar sx={{ bgcolor: whiteLabelProps.primary_color_light, width: 50, height: 50 }}
                                    variant="rounded"
                                    src={employee.person.photo_url}
                                    alt={`${employee.person.first_name} ${employee.person.last_name}`}
                            />
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                            <a className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                                {employee.person.first_name} {employee.person.last_name}
                            </a>
                            <span className="text-gray-400 fw-semibold d-block fs-7">{employee.works_at[0]?.location.name}</span>
                        </div>
                    </div>
                </td>

                {/* PHONE */}
                <td>
                    <div className="d-flex align-items-center">
                        { formatPhoneNumber(employee.person.phone_number) }
                    </div>
                </td>

                {/* EMAIL */}
                <td className="text-start">
                    <div className="d-flex align-items-center">
                        {employee.person.email}
                    </div>
                </td>

                {/* ACTIONS */}
                {canEdit && (
                  <td>
                      <div className="d-flex justify-content-center flex-shrink-0">
                          <ActionButton iconType="pencil" onClick={(e) => {
                              e.preventDefault();
                              onEditEmployeeClicked(employee);
                          }} />
                          <ActionButton iconType="trash" onClick={(e) => {
                              e.preventDefault();
                              onDeleteEmployeeClicked(employee);
                          }} />
                      </div>
                  </td>
                )}
            </>
          )}
      </tr>
    )
}

export default EmployeeRow


import { Organization } from "../organization.model";
import { Position } from "../position.model";
import { BaseSerializer } from "./baseSerializer";

export class PositionSerializer extends BaseSerializer {


    public deserialize( data ): any {

        const position = new Position();

        this._copyAttributes(position, data);

        return position;

    }

}
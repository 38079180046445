import { BaseSerializer } from "./baseSerializer";
import { WorkItem } from "../workItem.model";

export class WorkItemSerializer extends BaseSerializer {
  public deserialize(data): any {
    let work_item = new WorkItem();
    this._copyAttributes(work_item, data);

    if (!work_item.title) {
      work_item.title = `Work Item #${work_item.id || ''}`;
    }

    return work_item;
  }
}

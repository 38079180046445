
export class FrequencyModel {

  id: number;
  times_per_period: number;
  repeat_period: string;
  is_active: boolean;

  constructor() {
    this.id = 0;
    this.times_per_period = 1;
    this.repeat_period = 'Month';
    this.is_active = true;
  }

}
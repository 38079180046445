import { useRef, useState } from "react";
import useInspectionsStore from '../../../../inspectionsStore';
import useAppStore from '../../../../../../appStore';
import useTracer from '../../../../../../shared/framework/tracer/tracer';
import { WorkItem } from '../../../../../../shared/models/workItem.model';
import { useAuth } from "../../../../../auth";
import { InspectionService } from "../../../../../../shared/services/inspections/inspectionService";
import { WorkItemsService } from "../../../../../../shared/services/work-items/workItemsService";
import { useWorkItem } from "../../../../../work-items/context/workItemContext";

export interface ValidationResult {
  passed: boolean;
  messages: string[];
}

export function useEditInspectedEntity(onActionClicked) {
  const { currentUser } = useAuth();
  const { setWorkItemToEdit } = useWorkItem();
  const { selectedLocation, loggedInUser } = useAppStore();
  const scrollableContainerRef = useRef<any>();
  const { inspection, inspectedEntity, updateInspectedEntity, setInspection, newInspectedEntity } = useInspectionsStore();
  const tracer = useTracer();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [buttonAction, setButtonAction] = useState('save');
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true)
  const [isNextDisabled, setIsNextDisabled] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [canCreateWorkItem, setCanCreateWorkItem] = useState(loggedInUser.permissions.hasAccess('WorkItems'))
  const [activeStep, setActiveStep] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [workItemOpen, setWorkItemOpen] = useState(false);
  const [prefill, setPrefill] = useState<WorkItem | null>(null);
  const [workItem, setWorkItem] = useState<WorkItem | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [needsWorkItem, setNeedsWorkItem] = useState(false);
  const [validationInfo, setValidationInfo] = useState<ValidationResult>({passed: true, messages: []})

  let biRef = {
    selectionPage: {
      validate: (): ValidationResult => {
        return {passed: true, messages: []}
      }
    },
    scorePage: {
      validate: (): ValidationResult => {
        return {passed: true, messages: []}
      }
    }
  }
  const handleNextClicked = () => {
    if (activeStep === 0 ){
      let valInfo = biRef.selectionPage.validate()
      setValidationInfo(valInfo)
      if (!valInfo.passed) return
    }
    goToStep(activeStep + 1)
  }

  const handlePrevClicked = () => {
    goToStep(activeStep - 1)
  }

  const goToStep = (index) => {

    if( index < 0) index = 0;
    if( index > 1) index = 1;

    setActiveStep(index)
    disableButtons(index)

    if ( scrollableContainerRef.current !== null ){
      scrollableContainerRef.current.scrollTop = 0;
    }
  }

  const disableButtons = (index) => {

    if( index === 0 ){
      setIsSubmitDisabled(true)
      setIsPrevDisabled(true)
      setIsNextDisabled(false)
    }

    if( index === 1){
      setIsSubmitDisabled(false)
      setIsPrevDisabled(false)
      setIsNextDisabled(true)
    }

  }

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    setButtonAction(action);
    handleMenuClose();
  };

  const handleClose = () => {
    goToStep(0)
    validationInfo.passed = true
    validationInfo.messages = []
    setValidationInfo(validationInfo)
    onActionClicked('close')
  };

  const handleOnIndexChanged = (index, indexLatest, meta) => {
    goToStep(index)
  }

  const handleSubmitClicked = async (addWorkItem: boolean) => {

    const validatePageAndSetLoading = () => {
      const valInfo = biRef.scorePage.validate();
      setValidationInfo(valInfo);
      if (!valInfo.passed) return false;
      setIsLoading(true);
      return true;
    };

    const finalize = () => {
      setIsLoading(false);
      setActiveStep(1);
      if (!addWorkItem) {
        handlePrevClicked()
      }
    };

    if (!validatePageAndSetLoading()) return;

    tracer.trace('Inspections', 'Trace', 'Creating Inspected Entity');

    try {
      inspection.provider.id = loggedInUser.organization.id;
      inspection.location.id = selectedLocation ? selectedLocation.id : 0;
      inspection.inspected_by.id = loggedInUser ? loggedInUser.employee.id : 0;

      const service = new InspectionService();
      let inspectedEntityId;

      if (!inspection.id) {
        const newInspection = await service.createInspection(inspection);
        setInspection(newInspection);
        updateInspectedEntity(newInspection.inspected_entities[0]);
        inspectedEntityId = newInspection.inspected_entities[0].id;
      } else {
        const entity = inspectedEntity.id ? await service.updateInspectedEntity(inspection, inspectedEntity) : await service.createInspectedEntity(inspection, inspectedEntity);
        updateInspectedEntity(entity);
        inspectedEntityId = entity.id;
        if (!addWorkItem && isEditing)
          handleClose();

      }

      if (addWorkItem) handleAddWorkItem(inspectedEntityId);
      else newInspectedEntity();

    } catch (error) {
      if (error instanceof Error) setError(error.message);
    } finally {
      finalize();
    }
  };




  function handleAddWorkItem(ie_id) {

    setWorkItemToEdit(new WorkItem())

    const getWorkItemPrefill = async () => {
      setIsLoading(true);

      try {
        const service = new WorkItemsService();
        const prefilledWorkItem = service.getPrefill(ie_id);
        setPrefill(await prefilledWorkItem);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    }

    getWorkItemPrefill().then()

    const workItem = new WorkItem();

    Object.assign(workItem, {
      where_and_what: prefill?.where_and_what,
      description: "Created from inspection on " + inspection.create_date
    });

    setWorkItem(workItem);
    setWorkItemOpen(true); 

  }


  const handleWorkItemAction = (action) => {
    setWorkItemOpen(false);
    setWorkItem(null);
    setPrefill(null);

    if (action === "save") {
      setSnackbarOpen(true);

      setTimeout(() => {
        setSnackbarOpen(false);
      }, 3500);
    }
  };

  return {
    // States
    loggedInUser,
    scrollableContainerRef,
    biRef,
    isLoading,
    error,
    activeStep,
    isEditing,
    workItem,
    snackbarOpen,
    validationInfo,
    buttonAction,
    isMenuOpen,
    isPrevDisabled,
    isNextDisabled,
    isSubmitDisabled,
    canCreateWorkItem,
    workItemOpen,
    currentUser,
    selectedLocation,
    inspection,
    inspectedEntity,
    tracer,
    menuAnchorEl,
    needsWorkItem,
    prefill,
    // Functions
    setIsLoading,
    setError,
    setActiveStep,
    setIsEditing,
    setWorkItem,
    setSnackbarOpen,
    setValidationInfo,
    setButtonAction,
    setMenuAnchorEl,
    setIsNextDisabled,
    setIsPrevDisabled,
    setIsSubmitDisabled,
    setCanCreateWorkItem,
    setWorkItemOpen,
    updateInspectedEntity,
    setInspection,
    setNeedsWorkItem,
    setPrefill,
    newInspectedEntity,
    goToStep,
    disableButtons,
    // Handlers
    handleNextClicked,
    handlePrevClicked,
    handleMenuClick,
    handleMenuClose,
    handleMenuItemClick,
    handleClose,
    handleOnIndexChanged,
    handleSubmitClicked,
    handleWorkItemAction,
  };
}

import { BaseSerializer } from "./baseSerializer";
import { LocationContractsModel } from "../locationContracts.model";

export class LocationContractsSerializer extends BaseSerializer {


    public deserialize( data ): any {

        const contractData = new LocationContractsModel();

        this._copyAttributes(contractData, data);

        return contractData;

    }

}
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";

export class CommandLinkEntityType {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/entity_types/locations/`;
  }

  async run(entity_type, location_id) {

    const url = this.getUrl()

    const tracer = useTracer.getState()
    tracer.trace('Equipment', 'Api', `POST ${url}`)

    try {
      let data = {
        "entity_type": {
          "id": entity_type.id
        },
        "location": {
          "id": location_id
        }
      }

      const response = await httpClient.post(url, data);
      return response.data;
    } catch (error) {
      tracer.trace('EntityType', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    } finally {
      tracer.trace('EntityType', 'Api', 'Success')
    }

  }

}

import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { SupplySerializer } from "../../../models/serializers/supplySerializer";


export class CommandCreateSupply {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/supplies/`;
  }

  async run(supply) {

    const url = this.getUrl()

    const tracer = useTracer.getState()
    tracer.trace('Supply', 'Api', `POST ${url}`)

    try {

      let data = SupplySerializer.serialize(supply)
      const response = await httpClient.post(url, data);

      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Supply', 'Error', `${error}`)
      console.error('Error:', error);
      throw error;
    }finally {
      tracer.trace('Supply', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new SupplySerializer()
    const item = serializer.deserialize(data)

    return item
  }

}
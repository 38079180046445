import { Media } from "./media.model";
import { LocationContractsModel } from "./locationContracts.model";


export class LocationModel {

    id: number;
    uuid: string;
    name: string;
    description: string;
    media: Media[];
    contracts: LocationContractsModel;
    is_qr_scanning_enabled: boolean;

    constructor() {
        this.id = 0;
        this.uuid = '';
        this.name = '';
        this.description = '';
        this.media = [];
        this.contracts = new LocationContractsModel();
        this.is_qr_scanning_enabled = false;

    }

}


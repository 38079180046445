import { CommandGetLocations } from "./commands/cmdGetLocations";
import { CommandGetLocationInfo } from "./commands/cmdGetLocationInfo";
import { CommandUpdateLocationInfo } from "./commands/cmdUpdateLocationInfo";
import { CommandGetLocationContracts } from "./commands/cmdGetLocationContracts";
import { CommandUpdateLocationContracts } from "./commands/cmdUpdateLocationContracts";


export class LocationService {

  async getLocations(provider_id) {
    const cmd = new CommandGetLocations()
    const results = cmd.run(provider_id)
    return results
  }

  async getContracts(location_id) {
    const cmd = new CommandGetLocationContracts()
    const results = cmd.run(location_id)
    return results
  }

  async updateContracts(contracts, location) {
    const cmd = new CommandUpdateLocationContracts()
    const results = cmd.run(contracts, location)
    return results
  }

  async getLocationInfo(location_id) {
    const cmd = new CommandGetLocationInfo()
    const result = cmd.run(location_id)
    return result
  }

  async updateLocationInfo(location) {
    const cmd = new CommandUpdateLocationInfo()
    const result = cmd.run(location)
    return result
  }

}
import { Button } from "@mui/material";
import useAppStore from "../../../../appStore";

const SubHeader = (props) => {

  const {
    title,
    subtitle = null,
    actionButtonName = '',
    onActionClicked = null,
    showAction = true,
    color = '#444',
    variant="contained"
  } = props;


  return (
    <>
      <div className="row mb-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div className="col" style={{ flex: 1 }}>
          <h3 style={{ color: color, margin: 0 }}>{title}</h3>
          {subtitle && (
            <p style={{ color: '#777', fontSize: 'smaller' }}>{subtitle}</p>
          )}
        </div>
        {showAction && (
          <div className="col text-end" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant={variant}
              onClick={onActionClicked}
              sx={{
                maxHeight: '30px',
                color: variant === 'contained' ? 'white' : '#15aa78'
              }}
            >
              {actionButtonName}
            </Button>
          </div>
        )}
      </div>
    </>
  );

}

export default SubHeader;


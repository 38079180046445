import React from "react";
import './styles/where_and_what.css';
import CustomLoadingDots from "../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { useEntityFetch } from "../../../../../../../shared/custom-hooks/where-and-what/useEntityFetch";
import { ServiceItem } from "../../../../../../../shared/models/serviceItem.model";

const WhatPage = ({state, setState}) => {

  const { relevantServices, isLoading } = useEntityFetch(state.spaceType.id, state.floor.id, 1);

  const uniqueServices = relevantServices.reduce<ServiceItem[]>((acc, current) => {
    if (!acc.some(service => service.id === current.id)) {
      acc.push(current);
    }
    return acc;
  }, []);

  const handleServiceItemChange = (checked, item) => {
    if (checked) {
      setState(prev => ({ ...prev, servicesToPerform: [...state.servicesToPerform, item] }));
    } else {
      setState(prev => ({ ...prev, servicesToPerform: state.servicesToPerform.filter(si => si.id !== item.id) }));
    }
  };

  return (
    <div className="what-page-container">
      <h1>What needs work?</h1>
      <ul className="services-list">
        {uniqueServices.map((item) => (
          <li key={item.id} className="service-item">
            <label className="service-label">
              <input
                type="checkbox"
                className="service-checkbox"
                checked={state.servicesToPerform.some(si => si.id === item.id)}
                onChange={(e) => handleServiceItemChange(e.target.checked, item)}
              />
              <span className="custom-checkbox"></span>
              {item.entity_type.name}
            </label>
          </li>
        ))}
      </ul>
      {isLoading && <CustomLoadingDots />}
    </div>
  );
};

export default WhatPage;

import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SubHeader from "../../../../../../../shared/components/page/page-header/SubHeader";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import useAppStore from "../../../../../../../appStore";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/helpers";

const AverageScoreCard = ({ value, count, module, height = 120 }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const scorePercentage = (value / 5) * 100;

  return (
    <Card variant="outlined" sx={{ height: isMobile ? '120px' : height }}>
      <CardContent sx={{ padding: '16px', display: 'flex', alignItems: 'center', height: '100%' }}>
        {!isMobile && (
          <Box sx={{ position: 'relative', display: 'inline-flex', marginRight: '20px' }}>
            <CircularProgress
              variant="determinate"
              value={100}
              size={80}
              thickness={5}
              sx={{ color: '#e0e0e0' }}
            />
            <CircularProgress
              variant="determinate"
              value={scorePercentage}
              size={80}
              thickness={5}
              sx={{ color: theme.palette.primary.main, position: 'absolute', left: 0 }}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={toAbsoluteUrl('/white-labeling/verde/media/logos/thumbsUp2.png')} style={{width: '36px', height: '38px'}} />
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <SubHeader title={'Average Score'} showAction={false} />
          <Typography variant="h6">{value}</Typography>
          {module === "Inspections" && (
            <>
              <Typography variant="body2" color="textSecondary" component="p" sx={{ marginBottom: 0 }}>
                last 30 days
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <strong>{count}</strong> inspected spaces
              </Typography>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default AverageScoreCard;
